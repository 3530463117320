import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ActiveKeeperService } from 'prosumer-app/services/active-keeper';
import { ProjectFacadeService } from '../state';

@Injectable()
export class ProjectResolverService {
  constructor(
    private readonly projectFacade: ProjectFacadeService,
    private readonly keeper: ActiveKeeperService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): void {
    this.keeper.setActive({ project: this.extractProjectId(route) });
    this.projectFacade.get(this.extractProjectId(route));
  }

  private extractProjectId(from: ActivatedRouteSnapshot): string {
    return from.paramMap.get('projectId');
  }
}
