import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Utils } from 'prosumer-app/core/utils';
import { ScenarioGenericStore } from 'prosumer-app/stores/scenario-generic';

@Injectable({
  providedIn: 'root',
})
export class ScenarioGenericResolver {
  constructor(private scenarios: ScenarioGenericStore) {}

  resolve(route: ActivatedRouteSnapshot): void {
    this.scenarios.setActive(this.extractScenarioId(route));
  }

  private extractScenarioId(route: ActivatedRouteSnapshot): string {
    return Utils.resolveToEmptyObject(route.params).scenarioId;
  }
}
