<div fxLayout="column" fxLayoutGap="30px" class="co2-emissions-card">
  <div fxLayout="row" fxLayoutGap="20px">
    <!-- Filter-chip input per filter type -->
    <prosumer-filter-chips
      *ngFor="let filterMeta of filterMetaData"
      fxFlex="32%"
      [filterTitle]="filterMeta.filterName"
      [filterBy]="filterMeta.filterKey"
      [dataSource]="filterMeta.filteredData"
      (selectedDataSource)="onUpdateFilter($event, filterMeta.filterName)"
    >
    </prosumer-filter-chips>
  </div>
  <div fxLayout="row" fxLayoutGap="20px">
    <div fxLayoutGap="20px" class="prosumer-co2emission-types">
      <!-- Buttons -->
      <mat-button-toggle-group
        #group="matButtonToggleGroup"
        name="fontStyle"
        aria-label="Visualization Options"
        [value]="visualized$ | async"
        vertical="true"
        fxLayout="row"
        fxLayout.gt-xs="column"
      >
        <mat-button-toggle
          *ngFor="let option of visualizationOptions$ | async"
          [value]="option"
          (change)="onVisualizationTypeChange($event)"
        >
          {{ option }}
          <prosumer-tooltip-anchor
            *ngIf="option === 'Asset Type'"
            [message]="'TBD'"
          ></prosumer-tooltip-anchor>
          <prosumer-tooltip-anchor
            *ngIf="option === 'Scope'"
            [message]="'TBD'"
          ></prosumer-tooltip-anchor>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div [ngSwitch]="visualized$ | async" fxLayoutGap="20px">
      <!-- Stacked bar graph for asset type visualization -->
      <ng-container *ngSwitchCase="'Asset Type'">
        <prosumer-asset-type-visualization></prosumer-asset-type-visualization>
      </ng-container>
      <!-- Stacked bar graph for scope visualization -->
      <ng-container *ngSwitchCase="'Scope'">
        <prosumer-scope-type-visualization></prosumer-scope-type-visualization>
      </ng-container>
    </div>
  </div>
</div>
