import { Directive } from '@angular/core';
import { DispatchAdapter } from './dispatch.adapter';
import { NgxChartUtils } from 'prosumer-shared/modules/charts/utils';
import { StackedAreaData } from 'prosumer-shared/modules/chartjs/stacked-area-chartjs';
import { VisualizerData } from '@prosumer/results/components/results-visualizer';

@Directive()
export abstract class TraditionalDispatchAdapter extends DispatchAdapter<
  VisualizerData[],
  StackedAreaData
> {
  abstract formatTick(tick: string): string;

  mapToChartJSChart(from: VisualizerData[]): StackedAreaData {
    return NgxChartUtils.mapStackedAreaToChartJSs(
      this.filterOutZeroValues(from),
    );
  }
  formatTicks(chartJSData: StackedAreaData): StackedAreaData {
    return {
      ...chartJSData,
      xAxisTicks: chartJSData.xAxisTicks.map((tick) => this.formatTick(tick)),
    };
  }

  filterOutZeroValues(data: VisualizerData[]): VisualizerData[] {
    // test if all elements in series array are falsy, then invert to filter them out
    return data.filter(
      (datum) => !(datum.series || []).every((siri) => !!!siri.value),
    );
  }

  getLegendNames(data: VisualizerData[]): string[] {
    if (!!!data) {
      return [];
    }
    return data.map((d) => d.name);
  }

  injectAxisNames(data: StackedAreaData): StackedAreaData {
    return { ...data, xAxisLabel: 'Hours', yAxisLabel: 'kW' };
  }
}
