import { BaseComponent } from 'prosumer-app/libs/eyes-shared';
import { ResultStore } from 'prosumer-app/stores';
import { BehaviorSubject, of } from 'rxjs';

import { Component, OnInit } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

import { EquipmentService } from './equipment.service';
import { equipmentMockData, scenarioMockData } from './mock-equipment-data';

@Component({
  selector: 'prosumer-equipment',
  templateUrl: './equipment.component.html',
  styleUrls: ['./equipment.component.scss'],
  providers: [EquipmentService],
})
export class EquipmentComponent extends BaseComponent implements OnInit {
  data: Array<any> = [];
  chartData$ = new BehaviorSubject<Array<any>>([]);
  selectedChartTitle = '';
  selectedChartYAxisLabel = '';
  filterMetaData: Array<{ filterKey; filterName; filteredData }>;
  visualOptions$ = of([
    'Size (kW)',
    'Size (kWh)',
    'CAPEX (k€)',
    'OPEX (k€)',
    'TOTEX (k€)',
    'Consumption (kWh)',
    'Production (kWh)',
  ]);
  selectedVisualOption$ = new BehaviorSubject<string>('Size (kW)');
  isCumulativeGraphSelected$ = new BehaviorSubject<boolean>(false);
  isOptimizedYearsSelected$ = new BehaviorSubject<boolean>(false);
  selectedEquipments: any[];
  years: any[];
  optimizedYears: Array<any> = [];
  allYears: Array<any> = [];

  constructor(
    private resultStore: ResultStore,
    public _equipmentService: EquipmentService,
  ) {
    super();
  }

  ngOnInit(): void {
    /* to be uncommented when fetch data format from core is correct, for now we are mocking data*/
    // this.addSubscription(
    //   this.resultStore.derInvestments$.subscribe((results) => {
    // this.data = results.map((energy) => {
    //   const updatedEnergy = { ...energy };
    //   if (!energy.input_fluid) {
    //     updatedEnergy.input_fluid = updatedEnergy.output_fluid;
    //   }

    //   return updatedEnergy;
    // });

    this.data = equipmentMockData;
    if (
      !!this.data &&
      this.data.length > 0 &&
      this.data[0].size_kw.length > 0
    ) {
      this.allYears = this.data[0].size_kw.map((data) => data.name);
    }
    this.optimizedYears = scenarioMockData.time.yearsToOptimize.map((year) =>
      year.toString(),
    );
    this.years = this.allYears;
    this.selectedEquipments = this.data;
    this.chartData$.subscribe((data) => {
      this._equipmentService.setChartDataAndChartInfo(
        data,
        this.selectedChartTitle,
        this.selectedChartYAxisLabel,
      );
    });
    //   }),
    // );

    this.initializeFilterMetaData();
    this.updateChartData(this.selectedVisualOption$.value);
  }

  initializeFilterMetaData(): void {
    this.filterMetaData = [
      { filterKey: 'node', filterName: 'Node', filteredData: [...this.data] },
      {
        filterKey: 'type',
        filterName: 'DER Type',
        filteredData: [...this.data],
      },
      {
        filterKey: ['input_fluid'],
        filterName: 'Input Vector',
        filteredData: [...this.data],
      },
      {
        filterKey: ['output_fluid'],
        filterName: 'Output Vector',
        filteredData: [...this.data],
      },
    ];
  }

  /**
   * Method to handle when a filtered data has arrived from filter chips component
   *
   * @param eventEmitted - Filtered Data emitted by the filter chips component
   */
  updateDataByFilter(eventEmitted: any, metaEntry: any): void {
    metaEntry.filteredData =
      eventEmitted.length > 0 ? [...eventEmitted] : [...this.data];
    let updatedData = [...this.data];

    this.filterMetaData.forEach((filterMeta) => {
      updatedData = updatedData.filter((actualData) =>
        filterMeta.filteredData.includes(actualData),
      );
    });

    this.selectedEquipments = updatedData;
    this.updateChartData(this.selectedVisualOption$.value);
  }

  updateDataByVisualOption(selectedOption: MatButtonToggleChange) {
    // get selected visual option
    this.selectedVisualOption$.next(selectedOption.value);
    this.updateChartData(selectedOption.value);
  }

  // overall updater for chart data
  updateChartData(selectedOption: string) {
    if (!!!this.data || !!!this.years) {
      return;
    }
    switch (selectedOption) {
      case 'Size (kWh)':
        this.selectedChartTitle = 'Size';
        this.selectedChartYAxisLabel = 'kWh';
        this.mapDataBySizeKwh();
        break;
      case 'CAPEX (k€)':
        this.selectedChartTitle = 'CAPEX';
        this.selectedChartYAxisLabel = 'k€';
        this.mapDataByCapex();
        break;
      case 'OPEX (k€)':
        this.selectedChartTitle = 'OPEX';
        this.selectedChartYAxisLabel = 'k€';
        this.mapDataByOpex();
        break;
      case 'TOTEX (k€)':
        this.selectedChartTitle = 'TOTEX';
        this.selectedChartYAxisLabel = 'k€';
        this.mapDataByTotex();
        break;
      case 'Consumption (kWh)':
        this.selectedChartTitle = 'Consumption';
        this.selectedChartYAxisLabel = 'kWh';
        this.mapDataByConsumption();
        break;
      case 'Production (kWh)':
        this.selectedChartTitle = 'Production';
        this.selectedChartYAxisLabel = 'kWh';
        this.mapDataByProduction();
        break;
      default:
        // Size (kW)
        this.selectedChartTitle = 'Size';
        this.selectedChartYAxisLabel = 'kW';
        this.mapDataBySizeKw();
        break;
    }
  }

  mapDataBySizeKw() {
    const mappedData = this.years.map((data) => ({
      name: data,
      series: [],
    }));
    mappedData.forEach((data) => {
      this.selectedEquipments.forEach((equipment) => {
        const sizeValue = equipment.size_kw
          .filter((size) => size.name === data.name)
          .map((filteredSize) => filteredSize.value)[0];
        data.series.push({ name: equipment.name, value: sizeValue });
      });
    });
    this.chartData$.next(mappedData);
  }

  mapDataBySizeKwh() {
    const mappedData = this.years.map((data) => ({
      name: data,
      series: [],
    }));
    mappedData.forEach((data) => {
      this.selectedEquipments.forEach((equipment) => {
        const sizeValue = equipment.size_kwh
          .filter((size) => size.name === data.name)
          .map((filteredSize) => filteredSize.value)[0];
        data.series.push({ name: equipment.name, value: sizeValue });
      });
    });
    this.chartData$.next(mappedData);
  }

  mapDataByCapex() {
    const mappedData = this.years.map((data) => ({
      name: data,
      series: [],
    }));
    mappedData.forEach((data) => {
      this.selectedEquipments.forEach((equipment) => {
        const sizeValue = equipment.init_invest_cost
          .filter((size) => size.name === data.name)
          .map((filteredSize) => filteredSize.value)[0];
        data.series.push({ name: equipment.name, value: sizeValue });
      });
    });
    this.chartData$.next(mappedData);
  }

  mapDataByOpex() {
    const mappedData = this.years.map((data) => ({
      name: data,
      series: [],
    }));
    mappedData.forEach((data) => {
      this.selectedEquipments.forEach((equipment) => {
        const sizeValue = equipment.init_oper_cost
          .filter((size) => size.name === data.name)
          .map((filteredSize) => filteredSize.value)[0];
        data.series.push({ name: equipment.name, value: sizeValue });
      });
    });
    this.chartData$.next(mappedData);
  }

  mapDataByTotex() {
    const mappedData = this.years.map((data) => ({
      name: data,
      series: [],
    }));
    mappedData.forEach((data) => {
      this.selectedEquipments.forEach((equipment) => {
        const sizeValue = equipment.total_cost
          .filter((size) => size.name === data.name)
          .map((filteredSize) => filteredSize.value)[0];
        data.series.push({ name: equipment.name, value: sizeValue });
      });
    });
    this.chartData$.next(mappedData);
  }

  mapDataByConsumption() {
    const mappedData = this.years.map((data) => ({
      name: data,
      series: [],
    }));
    mappedData.forEach((data) => {
      this.selectedEquipments.forEach((equipment) => {
        const sizeValue = equipment.yearly_cons
          .filter((size) => size.name === data.name)
          .map((filteredSize) => filteredSize.value)[0];
        data.series.push({ name: equipment.name, value: sizeValue });
      });
    });
    this.chartData$.next(mappedData);
  }

  mapDataByProduction() {
    const mappedData = this.years.map((data) => ({
      name: data,
      series: [],
    }));
    mappedData.forEach((data) => {
      this.selectedEquipments.forEach((equipment) => {
        const sizeValue = equipment.yearly_prod
          .filter((size) => size.name === data.name)
          .map((filteredSize) => filteredSize.value)[0];
        data.series.push({ name: equipment.name, value: sizeValue });
      });
    });
    this.chartData$.next(mappedData);
  }

  onVisualizationTypeChange(selected: MatButtonToggleChange) {
    this.selectedVisualOption$.next(selected.value);
    this.updateChartData(selected.value);
  }

  onGraphTypeChange(selected: MatSlideToggleChange) {
    this.isCumulativeGraphSelected$.next(selected.checked);
  }

  onYearsTypeToShowChange(selected: MatSlideToggleChange) {
    this.isOptimizedYearsSelected$.next(selected.checked);
    this.years = this.isOptimizedYearsSelected$.value
      ? this.optimizedYears
      : this.allYears;
    this.updateChartData(this.selectedVisualOption$.value);
  }
}
