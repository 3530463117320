import { ActiveKeeperService } from 'prosumer-app/services/active-keeper';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
  FlowResult,
  SankeyResult,
} from '@prosumer/results/models/flow-results.model';

import {
  PerceptionMap,
  ResultsPerceptionService,
} from '../case-results-perception';
import { FlowResultsService } from './flow-results.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'prosumer-flow-results',
  templateUrl: './flow-results.component.html',
  styleUrls: ['./flow-results.component.scss'],
  providers: [FlowResultsService],
})
export class FlowResultsComponent implements OnInit {
  @Input() caseId: string;
  @Input() scenarioName: string;

  colors$: Observable<PerceptionMap>;
  result$: Observable<FlowResult>;
  years$: Observable<string[]>;
  yearOptions: Array<string>;
  yearSelectControl = new UntypedFormControl();
  selectedData$ = new BehaviorSubject<SankeyResult>(undefined);
  showEditor$ = new BehaviorSubject<boolean>(false);

  constructor(
    public results: FlowResultsService,
    private keeper: ActiveKeeperService,
    private perception: ResultsPerceptionService,
  ) {}

  ngOnInit(): void {
    this.results.getFlowOutput();
    this.subscribeToResult();
    this.subscribeToChanges();
  }

  onToggleEdit(): void {
    this.showEditor$.next(!this.showEditor$.getValue());
  }

  onEditorSubmit(yearFlow: SankeyResult): void {
    const year = this.yearSelectControl.getRawValue();
    const jsonFlow = this.results.flow$.getValue();
    const jsonFlowNew = { sankey: { ...jsonFlow.sankey, [year]: yearFlow } };
    const activeEntity = this.keeper.getActiveEntities();
    this.results.putCustomizedFlow(
      activeEntity.project,
      activeEntity.case,
      activeEntity.scenario,
      new File([JSON.stringify(jsonFlowNew)], 'input_flow.json', {
        type: 'application/json',
      }),
      activeEntity.result?.variation,
    );
  }

  onEditorRestore(): void {
    const activeEntity = this.keeper.getActiveEntities();
    this.results.deleteCustomizedFlow(
      activeEntity.project,
      activeEntity.case,
      activeEntity.scenario,
      activeEntity.result?.variation,
    );
  }

  onFlowDataRefresh(): void {
    const activeEntity = this.keeper.getActiveEntities();
    this.results.getFlowData(
      activeEntity.project,
      activeEntity.case,
      activeEntity.scenario,
      activeEntity.result?.variation,
    );
  }

  private subscribeToResult() {
    this.result$ = this.results.flow$.pipe(untilDestroyed(this));
    this.years$ = this.results.flow$.pipe(
      untilDestroyed(this),
      filter((data) => !!data.sankey),
      map((data) => {
        this.yearSelectControl.patchValue(Object.keys(data.sankey)[0]);
        return Object.keys(data.sankey);
      }),
    );
  }

  private subscribeToChanges() {
    this.colors$ = this.perception
      .getPerceptionMapStream(this.caseId)
      .pipe(untilDestroyed(this));
    combineLatest([this.result$, this.yearSelectControl.valueChanges])
      .pipe(
        untilDestroyed(this),
        filter(([result]) => !!result.sankey),
        filter(([result, year]) => !!result || !!year),
        map(([result, year]) => result.sankey[year]),
        filter((result) => !!result),
      )
      .subscribe((data) => {
        this.selectedData$.next(data);
        this.perception.registerLegendNames(
          this.caseId,
          data.nodes.map((node) => node.name),
        );
      });
  }
}
