import { BaseComponent } from 'prosumer-app/libs/eyes-shared';
import { ResultStore } from 'prosumer-app/stores';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';

import { Component, OnInit } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

import { CO2EmissionsService } from './co2-emissions.service';

@Component({
  selector: 'prosumer-co2-emissions',
  templateUrl: './co2-emissions.component.html',
  styleUrls: ['./co2-emissions.component.scss'],
  providers: [CO2EmissionsService],
})
export class CO2EmissionsComponent extends BaseComponent implements OnInit {
  co2EmissionsData: Array<any> = [];
  chartData$ = new BehaviorSubject<Array<any>>([]);
  filterMetaData: Array<{ filterKey; filterName; filteredData }>;
  visualizationOptions$ = of(['Asset Type', 'Scope']);
  visualized$ = new BehaviorSubject<string>('Asset Type');
  readonly chartID = 'Emissions';

  years = [];
  assetTypes = [
    { assetType: 'Equipment' },
    { assetType: 'Lines' },
    { assetType: 'Markets' },
    { assetType: 'Connectors' },
  ];
  selectedAssets = [];

  scopeTypes = [
    { scopeType: 'Scope 1' },
    { scopeType: 'Scope 2' },
    { scopeType: 'Scope 3' },
  ];
  selectedScopes = [];

  constructor(
    private _resultStore: ResultStore,
    private _co2EmissionService: CO2EmissionsService,
  ) {
    super();
  }

  ngOnInit(): void {
    combineLatest([
      this._resultStore.emissions$.pipe(take(1)),
      this._resultStore.chartData$(this.chartID).pipe(take(1)),
    ])
      .pipe(
        takeUntil(this.componentDestroyed$),
        map((combinedData) => ({
          fresh: combinedData[0],
          cached: combinedData[1],
        })),
      )
      .subscribe((emissionsData) => {
        if (emissionsData.cached) {
          this.co2EmissionsData = emissionsData.cached;
        } else {
          this.co2EmissionsData = emissionsData.fresh;
          this._resultStore.updateChartDataMapping(
            this.chartID,
            this.co2EmissionsData,
          );
        }
        if (
          this.co2EmissionsData.length > 0 &&
          this.co2EmissionsData[0].assetType.length > 0
        ) {
          this.years = this.co2EmissionsData[0].assetType[0].year.map(
            (year) => year.name,
          );
        }
        this.updateData('Asset Type');
      });

    // Initialize filter metadata
    this.filterMetaData = [
      {
        filterKey: 'assetType',
        filterName: 'Asset Type',
        filteredData: [...this.assetTypes],
      },
      {
        filterKey: 'scopeType',
        filterName: 'Scope',
        filteredData: [...this.scopeTypes],
      },
    ];

    this.chartData$.subscribe((chartData) => {
      this._co2EmissionService.setAsset(chartData);
      this._co2EmissionService.setScope(chartData);
    });
  }

  onUpdateFilter(eventEmitted: Array<any>, filterName: string): void {
    if (eventEmitted.length === 0) {
      /* This means, the selected filter is cleared. Show all entry */
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      filterName === 'Asset Type'
        ? (this.selectedAssets = [])
        : (this.selectedScopes = []);
    } else if (eventEmitted.length > 0) {
      if (eventEmitted[0].assetType) {
        this.selectedAssets = eventEmitted.map((asset) => asset.assetType);
      }
      if (eventEmitted[0].scopeType) {
        this.selectedScopes = eventEmitted.map((scope) => scope.scopeType);
      }
    }
    this.updateData(this.visualized$.getValue());
  }

  onVisualizationTypeChange(selected: MatButtonToggleChange) {
    this.visualized$.next(selected.value);
    this.updateData(selected.value);
  }

  updateData(selected: string) {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    selected === 'Asset Type'
      ? this.chartData$.next(this.updateDataAsAsset())
      : this.chartData$.next(this.updateDataAsScope());
  }

  updateDataAsAsset = () => {
    let updateData = this.years.map((year) => ({
      name: year,
      series: [
        { name: 'equipment', value: 0.0 },
        { name: 'lines', value: 0.0 },
        { name: 'markets', value: 0.0 },
        { name: 'connectors', value: 0.0 },
      ],
    }));

    this.co2EmissionsData.forEach((emissions) => {
      if (
        this.selectedScopes.length === 0 ||
        this.selectedScopes.find(
          (scopeName) =>
            scopeName.toLowerCase().replace(/\s/g, '') === emissions.name,
        )
      ) {
        emissions.assetType.forEach((asset) => {
          asset.year.forEach((year) => {
            updateData = updateData.map((data) => {
              data.series = data.series
                .filter(
                  (assetType) =>
                    this.selectedAssets.length === 0 ||
                    this.selectedAssets.find(
                      (name) => name.toLowerCase() === assetType.name,
                    ),
                )
                .map((assetType) => {
                  if (
                    assetType.name === asset.name &&
                    data.name === year.name
                  ) {
                    assetType.value += year.value;
                  }
                  return assetType;
                });
              return data;
            });
          });
        });
      }
    });
    return updateData;
  };

  updateDataAsScope = () => {
    let updateData = this.years.map((year) => ({
      name: year,
      series: [
        { name: 'Scope 1', value: 0.0 },
        { name: 'Scope 2', value: 0.0 },
        { name: 'Scope 3', value: 0.0 },
      ],
    }));

    this.co2EmissionsData.forEach((emissions) => {
      emissions.assetType.forEach((asset) => {
        /* Filter out asset type */
        if (
          this.selectedAssets.length === 0 ||
          this.selectedAssets.find((name) => name.toLowerCase() === asset.name)
        ) {
          asset.year.forEach((year) => {
            updateData = updateData.map((data) => {
              if (data.name === year.name) {
                data.series = data.series.map((scopeType) => {
                  if (
                    scopeType.name.toLowerCase().trim().replace(/\s/g, '') ===
                    emissions.name
                  ) {
                    scopeType.value += year.value;
                  }
                  return scopeType;
                });
              }
              return data;
            });
          });
        }
      });
    });

    /* Filter out emission type */
    if (this.selectedScopes.length > 0) {
      updateData = updateData.map((data) => {
        data.series = data.series.filter((series) =>
          this.selectedScopes.find((name) => name === series.name),
        );
        return data;
      });
    }

    return updateData;
  };
}
