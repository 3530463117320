import {
  BaseControlComponent,
  ColumnDefinition,
} from 'prosumer-app/libs/eyes-shared';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  Self,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { ManagedDataService } from 'prosumer-app/shared/services/managed-data';

@Component({
  selector: 'prosumer-netting-form-table',
  templateUrl: './netting-form-table.component.html',
  styleUrls: ['./netting-form-table.component.scss'],
  providers: [ManagedDataService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NettingFormTableComponent
  extends BaseControlComponent
  implements OnInit
{
  constructor(
    @Self() @Optional() public ngControl: NgControl,
    public changeDetector: ChangeDetectorRef,
  ) {
    super(ngControl, changeDetector);
  }

  @Output() add = new EventEmitter<any>();
  @Output() delete = new EventEmitter<any>();
  @Output() edit = new EventEmitter<any>();
  @Input() data: any;
  @Input() references: any;
  @Input() isViewOnly: boolean;

  columnsDef: ColumnDefinition = {
    name: {
      name: 'Name',
      toolTip: 'wizard_netting.wizard_netting_name',
      sortable: true,
    },
    energyVectorId: {
      name: 'Energy Vector',
      type: 'reference',
      referenceKey: 'energyVectors',
      toolTip: 'wizard_netting.wizard_netting_energy_vector',
      sortable: true,
    },
    nodes: {
      name: 'Nodes',
      type: 'referenceList',
      referenceKey: 'nodes',
      toolTip: 'wizard_netting.wizard_netting_nodes',
      sortable: true,
    },
    actions: {
      name: 'Actions',
      type: 'action',
    },
  };

  ngOnInit() {
    super.ngOnInit();
  }

  onAdd() {
    this.add.emit();
  }
  onDelete(e) {
    this.delete.emit(e);
  }

  onEdit(e) {
    this.edit.emit(e);
  }
}
