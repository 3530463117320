<div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="20px">
  <mat-card-title
    >Sizing
    <prosumer-tooltip-anchor [message]="'TBD'"></prosumer-tooltip-anchor>
  </mat-card-title>
  <ngx-charts-bar-horizontal
    [results]="data"
    [view]="view"
    [xAxis]="true"
    [yAxis]="true"
    [scheme]="highContrastScheme"
    xAxisLabel="(kW)"
    [showXAxisLabel]="true"
    [showYAxisLabel]="true"
    [showGridLines]="true"
    [showDataLabel]="false"
    [barPadding]="20"
    [roundEdges]="false"
  >
    <ng-template #tooltipTemplate let-model="model">
      <div>{{ model.name }} : {{ formatValue(model.value) }} kW</div>
    </ng-template>
  </ngx-charts-bar-horizontal>
</div>
