<div fxLayout="row" fxLayoutAlign="space-evenly stretch">
  <div fxLayout="column" fxLayoutAlign="center center">
    <mat-card-title>
      Initial CAPEX
      <prosumer-tooltip-anchor
        [message]="'output_main.output_main_initial_capex_pie_chart'"
      ></prosumer-tooltip-anchor>
    </mat-card-title>
    <ngx-charts-pie-chart
      [view]="[400, 350]"
      [scheme]="highContrastScheme"
      [results]="data?.capex"
      [gradient]="false"
      [legend]="false"
      [legendPosition]="'bottom'"
      [labels]="true"
      [trimLabels]="false"
    >
      <ng-template #tooltipTemplate let-model="model">
        <h4>Initial CAPEX</h4>
        <table>
          <tr *ngFor="let tooltipDatum of capexTooltipData">
            <td
              align="right"
              [class.selected-tooltip]="model.name === tooltipDatum.name"
            >
              {{ tooltipDatum.name }}
            </td>
            :
            <td
              *ngIf="tooltipDatum.value > 1000; else euro"
              align="right"
              [class.selected-tooltip]="model.name === tooltipDatum.name"
            >
              {{ tooltipDatum.value | metric: 1 }} kEUR
            </td>
            <ng-template #euro>
              <td
                align="right"
                [class.selected-tooltip]="model.name === tooltipDatum.name"
              >
                {{ tooltipDatum.value | metric: 1 }} kEUR
              </td>
            </ng-template>
          </tr>
        </table>
      </ng-template>
    </ngx-charts-pie-chart>
  </div>
  <div fxLayout="column" fxLayoutAlign="center center">
    <mat-card-title>
      OPEX First Year
      <prosumer-tooltip-anchor
        [message]="'output_main.output_main_opex_first_year_pie_chart'"
      ></prosumer-tooltip-anchor>
    </mat-card-title>
    <ngx-charts-pie-chart
      [view]="[400, 350]"
      [scheme]="highContrastScheme"
      [results]="data?.opex"
      [gradient]="false"
      [legend]="false"
      [legendPosition]="'bottom'"
      [labels]="true"
      [trimLabels]="false"
    >
      <ng-template #tooltipTemplate let-model="model">
        <h4>OPEX First Year</h4>
        <table>
          <tr *ngFor="let tooltipDatum of opexTooltipData">
            <td
              align="right"
              [class.selected-tooltip]="model.name === tooltipDatum.name"
            >
              {{ tooltipDatum.name }}
            </td>
            :
            <td
              *ngIf="tooltipDatum.value > 1000; else euro"
              align="right"
              [class.selected-tooltip]="model.name === tooltipDatum.name"
            >
              {{ tooltipDatum.value | metric: 1 }} kEUR
            </td>
            <ng-template #euro>
              <td
                align="right"
                [class.selected-tooltip]="model.name === tooltipDatum.name"
              >
                {{ tooltipDatum.value | metric: 1 }} kEUR
              </td>
            </ng-template>
          </tr>
        </table>
      </ng-template>
    </ngx-charts-pie-chart>
  </div>
</div>
