import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

import { ChartExporterModule } from 'prosumer-shared/components';
import { ExportChartDirectiveModule } from 'prosumer-shared/directives/export-chart';

import { PieChartjsComponent } from './pie-chartjs.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CustomLegendModule } from '../custom-legend/custom-legend.module';

@NgModule({
  declarations: [PieChartjsComponent],
  imports: [
    MatCheckboxModule,
    CommonModule,
    ExportChartDirectiveModule,
    MatMenuModule,
    MatIconModule,
    FlexLayoutModule,
    ChartExporterModule,
    CustomLegendModule,
  ],
  exports: [PieChartjsComponent],
})
export class PieChartjsModule {}
