import { DialogService } from 'prosumer-app/libs/eyes-core';
import { rowAnimation } from 'prosumer-app/libs/eyes-shared';
import { ReferencesBuilder } from 'prosumer-app/services/references-builder';
import { ShortCircuitEquipment } from 'prosumer-app/stores/short-circuit-equipment/short-circuit-equipment.state';
import { FrequencyControlFormService } from 'prosumer-scenario/components/frequency-control-form/frequency-control-form.service';
import {
  SCEquipmentFormData,
  SCEquipmentFormDialogComponent,
} from 'prosumer-scenario/components/frequency-control-form/short-circuit/sc-equipment-form-dialog';
import { EquipmentCircuitInfo } from 'prosumer-scenario/models/frequency-control.model';
import { SparklineTableColumnDefinition } from 'prosumer-shared/components/sparkline-table/sparkline-table.model';
import { convertToYearlyValues } from 'prosumer-shared/utils';
import { take } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'prosumer-sc-equipment-table',
  templateUrl: './sc-equipment-table.component.html',
  styleUrls: ['./sc-equipment-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [rowAnimation],
})
export class SCEquipmentTableComponent {
  columnsDef: SparklineTableColumnDefinition = {
    equipmentId: {
      name: 'Equipment',
      type: 'reference',
      referenceKey: 'equipments',
      sortable: true,
      toolTip: 'wizard_frequency_control.short_circuit_equipment',
    },
    shortCircuitFactor: {
      name: 'Short Circuit Factor [p.u.]',
      type: 'chart',
      toolTip: 'wizard_frequency_control.short_circuit_factor',
    },
    shortCircuitThreshold: {
      name: ' Short Circuit Threshold [p.u.]',
      type: 'chart',
      toolTip: 'wizard_frequency_control.short_circuit_threshold',
    },
    placeholder1: {},
    placeholder2: {},
    actions: {
      name: 'Actions',
      type: 'action',
    },
  };

  @Input() startYear: number;
  @Input() endYear: number;

  @Input() hideAdd: boolean;
  @Input() hideEdit: boolean;
  @Input() hideDelete: boolean;
  @Input() hideView: boolean;

  readonly scEquipments$ = this.service.selectShortCircuitEquipments();
  readonly references$ = this.references.selectRefs();

  constructor(
    public dialog: DialogService,
    private readonly service: FrequencyControlFormService,
    private readonly references: ReferencesBuilder,
  ) {}

  onAdd() {
    this.dialog
      .openDialog(SCEquipmentFormDialogComponent, {
        ...this.service.prepOptionsForSCEquipmentDialog(),
        mode: 'add',
        width: 600,
        equipment: {
          equipmentId: '',
          shortCircuitFactor: convertToYearlyValues(
            '0.00',
            this.startYear,
            this.endYear,
          ),
          shortCircuitThreshold: convertToYearlyValues(
            '0.00',
            this.startYear,
            this.endYear,
          ),
        },
        startYear: this.startYear,
        endYear: this.endYear,
        disableClose: true,
      } as SCEquipmentFormData)
      .subscribe();
  }

  onViewEdit(data: EquipmentCircuitInfo) {
    this.dialog
      .openDialog(SCEquipmentFormDialogComponent, {
        ...this.service.prepOptionsForSCEquipmentDialog(),
        mode: this.getViewEditMode(),
        width: 600,
        equipment: data,
        startYear: this.startYear,
        endYear: this.endYear,
        disableClose: true,
      } as SCEquipmentFormData)
      .subscribe();
  }

  onDelete(data: ShortCircuitEquipment) {
    this.service.deleteSCEquipment(data.id).pipe(take(1)).subscribe();
  }

  private getViewEditMode = (): string => (this.hideView ? 'edit' : 'view');
}
