import moment from 'moment';
import { DEFAULT_DEBOUNCE_TIME } from 'prosumer-app/app.references';
import { contains, FormFieldOption } from 'prosumer-app/libs/eyes-shared';
import { dayUtils } from 'prosumer-app/shared';
import { ResultStore } from 'prosumer-app/stores';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ResultsPerceptionService } from '@prosumer/results/components/case-results-perception';
import { ResultNameValue } from '@prosumer/results/components/results-visualizer';

import { StorageDispatchAdapter } from '../adapters';
import { DispatchService } from '../dispatch.service';

const STORAGE_CHARGE = 'Storage Charge';
const STORAGE_DISCH = 'Storage Discharge';

@Component({
  selector: 'prosumer-storage-weekly-dispatch',
  templateUrl: './storage-weekly-dispatch.component.html',
  styleUrls: ['./storage-weekly-dispatch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StorageWeeklyDispatchComponent
  extends StorageDispatchAdapter
  implements OnInit
{
  storageCharge$: Observable<any>;
  loading$ = new BehaviorSubject<boolean>(false);
  storageControl = new UntypedFormControl('');
  storage$: Observable<Array<string>> = of([]);
  _storageOptions;
  legendFilter$ = new BehaviorSubject<Array<string>>([]);

  @Output() dataLoaded = new EventEmitter<Array<any>>();
  @Input() scenarioName: string;
  @Input() set storageOptions(storageOptions: Array<FormFieldOption<string>>) {
    this._storageOptions = storageOptions;
    if (storageOptions && storageOptions.length > 0) {
      this.storageControl.setValue(storageOptions[0].value);
      this.loadStorages();
    }
  }

  get storageOptions(): Array<FormFieldOption<string>> {
    return this._storageOptions;
  }

  constructor(
    private _dispatchService: DispatchService,
    private _resultStore: ResultStore,
    perception: ResultsPerceptionService,
  ) {
    super(perception);
  }

  ngOnInit() {
    this.storageControl.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => this.loadStorages());
    this.initializeChartDataAndColors();
  }

  loadStorages() {
    this.loading$.next(true);
    this.storageCharge$ = combineLatest([
      this._dispatchService.year$,
      this._dispatchService.node$,
      this._dispatchService.energyVector$,
      combineLatest([
        this._dispatchService.minDate$,
        this._dispatchService.maxDate$,
      ]).pipe(
        debounceTime(DEFAULT_DEBOUNCE_TIME),
        distinctUntilChanged(),
        takeUntil(this.componentDestroyed$),
      ),
      this.legendFilter$,
    ]).pipe(
      switchMap(([year, node, energyVector, [minDay, maxDay], legendFilter]) =>
        this._resultStore
          .getStorageCharge$(
            year,
            node,
            energyVector,
            this.storageControl.value,
          )
          .pipe(
            map((filteredData) => {
              const minDate =
                // eslint-disable-next-line @typescript-eslint/naming-convention
                year && minDay
                  ? moment({
                      y: +year,
                      M: 0,
                      d: 1,
                      h: 0,
                      m: 0,
                      s: 0,
                      ms: 0,
                    }).dayOfYear(minDay)
                  : undefined;
              const maxDate =
                year && maxDay
                  ? // eslint-disable-next-line @typescript-eslint/naming-convention
                    moment({
                      y: +year,
                      M: 0,
                      d: 1,
                      h: 23,
                      m: 59,
                      s: 59,
                      ms: 999,
                    }).dayOfYear(maxDay)
                  : undefined;
              const dataSet = !!!filteredData?.values
                ? []
                : (filteredData.values as Array<any>)
                    .map((value, index) => {
                      let v = value * -1;
                      if (contains(legendFilter, STORAGE_CHARGE) && v > 0) {
                        v = 0;
                      }
                      if (contains(legendFilter, STORAGE_DISCH) && v < 0) {
                        v = 0;
                      }
                      return {
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        hour: moment({
                          y: +year,
                          M: 0,
                          d: 1,
                          h: 0,
                          m: 0,
                          s: 0,
                          ms: 0,
                        })
                          .hour(index)
                          .get('h'),
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        moment: moment({
                          y: +year,
                          M: 0,
                          d: 1,
                          h: 0,
                          m: 0,
                          s: 0,
                          ms: 0,
                        }).hour(index),
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        day: moment({
                          y: +year,
                          M: 0,
                          d: 1,
                          h: 0,
                          m: 0,
                          s: 0,
                          ms: 0,
                        })
                          .hour(index)
                          .get('d'),
                        value: v, // Negate value as per requirement based on PR-2845
                      };
                    })
                    .filter((dataSetItem) =>
                      minDate && maxDate
                        ? minDate.isSameOrBefore(dataSetItem.moment) &&
                          maxDate.isSameOrAfter(dataSetItem.moment)
                        : true,
                    );

              if (dataSet && dataSet instanceof Array && dataSet.length > 0) {
                const series = [];
                const groupedData = this.groupByDayAndHour(dataSet);

                Array.from(Array(168).keys()).forEach((i) => {
                  const weeklyDataSet = groupedData.filter(
                    (data) => data && data.newIndex === i + 1,
                  );
                  if (weeklyDataSet.length > 0) {
                    const weeklyData = weeklyDataSet
                      .map((data) => ({
                        ...data,
                        name: '' + data.newIndex,
                      }))
                      .reduce((prev, curr) => ({ ...curr }));
                    series.push({ ...weeklyData, value: weeklyData.average });
                  } else {
                    series.push({
                      hour: i,
                      value: 0.0,
                      average: 0.0,
                      day: Math.trunc((i + 1) / 24),
                      name: (i + 1).toString(),
                      newIndex: i + 1,
                    });
                  }
                });
                return series;
              }
              return [];
            }),
            takeUntil(this.componentDestroyed$),
          ),
      ),
      tap((data) => {
        this.dataLoaded.emit(data);
        if (data) {
          this.loading$.next(false);
        }
      }),
      takeUntil(this.componentDestroyed$),
    );
  }

  groupByDayAndHour(dataSet: Array<any>) {
    const holder = {};
    const result = dataSet.reduce((acc, current) => {
      const key = current.day + '-' + current.hour;
      // new index based on day and hour
      current.newIndex = this.computeNewIndex(current.day, current.hour);

      if (!holder[key]) {
        // create a copy of current as initial value
        holder[key] = Object.assign({}, current);
        holder[key].count = 1;
        acc.push(holder[key]);
      } else {
        holder[key].value += current.value;
        holder[key].count += 1;
      }
      holder[key].average = holder[key].value / holder[key].count;
      return acc;
    }, []);

    // sort result based on index; return data from Monday to Sunday
    result.sort((a, b) => a.newIndex - b.newIndex);
    return result;
  }

  computeNewIndex(day: number, hour: number) {
    // day 0 == Sunday, but since in the graph, the first day is Monday, we move days with sunday to end
    const hoursOfSixDays = 144;
    const startHour = day === 0 ? hoursOfSixDays : (day - 1) * 24;
    // start of new index is 1
    return startHour + hour + 1;
  }

  formatTick(tick: string): string {
    return dayUtils.prependDay(Number(tick));
  }
  getChartName(): string {
    return `Storage Weekly Dispatch (${this.storageControl.value})`;
  }
  getNgxChartsDataStream(): Observable<ResultNameValue[]> {
    return this.storageCharge$;
  }
}
