import { Injectable } from '@angular/core';
import {
  EnergyBalanceDirection,
  EnergyBalanceResult,
} from '@prosumer/results/models';
import { ResultsExtractorService } from '@prosumer/results/state';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  BaseResultsVisualizerService,
  ResultNameValue,
  VisualizerData,
} from '../results-visualizer';

type ReducedEnergyBalanceResults = { [name: string]: EnergyBalanceResult[] };
@Injectable()
export class EnergyBalanceResultsService extends BaseResultsVisualizerService<EnergyBalanceResult> {
  constructor(private results: ResultsExtractorService) {
    super();
  }

  getFilterKeys(): string[] {
    return ['year', 'node', 'energyVector'];
  }
  getResultDataStream(): Observable<EnergyBalanceResult[]> {
    return this.results.getEnergyBalanceStream();
  }

  getProductionPieStream(): Observable<ResultNameValue[]> {
    return this.getProductionStream().pipe(
      map((data) => this.mapToPieChart(data)),
    );
  }

  getConsumptionPieStream(): Observable<ResultNameValue[]> {
    return this.getConsumptionStream().pipe(
      map((data) => this.mapToPieChart(data)),
    );
  }

  getProductionBarStream(): Observable<VisualizerData[]> {
    return this.getProductionStream().pipe(
      map((data) => this.mapToBarChart(data)),
    );
  }
  getConsumptionBarStream(): Observable<VisualizerData[]> {
    return this.getConsumptionStream().pipe(
      map((data) => this.mapToBarChart(data)),
    );
  }

  getTotalProduction$(): Observable<number> {
    return this.getProductionStream().pipe(
      map((results) => this.totalEnergyBalanceResults(results)),
    );
  }

  getTotalConsumption$(): Observable<number> {
    return this.getConsumptionStream().pipe(
      map((results) => this.totalEnergyBalanceResults(results)),
    );
  }

  getAllYearsStream(): Observable<number[]> {
    return this.results.getAllYearsStream();
  }

  private totalEnergyBalanceResults(results: EnergyBalanceResult[]): number {
    return results.reduce((sum, result) => sum + result.value, 0);
  }

  private mapToBarChart(data: EnergyBalanceResult[]): VisualizerData[] {
    const reducedToNameList = this.reduceData(data, 'name');
    return Object.entries(reducedToNameList).map(([name, list]) => ({
      name,
      series: this.createSeriesFromList(list),
    }));
  }

  private createSeriesFromList(list: EnergyBalanceResult[]): ResultNameValue[] {
    return list.map((l) => ({ name: String(l.year), value: l.value }));
  }

  private mapToPieChart(data: EnergyBalanceResult[]): ResultNameValue[] {
    const reducedToNameList = this.reduceData(data, 'name');
    return Object.entries(reducedToNameList).map(([name, list]) => ({
      name,
      value: this.sumValues(list),
    }));
  }

  private reduceData(
    data: EnergyBalanceResult[],
    key: string,
  ): ReducedEnergyBalanceResults {
    return data.reduce((acc, curr) => {
      const value = curr[key];
      const currValue = acc[value] || [];
      acc[value] = [...currValue, curr];
      return acc;
    }, {});
  }

  private sumValues(data: EnergyBalanceResult[]): number {
    return data.reduce((sum, curr) => sum + curr.value, 0);
  }

  private getProductionStream(): Observable<EnergyBalanceResult[]> {
    return this.getFilteredRawDataStream().pipe(
      map((data) =>
        data.filter((d) => d.direction === EnergyBalanceDirection.PRODUCTION),
      ),
    );
  }
  private getConsumptionStream(): Observable<EnergyBalanceResult[]> {
    return this.getFilteredRawDataStream().pipe(
      map((data) =>
        data.filter((d) => d.direction === EnergyBalanceDirection.CONSUMPTION),
      ),
    );
  }

  filterOutZeroValues(data: EnergyBalanceResult[]): EnergyBalanceResult[] {
    return data.filter((datum) => !!datum['value']);
  }
}
