import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  FilledStatus,
  ScenarioCompletionService,
  ScenarioWizardStep,
} from 'prosumer-app/+scenario/services/scenario-completion';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'prosumer-filled-indicator',
  templateUrl: './filled-indicator.component.html',
  styleUrls: ['./filled-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilledIndicatorComponent {
  private forStepSubject = new BehaviorSubject<ScenarioWizardStep>(undefined);
  @Input() set forStep(step: ScenarioWizardStep) {
    this.forStepSubject.next(step);
  }
  readonly forStep$ = this.forStepSubject.asObservable();

  readonly filledIconKey$: Observable<string> = this.selectIconKey();

  constructor(private completion: ScenarioCompletionService) {}

  private selectIconKey(): Observable<string> {
    return this.selectForStep().pipe(
      mergeMap((step) => this.completion.selectStatusFor(step)),
      map((status) => this.getFilledStatusToIconKeyMap()[status]),
    );
  }

  private selectForStep(): Observable<ScenarioWizardStep> {
    return this.forStepSubject.asObservable().pipe(filter((step) => !!step));
  }

  private getFilledStatusToIconKeyMap(): Record<FilledStatus, string> {
    return {
      empty: 'radio_button_unchecked',
      full: 'check_circle',
      half: 'tonality',
    };
  }
}
