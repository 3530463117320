import { Injectable } from '@angular/core';
import { StoreConfig } from '@datorama/akita';
import { ScenarioDetailStore, ScenarioDetailType } from '../scenario-detail';
import { SpinningReserveActivation } from './spinning-reserve-activation.state';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: ScenarioDetailType.srActivation })
export class SpinningReserveActivationStore extends ScenarioDetailStore<SpinningReserveActivation> {
  toBE(from: SpinningReserveActivation): SpinningReserveActivation {
    return {
      ...from,
      duration: String(from.duration),
      rate: String(from.rate),
    };
  }
}
