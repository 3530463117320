import { CoherenceResult, CoherenceStepValidation } from '../../models';
import { CompletionStrategy } from './completion-strategy';

export class LaunchCompletion extends CompletionStrategy<
  Array<CoherenceStepValidation>
> {
  isFull(form: Array<CoherenceStepValidation>): boolean {
    const { hasMessage, hasError } = this.getMessagesType(form);
    return hasMessage && !hasError;
  }

  isHalf(form: Array<CoherenceStepValidation>): boolean {
    const { hasMessage, hasError, hasWarning } = this.getMessagesType(form);
    return hasMessage && hasError && hasWarning;
  }

  private getMessagesType(form: Array<CoherenceStepValidation>): {
    hasMessage: boolean;
    hasError: boolean;
    hasWarning: boolean;
  } {
    const messages = (form || []).map((d) => d.validations.map((v) => v.type));
    const hasError = messages
      .map((d) => d.some((a) => a === 'error'))
      .some(Boolean);
    const hasWarning = messages
      .map((d) => d.some((a) => a === 'warning'))
      .some(Boolean);
    const hasMessage = form !== undefined;
    return {
      hasError,
      hasWarning,
      hasMessage,
    };
  }
}
