import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ActiveKeeperService } from 'prosumer-app/services/active-keeper';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { FuelInfo } from './fuel.state';
import { FuelStore } from './fuel.store';
import { ScenarioDetailState } from '../scenario-detail';

@Injectable({ providedIn: 'root' })
export class FuelQuery extends QueryEntity<ScenarioDetailState<FuelInfo>> {
  constructor(
    public store: FuelStore,
    private readonly keeper: ActiveKeeperService,
  ) {
    super(store);
  }

  selectActiveFuels(): Observable<FuelInfo[]> {
    return this.selectActiveScenarioId().pipe(
      switchMap((id) => this.selectFuelsByScenario(id)),
    );
  }

  private selectActiveScenarioId(): Observable<string> {
    return this.keeper.selectActive().pipe(map((active) => active.scenario));
  }

  private selectFuelsByScenario(id: string): Observable<FuelInfo[]> {
    return this.selectAll().pipe(
      map((fuels) => this.onlyForScenario(fuels, id)),
    );
  }

  private onlyForScenario(fuels: FuelInfo[], id: string): FuelInfo[] {
    return fuels.filter((fuel) => fuel.scenarioUuid === id);
  }
}
