import { Component } from '@angular/core';
import { TopologyResultsService } from '@prosumer/results/components/topology-results';
import { TopologyResult } from '@prosumer/results/models';

import { ComparisonController } from '../base';
import { COMP_VIZ_SERVICE } from '../comparison-visualizer';
import { TopologyComparisonService } from './topology-comparison.service';

@Component({
  selector: 'prosumer-topology-comparison',
  templateUrl: './topology-comparison.component.html',
  styleUrls: ['./topology-comparison.component.scss'],
  providers: [
    { provide: COMP_VIZ_SERVICE, useClass: TopologyComparisonService },
    TopologyResultsService,
  ],
})
export class TopologyComparisonComponent extends ComparisonController<TopologyResult> {}
