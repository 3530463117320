import { DIALOG_SIZES, DIRECTION_ICONS } from 'prosumer-app/app.references';
import { ReferencesBuilder } from 'prosumer-app/services/references-builder';
import { ScenarioDetailType } from 'prosumer-app/stores/scenario-detail/scenario-detail.state';
import {
  SpinningReserveMargin,
  SpinningReserveMarginStore,
} from 'prosumer-app/stores/spinning-reserve-margin';
import { DialogService } from 'prosumer-libs/eyes-core/services/dialog.service';
import {
  ColumnDefinition,
  ManagedTableComponent,
  rowAnimation,
} from 'prosumer-libs/eyes-shared';
import { FrequencyControlFormService } from 'prosumer-scenario/components/frequency-control-form/frequency-control-form.service';
import {
  ReserveMarginFormDialogComponent,
  ReserveMarginFormDialogData,
} from 'prosumer-scenario/components/frequency-control-form/spinning-reserves/margin-form-dialog';
import { EnergyVector, MarginReserve, Profile } from 'prosumer-scenario/models';
import { ManagedDataService } from 'prosumer-shared/services/managed-data/managed-data.service';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { NgControl, UntypedFormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'prosumer-margin-table',
  templateUrl: './margin-table.component.html',
  styleUrls: ['./margin-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ManagedDataService],
  animations: [rowAnimation],
})
export class MarginTableComponent extends ManagedTableComponent<MarginReserve> {
  _energyVectors: Array<EnergyVector>;
  _persistedMargins: Profile[];

  // eslint-disable-next-line @typescript-eslint/naming-convention
  readonly DIRECTION_ICONS = { ...DIRECTION_ICONS };

  @Input() startYear: number;
  @Input() endYear: number;
  @Input() binToDelete: UntypedFormControl;

  @Input() scenarioIdentity: {
    projectId: string;
    caseId: string;
    scenarioId: string;
  } = { caseId: undefined, projectId: undefined, scenarioId: undefined };

  @Input() energyVectors = [];

  @Input() hideAdd: boolean;
  @Input() hideEdit: boolean;
  @Input() hideDelete: boolean;
  @Input() hideView: boolean;

  readonly references$ = this.referenceBuilder.selectRefs();
  readonly source$ = this.selectDataSource();
  readonly hasData$ = this.selectHasData();

  constructor(
    public ngControl: NgControl,
    public changeDetector: ChangeDetectorRef,
    public managedData: ManagedDataService<MarginReserve>,
    public dialogService: DialogService,
    private readonly service: FrequencyControlFormService,
    private readonly referenceBuilder: ReferencesBuilder,
    private readonly srMargins: SpinningReserveMarginStore,
  ) {
    super(ngControl, changeDetector, managedData);
  }

  defineColumns(): ColumnDefinition {
    return {
      energyVectorId: {
        name: 'Energy Vector',
        type: 'reference',
        referenceKey: 'energyVectors',
        sortable: true,
        toolTip: 'wizard_frequency_control.reserve_energy_vector',
      },
      placeholder1: {},
      direction: {
        name: 'Direction',
        type: 'custom',
        sortable: true,
        toolTip: 'wizard_frequency_control.direction',
      },
      placeholder2: {},
      placeholder3: {},
      actions: {
        name: 'Actions',
        type: 'action',
      },
    };
  }

  onAdd() {
    this.dialogService.openDialog(
      ReserveMarginFormDialogComponent,
      this.buildAddDialogData(),
    );
  }

  onViewEdit(data: SpinningReserveMargin) {
    this.srMargins
      .getSingle(ScenarioDetailType.srMargin, data.id)
      .pipe(take(1))
      .subscribe((margin) =>
        this.dialogService.openDialog(
          ReserveMarginFormDialogComponent,
          this.buildEditDialogData(margin),
        ),
      );
  }

  onDelete(data: SpinningReserveMargin) {
    this.service.deleteReserveMargin(data.id).pipe(take(1)).subscribe();
  }

  writeValue(): void {
    // do nothing
  }

  private selectHasData(): Observable<boolean> {
    return this.service
      .selectSpinningReserveMargins()
      .pipe(map((margins) => margins.length > 0));
  }

  private selectDataSource(): Observable<
    MatTableDataSource<SpinningReserveMargin>
  > {
    return this.service
      .selectSpinningReserveMargins()
      .pipe(map((margins) => this.toDataSource(margins)));
  }

  private toDataSource(
    margins: SpinningReserveMargin[],
  ): MatTableDataSource<SpinningReserveMargin> {
    return new MatTableDataSource(margins);
  }

  private buildAddDialogData(): ReserveMarginFormDialogData {
    return { ...this.buildDialogData(), mode: 'add' };
  }

  private buildEditDialogData(
    data: SpinningReserveMargin,
  ): ReserveMarginFormDialogData {
    return {
      ...this.buildDialogData(),
      mode: this.getViewEditMode(),
      currentReserveMargin: data,
    };
  }

  private buildDialogData(): ReserveMarginFormDialogData {
    return {
      width: DIALOG_SIZES.xLarge,
      disableClose: true,
      startYear: this.startYear,
      endYear: this.endYear,
      scenarioId: this.scenarioIdentity.scenarioId,
      caseId: this.scenarioIdentity.caseId,
      projectId: this.scenarioIdentity.projectId,
      ...this.service.preOptsForReserveMarginDialog(),
    } as ReserveMarginFormDialogData;
  }

  private getViewEditMode = (): 'edit' | 'view' =>
    this.hideView ? 'edit' : 'view';
}
