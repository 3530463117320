<div
  class="main-output-container"
  fxLayout="column"
  fxLayoutAlign="start stretch"
  fxLayoutGap="16px"
>
  <div fxLayout="row" fxLayoutAlign="space-around stretch" fxLayoutGap="32px">
    <prosumer-total-discounted-cost
      fxFlex="50"
      [colors]="colors$ | async"
      [scenarioName]="scenarioName"
    ></prosumer-total-discounted-cost>
    <prosumer-total-emissions
      *ngIf="!!(results?.yearlyTotalEmissions$ | async)?.length"
      fxFlex="50"
      [colors]="colors$ | async"
      [scenarioName]="scenarioName"
    ></prosumer-total-emissions>
  </div>
  <prosumer-sizing-and-costs-results
    [colors]="colors$ | async"
    [scenarioName]="scenarioName"
  ></prosumer-sizing-and-costs-results>
</div>
