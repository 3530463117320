import { ActiveEntities } from 'prosumer-app/services/active-keeper';
import { Observable } from 'rxjs';
import { skipWhile } from 'rxjs/operators';
import {
  ScenarioWizardStep,
  WizardStepCriteria,
} from '../../services/scenario-completion';

const removeStep = (from: ScenarioWizardStep[], thisOne: ScenarioWizardStep) =>
  from.filter((step) => step !== thisOne);

const removeTopology = (from: ScenarioWizardStep[], should: boolean) =>
  should ? removeStep(from, ScenarioWizardStep.topology) : from;

const removeMobility = (from: ScenarioWizardStep[], should: boolean) =>
  should ? removeStep(from, ScenarioWizardStep.mobility) : from;

const removeNetting = (from: ScenarioWizardStep[], should: boolean) =>
  should ? removeStep(from, ScenarioWizardStep.netting) : from;

const removeFrequencyControl = (from: ScenarioWizardStep[], should: boolean) =>
  should ? removeStep(from, ScenarioWizardStep.frequencyControl) : from;

const getStepsInOrder = (): ScenarioWizardStep[] => [
  ScenarioWizardStep.general,
  ScenarioWizardStep.timeHorizon,
  ScenarioWizardStep.topology,
  ScenarioWizardStep.loads,
  ScenarioWizardStep.equipment,
  ScenarioWizardStep.commodities,
  ScenarioWizardStep.mobility,
  ScenarioWizardStep.frequencyControl,
  ScenarioWizardStep.netting,
  ScenarioWizardStep.regulation,
  ScenarioWizardStep.optimizationCockpit,
  ScenarioWizardStep.launch,
];

const getApplicableSteps = (
  criteria: WizardStepCriteria,
): ScenarioWizardStep[] => {
  let steps = getStepsInOrder();
  steps = removeTopology(steps, criteria.removeTopology);
  steps = removeMobility(steps, criteria.removeMobility);
  steps = removeNetting(steps, criteria.removeNetting);
  steps = removeFrequencyControl(steps, criteria.removeFrequencyControl);
  return steps;
};

/** Reverse track active tab */
const getSelectedStep = (
  criteria: WizardStepCriteria,
  idx: number,
): ScenarioWizardStep => getApplicableSteps(criteria)[idx];

const getIndexOfStep = (
  step: ScenarioWizardStep,
  applicableSteps: ScenarioWizardStep[],
): number => applicableSteps.indexOf(step);

/** Pipe function to skip first emit of observable when it is the default value (general) */
const skipDefaultFirstStep = (
  $: Observable<ActiveEntities>,
): Observable<ActiveEntities> => $.pipe(skipWhile(checkDefaultFirstStep));

const checkDefaultFirstStep = (evt: ActiveEntities, idx: number): boolean =>
  [evt.selectedScenarioTab === ScenarioWizardStep.general, idx === 0].every(
    Boolean,
  );

// eslint-disable-next-line
export const ScenarioPageExts = {
  removeTopology,
  removeMobility,
  removeNetting,
  removeFrequencyControl,
  getStepsInOrder,
  getApplicableSteps,
  getSelectedStep,
  getIndexOfStep,
  skipDefaultFirstStep,
};
