import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MarketLimitsGetterDirective } from './market-limits-getter.directive';

@NgModule({
  declarations: [MarketLimitsGetterDirective],
  imports: [CommonModule],
  exports: [MarketLimitsGetterDirective],
})
export class MarketLimitsGetterModule {}
