import { Injectable } from '@angular/core';
import { StoreConfig } from '@datorama/akita';

import { ScenarioDetailType } from '../scenario-detail';
import { VehiclesDispatch } from './vehicles-dispatch.state';
import { CascadableDetailStore } from '../cascadable-detail';

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: ScenarioDetailType.vehiclesDispatch })
export class VehiclesDispatchStore extends CascadableDetailStore<VehiclesDispatch> {}
