import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class EquipmentService {
  _chartData$ = new BehaviorSubject<Array<any>>(undefined);
  _chartTitle$ = new BehaviorSubject<string>('');
  _yAxisLabel$ = new BehaviorSubject<string>('');

  chartData$ = this._chartData$.asObservable();
  chartTitle$ = this._chartTitle$.asObservable();
  yAxisLabel$ = this._yAxisLabel$.asObservable();

  constructor() {}

  setChartData(value: Array<any>) {
    this._chartData$.next(value);
  }

  setChartDataAndChartInfo(
    value: Array<any>,
    chartTitle: string,
    yAxisLabel: string,
  ) {
    this.setChartData(value);
    this._chartTitle$.next(chartTitle);
    this._yAxisLabel$.next(yAxisLabel);
  }
}
