import { Directive, EventEmitter, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ScenarioGenericQuery } from 'prosumer-app/stores/scenario-generic';
import { Observable } from 'rxjs';
import { delay, distinctUntilChanged, map } from 'rxjs/operators';

@UntilDestroy()
@Directive({
  selector: '[prosumerProjectDurationDetector]',
})
export class ProjectDurationDetectorDirective {
  @Output() startYearChange = new EventEmitter<number>();
  @Output() endYearChange = new EventEmitter<number>();

  constructor(private readonly scenario: ScenarioGenericQuery) {
    this.subscribeToDistinctStartYearForPropagation();
    this.subscribeToDistinctEndYearForPropagation();
  }

  private subscribeToDistinctStartYearForPropagation(): void {
    this.selectDistinctStartYear()
      .pipe(untilDestroyed(this))
      .subscribe((startYear) => this.startYearChange.emit(startYear));
  }

  private subscribeToDistinctEndYearForPropagation(): void {
    this.selectDistinctEndYear()
      .pipe(untilDestroyed(this))
      .subscribe((endYear) => this.endYearChange.emit(endYear));
  }

  private selectDistinctStartYear(): Observable<number> {
    return this.selectActiveStartYear().pipe(distinctUntilChanged());
  }

  private selectDistinctEndYear(): Observable<number> {
    return this.selectActiveEndYear().pipe(distinctUntilChanged());
  }

  private selectActiveStartYear(): Observable<number> {
    return this.delayedActivePeriod().pipe(map((period) => period[0]));
  }

  private selectActiveEndYear(): Observable<number> {
    return this.delayedActivePeriod().pipe(map((period) => period[1]));
  }

  private delayedActivePeriod(): Observable<[number, number]> {
    return this.scenario.selectActivePeriod().pipe(delay(0));
  }
}
