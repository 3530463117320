<form [formGroup]="form" class="form-tab-group">
  <div class="tab-group-btn-container">
    <button
      color="accent"
      mat-flat-button
      [disabled]="disabled || loading"
      (click)="onEditInterval()"
      data-testid="yearly-market-limits-interval-edit-button"
    >
      <mat-icon aria-hidden="false">edit</mat-icon>
      <span>Interval</span>
    </button>
  </div>

  <mat-tab-group
    #tabGroup
    formArrayName="intervals"
    [selectedIndex]="selected?.value"
    (selectedIndexChange)="onChangeSelectedTab($event)"
  >
    <mat-tab
      *ngFor="let interval of intervalsFormArray?.controls; let i = index"
      [disabled]="loading"
      [formGroupName]="i"
    >
      <ng-template matTabLabel>
        <div
          fxLayout="row"
          fxLayoutAlign="center center"
          [ngClass]="{ 'eyes-warn': isInvalid(i) }"
        >
          <b>{{ interval?.controls?.startYear?.value || 'Start Year' }}</b>
          <mat-icon aria-hidden="false">chevron_right</mat-icon>
          <b>{{ interval?.controls?.endYear?.value || 'End Year' }}</b>
          <span *ngIf="isInvalid(i)" class="eyes-warn">*</span>
        </div>
      </ng-template>

      <ng-template matTabContent>
        <div fxLayout="row" fxLayoutGap="10px" class="energy-grid-limit-inputs">
          <mat-form-field class="eyes-maximize-width" appearance="outline">
            <mat-label>{{
              'Scenario.labels.energyGridLimits.minPower' | translate
            }}</mat-label>
            <input
              matInput
              formControlName="minPower"
              type="number"
              [placeholder]="
                'Scenario.placeholders.energyGridLimits.minPower' | translate
              "
              data-testid="market-limit-dialog-min-power"
            />
            <mat-icon
              aria-hidden="false"
              matSuffix
              tooltipPosition="left"
              class="eyes-input-tooltip-anchor"
              [prosumerTooltip]="
                'wizard_commodities.wizard_market_limits_min_power' | translate
              "
              data-testid="market-limit-dialog-min-power-help"
            >
              help
            </mat-icon>
          </mat-form-field>
          <mat-form-field class="eyes-maximize-width" appearance="outline">
            <mat-label>{{
              'Scenario.labels.energyGridLimits.minEnergy' | translate
            }}</mat-label>
            <input
              matInput
              formControlName="minEnergy"
              type="number"
              [placeholder]="
                'Scenario.placeholders.energyGridLimits.minEnergy' | translate
              "
              data-testid="market-limit-dialog-min-energy"
            />
            <mat-icon
              aria-hidden="false"
              matSuffix
              tooltipPosition="left"
              class="eyes-input-tooltip-anchor"
              [prosumerTooltip]="
                'wizard_commodities.wizard_market_limits_min_energy' | translate
              "
              data-testid="market-limit-dialog-min-energy-help"
            >
              help
            </mat-icon>
          </mat-form-field>
          <mat-form-field class="eyes-maximize-width" appearance="outline">
            <mat-label>{{
              'Scenario.labels.energyGridLimits.minCapacity' | translate
            }}</mat-label>
            <input
              matInput
              formControlName="minCapacity"
              type="number"
              [placeholder]="
                'Scenario.placeholders.energyGridLimits.minCapacity' | translate
              "
              data-testid="market-limit-dialog-min-capacity"
            />
            <mat-icon
              aria-hidden="false"
              matSuffix
              tooltipPosition="left"
              class="eyes-input-tooltip-anchor"
              [prosumerTooltip]="
                'wizard_commodities.wizard_market_limits_min_capacity'
                  | translate
              "
              data-testid="market-limit-dialog-min-capacity-help"
            >
              help
            </mat-icon>
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="10px" class="energy-grid-limit-inputs">
          <mat-form-field class="eyes-maximize-width" appearance="outline">
            <mat-label>{{
              'Scenario.labels.energyGridLimits.maxPower' | translate
            }}</mat-label>
            <input
              matInput
              formControlName="maxPower"
              type="number"
              [placeholder]="
                'Scenario.placeholders.energyGridLimits.maxPower' | translate
              "
              data-testid="market-limit-dialog-max-power"
            />
            <mat-icon
              aria-hidden="false"
              matSuffix
              tooltipPosition="left"
              class="eyes-input-tooltip-anchor"
              [prosumerTooltip]="
                'wizard_commodities.wizard_market_limits_max_power' | translate
              "
              data-testid="market-limit-dialog-max-power-help"
            >
              help
            </mat-icon>
          </mat-form-field>
          <mat-form-field class="eyes-maximize-width" appearance="outline">
            <mat-label>{{
              'Scenario.labels.energyGridLimits.maxEnergy' | translate
            }}</mat-label>
            <input
              matInput
              formControlName="maxEnergy"
              type="number"
              [placeholder]="
                'Scenario.placeholders.energyGridLimits.maxEnergy' | translate
              "
              data-testid="market-limit-dialog-max-energy"
            />
            <mat-icon
              aria-hidden="false"
              matSuffix
              tooltipPosition="left"
              class="eyes-input-tooltip-anchor"
              [prosumerTooltip]="
                'wizard_commodities.wizard_market_limits_max_energy' | translate
              "
              data-testid="market-limit-dialog-max-energy-help"
            >
              help
            </mat-icon>
          </mat-form-field>
          <mat-form-field class="eyes-maximize-width" appearance="outline">
            <mat-label>{{
              'Scenario.labels.energyGridLimits.maxCapacity' | translate
            }}</mat-label>
            <input
              matInput
              formControlName="maxCapacity"
              type="number"
              [placeholder]="
                'Scenario.placeholders.energyGridLimits.maxCapacity' | translate
              "
              data-testid="market-limit-dialog-max-capacity"
            />
            <mat-icon
              aria-hidden="false"
              matSuffix
              tooltipPosition="left"
              class="eyes-input-tooltip-anchor"
              [prosumerTooltip]="
                'wizard_commodities.wizard_market_limits_max_capacity'
                  | translate
              "
              data-testid="market-limit-dialog-max-capacity-help"
            >
              help
            </mat-icon>
          </mat-form-field>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>

  <button id="btn" [hidden]="true"></button>
</form>
