import { PipeUtils } from 'prosumer-core/utils';
import { StackedBarMeta } from 'prosumer-shared/modules/chartjs/stacked-bar-chartjs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Component, Input, OnInit } from '@angular/core';

import { PerceptionMap } from '../../case-results-perception';
import { ResultNameValue, VisualizerData } from '../../results-visualizer';
import { MainOutputResultsService } from '../main-output-results.service';

@Component({
  selector: 'prosumer-total-emissions',
  templateUrl: './total-emissions.component.html',
  styleUrls: ['./total-emissions.component.scss'],
})
export class TotalEmissionsComponent implements OnInit {
  @Input() colors: PerceptionMap;
  @Input() scenarioName: string;

  chartData$: Observable<StackedBarMeta>;

  constructor(private results: MainOutputResultsService) {}

  ngOnInit(): void {
    this.chartData$ = this.getChartDataStream();
  }

  private getChartDataStream(): Observable<StackedBarMeta> {
    return this.results.yearlyTotalEmissions$.pipe(
      PipeUtils.filterOutEmpty,
      map((emissions) => this.mapEmissionsToChartJSData(emissions)),
    );
  }

  private mapEmissionsToChartJSData(
    emissions: VisualizerData[],
  ): StackedBarMeta {
    const names = this.collectNames(emissions);
    const years = emissions.map((e) => e.name);
    const output = {
      axisTicks: years,
      data: names.map((name) => ({
        name,
        values: years.reduce((acc, year) => {
          const matched = this.findDataForYear(emissions, year);
          acc[year] = this.findValueForName(matched.series, name);
          return acc;
        }, {}),
      })),
      name: 'Total Emissions per Year',
    };
    return this.filterOutZero(output);
  }

  private filterOutZero(raw: StackedBarMeta) {
    return {
      axisTicks: raw.axisTicks,
      data: raw.data.filter(
        (datum) =>
          !(Object.values(datum.values) || []).every((value) => !!!value),
      ),
      name: raw.name,
    };
  }

  private collectNames(data: VisualizerData[]): string[] {
    return data[0].series.map((siri) => siri.name);
  }

  private findDataForYear(
    data: VisualizerData[],
    year: string,
  ): VisualizerData {
    return data.find((d) => d.name === year);
  }

  private findValueForName(series: ResultNameValue[], name: string): number {
    return series.find((siri) => siri.name === name).value;
  }
}
