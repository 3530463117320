<form [formGroup]="form" (ngSubmit)="onClickNext()" *ngrxLet="refs$ as refs">
  <mat-expansion-panel [expanded]="expanded">
    <mat-expansion-panel-header
      [collapsedHeight]="collapsedHeight"
      [expandedHeight]="expandedHeight"
    >
      <mat-panel-title data-testid="netting-panel-title">
        {{ 'Scenario.labels.wizard.netting.headers.netting' | translate }}
        <prosumer-tooltip-anchor
          [message]="'wizard_netting.wizard_netting_section' | translate"
          data-testid="netting-table-tooltip"
        >
        </prosumer-tooltip-anchor>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <prosumer-netting-form-table
      (add)="onAddNetting()"
      (delete)="onDeleteNetting($event)"
      (edit)="onEditNetting($event)"
      [data]="nettingList$ | async"
      [references]="refs"
      formControlName="netting"
      [isViewOnly]="isViewOnly || mode === 'read'"
    ></prosumer-netting-form-table>
  </mat-expansion-panel>

  <mat-expansion-panel [expanded]="expanded">
    <mat-expansion-panel-header
      [collapsedHeight]="collapsedHeight"
      [expandedHeight]="expandedHeight"
    >
      <mat-panel-title data-testid="tax-subsidy-panel-title">
        {{
          'Scenario.labels.wizard.netting.headers.taxAndSubsidies' | translate
        }}
        <prosumer-tooltip-anchor
          [message]="'wizard_netting.wizard_tax_section'"
          data-testid="tax-subsidy-table-tooltip"
        >
        </prosumer-tooltip-anchor>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <prosumer-tax-subsidy-form-table
      data-testid="tax-subsidy-form-table"
      (add)="onAddTaxAndSubsidies()"
      (edit)="onEditTaxAndSubsidies($event)"
      (delete)="onDeleteTaxAndSubsidies($event)"
      [nettingList]="nettingList$ | async"
      [data]="taxAndSubs$ | async"
      [references]="refs"
      [searchPredicate]="searchPredicate"
      formControlName="taxAndSubsidies"
      [isViewOnly]="isViewOnly || mode === 'read'"
    ></prosumer-tax-subsidy-form-table>
  </mat-expansion-panel>

  <prosumer-stepper-nav
    data-testid="netting-stepper-nav"
    [disabled]="disabled"
    [previousDisabled]="previousDisabled"
    [previousLabel]="previousLabel"
    [previousColor]="previousColor"
    [nextDisabled]="nextDisabled"
    [nextLabel]="nextLabel"
    [nextColor]="nextColor"
    [reverseButtons]="reverseButtons"
    (previous)="onClickPrevious()"
  >
  </prosumer-stepper-nav>
</form>
