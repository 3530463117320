import { Equipment } from 'prosumer-app/+scenario/models';
import { VariationFinder } from 'prosumer-app/+scenario/services';
import {
  CustomValidators,
  FormDialogComponent,
  FormService,
  fadeInAnimation,
} from 'prosumer-app/libs/eyes-shared';
import { of } from 'rxjs';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  YearlyValues,
  expandYearlyValuesIfApplicable,
} from 'prosumer-app/shared';
import { provideUpserter } from 'prosumer-app/stores';
import { ShortCircuitEquipmentStore } from 'prosumer-app/stores/short-circuit-equipment';
import { SCEquipmentFormData } from './sc-equipment-form-dialog.model';

@UntilDestroy()
@Component({
  selector: 'prosumer-sc-squipment-form-dialog',
  templateUrl: './sc-equipment-form-dialog.component.html',
  styleUrls: ['./sc-equipment-form-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideUpserter(ShortCircuitEquipmentStore)],
  animations: [fadeInAnimation],
})
export class SCEquipmentFormDialogComponent
  extends FormDialogComponent<
    SCEquipmentFormDialogComponent,
    SCEquipmentFormData
  >
  implements OnInit
{
  equipmentOptions: Array<Equipment> = [];

  get equipmentIdControl() {
    return this.form.controls.equipmentId;
  }

  // istanbul ignore next
  get factorControl() {
    return this.form.controls.shortCircuitFactor;
  }

  // istanbul ignore next
  get thresholdControl() {
    return this.form.controls.shortCircuitThreshold;
  }

  startYear: number;
  endYear: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SCEquipmentFormData,
    public changeDetector: ChangeDetectorRef,
    public formService: FormService,
    public dialogRef: MatDialogRef<SCEquipmentFormDialogComponent>,
    public formBuilder: UntypedFormBuilder,
    private readonly varFinder: VariationFinder,
  ) {
    super(null, changeDetector, dialogRef, formBuilder);
  }

  defineForm() {
    return {
      equipmentId: ['', [Validators.required]],
      shortCircuitFactor: ['', Validators.required],
      shortCircuitThreshold: ['', Validators.required],
    };
  }

  updateValidators() {
    this.equipmentIdControl.setAsyncValidators([
      CustomValidators.dataExist(
        !!this.data.equipments && this.data.equipments.length > 0
          ? of(this.data.equipments)
          : null,
        'equipmentId',
        this.data.equipment,
      ),
    ]);
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.data.equipmentOptions) {
      this.data.equipmentOptions
        .map((equipment) => this.toWithVariationInName(equipment))
        .forEach((option) => this.equipmentOptions.push(option));
    }
    this.updateValidators();
    this.initData();
    this.take1FromFirstFormChangeForManualChangeDetection();
  }

  initData() {
    this.form.patchValue({
      equipmentId: this.data.equipment.equipmentId,
      shortCircuitFactor: this.sanitizeYearlyValues(
        this.data.equipment.shortCircuitFactor,
      ),
      shortCircuitThreshold: this.sanitizeYearlyValues(
        this.data.equipment.shortCircuitThreshold,
      ),
    });
    this.startYear = this.data ? this.data.startYear : 0;
    this.endYear = this.data ? this.data.endYear : 0;
  }

  private sanitizeYearlyValues(values: YearlyValues): YearlyValues {
    return expandYearlyValuesIfApplicable(values, this.getStartEndPeriod());
  }

  private getStartEndPeriod(): [number, number] {
    return [this.data.startYear, this.data.endYear];
  }

  private take1FromFirstFormChangeForManualChangeDetection(): void {
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      this.form.markAsDirty();
      this.changeDetector.detectChanges();
    });
  }

  onClose() {
    this.dialogRef.close();
  }

  onSaveSuccess(): void {
    this.dialogRef.close();
  }

  onSaveAttempt(): void {
    this.changeDetector.detectChanges();
  }

  private toWithVariationInName(equipment: Equipment): Equipment {
    return {
      ...equipment,
      name: this.concatenateVariationName(equipment.name, equipment),
    };
  }

  private concatenateVariationName(name: string, equipment: Equipment): string {
    return `${name} (${this.varFinder.getVariationName(
      equipment.scenarioVariation,
    )})`;
  }
}
