import { Injectable } from '@angular/core';
import { StoreConfig } from '@datorama/akita';
import { CommoditiesMapper } from 'prosumer-app/+scenario/services/mappers';
import { ScenarioDetailStore, ScenarioDetailType } from '../scenario-detail';
import { MarketLimit, MarketLimitBE } from './market-limit.state';

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: ScenarioDetailType.marketLimit })
export class MarketLimitStore extends ScenarioDetailStore<MarketLimit> {
  getDataUuid(data: Record<string, unknown>): unknown {
    return data['id'];
  }

  toFE(from: unknown): MarketLimit {
    return CommoditiesMapper.egcLimitToFE(from);
  }

  toBE(from: MarketLimit): MarketLimitBE {
    return CommoditiesMapper.egcLimitToBE(from);
  }
}
