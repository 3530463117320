import { Injectable } from '@angular/core';
import {
  ReducedResultNameValues,
  ReducedSliceData,
} from '@prosumer/results/components/bar-and-area-results';
import {
  ResultNameValue,
  ResultVisualizerSlice,
} from '@prosumer/results/components/results-visualizer';
import { EmissionsData } from '@prosumer/results/models';
import { ScenarioCompareFacadeService } from 'prosumer-app/+scenario/state/scenario-compare/scenario-compare-facade.service';
import { iif, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ComparisonData, ComparisonTray } from '../base';
import { ComparisonVisualizerService } from '../comparison-visualizer';
import { ComparisonPreparerService } from '../state';

export enum EmissionFilters {
  overall = 'year',
  type = 'type',
  scope = 'scope',
  node = 'node',
  name = 'name',
}

@Injectable()
export class EmissionComparisonService extends ComparisonVisualizerService<EmissionsData> {
  constructor(
    private preparer: ComparisonPreparerService,
    private compareFacadeService: ScenarioCompareFacadeService,
  ) {
    super();
  }
  getSlices(): ResultVisualizerSlice[] {
    return Object.keys(EmissionFilters).map((name) => ({ name }));
  }
  getFilterKeys(): string[] {
    return Object.values(EmissionFilters);
  }
  getAllYearsStream(): Observable<number[]> {
    return this.preparer.getAllYears$();
  }
  getComparisonTray$(): Observable<ComparisonTray<EmissionsData>> {
    const isOutputSplit$ = this.compareFacadeService.isOutputSplit$;
    const emissionsResult$ = this.compareFacadeService.emissionsResult$;
    const preparedEmissionsResult$ = this.preparer.getEmissionTray$();
    return isOutputSplit$.pipe(
      mergeMap((isOutputSplit) =>
        iif(() => isOutputSplit, emissionsResult$, preparedEmissionsResult$),
      ),
    );
  }

  buildSliceData(raw: EmissionsData[]): ReducedSliceData {
    const scenarioNames = this.collectScenarioNames(raw);
    return this.getSlices().reduce((acc, slice) => {
      acc[slice.name] = this.mapScenarioValuesToNameValues(
        scenarioNames,
        raw,
        slice.name,
      );
      return acc;
    }, {});
  }

  private mapScenarioValuesToNameValues(
    names: string[],
    data: ComparisonData[],
    slice: string,
  ): ReducedResultNameValues {
    return names.reduce((acc, scenarioName) => {
      const scenarioValues = data.filter(
        (d) => d.scenarioName === scenarioName,
      );
      acc[scenarioName] = this.mapDataToNameValue(
        slice,
        'value',
        scenarioValues,
      );
      return acc;
    }, {});
  }

  private mapDataToNameValue(
    sliceName: string,
    slice: string,
    raw: ComparisonData[],
  ): ResultNameValue[] {
    return raw.map((data) => ({
      name: data[EmissionFilters[sliceName]].toString(),
      value: data[slice],
    }));
  }
}
