<button
  data-testid="perception-trigger"
  class="perception-trigger"
  [ngClass]="{
    'perception-trigger-solo': isLinkShowing$ | async
  }"
  mat-fab
  color="primary"
  (click)="onTrigger()"
>
  <mat-icon aria-hidden="false">palette</mat-icon>
</button>
