import { Injectable } from '@angular/core';
import { StoreConfig } from '@datorama/akita';
import { MobilityMapper } from 'prosumer-app/+scenario/services/mappers';
import { Observable } from 'rxjs';

import { ScenarioDetailStore, ScenarioDetailType } from '../scenario-detail';
import { MobilityStation, MobilityStationBE } from './mobility-station.state';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: ScenarioDetailType.mobilityStation })
export class MobilityStationStore extends ScenarioDetailStore<MobilityStation> {
  toBE(from: MobilityStation): MobilityStationBE {
    return MobilityMapper.stationToBE(from);
  }

  toFE(from: MobilityStationBE): MobilityStation {
    return MobilityMapper.stationToFE(from);
  }

  create(data: MobilityStation): Observable<unknown> {
    return super.create(this.removeIdSoBEWouldNotBeConfused(data));
  }

  private removeIdSoBEWouldNotBeConfused(
    data: MobilityStation,
  ): MobilityStation {
    return { ...data, id: undefined };
  }
}
