import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LetDirective, PushPipe } from '@ngrx/component';
import { LoadDuplicateDialogComponent } from './load-duplicate-dialog.component';
import { ScenarioDetailsDuplicateDialogModule } from '../../scenario-details-duplicate-dialog';

@NgModule({
  declarations: [LoadDuplicateDialogComponent],
  imports: [
    CommonModule,
    ScenarioDetailsDuplicateDialogModule,
    LetDirective,
    PushPipe,
  ],
  exports: [LoadDuplicateDialogComponent],
})
export class LoadDuplicateDialogModule {}
