import { Injectable } from '@angular/core';
import { EquipmentResultsService } from '@prosumer/results/components/equipment-results';
import { ResultVisualizerSlice } from '@prosumer/results/components/results-visualizer';
import { EquipmentResult } from '@prosumer/results/models';
import { ScenarioCompareFacadeService } from 'prosumer-app/+scenario/state/scenario-compare/scenario-compare-facade.service';
import { iif, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ComparisonTray } from '../base';
import { ComparisonVisualizerService } from '../comparison-visualizer';
import { ComparisonPreparerService } from '../state';

@Injectable()
export class EquipmentComparisonService extends ComparisonVisualizerService<EquipmentResult> {
  constructor(
    private equipmentResults: EquipmentResultsService,
    private preparer: ComparisonPreparerService,
    private compareFacadeService: ScenarioCompareFacadeService,
  ) {
    super();
  }

  getSlices(): ResultVisualizerSlice[] {
    return this.equipmentResults.getSlices();
  }
  getFilterKeys(): string[] {
    return this.equipmentResults.getFilterKeys();
  }
  getAllYearsStream(): Observable<number[]> {
    return this.preparer.getAllYears$();
  }
  getComparisonTray$(): Observable<ComparisonTray<EquipmentResult>> {
    const isOutputSplit$ = this.compareFacadeService.isOutputSplit$;
    const equipmentsResult$ = this.compareFacadeService.equipmentsResult$;
    const preparedEquipmentsResult$ = this.preparer.getEquipmentTray$();
    return isOutputSplit$.pipe(
      mergeMap((isOutputSplit) =>
        iif(() => isOutputSplit, equipmentsResult$, preparedEquipmentsResult$),
      ),
    );
  }
}
