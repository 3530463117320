<div fxLayout="column" fxLayoutAlign="start stretch">
  <mat-form-field appearance="outline" class="eyes-spacer-half">
    <mat-label>Storage</mat-label>
    <mat-select
      [formControl]="storageControl"
      [hideSingleSelectionIndicator]="true"
    >
      <mat-option *ngFor="let option of options" [value]="option">
        {{ option }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <prosumer-stacked-bar-chartjs
    [id]="randomChartID"
    *ngIf="(storageSoc$ | async) && (storageCharge$ | async)"
    [data]="chartData$ | async"
    [line]="lineData$ | async"
    [colors]="colors$ | async"
    resultsName="compare_raw_storage_dispatch"
  ></prosumer-stacked-bar-chartjs>
</div>
