import { ProfileBE } from 'prosumer-app/+scenario/models';
import { Utils } from 'prosumer-app/core/utils';
import {
  mapVariationToBackend,
  mapVariationToFrontend,
  mapYearlyProfileToBackend,
  mapYearlyProfileToFrontend,
} from 'prosumer-app/shared/utils';
import { EGCEmission, EGCEmissionBE } from 'prosumer-app/stores/egc-emission';

const PROFILE_NAME = 'Emissions Rate';

export class EmissionMapper {
  static mapToBE = (emission: EGCEmission): EGCEmissionBE => ({
    id: emission.id,
    name: emission.marketName,
    nodeId: emission.node,
    scope: emission.scope,
    connectedEgcId: emission.marketName,
    scenarioUuid: emission.scenarioUuid,
    emissionsRate: {
      name: PROFILE_NAME,
      profiles: emission.profiles.map((profile) =>
        mapYearlyProfileToBackend(profile),
      ),
    },
    scenarioVariation: mapVariationToBackend(emission),
    tdbFilters: emission.tdbFilters,
  });

  static mapToFE = (emission: EGCEmissionBE): EGCEmission => ({
    id: emission.id,
    marketName: emission.connectedEgcId,
    node: emission.nodeId,
    scope: emission.scope,
    connectedEgcId: emission.connectedEgcId,
    scenarioUuid: emission.scenarioUuid,
    profiles: EmissionMapper.extractProfiles(emission).map((profile) =>
      mapYearlyProfileToFrontend(profile, 'ENERGY_GRID_CONNECTIONS_EMISSIONS'),
    ),
    scenarioVariation: mapVariationToFrontend(emission),
    tdbFilters: emission.tdbFilters,
  });

  private static extractProfiles(from: EGCEmissionBE): ProfileBE[] {
    return Utils.resolveToEmptyArray(
      Utils.resolveToEmptyObject(from.emissionsRate).profiles,
    );
  }
}
