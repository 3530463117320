import { FEATURES } from 'prosumer-app/app-routing.module';
import { CaseResolverService } from 'prosumer-case/services/case-resolver.service';
import { UserInfoResolver } from 'prosumer-core/guards/user-info/user-info.guard';
import { ProjectResolverService } from 'prosumer-project/services/project-resolver.service';

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ScenarioDetailsComponent } from './components/scenario-details/scenario-details.component';
import {
  ScenarioComparePageComponent,
  ScenarioCompareResolverService,
  ScenarioPageComponent,
  ScenarioSimulationComponent,
  ScenarioUploadPageComponent,
  SimulationResultResolverService,
  SimulationResultsComponent,
} from './containers';
import { ScenarioGenericResolver } from './routing/scenario-generic.resolver';
import { ScenarioResolverService } from './services/scenario-resolver.service';
import { ScenarioStepperResolverService } from './services/scenario-stepper-resolver.service';

const routes: Routes = [
  {
    path: 'create',
    component: ScenarioPageComponent,
    data: {
      breadcrumb: 'Create Scenario',
      mode: 'create',
      reference: false,
      trim: false,
    },
    resolve: {
      user: UserInfoResolver,
    },
  },
  {
    path: 'upload',
    component: ScenarioUploadPageComponent,
    data: {
      breadcrumb: 'Upload Scenario',
      mode: 'create',
      reference: false,
      trim: false,
    },
    resolve: [ProjectResolverService, CaseResolverService, UserInfoResolver],
  },
  {
    path: 'compare',
    component: ScenarioComparePageComponent,
    data: {
      breadcrumb: 'Compare Scenario',
      mode: 'create',
      reference: false,
      trim: false,
    },
    resolve: [
      ProjectResolverService,
      CaseResolverService,
      ScenarioCompareResolverService,
    ],
  },
  {
    path: 'running',
    component: ScenarioSimulationComponent,
    data: {
      reference: false,
      title: FEATURES.scenarios.title,
      multipleUpload: true,
    },
  },
  {
    path: ':scenarioId',
    component: ScenarioDetailsComponent,
    data: {
      breadcrumb: '*',
      referenceKey: 'scenarioId',
      reference: true,
    },
    children: [
      {
        path: 'compare',
        component: ScenarioComparePageComponent,
        data: {
          reference: false,
          title: FEATURES.scenarios.title,
        },
        resolve: [ScenarioCompareResolverService],
      },
      {
        path: 'view',
        component: ScenarioPageComponent,
        data: {
          mode: 'read',
          reference: false,
          title: FEATURES.scenarios.title,
        },
        resolve: [ScenarioStepperResolverService],
      },
      {
        path: 'update',
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        component: ScenarioPageComponent,
        data: {
          mode: 'update',
          reference: false,
          title: FEATURES.scenarios.title,
        },
        resolve: [ScenarioStepperResolverService],
      },
      {
        path: 'running',
        component: ScenarioSimulationComponent,
        data: {
          reference: false,
          title: FEATURES.scenarios.title,
        },
      },
      {
        path: 'results',
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        component: SimulationResultsComponent,
        resolve: {
          results: SimulationResultResolverService,
        },
        data: {
          reference: false,
          title: FEATURES.scenarios.title,
        },
      },
      {
        path: '**',
        redirectTo: `${FEATURES.error.path}`,
      },
    ],
    resolve: [
      UserInfoResolver,
      ProjectResolverService,
      CaseResolverService,
      ScenarioResolverService,
      ScenarioGenericResolver,
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ScenarioRoutingModule {}
