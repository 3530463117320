<prosumer-sparkline-table
  [columnsDef]="tableMetaData"
  [references]="references$ | ngrxPush"
  [data]="vehicleDispatchList$ | async"
  [searchInput]="true"
  (add)="onAddVehiclesDispatch()"
  (edit)="onEditVehiclesDispatch($event)"
  (delete)="onDeleteVehiclesDispatch($event)"
  [hideAdd]="isViewOnly"
  [hideEdit]="isViewOnly"
  [hideDelete]="isViewOnly"
  [hideView]="!isViewOnly"
>
</prosumer-sparkline-table>
