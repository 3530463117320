import { fadeInAnimation } from 'prosumer-app/libs/eyes-shared';

import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { filterNilValue } from '@datorama/akita';
import { ProsumerView } from 'prosumer-app/+scenario/models';
import {
  YearlyLoadsValidator,
  mapYearlyValuesToBackend,
} from 'prosumer-app/shared';
import { UpdateStatus } from 'prosumer-app/shared/directives/scenario-updater';
import {
  Limits,
  ScenarioGenericQuery,
  ScenarioUpdateKey,
} from 'prosumer-app/stores/scenario-generic';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { LimitsFormMapper } from './limits-form.mapper';

import {
  BEYearlyModel,
  FormYearlyModel,
  LimitsLoadingMap,
  OptionLimits,
  OutgoingLimits,
} from './limits-form.models';

@Component({
  selector: 'prosumer-limits-form-component',
  templateUrl: './limits-form.component.html',
  styleUrls: ['./limits-form.component.scss'],
  animations: [fadeInAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LimitsFormComponent implements OnInit {
  private readonly limitKeyLoading = new BehaviorSubject<LimitsLoadingMap>({
    globalMaximumCosts: false,
    globalMaximumEmissions: false,
    yearlyMaximumCosts: false,
    yearlyMaximumEmissions: false,
  });

  private readonly minValue = 0;

  @Input() scenarioIdentity: unknown = {};
  @Input() isViewOnly: boolean;

  readonly loadingMap$ = this.limitKeyLoading.asObservable();
  readonly form = new UntypedFormGroup({
    yearlyMaximumEmissions: new UntypedFormControl(),
    yearlyMaximumCosts: new UntypedFormControl(),
    globalMaximumEmissions: new UntypedFormControl(null, [
      YearlyLoadsValidator.yearlyValuesMin(this.minValue),
    ]),
    globalMaximumCosts: new UntypedFormControl(null, [
      YearlyLoadsValidator.yearlyValuesMin(this.minValue),
    ]),
  });
  readonly updateKey: ScenarioUpdateKey = 'limits';
  readonly updateView: ProsumerView = ProsumerView.optimizationCockpit;
  readonly yearlyMaxEmissions$ = this.selectYearlyMaxEmissions();
  readonly yearlyMaxCosts$ = this.selectYearlyMaxCosts();
  readonly globalMaxEmissions$ = this.selectGlobalEmissions();
  readonly globalMaxCosts$ = this.selectGlobalMaxCosts();
  scenarioName$: Observable<string>;

  startYear$: Observable<number>;
  endYear$: Observable<number>;
  startYearValue?: number;
  endYearValue?: number;

  get yearlyMaximumEmissionsCtr() {
    return this.form.controls.yearlyMaximumEmissions;
  }

  get yearlyMaximumCostsCtrl() {
    return this.form.controls.yearlyMaximumCosts;
  }

  get globalMaximumEmissionsCtrl() {
    return this.form.controls.globalMaximumEmissions;
  }

  get globalMaximumCostsCtrl() {
    return this.form.controls.globalMaximumCosts;
  }

  constructor(private readonly scenarioQuery: ScenarioGenericQuery) {}

  ngOnInit(): void {
    this.startYear$ = this.selectStartYear();
    this.endYear$ = this.selectEndYear();
    this.waitForYearValuesToInitialize();
    this.initScenarioName();
  }

  private initScenarioName() {
    this.scenarioName$ = this.scenarioQuery
      .selectActive()
      .pipe(map((scenario) => scenario?.name));
  }

  onYearlyEmissionsChange(change: UpdateStatus): void {
    this.setLoadingFor('yearlyMaximumEmissions', change);
  }

  onYearlyCostsChange(change: UpdateStatus): void {
    this.setLoadingFor('yearlyMaximumCosts', change);
  }

  onGlobalEmissionsChange(change: UpdateStatus): void {
    this.setLoadingFor('globalMaximumEmissions', change);
  }

  onGlobalCostsChange(change: UpdateStatus): void {
    this.setLoadingFor('globalMaximumCosts', change);
  }

  private waitForYearValuesToInitialize() {
    combineLatest([this.startYear$, this.endYear$])
      .pipe(
        filter(([startYear, endYear]) => !!startYear && !!endYear),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        tap(([startYear, endYear]) => {
          this.startYearValue = startYear;
          this.endYearValue = endYear;
        }),
        switchMap((_) => this.take1FromActiveLimitsForFormPatching()),
      )
      .subscribe((limits) => {
        this.form.patchValue(limits, { emitEvent: false });
      });
  }
  private take1FromActiveLimitsForFormPatching(): Observable<Limits> {
    return this.selectTruthyLimits().pipe(
      map((incoming) => this.mapToForm(incoming)),
    );
  }

  private selectTruthyLimits(): Observable<Limits> {
    return this.scenarioQuery.selectActiveLimits().pipe(filterNilValue());
  }

  private mapToForm(from: Limits): any {
    return LimitsFormMapper.toFE(from as OptionLimits, [
      this.startYearValue,
      this.endYearValue,
    ]);
  }

  private selectStartYear(): Observable<number> {
    return this.scenarioQuery
      .selectActivePeriod()
      .pipe(map(([startYear]) => startYear));
  }

  private selectEndYear(): Observable<number> {
    return this.scenarioQuery
      .selectActivePeriod()
      .pipe(map(([, endYear]) => endYear));
  }

  private setLoadingFor(
    key: keyof LimitsLoadingMap,
    change: UpdateStatus,
  ): void {
    this.setLimitLoading(key, this.isLoading(change));
  }

  private isLoading(change: UpdateStatus): boolean {
    return change.status === 'loading';
  }

  private setLimitLoading(key: keyof LimitsLoadingMap, value: boolean): void {
    this.limitKeyLoading.next({
      ...this.limitKeyLoading.getValue(),
      [key]: value,
    });
  }

  private selectYearlyMaxEmissions(): Observable<OutgoingLimits> {
    return this.selectYearlyMaxEmissionsValueChanges().pipe(
      map((value) => ({
        yearlyMaximumEmissions: this.toOutgoingYearlyValues(value),
      })),
    );
  }

  private selectYearlyMaxCosts(): Observable<OutgoingLimits> {
    return this.selectYearlyMaxCostsValueChanges().pipe(
      map((value) => ({
        yearlyMaximumCosts: this.toOutgoingYearlyValues(value),
      })),
    );
  }

  private selectGlobalEmissions(): Observable<OutgoingLimits> {
    return this.selectGlobalEmissionsChanges().pipe(
      filter(() => this.form.controls.globalMaximumEmissions.valid),
      tap(
        (val) =>
          val &&
          this.globalMaximumEmissionsCtrl.markAsTouched({ onlySelf: true }),
      ),
      map((value) => this.toPartialGlobalEmissions(value)),
    );
  }

  private selectGlobalMaxCosts(): Observable<OutgoingLimits> {
    return this.selectGlobalMaxCostsValueChanges().pipe(
      filter(() => this.form.controls.globalMaximumCosts.valid),
      tap(
        (val) =>
          val && this.globalMaximumCostsCtrl.markAsTouched({ onlySelf: true }),
      ),
      map((value) => this.toPartialGlobalCosts(value)),
    );
  }

  private toPartialGlobalCosts(value: unknown): OutgoingLimits {
    // eslint-disable-next-line
    return {
      globalMaximumCosts: value == 0 ? '0' : this.stringifyOrNullify(value),
    };
  }

  private toPartialGlobalEmissions(value: unknown): OutgoingLimits {
    // eslint-disable-next-line
    return {
      globalMaximumEmissions: value == 0 ? '0' : this.stringifyOrNullify(value),
    };
  }

  private stringifyOrNullify(value: unknown): string | null {
    if ([value != null, String(value).length > 0].every(Boolean)) {
      return String(value);
    }
    return null;
  }

  private selectYearlyMaxEmissionsValueChanges(): Observable<FormYearlyModel> {
    return this.selectControlValueChanges(
      'yearlyMaximumEmissions',
    ) as Observable<FormYearlyModel>;
  }

  private selectYearlyMaxCostsValueChanges(): Observable<FormYearlyModel> {
    return this.selectControlValueChanges(
      'yearlyMaximumCosts',
    ) as Observable<FormYearlyModel>;
  }

  private selectGlobalEmissionsChanges(): Observable<string> {
    return this.selectControlValueChanges(
      'globalMaximumEmissions',
    ) as Observable<string>;
  }

  private selectGlobalMaxCostsValueChanges(): Observable<string> {
    return this.selectControlValueChanges(
      'globalMaximumCosts',
    ) as Observable<string>;
  }

  private selectControlValueChanges(controlName: string): Observable<unknown> {
    return this.form.get(controlName).valueChanges;
  }

  private toOutgoingYearlyValues(from: FormYearlyModel): BEYearlyModel {
    return mapYearlyValuesToBackend(from) as BEYearlyModel;
  }
}
