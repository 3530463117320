<div fxLayout="column">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="start" fxFlex>
      <ng-content></ng-content>
    </div>

    <div fxLayout="row" fxLayoutAlign="end stretch" fxLayoutGap="8px">
      <nav mat-tab-nav-bar *ngrxLet="urlTail$ as tail" [tabPanel]="tabPanel">
        <a
          mat-tab-link
          class="h-auto px-9"
          data-testid="toInputs"
          [active]="tail === 'update' || tail === 'view'"
          [disabled]="inputDisabled()"
          (click)="onToInputs()"
        >
          Input Data
        </a>
        <button
          mat-button
          type="button"
          color="primary"
          class="min-w-12 h-12"
          data-testid="download-input-data"
          [class.spinner]="inputsing$ | ngrxPush"
          [disabled]="inputsing$ | ngrxPush"
          (click)="onDownloadInputData($event)"
        >
          <mat-icon
            aria-hidden="false"
            aria-label="Download Input File"
            eyesTooltip="Download Input File"
            tooltipPosition="center"
            class="m-0"
          >
            save_alt
          </mat-icon>
        </button>
        <a
          mat-tab-link
          class="h-auto px-9"
          data-testid="toResults"
          [active]="tail === 'results'"
          [disabled]="outputDisabled()"
          (click)="onToResults()"
        >
          Results
        </a>
        <button
          mat-button
          type="button"
          color="primary"
          class="min-w-12 h-12"
          data-testid="scenario-download-output"
          [class.spinner]="resultsing$ | ngrxPush"
          [disabled]="resultsing$ | ngrxPush"
          [disabled]="outputDisabled()"
          (click)="onDownloadOutput($event)"
        >
          <mat-icon
            aria-hidden="false"
            aria-label="Download Output File"
            eyesTooltip="Download Output File"
            tooltipPosition="center"
            class="m-0"
          >
            save_alt
          </mat-icon>
        </button>
      </nav>
      <mat-divider vertical></mat-divider>
      <button
        mat-button
        class="h-auto"
        data-testid="actions"
        [matMenuTriggerFor]="menu"
      >
        <mat-icon aria-hidden="false">more_vert</mat-icon>
      </button>
      <mat-menu
        #menu="matMenu"
        xPosition="before"
        yPosition="below"
        overlapTrigger
      >
        <prosumer-scenario-actions
          [scenario]="scenario$ | ngrxPush"
          [canDelete]="true"
        ></prosumer-scenario-actions>
      </mat-menu>
    </div>
  </div>
</div>
<mat-tab-nav-panel #tabPanel>
  <!-- <router-outlet></router-outlet> -->
</mat-tab-nav-panel>
