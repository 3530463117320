import moment from 'moment';
import {
  DIRECTIONS,
  RESULTS_DISPATCH_DAY_FORMAT,
  RESULTS_DISPATCH_HOUR_FORMAT,
  RESULTS_DISPATCH_MONTH_FORMAT,
} from 'prosumer-app/app.references';
import {
  DateFilterService,
  ProfileFormHelperService,
  YearlyLoadsIntevalValidators,
} from 'prosumer-app/shared';
import { provideUpserter, ScenarioBinStore } from 'prosumer-app/stores';
import { ReserveMarginCreator } from 'prosumer-app/stores/spinning-reserve-margin';
import {
  ALLOWABLE_KEYS,
  CustomValidators,
  fadeInAnimation,
  FormDialogComponent,
  isKeyPressedNumber,
  stringToArray,
} from 'prosumer-libs/eyes-shared';
import { EnergyVector, LibraryLoads, Profile } from 'prosumer-scenario/models';
import { ScenarioFacadeService } from 'prosumer-scenario/state';
import { combineLatest, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  take,
} from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ReserveMarginFormDialogData } from './margin-form-dialog.model';

const DEFAULT_TOOLTIP_FORMAT = `${RESULTS_DISPATCH_MONTH_FORMAT} ${RESULTS_DISPATCH_DAY_FORMAT} ${RESULTS_DISPATCH_HOUR_FORMAT}`;

@Component({
  selector: 'prosumer-reserve-margin-form-dialog',
  templateUrl: './margin-form-dialog.component.html',
  styleUrls: ['./margin-form-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInAnimation],
  providers: [
    DateFilterService,
    ProfileFormHelperService,
    provideUpserter(ReserveMarginCreator),
  ],
})
export class ReserveMarginFormDialogComponent
  extends FormDialogComponent<
    ReserveMarginFormDialogComponent,
    ReserveMarginFormDialogData
  >
  implements OnInit
{
  energyVectorOptions: Array<EnergyVector> = [];
  directions = DIRECTIONS;
  selectedLoads: Array<string> = [];
  activeIndex = 0;
  startYear: number;
  endYear: number;
  yearlyLoadMessage = {
    loadTypeLabel: 'Scenario.labels.technical.profile',
    loadTypeTooltip: 'wizard_renewable.wizard_renewable_profile',
    loadsDataPlaceholder: 'Paste profile here',
    loadsDataLabelCustom: 'Scenario.labels.technical.profileKw',
    loadsDataTooltip: 'wizard_loads.wizard_loards_load_profile',
    loadsDataRequired: 'Scenario.messages.loads.required',
  };

  sortedDirections = Object.keys(DIRECTIONS)
    .map((dirKey) => ({
      key: dirKey,
      value: DIRECTIONS[dirKey],
    }))
    .sort();

  reserveMarginValue$ = this.reserveMarginControl.valueChanges.pipe(
    startWith(this.reserveMarginControl.value),
    this.takeUntilShare(),
  );

  directionValue$ = this.directionControl.valueChanges.pipe(
    startWith(this.directionControl.value),
    this.takeUntilShare(),
  );

  chartData$ = this.reserveMarginValue$.pipe(
    filter((value) => !!value),
    distinctUntilChanged(),
    debounceTime(400),
    map((value) => stringToArray(value)),
    this.takeUntilShare(),
  );

  filteredChartData$ = combineLatest([
    this.chartData$,
    this.dateFilter.dateChange$,
  ]).pipe(this.dateFilter.filter$('Reserve Margin'), this.takeUntilShare());

  binaryLoading$ = this._binaryStore.loading$.pipe(this.takeUntilShare());

  chartXAxisFormat = (value: any) =>
    moment(value).format(DEFAULT_TOOLTIP_FORMAT);
  chartTooltipTitleFormat = (obj: any) =>
    moment((obj || {}).name).format(DEFAULT_TOOLTIP_FORMAT);

  get energyVectorIdControl() {
    return this.form.controls.energyVectorId;
  }

  get directionControl() {
    return this.form.controls.direction;
  }

  get reserveMarginControl() {
    return this.form.controls.reserveMargin;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ReserveMarginFormDialogData,
    public changeDetector: ChangeDetectorRef,
    public profileFormHelperService: ProfileFormHelperService,
    public dateFilter: DateFilterService,
    public dialogRef: MatDialogRef<ReserveMarginFormDialogComponent>,
    public formBuilder: UntypedFormBuilder,
    public scenarioFacade: ScenarioFacadeService,
    private _binaryStore: ScenarioBinStore,
  ) {
    super(null, changeDetector, dialogRef, formBuilder);
  }

  defineForm() {
    return {
      energyVectorId: ['', [Validators.required]],
      direction: [DIRECTIONS.up.toLowerCase(), Validators.required],
      reserveMargin: [''], // TODO should be removed since its no longer used
      profiles: [],
      startYear: !!!this.data ? 0 : this.data.startYear,
      endYear: !!!this.data ? 0 : this.data.endYear,
    };
  }

  ngOnInit() {
    super.ngOnInit();
    this.initData();
    this.energyVectorOptions = this.data.energyVectorOptions || [];
    this.updateValidators();
    this.directionValue$.subscribe((_) =>
      this.energyVectorIdControl.updateValueAndValidity(),
    );
    this.binaryLoading$.subscribe((isIt) =>
      isIt
        ? this.reserveMarginControl.disable()
        : this.reserveMarginControl.enable(),
    );
  }

  updateValidators() {
    this.energyVectorIdControl.setAsyncValidators([
      CustomValidators.dataExist(
        !!this.data.currentReserveMarginList &&
          this.data.currentReserveMarginList.length > 0
          ? of(this.data.currentReserveMarginList)
          : null,
        'energyVectorId',
        this.data.currentReserveMargin,
        { direction: this.directionControl as UntypedFormControl },
      ),
    ]);

    this.controls.profiles.setValidators([
      YearlyLoadsIntevalValidators.yearlyLoadsValid(false, true, false),
    ]);
  }

  onSaveSuccess(): void {
    this.dialogRef.close();
  }

  onSaveAttempt(): void {
    this.changeDetector.detectChanges();
  }

  onKeyPress(event: KeyboardEvent) {
    if (
      !isKeyPressedNumber(event, [
        ALLOWABLE_KEYS.decimalPoint,
        ALLOWABLE_KEYS.expConst,
        ALLOWABLE_KEYS.expConstUpper,
      ])
    ) {
      event.preventDefault();
    }
  }

  onSelectInterval(value: { selectedProfile: Profile; index: number }) {
    this.activeIndex = value.index;
    if (
      this.data.mode === 'edit' &&
      this.profileFormHelperService.forBinDownload(
        this.data.currentReserveMargin.profiles,
        value.selectedProfile.localId,
      )
    ) {
      this.loadBinaryData(value.selectedProfile);
    }
  }

  initData() {
    this.form.patchValue(
      {
        ...this.data.currentReserveMargin,
        startYear: this.data.startYear,
        endYear: this.data.endYear,
        // reserveMargin: arrayToString((this.data.currentReserveMargin || {} as MarginReserve).data) TODO
      } || {},
    );
    this.startYear = this.data.startYear;
    this.endYear = this.data.endYear;
    if (this.data.mode === 'edit') {
      this.form.controls.profiles.patchValue(
        this.data.currentReserveMargin.profiles.map((profile) => ({
          ...profile,
          loadProfile: profile.loadProfile || [],
        })),
      );
      this.loadBinaryData(this.data.currentReserveMargin.profiles[0]);
    }
  }

  loadBinaryData(selectedProfile: Profile) {
    this.loadBinaryData$(selectedProfile)
      .pipe(take(1))
      .subscribe((loads) => {
        if (!!loads) {
          const profile = (loads || ({} as LibraryLoads)).data;
          this.profileFormHelperService.setProfile({
            index: this.activeIndex,
            profiles: profile,
            type: 'bin',
          });
          // this.selectedLoads = (loads || {} as LibraryLoads).data;
        }
      });
  }

  loadBinaryData$(selectedProfile: Profile) {
    if (
      !!selectedProfile &&
      (!!!selectedProfile.loadProfile ||
        selectedProfile.loadProfile.length === 0)
    ) {
      return this._binaryStore
        .get(
          this.data.projectId,
          this.data.caseId,
          this.data.scenarioId,
          selectedProfile.location,
          selectedProfile.localId,
          true,
        )
        .pipe(
          filter((loads) => !!loads),
          take(1),
        );
    }
    return of(null);
  }
}
