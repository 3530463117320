import moment from 'moment';
import {
  RESULTS_DISPATCH_DAY_FORMAT,
  RESULTS_DISPATCH_MONTH_FORMAT,
  RESULTS_DISPATCH_OPTIONS,
} from 'prosumer-app/app.references';
import {
  BaseComponent,
  contains,
  FormFieldOption,
  FormService,
} from 'prosumer-app/libs/eyes-shared';
import { ResultStore } from 'prosumer-app/stores';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import {
  filter,
  shareReplay,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

import { DispatchService } from './dispatch.service';

@Component({
  selector: 'prosumer-dispatch',
  templateUrl: './dispatch.component.html',
  styleUrls: ['./dispatch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DispatchService],
})
export class DispatchComponent extends BaseComponent implements OnInit {
  @Input() caseId: string;
  @Input() scenarioName: string;

  filterForm = this._formService.initForm({
    year: '',
    node: '',
    energyVector: '',
  });

  dispatchOptions$ = of(RESULTS_DISPATCH_OPTIONS);

  yearOptions$: Observable<Array<FormFieldOption<string>>>;
  nodeOptions$: Observable<Array<FormFieldOption<string>>>;
  energyVectorOptions$: Observable<Array<FormFieldOption<string>>>;
  storageOptions$: Observable<Array<FormFieldOption<string>>>;

  animationEnded$ = new BehaviorSubject<boolean>(false);
  loaded$ = new BehaviorSubject<boolean>(false);
  loading$ = new BehaviorSubject<boolean>(true);
  dispatchType$ = new BehaviorSubject<string>('Raw');

  productionHasData$ = new BehaviorSubject<boolean>(false);
  consumptionHasData$ = new BehaviorSubject<boolean>(false);
  storageHasData$ = new BehaviorSubject<boolean>(false);

  /* Slider Configuration */
  @Input() sliderFloor = 1;
  @Input() sliderCeiling = 365;
  @Input() sliderStep = 1;
  @Input() sliderMinRange = 0;
  @Input() sliderMaxRange = 31;
  @Input() sliderDraggableRange = true;
  @Input() sliderInitMinValue = 1;
  @Input() sliderInitMaxValue = 7;
  @Input() sliderShowTicks = true;

  sliderOptions$ = new BehaviorSubject<any>({
    floor: this.sliderFloor,
    ceil: this.sliderCeiling,
    step: this.sliderStep,
    minRange: this.sliderMinRange,
    maxRange: this.sliderMaxRange,
    draggableRange: this.sliderDraggableRange,
    showTicks: this.sliderShowTicks,
  });

  isDaily = false;

  constructor(
    private _dispatchService: DispatchService,
    private _formService: FormService,
    private _resultStore: ResultStore,
  ) {
    super();
  }

  ngOnInit() {
    combineLatest([
      this._resultStore.loading$,
      this._resultStore.isOutputSplit$,
    ])
      .pipe(filter(([loading, isSplit]) => !loading || !isSplit))
      .subscribe(() => {
        this.yearOptions$ = this._resultStore
          .getRawDispatchFilterOptions$('year')
          .pipe(
            take(1),
            filter((yearOptions) => yearOptions && yearOptions.length > 0),
            tap((yearOptions) =>
              this.filterForm.controls.year.patchValue(yearOptions[0].value),
            ),
            takeUntil(this.componentDestroyed$),
            shareReplay(1),
          );

        this.nodeOptions$ = this._resultStore
          .getRawDispatchFilterOptions$('node')
          .pipe(
            take(1),
            filter((nodeOptions) => nodeOptions && nodeOptions.length > 0),
            tap((nodeOptions) =>
              this.filterForm.controls.node.patchValue(nodeOptions[0].value),
            ),
            takeUntil(this.componentDestroyed$),
            shareReplay(1),
          );

        this.energyVectorOptions$ = this._resultStore
          .getRawDispatchFilterOptions$('energyVector')
          .pipe(
            take(1),
            filter(
              (energyVectorOptions) =>
                energyVectorOptions && energyVectorOptions.length > 0,
            ),
            tap((energyVectorOptions) =>
              this.filterForm.controls.energyVector.patchValue(
                energyVectorOptions[0].value,
              ),
            ),
            takeUntil(this.componentDestroyed$),
            shareReplay(1),
          );

        this.storageOptions$ = combineLatest([
          this._dispatchService.year$,
          this._dispatchService.node$,
          this._dispatchService.energyVector$,
        ]).pipe(
          switchMap(([year, node, energyVector]) =>
            this._resultStore.getStorageOptions$(year, node, energyVector),
          ),
          takeUntil(this.componentDestroyed$),
          shareReplay(1),
        );

        this.filterForm.controls.year.valueChanges
          .pipe(takeUntil(this.componentDestroyed$), shareReplay(1))
          .subscribe((year) => this._dispatchService.setYear(year));

        this.filterForm.controls.node.valueChanges
          .pipe(takeUntil(this.componentDestroyed$), shareReplay(1))
          .subscribe((node) => this._dispatchService.setNode(node));

        this.filterForm.controls.energyVector.valueChanges
          .pipe(takeUntil(this.componentDestroyed$), shareReplay(1))
          .subscribe((energyVector) =>
            this._dispatchService.setEnergyVector(energyVector),
          );

        setTimeout(() => this.animationEnded$.next(true), 200);

        this._dispatchService.year$
          .pipe(takeUntil(this.componentDestroyed$))
          .subscribe((year) =>
            this.sliderOptions$.next(this.getSliderUpdatedOptions(+year)),
          );
      });
  }

  onValueChange(selected: MatButtonToggleChange) {
    this.loading$.next(true);
    this.dispatchType$.next(selected.value);
    if (selected.value === 'Avg. Day' || selected.value === 'Avg. Week') {
      this.isDaily = true;
      this.sliderOptions$.next(this.setDailySlider());
    } else {
      this.isDaily = false;
      this.sliderOptions$.next(this.setRawSlider());
    }
  }

  setDailySlider() {
    this.sliderInitMinValue = 1;
    this.sliderInitMaxValue = 30;
    this._dispatchService.setMinDate(1);
    this._dispatchService.setMaxDate(30);
    return {
      ...this.sliderOptions$.getValue(),
      maxRange: 365,
    };
  }

  setRawSlider() {
    this.sliderInitMinValue = 1;
    this.sliderInitMaxValue = 7;
    this._dispatchService.setMinDate(1);
    this._dispatchService.setMaxDate(7);
    return {
      ...this.sliderOptions$.getValue(),
      maxRange: 31,
    };
  }

  onDataLoaded(
    data: Array<any>,
    rawDispatchType: 'production' | 'consumption' | 'storage',
  ): void {
    if (!this.loaded$.getValue()) {
      this.loaded$.next(true);
    }
    switch (rawDispatchType) {
      case 'production':
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        data && data.length > 0
          ? this.productionHasData$.next(true)
          : this.productionHasData$.next(false);
        break;
      case 'consumption':
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        data && data.length > 0
          ? this.consumptionHasData$.next(true)
          : this.consumptionHasData$.next(false);
        break;
      case 'storage':
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        data && data.length > 0
          ? this.storageHasData$.next(true)
          : this.storageHasData$.next(false);
        break;
    }
    this.loading$.next(false);
  }

  getSliderUpdatedOptions(year: number): any {
    if (!year) {
      return this.sliderOptions$.getValue();
    }

    const sliderTicks = [];
    const monthIndexes = Array.from(Array(12).keys());

    let accumulatedDays = 0;
    monthIndexes.forEach((value) => {
      const daysInMonth = moment().year(year).month(value).daysInMonth();
      const monthName = moment()
        .month(value)
        .format(RESULTS_DISPATCH_MONTH_FORMAT);
      const midDayInMonth = daysInMonth / 2;
      const position = accumulatedDays + midDayInMonth;
      accumulatedDays += daysInMonth;
      sliderTicks.push({
        value: accumulatedDays,
        legend: monthName,
        position: position.toFixed(0),
      });
    });

    const sliderOptions = {
      ...this.sliderOptions$.getValue(),
      ceil: accumulatedDays,
      translate: (value: number): string => {
        const date = moment()
          .year(year)
          .dayOfYear(value)
          .format(
            `${RESULTS_DISPATCH_MONTH_FORMAT} ${RESULTS_DISPATCH_DAY_FORMAT}`,
          );
        return date;
      },
      getLegend: (value: number): string => {
        const positions = sliderTicks.map((tick) => tick.position);
        if (contains(positions, '' + value)) {
          return sliderTicks.find((tick) => tick.position === '' + value)
            .legend;
        }
        return '';
      },
    };

    if (this.isDaily) {
      return {
        ...sliderOptions,
        maxRange: accumulatedDays,
      };
    }
    return sliderOptions;
  }

  onMinChange(value: number) {
    this.sliderInitMinValue = value;
    this._dispatchService.setMinDate(value);
  }

  onMaxChange(value: number) {
    this.sliderInitMaxValue = value;
    this._dispatchService.setMaxDate(value);
  }
}
