import { Injectable } from '@angular/core';
import { EnergyGridConnection, Node } from 'prosumer-app/+scenario/models';
import { VariationFinder } from 'prosumer-app/+scenario/services';
import { EnergyGridConnectionQuery } from 'prosumer-app/stores/energy-grid-connection';
import { NodeQuery } from 'prosumer-app/stores/node';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const ALL_NODES = 'ALL';

@Injectable({
  providedIn: 'root',
})
export class EmissionsDuplicateDialogService {
  constructor(
    private readonly egcs: EnergyGridConnectionQuery,
    private readonly variations: VariationFinder,
    private readonly nodes: NodeQuery,
  ) {}

  selectMarketOptions(): Observable<EnergyGridConnection[]> {
    return this.egcs
      .selectAllForActiveScenario()
      .pipe(map((markets) => this.injectVarNamesToMarkets(markets)));
  }

  selectNodeOptions(): Observable<Node[]> {
    return this.nodes.selectActiveNodes();
  }

  onlyNodesOfMarket(nodes: Node[], marketId: string): Node[] {
    return nodes.filter((node) => this.isNodeOfMarket(node, marketId));
  }

  private isNodeOfMarket(node: Node, marketId: string): boolean {
    const marketNodes = this.egcs.getNodesOfMarket(marketId);
    return [
      this.isMarketUsingAllNodes(marketNodes),
      this.inMarketNodes(node, marketNodes),
    ].some(Boolean);
  }

  private isMarketUsingAllNodes(nodes: string[]): boolean {
    return nodes.includes(ALL_NODES);
  }

  private inMarketNodes(node: Node, nodes: string[]): boolean {
    return nodes.includes(node.value);
  }

  private injectVarNamesToMarkets(
    markets: EnergyGridConnection[],
  ): EnergyGridConnection[] {
    return markets.map((market) => this.injectVarNameToMarket(market));
  }

  private injectVarNameToMarket(
    market: EnergyGridConnection,
  ): EnergyGridConnection {
    return { ...market, marketName: this.suffixVarNameToMarketName(market) };
  }

  private suffixVarNameToMarketName(market: EnergyGridConnection): string {
    return `${market.marketName} (${this.variations.getVariationName(
      market.scenarioVariation,
    )})`;
  }
}
