<div class="cashflow-card">
  <ngx-charts-bar-vertical-stacked
    yAxisLabel="Discounted Expenditures [k€]"
    [results]="data"
    [xAxis]="true"
    [yAxis]="true"
    [legendTitle]="'Legend'"
    [legend]="true"
    [showXAxisLabel]="true"
    [showYAxisLabel]="true"
    [showGridLines]="true"
    [showDataLabel]="true"
    [scheme]="highContrastScheme"
    [barPadding]="20"
    (activate)="getSeriesMembers($event)"
    (select)="onSelect($event)"
  >
    <ng-template #tooltipTemplate let-model="model">
      <h4>{{ model.series }}</h4>
      <table>
        <tr *ngFor="let tooltipDatum of tooltipData">
          <td
            align="right"
            [class.selected-tooltip]="model.name === tooltipDatum.name"
          >
            {{ tooltipDatum.name }}
          </td>
          :
          <td
            align="right"
            [class.selected-tooltip]="model.name === tooltipDatum.name"
          >
            {{ tooltipDatum.value | absoluteValuePipe | number: '1.1-1' }} kEUR
          </td>
        </tr>
      </table>
    </ng-template>
  </ngx-charts-bar-vertical-stacked>
</div>
