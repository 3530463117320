import { Directive, OnInit } from '@angular/core';
import { NotificationsService } from 'prosumer-app/shared/services/notification';
import { EnergyVectorStore } from 'prosumer-app/stores/energy-vector';
import { take } from 'rxjs/operators';

@Directive({
  selector: '[prosumerVectorsGetter]',
})
export class VectorsGetterDirective implements OnInit {
  constructor(
    private readonly vectors: EnergyVectorStore,
    private readonly notifs: NotificationsService,
  ) {}

  ngOnInit(): void {
    this.vectors
      .getEnergyVectors({})
      .pipe(take(1))
      .subscribe({
        error: () => this.showError(),
      });
  }

  private showError(): void {
    this.notifs.showError(this.getErrorMsg());
  }

  private getErrorMsg(): string {
    return `Failed to get the energy vectors for this scenario`;
  }
}
