import { Netting } from 'prosumer-app/+scenario';
import {
  BaseControlComponent,
  ColumnDefinition,
} from 'prosumer-app/libs/eyes-shared';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  Self,
} from '@angular/core';
import { NgControl } from '@angular/forms';

@Component({
  selector: 'prosumer-tax-subsidy-form-table',
  templateUrl: './tax-subsidy-form-table.component.html',
  styleUrls: ['./tax-subsidy-form-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaxSubsidyFormTableComponent
  extends BaseControlComponent
  implements OnInit
{
  _nettingList: Array<Netting> = [];

  @Input() set nettingList(netting: Array<Netting>) {
    this._nettingList = netting;
  }

  get nettingList(): Array<Netting> {
    return this._nettingList;
  }

  @Output() add = new EventEmitter<any>();
  @Output() edit = new EventEmitter<any>();
  @Output() delete = new EventEmitter<any>();
  @Input() data: any;
  @Input() references: any;
  @Input() searchPredicate: any;
  @Input() isViewOnly: boolean;

  get disableNewButton() {
    return !this.nettingList.length;
  }

  constructor(
    @Self() @Optional() public ngControl: NgControl,
    public changeDetector: ChangeDetectorRef,
  ) {
    super(ngControl, changeDetector);
  }

  columnsDef: ColumnDefinition = {
    netting: {
      name: 'Netting',
      type: 'reference',
      referenceKey: 'netting',
      toolTip: 'wizard_netting.wizard_tax_netting',
      sortable: true,
    },
    nodes: {
      name: 'Nodes',
      type: 'referenceList',
      referenceKey: 'nodes',
      toolTip: 'wizard_netting.wizard_tax_nodes',
      sortable: true,
    },
    actions: {
      name: 'Actions',
      type: 'action',
    },
  };

  ngOnInit() {
    super.ngOnInit();
  }

  onAdd() {
    this.add.emit();
  }

  onEdit(e) {
    this.edit.emit(e);
  }

  onDelete(e) {
    this.delete.emit(e);
  }
}
