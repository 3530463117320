<form [formGroup]="customOptionsForm" class="form">
  <h2 mat-dialog-title>
    {{ data?.mode | titlecase }}
    {{ 'Scenario.labels.optimizationCockpit.customOptions.title' | translate }}
  </h2>
  <div class="mat-body" mat-dialog-content fxLayout="column">
    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-around stretch">
      <!-- Custom Option Name -->
      <prosumer-input
        fxFlex="50%"
        type="string"
        class="eyes-maximize-width"
        [placeholder]="'Option Name'"
        [label]="
          'Scenario.labels.optimizationCockpit.customOptions.name' | translate
        "
        [control]="customOptionsForm.controls?.name"
        [required]="true"
        [errorMessageMap]="errormessages['customOptionsName']"
        data-testid="custom-options-option-name"
      ></prosumer-input>
      <!-- Custom Option Value -->
      <prosumer-input
        fxFlex="50%"
        type="number"
        class="eyes-maximize-width"
        [placeholder]="'Option Value'"
        [label]="
          'Scenario.labels.optimizationCockpit.customOptions.value' | translate
        "
        [control]="customOptionsForm.controls?.value"
        [required]="true"
        [errorMessageMap]="errormessages['customOptionsValue']"
        data-testid="custom-options-option-value"
      ></prosumer-input>
    </div>
  </div>
  <div
    mat-dialog-actions
    fxLayout="row"
    fxLayoutGap="10px"
    fxLayout="space-around stretch"
  >
    <prosumer-entity-upsert-button
      *ngIf="!isViewOnly"
      data-testid="custom-options-form-dialog-confirm-button"
      [id]="data.id"
      [disabled]="customOptionsForm?.pristine"
      [data]="customOptionsForm.value"
      [mode]="data.mode"
      [valid]="customOptionsForm.valid"
      (ok)="onSaveSuccess()"
    ></prosumer-entity-upsert-button>
    <button
      data-testid="custom-options-form-dialog-cancel-button"
      mat-flat-button
      type="button"
      color=""
      (click)="onClose()"
    >
      <span *ngIf="!isViewOnly; else closeButton">{{
        'Generic.labels.cancel' | translate
      }}</span>
      <ng-template #closeButton>
        <span>{{ 'Generic.labels.close' | translate }}</span>
      </ng-template>
    </button>
  </div>
</form>
