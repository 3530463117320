<mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
  <mat-tab label="Basic">
    <prosumer-flow-results-editor-basic
      [inputData]="data"
      (save)="onSave($event)"
    ></prosumer-flow-results-editor-basic>
  </mat-tab>
  <mat-tab label="Advanced">
    <prosumer-ace-editor
      [aceValue]="data"
      (saveFile)="onSave($event)"
    ></prosumer-ace-editor>
  </mat-tab>
</mat-tab-group>
