import { EnergyVector, Library, Storage } from 'prosumer-app/+scenario/models';
import {
  EnergyVectorCascaderService,
  NodeCascaderService,
} from 'prosumer-app/+scenario/services';
import { ProfileType } from 'prosumer-app/+scenario/types';
import { DER_TYPES } from 'prosumer-app/app.references';
import { LoggerService } from 'prosumer-app/libs/eyes-core';
import {
  BaseComponent,
  ColumnDefinition,
  CustomValidators,
  doNothing,
  FormFieldErrorMessageMap,
  FormFieldOption,
  FormService,
  getKeys,
} from 'prosumer-app/libs/eyes-shared';
import {
  convertToYearlyValues,
  getSelectedEnergyVector,
  NameValidator,
} from 'prosumer-app/shared';
import { getTDBTechFilterFromData } from 'prosumer-app/shared/modules/tdb/mappers';
import { LibraryService } from 'prosumer-app/stores';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { TitleCasePipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { EquipmentFormDialogData } from '../equipment-dialog.model';

const NON_LIBRARY_SOURCETYPES = ['custom', 'tdb'];

@Component({
  selector: 'prosumer-storage',
  templateUrl: './storage.component.html',
  styleUrls: ['./storage.component.scss'],
  providers: [EnergyVectorCascaderService, NodeCascaderService, TitleCasePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StorageComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  @ViewChild('submit', { read: ElementRef }) submitButton: ElementRef;
  submitted$ = new BehaviorSubject<boolean>(false);

  @Input() nodeOptions: Array<FormFieldOption<string>> = [];
  @Input() outputEnergyVectorOptions: Array<EnergyVector> = [];

  scenarioId: string;
  caseId: string;
  projectId: string;

  controlNames: Array<string> = [
    'capacityLoss',
    'secondCapacityLoss',
    'roundTripEfficiency',
    'maxDoD',
    'minEp',
    'maxEp',
    'chargeDischargeRateFactor',
    'dissipationRate',
    'agingFactor',
    'minPower',
    'maxPower',
    'minEnergy',
    'maxEnergy',
    'forcedInvestment',
    'existingAsset',
    'capacityExpansion',
    'buildCost',
    'secondBuildCost',
    'indivisibleCost',
    'fOAndMCharge',
    'fOAndMPerInstall',
    'technicalLife',
    'depreciationTime',
    'fOAndMChargeKwh',
    'buildEmissionsKw',
    'buildEmissionsKwh',
    'buildEmissionsIndivisible',
  ];

  yearlyFields = {
    capacityLoss: 'yearlyCapacityLoss',
    secondCapacityLoss: 'yearlySecondCapacityLoss',
    roundTripEfficiency: 'yearlyRoundTripEfficiency',
    maxDoD: 'yearlyMaxDoD',
    minEp: 'yearlyMinEp',
    maxEp: 'yearlyMaxEp',
    chargeDischargeRateFactor: 'yearlyChargeDischargeRateFactor',
    dissipationRate: 'yearlyDissipationRate',
    agingFactor: 'yearlyAgingFactor',
    minPower: 'yearlyMinPower',
    maxPower: 'yearlyMaxPower',
    minEnergy: 'yearlyMinEnergy',
    maxEnergy: 'yearlyMaxEnergy',
    buildCost: 'yearlyBuildCost',
    secondBuildCost: 'yearlySecondBuildCost',
    indivisibleCost: 'yearlyIndivisibleCost',
    fOAndMCharge: 'yearlyFOAndMCharge',
    fOAndMPerInstall: 'yearlyFOAndMInstall',
    fOAndMChargeKwh: 'yearlyFOAndMChargeKWh',
    technicalLife: 'yearlyTechnicalLife',
    depreciationTime: 'yearlyDepreciationTime',
    buildEmissionsKw: 'yearlyBuildEmissionsKw',
    buildEmissionsKwh: 'yearlyBuildEmissionsKwh',
    buildEmissionsIndivisible: 'yearlyBuildEmissionsIndivisible',
    costOfFinancing: 'yearlyCostOfFinancing',
    costOfDecommissioning: 'yearlyCostOfDecommissioning',
  };

  defaultValues: Storage = {
    forcedInvestment: false,
    existingAsset: false,
    capacityExpansion: false,
    yearlyCapacityLoss: convertToYearlyValues(
      '0.0',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlySecondCapacityLoss: convertToYearlyValues(
      '0.0',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlyRoundTripEfficiency: convertToYearlyValues(
      '1.0',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlyMaxDoD: convertToYearlyValues(
      '1.0',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlyMinEp: convertToYearlyValues(
      '0.0',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlyMaxEp: convertToYearlyValues(
      '100000.0',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlyChargeDischargeRateFactor: convertToYearlyValues(
      '1.0',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlyAgingFactor: convertToYearlyValues(
      '0.0',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlyDissipationRate: convertToYearlyValues(
      '0.0',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlyMinPower: convertToYearlyValues(
      '0.0',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlyMaxPower: convertToYearlyValues(
      '100000.0',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlyMinEnergy: convertToYearlyValues(
      '0.0',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlyMaxEnergy: convertToYearlyValues(
      '100000.0',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlyBuildCost: convertToYearlyValues(
      '0.0',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlySecondBuildCost: convertToYearlyValues(
      '0.0',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlyIndivisibleCost: convertToYearlyValues(
      '0.0',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlyFOAndMCharge: convertToYearlyValues(
      '0.0',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlyFOAndMInstall: convertToYearlyValues(
      '0.0',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlyFOAndMChargeKWh: convertToYearlyValues(
      '0.0',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlyTechnicalLife: convertToYearlyValues(
      '20',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlyDepreciationTime: convertToYearlyValues(
      '0',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlyBuildEmissionsKw: convertToYearlyValues(
      '0.0',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlyBuildEmissionsKwh: convertToYearlyValues(
      '0.0',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlyBuildEmissionsIndivisible: convertToYearlyValues(
      '0.0',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlyFootprint: convertToYearlyValues(
      '0.0',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlyCostOfFinancing: convertToYearlyValues(
      '0.0',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlyCostOfDecommissioning: null,
    // !!!this.data
    // ? {}
    // : convertToYearlyValues(null, this.data.startYear, this.data.endYear),
  };

  storageForm: UntypedFormGroup = this.formBuilder.group({
    name: '',
    nodes: [],
    scenarioVariation: 'basecase',
    energyVector: '',
    outputEnergyVector: '',
    forcedInvestment: false,
    existingAsset: false,
    capacityExpansion: false,
    type: 'storage',
    id: '',
    sourceType: 'library',
    library: [{ value: '' }, Validators.required],
    yearlyCapacityLoss: [
      this.defaultValues.yearlyCapacityLoss,
      Validators.required,
    ],
    yearlySecondCapacityLoss: [
      this.defaultValues.yearlySecondCapacityLoss,
      Validators.required,
    ],
    yearlyRoundTripEfficiency: [
      this.defaultValues.yearlyRoundTripEfficiency,
      Validators.required,
    ],
    yearlyMaxDoD: [this.defaultValues.yearlyMaxDoD, Validators.required],
    yearlyMinEp: [this.defaultValues.yearlyMinEp, Validators.required],
    yearlyMaxEp: [this.defaultValues.yearlyMaxEp, Validators.required],
    yearlyChargeDischargeRateFactor: [
      this.defaultValues.yearlyChargeDischargeRateFactor,
      Validators.required,
    ],
    yearlyAgingFactor: [
      this.defaultValues.yearlyAgingFactor,
      Validators.required,
    ],
    yearlyDissipationRate: [
      this.defaultValues.yearlyDissipationRate,
      Validators.required,
    ],
    yearlyMinPower: [this.defaultValues.yearlyMinPower, Validators.required],
    yearlyMaxPower: [this.defaultValues.yearlyMaxPower, Validators.required],
    yearlyMinEnergy: [this.defaultValues.yearlyMinEnergy, Validators.required],
    yearlyMaxEnergy: [this.defaultValues.yearlyMaxEnergy, Validators.required],
    yearlyBuildCost: [this.defaultValues.yearlyBuildCost, Validators.required],
    yearlySecondBuildCost: [
      this.defaultValues.yearlySecondBuildCost,
      Validators.required,
    ],
    yearlyIndivisibleCost: [
      this.defaultValues.yearlyIndivisibleCost,
      Validators.required,
    ],
    yearlyFOAndMCharge: [
      this.defaultValues.yearlyFOAndMCharge,
      Validators.required,
    ],
    yearlyFOAndMInstall: [
      this.defaultValues.yearlyFOAndMInstall,
      Validators.required,
    ],
    yearlyFOAndMChargeKWh: [
      this.defaultValues.yearlyFOAndMChargeKWh,
      Validators.required,
    ],
    yearlyTechnicalLife: [
      this.defaultValues.yearlyTechnicalLife,
      Validators.required,
    ],
    yearlyDepreciationTime: [
      this.defaultValues.yearlyDepreciationTime,
      Validators.required,
    ],
    yearlyBuildEmissionsKw: [
      this.defaultValues.yearlyBuildEmissionsKw,
      Validators.required,
    ],
    yearlyBuildEmissionsKwh: [
      this.defaultValues.yearlyBuildEmissionsKwh,
      Validators.required,
    ],
    yearlyBuildEmissionsIndivisible: [
      this.defaultValues.yearlyBuildEmissionsIndivisible,
      Validators.required,
    ],
    yearlyFootprint: [this.defaultValues.yearlyFootprint, Validators.required],
    yearlyCostOfFinancing: [
      this.defaultValues.yearlyCostOfFinancing,
      Validators.required,
    ],
    yearlyCostOfDecommissioning: [
      this.defaultValues.yearlyCostOfDecommissioning,
    ],
    operatingCostProfiles: [null],
    tdbTechnologyFilter: [null],
  });

  columnsDef: ColumnDefinition = {
    selection: {
      type: 'selection',
      flex: '50px',
    },
    id: {
      name: 'ID',
      flex: '20',
      sortable: true,
    },
    description: {
      name: 'Description',
      flex: 'calc(30% - 50px)',
      sortable: true,
    },
    buildCost: {
      name: 'Build Cost [€/kW]',
      flex: '10',
      sortable: true,
      alignment: 'flex-end',
    },
    secondBuildCost: {
      name: 'Build Cost kWh [€/kWh]',
      flex: '10',
      sortable: true,
      alignment: 'flex-end',
    },
    technicalLife: {
      name: 'Technical Life [year]',
      flex: '10',
      sortable: true,
      alignment: 'flex-end',
    },
    roundTripEfficiency: {
      name: 'Roundtrip Efficiency [-]',
      flex: '10',
      sortable: true,
      alignment: 'flex-end',
    },
    maxDoD: {
      name: 'Max DoD [-]',
      flex: '10',
      sortable: true,
      alignment: 'flex-end',
    },
  };

  get messages() {
    return {
      loading: 'Scenario.messages.library.loading',
      noRecords: this.storageForm?.controls?.energyVector?.value
        ? 'Scenario.messages.library.noLibraryForEnergyVector'
        : 'Scenario.messages.library.noEnergyVectorSelected',
      noResults: 'Scenario.messages.library.noLibraryForEnergyVector',
      error: 'Scenario.messages.library.error',
    };
  }

  scenarioVariationOptions: Array<FormFieldOption<any>> = [];
  profileOptions: Array<FormFieldOption<ProfileType>> = [];

  library$: Observable<Array<Library>>;
  viewInitialized: boolean;
  isMultiNode: boolean;

  nameCtrl: UntypedFormControl = this.storageForm.get(
    'name',
  ) as UntypedFormControl;
  scenarioVariationCtrl: UntypedFormControl = this.storageForm.get(
    'scenarioVariation',
  ) as UntypedFormControl;
  errorMessages: FormFieldErrorMessageMap =
    this._formService.getErrorMessageMap('Scenario.messages.der');
  formType: string = this.storageForm.get('type').value;

  inputEnergyVectorOptions$ = new BehaviorSubject<EnergyVector[]>([]);
  nodeWarningShown$ = new BehaviorSubject<boolean>(false);
  evWarningShown$ = new BehaviorSubject<boolean>(false);
  isFormLoading$ = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EquipmentFormDialogData<Storage>,
    private _changeDetector: ChangeDetectorRef,
    private _formService: FormService,
    private _logger: LoggerService,
    private _cascaderService: EnergyVectorCascaderService,
    private _nodeCascaderService: NodeCascaderService,
    public libraryService: LibraryService,
    public formBuilder: UntypedFormBuilder,
  ) {
    super();
  }

  ngOnInit(): void {
    if (!this.data) {
      return;
    }

    this.initScenarioEntity();
    this.initNodeType();
    this.initOptions();
    this.initValidators();
    this.initHandlers();
    this.initInputEnergyVectorValueChange();
    this.initNodeValueChange();
    this.initForm();
  }

  ngAfterViewInit(): void {
    this.viewInitialized = true;
  }

  initScenarioEntity(): void {
    this.projectId = this.data.scenarioIdentity.projectId;
    this.caseId = this.data.scenarioIdentity.caseId;
    this.scenarioId = this.data.scenarioIdentity.scenarioId;
  }

  initNodeType(): void {
    this.isMultiNode = this.data.isMultiNode;
  }

  initForm(): void {
    this.storageForm.patchValue({
      ...this.data.equipment,
      type: 'storage',
    });
  }

  initHandlers(): void {
    // Source Type Control Changes
    this.storageForm.controls.sourceType.valueChanges
      .pipe(
        startWith(
          this.data && this.data.equipment
            ? this.data.equipment.sourceType
            : 'library',
        ),
        this.takeUntil(),
      )
      .subscribe((sourceType) => {
        if (NON_LIBRARY_SOURCETYPES.includes(sourceType)) {
          this.storageForm.controls.library.clearValidators();
          this.storageForm.controls.library.patchValue(undefined);
        } else {
          this.storageForm.controls.library.setValidators(Validators.required);
          this.storageForm.controls.library.patchValue(
            (this.data.equipment || ({} as any)).library || undefined,
          );
        }
      });

    // Energy Vector Control Changes
    // Filters the library list based on selected energy vector
    this.library$ = combineLatest([
      this.storageForm.controls.energyVector.valueChanges.pipe(
        startWith(
          this.data && this.data.equipment
            ? this.data.equipment.energyVector
            : undefined,
        ),
        getSelectedEnergyVector(
          ((this.data || ({} as any)).equipments || ({} as any)).energyVector,
          of(this.outputEnergyVectorOptions),
        ),
      ),
    ]).pipe(
      tap(() => {
        // Checks if the view is already initialized before doing the clean-up to the library field
        // This is to resolve the issue in edit mode where the library is not selected even we selected one before
        if (this.viewInitialized) {
          this.storageForm.controls.library.patchValue(undefined);
          this._changeDetector.markForCheck();
        }
      }),
      switchMap(([energyVector]) =>
        this.libraryService.getLibraryList$('storage', energyVector),
      ),
      this.takeUntilShare(),
    );

    // Library Control Changes
    // Patches the fields based on the library selected
    this.storageForm.controls.library.valueChanges
      .pipe(this.takeUntil())
      .subscribe((library: Library) => {
        this._logger.debug(library);

        if (!library) {
          return;
        }

        this.controlNames.forEach((ctrName) => {
          this.patchControlWithValue(
            this.storageForm.controls[ctrName],
            library[ctrName],
            this.defaultValues[ctrName],
          );
        });

        // Yearly fields library handling
        getKeys(this.yearlyFields).forEach((key) => {
          this.patchControlWithValue(
            this.storageForm.controls[this.yearlyFields[key]],
            !!library[key]
              ? convertToYearlyValues(
                  library[key],
                  this.data.startYear,
                  this.data.endYear,
                )
              : null,
            this.defaultValues[this.yearlyFields[key]],
          );
        });
      });
  }

  initOptions(): void {
    // if (this.data.outputEnergyVectorOptions) {
    //   this.data.outputEnergyVectorOptions.forEach((option) => this.outputEnergyVectorOptions.push(option));
    // }

    // if (this.data.nodeOptions) {
    //   this.data.nodeOptions.forEach((option) => this.nodeOptions.push(option));
    // }

    if (this.data.profileOptions) {
      this.data.profileOptions.forEach((option) =>
        this.profileOptions.push(option),
      );
    }

    if (this.data.scenarioVariationOptions) {
      this.scenarioVariationOptions.push(...this.data.scenarioVariationOptions);
    }
  }

  initValidators(): void {
    // Name Validators
    this.storageForm.controls.name.setValidators([
      NameValidator.validWithCore(),
    ]);
    const skipEquipment =
      this.data.mode === 'edit' ? this.data.equipment : null;
    this.nameCtrl.setAsyncValidators(
      CustomValidators.dataExist(this.data.equipment$, 'name', skipEquipment, {
        scenarioVariation: this.scenarioVariationCtrl,
      }),
    );
    this.scenarioVariationCtrl.valueChanges
      .pipe(this.takeUntil())
      .subscribe(() => this.nameCtrl.updateValueAndValidity());

    // Existing Asset Validators (Apply same value validator for existing asset and forced investment)
    this.storageForm.controls.existingAsset.setValidators(
      CustomValidators.sameValue(this.storageForm.controls.forcedInvestment),
    );
    this.storageForm.controls.forcedInvestment.valueChanges
      .pipe(this.takeUntil())
      .subscribe(() =>
        this.storageForm.controls.existingAsset.updateValueAndValidity(),
      );
  }

  initInputEnergyVectorValueChange(): void {
    const revertVectorValue = (
      currentProfileOptions,
      currentEnergyVector,
      currentLibrary,
    ) => {
      if (!!currentProfileOptions && currentProfileOptions.length === 0) {
        doNothing();
      } else {
        this.storageForm.controls.energyVector.patchValue(currentEnergyVector);
        this.storageForm.controls.library.patchValue(currentLibrary);
      }
    };

    this.storageForm
      .get('energyVector')
      .valueChanges.pipe(takeUntil(this.componentDestroyed$))
      .subscribe((value) => {
        // show warning dialog only once
        if (this.evWarningShown$.value && this.storageForm.dirty) {
          return;
        }
        const currentEnergyVector = this.storageForm.value['energyVector'];
        const currentLibrary = this.storageForm.value['library'];
        const currentProfileOptions = this.inputEnergyVectorOptions$.value;

        let associatedTableVectors = [];
        const operatingCostProfileCtrlVal =
          this.storageForm.controls.operatingCostProfiles.value;
        if (
          !!operatingCostProfileCtrlVal &&
          !!operatingCostProfileCtrlVal.operatingCostProfiles
        ) {
          associatedTableVectors =
            operatingCostProfileCtrlVal.operatingCostProfiles.map(
              (profile) => profile.energyVectorId,
            );
        }

        const isNettingAffected = this._cascaderService.isNettingAffected(
          this.storageForm.value['id'],
          [currentEnergyVector],
          this.data.currentNetting,
        );
        if (isNettingAffected) {
          this.evWarningShown$.next(true);
        }
        this._cascaderService
          .showCascadingWarningDialog([isNettingAffected])
          .subscribe((isOk) => {
            if (isOk) {
              this.inputEnergyVectorOptions$.next(
                this.outputEnergyVectorOptions.filter(
                  (options) => options.value === value,
                ),
              );
            } else {
              revertVectorValue(
                currentProfileOptions,
                currentEnergyVector,
                currentLibrary,
              );
            }
          });
      });
  }

  initNodeValueChange(): void {
    this.storageForm
      .get('nodes')
      .valueChanges.pipe(takeUntil(this.componentDestroyed$))
      .subscribe((value) => {
        // show warning dialog only once
        if (this.nodeWarningShown$.value && this.storageForm.dirty) {
          return;
        }
        const isNettingAffected = this._nodeCascaderService.isNettingAffected(
          this.storageForm.value['id'],
          value.nodes,
          this.data.currentNetting,
        );

        if (isNettingAffected) {
          this.nodeWarningShown$.next(true);
        }
        this._nodeCascaderService
          .showCascadingWarningDialog([isNettingAffected])
          .subscribe((isOk) => {
            // TODO: implement revert option
          });
      });
  }

  onConfirm(): Storage {
    this.submit();
    this.submitted$.next(true);
    this._changeDetector.detectChanges();
    if (this.storageForm.valid) {
      this.storageForm.controls.outputEnergyVector.patchValue(
        this.storageForm.controls.energyVector.value,
      );
      this._changeDetector.markForCheck();
      const storageFormValue = this.storageForm.getRawValue();
      return {
        ...storageFormValue,
        nodes: storageFormValue['nodes'] // this will check if nodes is null or defined.
          ? storageFormValue['nodes']['nodes'] ||
            storageFormValue['nodes'] ||
            [] // if not null set the value or []
          : storageFormValue['nodes'], // if null set default nodes value
        operatingCostProfiles: storageFormValue?.operatingCostProfiles
          ? storageFormValue.operatingCostProfiles['operatingCostProfiles']
          : null,
      };
    }
  }

  onSelect(libraryId: string): void {
    this.library$.pipe(take(1)).subscribe((library) => {
      if (!!library && library.length > 0) {
        this.storageForm.controls.library.patchValue(
          library.find(({ id }) => id === libraryId),
        );
        this.storageForm.markAsDirty();
      }
    });
  }

  submit(): void {
    if (!!this.submitButton && !!this.submitButton.nativeElement) {
      this.submitButton.nativeElement.click();
    }
  }

  get derTypes() {
    return DER_TYPES;
  }

  handleTechnologyFiltersLoading(isLoading: boolean): void {
    this.isFormLoading$.next(isLoading);
  }

  handleTdbTechnologyData(data: Record<string, unknown>): void {
    getKeys(this.yearlyFields).forEach((key) => {
      this.patchControlWithValue(
        this.storageForm.controls[this.yearlyFields[key]],
        !!data[key]
          ? convertToYearlyValues(
              data[key] as string | number,
              this.data.startYear,
              this.data.endYear,
            )
          : null,
        this.defaultValues[this.yearlyFields[key]],
      );
    });
    this.patchTDBTechFilters(data);
  }

  private patchTDBTechFilters(data: Record<string, unknown>): void {
    this.patchControlWithValue(
      this.storageForm.controls.tdbTechnologyFilter,
      getTDBTechFilterFromData(data),
    );
  }

  get extraQueryParamsTDB(): Record<string, string> {
    return {
      startYear: String(this.data.startYear),
      endYear: String(this.data.endYear),
    };
  }
}
