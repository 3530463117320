import { ColumnDefinition } from 'prosumer-app/libs/eyes-shared';

export const LINES_COLUMN_DEF: ColumnDefinition = {
  name: {
    name: 'Line',
    sortable: true,
  },
  energyVectorId: {
    name: 'Energy vector',
    type: 'reference',
    referenceKey: 'energyVectors',
    toolTip: 'wizard_topology.wizard_topology_energy_vector',
    sortable: true,
  },
  originNodeId: {
    name: 'Origin',
    type: 'reference',
    referenceKey: 'nodes',
    toolTip: 'wizard_topology.wizard_topology_origin_node',
    sortable: true,
  },
  destinationNodeId: {
    name: 'Destination',
    type: 'reference',
    referenceKey: 'nodes',
    toolTip: 'wizard_topology.wizard_topology_destination_node',
    sortable: true,
  },
  bidirectional: {
    name: 'Bidirectional',
    type: 'reference',
    referenceKey: 'bidirectional',
    toolTip: 'wizard_topology.wizard_topology_bidirectional_line',
    sortable: true,
  },
  distance: {
    name: 'Distance [m]',
    toolTip: 'wizard_topology.wizard_topology_distance',
    sortable: true,
    alignment: 'flex-end',
  },
  actions: {
    name: 'Actions',
    type: 'action',
  },
};
