<prosumer-sparkline-table
  prosumerOpCostsGetter
  prosumerOpCostsLister
  (opCostsChange)="costs = $event"
  [columnsDef]="columnsDef"
  (add)="addNew()"
  (edit)="edit($event)"
  (delete)="delete($event)"
  [disableNewButton]="disableNewButton"
  [data]="costs"
  [disabledAddBtnMessage]="
    'wizard_equipment.wizard_equipment_operational_cost_new_btn_disabled_tooltip'
  "
  data-testid="op-cost-table"
></prosumer-sparkline-table>
