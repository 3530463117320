<mat-progress-bar
  color="accent"
  mode="indeterminate"
  *ngIf="binaryLoading$ | async"
>
</mat-progress-bar>
<form data-testid="operating-cost-dialog-form" [formGroup]="operatingCostForm">
  <h2 mat-dialog-title>Add Operational Cost Parameters</h2>
  <div mat-dialog-content>
    <div fxLayout="row" fxLayoutGap="10px">
      <prosumer-select
        type="string"
        fxFlex="40%"
        [tooltip]="
          'wizard_renewable.wizard_renewable_operational_cost_parameter_type'
        "
        [placeholder]="'Select parameter type'"
        [label]="'Parameter Type'"
        [control]="parameterTypeCtrl"
        [options]="parameterType$ | async"
        [required]="true"
        data-testid="operating-cost-dialog-parameter-type"
      ></prosumer-select>
      <prosumer-select
        *ngIf="isPDOC$ | async; else inputEnergyVector"
        type="string"
        fxFlex="40%"
        [tooltip]="
          'wizard_renewable.wizard_renewable_operational_cost_output_energy_vector'
        "
        [placeholder]="
          'Scenario.placeholders.general.outputEnergyVector' | translate
        "
        [label]="'Scenario.labels.general.outputEnergyVector' | translate"
        [control]="outputEnergyVectorCtrl"
        [options]="outputEnergyVectorsOptions"
        data-testid="output-vector"
      ></prosumer-select>
    </div>
    <prosumer-yearly-loads-interval
      formControlName="profiles"
      yAxisLabel="EUR/kWh"
      xAxisLabel="Hours"
      [mode]="data.mode"
      [required]="true"
      [alwaysCustom]="true"
      [useLibraryPanel]="false"
      [yearlyLoadEnabled]="false"
      [allowNegativeInput]="true"
      [yearlyLoadMessages]="yearlyLoadMessage"
      [startYear]="operatingCostForm?.controls?.startYear.value"
      [endYear]="operatingCostForm?.controls?.endYear.value"
      [defaultLocation]="'ders.operatingCostProfile'"
      (selectedTabChange)="onSelectInterval($event)"
    ></prosumer-yearly-loads-interval>
  </div>
  <div mat-dialog-actions fxLayout="row" fxLayoutGap="8px">
    <prosumer-opcosts-upsert-trigger
      [disabled]="isFormPrestine || !isFormValid"
      [data]="currentValue$ | ngrxPush"
      [mode]="data.mode"
      [id]="data.operatingCost.id"
      [valid]="isFormValid"
      (ok)="onUpsertOk()"
      (errorResponse)="onErrorResponse($event)"
    ></prosumer-opcosts-upsert-trigger>

    <button
      data-testid="cancel-button"
      mat-flat-button
      type="button"
      color=""
      (click)="onClose()"
    >
      {{ 'Generic.labels.cancel' | translate }}
    </button>
  </div>
</form>

<ng-template #inputEnergyVector>
  <prosumer-select
    type="string"
    fxFlex="40%"
    [tooltip]="'TBD'"
    [placeholder]="
      'Scenario.placeholders.general.inputEnergyVector' | translate
    "
    [label]="'Scenario.labels.general.inputEnergyVector' | translate"
    [control]="inputEnergyVectorCtrl"
    [options]="inputEnergyVectorsOptions"
    data-testid="input-vector"
  ></prosumer-select>
</ng-template>
