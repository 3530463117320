<form [formGroup]="taxSubsidyForm">
  <h2 mat-dialog-title>{{ data?.mode | titlecase }} Tax & Subsidies</h2>
  <div mat-dialog-content fxLayout="column">
    <!-- Netting -->
    <prosumer-select
      [required]="true"
      [control]="nettingCtrl"
      [options]="nettingOptions"
      [errorMessageMap]="errorMessages?.taxAndSubsidies.netting"
      [placeholder]="
        'Scenario.placeholders.taxAndSubsidies.netting' | translate
      "
      [label]="'Scenario.labels.taxAndSubsidies.netting' | translate"
      [tooltip]="'wizard_netting.wizard_tax_netting' | translate"
      data-testid="tax-subsidy-dialog-netting"
      fxFlex="100%"
    ></prosumer-select>
    <!-- Nodes -->
    <prosumer-node-filterchip-component
      fxFlex="100%"
      formControlName="nodes"
      data-testid="tax-subsidy-dialog-nodes"
      [tooltip]="'wizard_netting.wizard_tax_nodes' | translate"
      [errorMessage]="errorMessages?.taxAndSubsidies.node"
      [setSubmitted]="submitted$ | async"
      [nodeOptions]="nodeOptions"
      *ngIf="isMultiNode; else single"
    ></prosumer-node-filterchip-component>
    <ng-template #single>
      <div fxFlex="100%"></div>
    </ng-template>
    <!-- Interval -->
    <div fxLayout="row" fxLayoutGap="10px" class="interval">
      <prosumer-tax-subsidy-interval
        formControlName="taxSubsidyIntervals"
        [required]="true"
        [startYear]="startYearCtrl.value"
        [endYear]="endYearCtrl.value"
        (hasInvalidInterval)="hasInvalidInterval($event)"
      ></prosumer-tax-subsidy-interval>
    </div>
    <div
      *ngIf="
        (submitted$ | async) &&
        (taxSubsidyIntervalsCtrl.errors?.required || invalidInterval)
      "
    >
      <mat-error
        [innerHtml]="
          'Scenario.messages.taxAndSubsidies.intervals.required' | translate
        "
      ></mat-error>
    </div>
  </div>

  <div mat-dialog-actions fxLayout="row" fxLayoutGap="8px">
    <prosumer-taxub-upsert-trigger
      *ngIf="!isViewOnly"
      [disabled]="isFormPristine"
      [id]="data?.editData?.id"
      [data]="formatFormValues()"
      [mode]="data.mode"
      [valid]="isFormValid"
      (ok)="onSaveSuccess()"
      (attempt)="onSaveAttempt()"
    ></prosumer-taxub-upsert-trigger>

    <button
      data-testid="cancel-button"
      mat-flat-button
      type="button"
      color=""
      (click)="onClose()"
    >
      <span *ngIf="!isViewOnly; else closeButton">{{
        'Generic.labels.cancel' | translate
      }}</span>
      <ng-template #closeButton>
        <span>{{ 'Generic.labels.close' | translate }}</span>
      </ng-template>
    </button>
  </div>
</form>
