import {
  expandYearlyValuesIfApplicable,
  mapYearlyValuesToFrontend,
  YearlyValues,
} from 'prosumer-app/shared';
import { YearValues } from 'prosumer-app/stores/scenario-generic';
import { LimitsForm, OptionLimits } from './limits-form.models';

export class LimitsFormMapper {
  static toFE(from: OptionLimits, period: [number, number]): LimitsForm {
    const {
      yearlyMaximumCosts,
      yearlyMaximumEmissions,
      globalMaximumCosts,
      globalMaximumEmissions,
    } = from;
    return {
      ...(yearlyMaximumEmissions && {
        yearlyMaximumEmissions: LimitsFormMapper.toSanitizedYearlyValues(
          yearlyMaximumEmissions,
          period,
        ),
      }),
      ...(yearlyMaximumCosts && {
        yearlyMaximumCosts: LimitsFormMapper.toSanitizedYearlyValues(
          yearlyMaximumCosts,
          period,
        ),
      }),
      ...(globalMaximumEmissions && {
        globalMaximumEmissions: LimitsFormMapper.toSanitizeValue(
          globalMaximumEmissions,
        ),
      }),
      ...(globalMaximumCosts && {
        globalMaximumCosts:
          LimitsFormMapper.toSanitizeValue(globalMaximumCosts),
      }),
    };
  }

  private static toSanitizedYearlyValues(
    from: YearValues,
    period: [number, number],
  ): YearlyValues {
    if (!from) {
      return null;
    }
    let yearlyValues = mapYearlyValuesToFrontend(from);
    yearlyValues = expandYearlyValuesIfApplicable(yearlyValues, period);
    return yearlyValues;
  }

  private static toSanitizeValue(from) {
    if (!from) {
      return null;
    }
    return from;
  }
}
