import { Injectable } from '@angular/core';
import { ActiveKeeperService } from 'prosumer-app/services/active-keeper';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';
import { HasLegacyProfiles, ProfileParents, ProfileStore } from '../profile';
import {
  CreateOpCostBody,
  OperationalCostInfo,
} from './operational-cost.state';
import { OperationalCostStore } from './operational-cost.store';
import { mapSickProfiles } from 'prosumer-app/shared';

@Injectable({
  providedIn: 'root',
})
export class OperationalCostUpserter {
  private readonly loading = new BehaviorSubject<boolean>(false);
  readonly loading$ = this.loading.asObservable();

  constructor(
    private readonly costs: OperationalCostStore,
    private readonly profiles: ProfileStore,
    private readonly keeper: ActiveKeeperService,
  ) {}

  create(
    equipmentId: string,
    cost: CreateOpCostBody,
  ): Observable<OperationalCostInfo> {
    this.loading.next(true);
    cost = mapSickProfiles(cost);
    return this.profiles
      .legacyCreateByBatch(
        cost as HasLegacyProfiles,
        this.getProfileParents(),
        false,
      )
      .pipe(
        switchMap(() => this.costs.create(equipmentId, cost)),
        finalize(() => this.loading.next(false)),
      );
  }

  edit(
    equipmentId: string,
    id: string,
    cost: CreateOpCostBody,
  ): Observable<OperationalCostInfo> {
    this.loading.next(true);
    cost = mapSickProfiles(cost);
    return this.profiles
      .legacyCreateByBatch(
        cost as HasLegacyProfiles,
        this.getProfileParents(),
        false,
      )
      .pipe(
        switchMap(() => this.costs.edit(equipmentId, id, { ...cost })),
        finalize(() => this.loading.next(false)),
      );
  }

  private getProfileParents(): ProfileParents {
    return {
      projectId: this.getProjectId(),
      caseId: this.getCaseId(),
      scenarioId: this.getScenarioId(),
    };
  }

  private getCaseId(): string {
    return this.keeper.getActiveEntities().case;
  }

  private getScenarioId(): string {
    return this.keeper.getActiveEntities().scenario;
  }

  private getProjectId(): string {
    return this.keeper.getActiveEntities().project;
  }
}
