<form fxLayout="column" fxLayoutGap="20px" [formGroup]="storageForm">
  <div fxLayout="column">
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
        <mat-panel-title>
          <h3 data-testid="general-params-title">
            {{ 'Scenario.labels.general.parameters' | translate }}
            <prosumer-tooltip-anchor
              [message]="'wizard_storage.wizard_storage_general_parameters'"
            ></prosumer-tooltip-anchor>
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="column">
        <div fxLayout="row" fxLayoutGap="10px">
          <!-- Storage Name -->
          <prosumer-input
            fxFlex="33%"
            module="Scenario"
            formFieldName="der.name"
            [attr.data-testid]="'storage-name'"
            [inputTooltip]="'wizard_storage.wizard_storage_name'"
            [control]="storageForm?.controls?.name"
            [required]="true"
          ></prosumer-input>
          <!-- Associated Vector -->
          <prosumer-select
            fxFlex="33%"
            [placeholder]="
              'Scenario.placeholders.general.associatedEnergyVector' | translate
            "
            [label]="
              'Scenario.labels.general.associatedEnergyVector' | translate
            "
            [options]="outputEnergyVectorOptions"
            [control]="storageForm?.controls?.energyVector"
            [required]="true"
            [errorMessageMap]="errorMessages?.energyVector"
            [tooltip]="'wizard_storage.wizard_storage_associated_vector'"
            data-testid="output-energy"
          ></prosumer-select>
          <!-- Scenario Variation -->
          <prosumer-select
            fxFlex="33%"
            *ngIf="scenarioVariationOptions.length > 1"
            [placeholder]="
              'Scenario.placeholders.der.scenarioVariation' | translate
            "
            [label]="'Scenario.labels.der.scenarioVariation' | translate"
            [options]="scenarioVariationOptions"
            [control]="storageForm?.controls?.scenarioVariation"
            [errorMessageMap]="errorMessages['scenarioVariation']"
            data-testid="station-variation"
          ></prosumer-select>
        </div>
        <prosumer-node-filterchip-component
          *ngIf="isMultiNode"
          [required]="true"
          [nodeOptions]="nodeOptions"
          formControlName="nodes"
          [errorMessage]="errorMessages?.node"
          [setSubmitted]="submitted$ | async"
          data-testid="storage-node"
        >
        </prosumer-node-filterchip-component>
        <prosumer-radio-button
          [label]="'Scenario.labels.general.source' | translate"
          [options]="profileOptions"
          [control]="storageForm?.controls?.sourceType"
          [labelTooltip]="'wizard_storage.wizard_storage_source'"
          data-testid="source-options"
        >
        </prosumer-radio-button>
        <div>
          <prosumer-tdb-technology-filters
            *ngIf="storageForm?.controls?.sourceType?.value === 'tdb'"
            [extraDataQueryParams]="extraQueryParamsTDB"
            [technologyType]="derTypes.storage"
            (loadingState)="handleTechnologyFiltersLoading($event)"
            (dataSelected)="handleTdbTechnologyData($event)"
          ></prosumer-tdb-technology-filters>
        </div>
        <div
          *ngIf="storageForm?.controls?.sourceType?.value === 'library'"
          fxLayout="column"
          fxLayoutGap="10px"
        >
          <prosumer-sparkline-table
            maxHeight="300px"
            [columnsDef]="columnsDef"
            [data]="library$ | async"
            [selected]="storageForm.controls.library?.value?.id"
            [loading]="libraryService.loading$ | async"
            [error]="libraryService.error$ | async"
            [messages]="messages"
            [hideAdd]="true"
            [hideEdit]="true"
            [hideDelete]="true"
            (selectionChanged)="onSelect($event)"
          >
          </prosumer-sparkline-table>
          <!-- Using class 'hidden' will use a special mat-form-field to be used when displaying only the error message -->
          <mat-form-field class="hidden">
            <input matInput [formControl]="storageForm?.controls?.library" />
            <mat-error
              *ngIf="storageForm?.controls?.library?.errors?.required"
              [innerHTML]="'Scenario.messages.der.library.required' | translate"
            >
            </mat-error>
          </mat-form-field>
        </div>

        <span
          *ngIf="!!data.equipment.tdbTechnologyFilter"
          class="reference-info"
        >
          <span>*Reference: </span>
          <span class="data">{{
            data.equipment.tdbTechnologyFilter | referenceStr
          }}</span>
        </span>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
        <mat-panel-title>
          <h3 data-testid="technical-params-title">
            {{ 'Scenario.labels.technical.parameters' | translate }}
            <prosumer-tooltip-anchor
              [message]="'wizard_storage.wizard_storage_technical_parameters'"
            >
            </prosumer-tooltip-anchor>
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div fxLayout="column">
        <div fxLayout="row" fxLayoutGap="10px">
          <prosumer-yearly-chart-input
            fxFlex="24%"
            formControlName="yearlyCapacityLoss"
            inputLabel="Scenario.labels.technical.capacityLossKW"
            errorMessage="Scenario.messages.der.capacityLoss"
            contextHelpMsg="wizard_storage.wizard_storage_capacity_loss_kw"
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            data-testid="storage-yearly-capacity-loss"
          >
          </prosumer-yearly-chart-input>

          <prosumer-yearly-chart-input
            fxFlex="24%"
            formControlName="yearlySecondCapacityLoss"
            inputLabel="Scenario.labels.technical.capacityLossKWh"
            errorMessage="Scenario.messages.der.capacityLoss"
            contextHelpMsg="wizard_storage.wizard_storage_capacity_loss_kw2"
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            data-testid="storage-second-capacity-loss"
          >
          </prosumer-yearly-chart-input>

          <prosumer-yearly-chart-input
            fxFlex="24%"
            formControlName="yearlyRoundTripEfficiency"
            inputLabel="Scenario.labels.technical.roundTripEfficiency"
            errorMessage="Scenario.messages.der.roundTripEfficiency"
            contextHelpMsg="wizard_storage.wizard_storage_roundtrip_efficiency"
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            data-testid="storage-yearly-round-trip"
          >
          </prosumer-yearly-chart-input>

          <prosumer-yearly-chart-input
            fxFlex="24%"
            formControlName="yearlyMaxDoD"
            inputLabel="Scenario.labels.technical.maxDoD"
            errorMessage="Scenario.messages.der.maxDoD"
            contextHelpMsg="wizard_storage.wizard_storage_max_dod"
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            data-testid="storage-yearly-max-dod"
          >
          </prosumer-yearly-chart-input>

          <!-- <div fxFlex="19%"></div> -->
        </div>

        <div fxLayout="row" fxLayoutGap="10px">
          <prosumer-yearly-chart-input
            fxFlex="24%"
            formControlName="yearlyMinEp"
            inputLabel="Scenario.labels.technical.minEp"
            errorMessage="Scenario.messages.der.minEp"
            contextHelpMsg="wizard_storage.wizard_storage_min_ep"
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            data-testid="storage-yearly-min-ep"
          >
          </prosumer-yearly-chart-input>

          <prosumer-yearly-chart-input
            fxFlex="24%"
            formControlName="yearlyMaxEp"
            inputLabel="Scenario.labels.technical.maxEp"
            errorMessage="Scenario.messages.der.maxEp"
            contextHelpMsg="wizard_storage.wizard_storage_max_ep"
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            data-testid="storage-yearly-max-ep"
          >
          </prosumer-yearly-chart-input>

          <prosumer-yearly-chart-input
            fxFlex="24%"
            formControlName="yearlyChargeDischargeRateFactor"
            inputLabel="Scenario.labels.technical.chargeDischargeRateFactor"
            errorMessage="Scenario.messages.der.chargeDischargeRateFactor"
            contextHelpMsg="wizard_storage.wizard_storage_charging_edischarging_rate_factor"
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            data-testid="storage-yearly-rate-factor"
          >
          </prosumer-yearly-chart-input>

          <prosumer-yearly-chart-input
            fxFlex="24%"
            formControlName="yearlyAgingFactor"
            inputLabel="Scenario.labels.technical.agingFactor"
            errorMessage="Scenario.messages.der.agingFactor"
            contextHelpMsg="wizard_storage.wizard_storage_ageing_factor"
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            data-testid="storage-yearly-aging-factor"
          >
          </prosumer-yearly-chart-input>

          <!-- <div fxFlex="19%"></div> -->
        </div>

        <div fxLayout="row" fxLayoutGap="10px">
          <prosumer-yearly-chart-input
            fxFlex="24%"
            formControlName="yearlyDissipationRate"
            inputLabel="Scenario.labels.technical.dissipationRate"
            errorMessage="Scenario.messages.der.dissipationRate"
            contextHelpMsg="wizard_storage.wizard_storage_dissipation_rate"
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            data-testid="storage-yearly-dissipation-rate"
          >
          </prosumer-yearly-chart-input>

          <prosumer-yearly-chart-input
            *ngIf="isMultiNode"
            fxFlex="24%"
            formControlName="yearlyFootprint"
            inputLabel="Scenario.labels.technical.footprint"
            errorMessage="Scenario.messages.der.footprint"
            contextHelpMsg="wizard_storage.wizard_storage_footprint"
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            data-testid="storage-yearly-footprint"
          >
          </prosumer-yearly-chart-input>
          <div fxFlex="24%"></div>
          <div fxFlex="24%"></div>
          <!-- <div fxFlex="19%"></div> -->
        </div>
      </div>
    </mat-expansion-panel>

    <!-- Scope3 Emissions Parameters -->
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
        <mat-panel-title>
          <h3 data-testid="emissions-params-title">
            {{ 'Scenario.labels.co2Emissions.parameters' | translate }}
            <prosumer-tooltip-anchor
              [message]="
                'wizard_storage.wizard_storage_scope3emissions_parameters'
              "
            >
            </prosumer-tooltip-anchor>
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="column">
        <div fxLayout="row" fxLayoutGap="10px">
          <!-- Build emissions kW -->
          <prosumer-yearly-chart-input
            formControlName="yearlyBuildEmissionsKw"
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            [errorMessage]="'Scenario.messages.der.buildEmissionsKw'"
            [inputLabel]="
              'Scenario.labels.co2Emissions.buildEmissionsKw' | translate
            "
            fxFlex="24%"
            [contextHelpMsg]=""
            data-testid="storage-build-emissions-kw"
          >
          </prosumer-yearly-chart-input>
          <!-- Build emissions kWh -->
          <prosumer-yearly-chart-input
            formControlName="yearlyBuildEmissionsKwh"
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            [errorMessage]="'Scenario.messages.der.buildEmissionsKwh'"
            [inputLabel]="
              'Scenario.labels.co2Emissions.buildEmissionsKwh' | translate
            "
            fxFlex="24%"
            [contextHelpMsg]=""
            data-testid="storage-build-emissions-kwh"
          >
          </prosumer-yearly-chart-input>
          <!-- Build emissions indivisible -->
          <prosumer-yearly-chart-input
            formControlName="yearlyBuildEmissionsIndivisible"
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            [errorMessage]="'Scenario.messages.der.buildEmissionsIndivisible'"
            [inputLabel]="
              'Scenario.labels.co2Emissions.buildEmissionsIndivisible'
                | translate
            "
            fxFlex="24%"
            [contextHelpMsg]=""
            data-testid="storage-build-emissions-indivisible"
          >
          </prosumer-yearly-chart-input>
          <div fxFlex="24%"></div>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="true" class="sizing-parameters">
      <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
        <mat-panel-title>
          <h3 data-testid="sizing-params-title">
            {{ 'Scenario.labels.sizing.parameters' | translate }}
            <prosumer-tooltip-anchor
              [message]="'wizard_storage.wizard_storage_sizing_parameters'"
            ></prosumer-tooltip-anchor>
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div fxLayout="column">
        <div fxLayout="row" fxLayoutGap="10px">
          <prosumer-yearly-chart-input
            fxFlex="24%"
            formControlName="yearlyMinPower"
            inputLabel="Scenario.labels.sizing.minPower"
            errorMessage="Scenario.messages.der.minPower"
            contextHelpMsg="wizard_storage.wizard_storage_minimum_power"
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            data-testid="storage-yearly-min-power"
          >
          </prosumer-yearly-chart-input>

          <prosumer-yearly-chart-input
            fxFlex="24%"
            formControlName="yearlyMaxPower"
            inputLabel="Scenario.labels.sizing.maxPower"
            errorMessage="Scenario.messages.der.maxPower"
            contextHelpMsg="wizard_storage.wizard_storage_maximum_power"
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            data-testid="storage-yearly-max-power"
          >
          </prosumer-yearly-chart-input>

          <prosumer-yearly-chart-input
            fxFlex="24%"
            formControlName="yearlyMinEnergy"
            inputLabel="Scenario.labels.sizing.minEnergy"
            errorMessage="Scenario.messages.der.minEnergy"
            contextHelpMsg="wizard_storage.wizard_storage_minimum_energy"
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            data-testid="storage-yearly-min-energy"
          >
          </prosumer-yearly-chart-input>

          <prosumer-yearly-chart-input
            fxFlex="24%"
            formControlName="yearlyMaxEnergy"
            inputLabel="Scenario.labels.sizing.maxEnergy"
            errorMessage="Scenario.messages.der.maxEnergy"
            contextHelpMsg="wizard_storage.wizard_storage_maximum_energy"
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            data-testid="storage-yearly-max-energy"
          >
          </prosumer-yearly-chart-input>
        </div>

        <div fxLayout="row" fxLayoutGap="24px">
          <!-- Forced Investment -->
          <prosumer-checkbox
            fxFlex="24%"
            [label]="'Scenario.labels.sizing.forcedInvestment' | translate"
            [control]="storageForm?.controls?.forcedInvestment"
            [errorMessageMap]="errorMessages?.forcedInvestment"
            [tooltip]="'wizard_storage.wizard_storage_forced_investment'"
            data-testid="storage-forced-investment"
          >
          </prosumer-checkbox>

          <!-- Existing Asset -->
          <prosumer-checkbox
            fxFlex="24%"
            [label]="'Scenario.labels.sizing.existingAsset' | translate"
            [control]="storageForm?.controls?.existingAsset"
            [tooltip]="'wizard_storage.wizard_storage_existing_asset'"
            [errorMessageMap]="errorMessages?.existingAsset"
            data-testid="storage-existing-asset"
          >
          </prosumer-checkbox>

          <!-- Capacity Expansion -->
          <prosumer-checkbox
            fxFlex="24%"
            [label]="'Scenario.labels.sizing.capacityExpansion' | translate"
            [control]="storageForm?.controls?.capacityExpansion"
            [tooltip]="'wizard_storage.wizard_storage_capacity_expansion'"
            data-testid="storage-capacity-expansion"
          >
          </prosumer-checkbox>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
        <mat-panel-title>
          <h3 data-testid="economical-params-title">
            {{ 'Scenario.labels.economical.parameters' | translate }}
            <prosumer-tooltip-anchor
              [message]="'wizard_storage.wizard_storage_economical_parameters'"
            ></prosumer-tooltip-anchor>
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div fxLayout="column">
        <div fxLayout="row" fxLayoutGap="10px">
          <prosumer-yearly-chart-input
            fxFlex="19%"
            formControlName="yearlyBuildCost"
            inputLabel="Scenario.labels.economical.buildCost"
            errorMessage="Scenario.messages.der.buildCost"
            contextHelpMsg="wizard_storage.wizard_storage_build_cost_kw"
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            [smallerChart]="true"
            data-testid="storage-yearly-build-cost"
          >
          </prosumer-yearly-chart-input>

          <prosumer-yearly-chart-input
            fxFlex="19%"
            formControlName="yearlySecondBuildCost"
            inputLabel="Scenario.labels.economical.buildCostKwH"
            errorMessage="Scenario.messages.der.buildCost"
            contextHelpMsg="wizard_storage.wizard_storage_build_cost_kwh"
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            [smallerChart]="true"
            data-testid="storage-yearly-second-build-cost"
          >
          </prosumer-yearly-chart-input>

          <prosumer-yearly-chart-input
            fxFlex="19%"
            formControlName="yearlyIndivisibleCost"
            inputLabel="Scenario.labels.economical.indivisibleCost"
            errorMessage="Scenario.messages.der.indivisibleCost"
            contextHelpMsg="wizard_storage.wizard_storage_indivisible_cost"
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            [smallerChart]="true"
            data-testid="storage-indivisible-cost"
          >
          </prosumer-yearly-chart-input>

          <prosumer-yearly-chart-input
            fxFlex="19%"
            formControlName="yearlyFOAndMCharge"
            inputLabel="Scenario.labels.economical.fOAndMCharge"
            errorMessage="Scenario.messages.der.fOAndMCharge"
            contextHelpMsg="wizard_storage.wizard_storage_fom_charge_kw"
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            [smallerChart]="true"
            data-testid="storage-yearly-fo-and-charge"
          >
          </prosumer-yearly-chart-input>

          <prosumer-yearly-chart-input
            fxFlex="19%"
            formControlName="yearlyFOAndMInstall"
            inputLabel="Scenario.labels.economical.fOAndMPerInstall"
            errorMessage="Scenario.messages.der.fOAndMPerInstall"
            contextHelpMsg="wizard_storage.wizard_storage_fom_per_install"
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            [smallerChart]="true"
            data-testid="storage-fo-and-m-install"
          >
          </prosumer-yearly-chart-input>
        </div>

        <div fxLayout="row" fxLayoutGap="10px">
          <prosumer-yearly-chart-input
            fxFlex="19%"
            formControlName="yearlyFOAndMChargeKWh"
            inputLabel="Scenario.labels.economical.fOAndMChargeKwh"
            errorMessage="Scenario.messages.der.fOAndMChargeKwh"
            contextHelpMsg="wizard_storage.wizard_storage_eur_per_kwh_opex"
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            [smallerChart]="true"
            data-testid="storage-foandm-charge-kwh"
          >
          </prosumer-yearly-chart-input>

          <prosumer-yearly-chart-input
            fxFlex="19%"
            formControlName="yearlyTechnicalLife"
            inputLabel="Scenario.labels.economical.technicalLife"
            errorMessage="Scenario.messages.der.technicalLife"
            contextHelpMsg="wizard_storage.wizard_storage_technical_life"
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            [smallerChart]="true"
            data-testid="storage-technical-life"
          >
          </prosumer-yearly-chart-input>

          <prosumer-yearly-chart-input
            fxFlex="19%"
            formControlName="yearlyDepreciationTime"
            inputLabel="Scenario.labels.economical.depreciationTime"
            errorMessage="Scenario.messages.der.depreciationTime"
            contextHelpMsg="wizard_storage.wizard_storage_depreciation_time"
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            [smallerChart]="true"
            data-testid="storage-yearly-depreciation-time"
          >
          </prosumer-yearly-chart-input>

          <prosumer-yearly-chart-input
            fxFlex="19%"
            formControlName="yearlyCostOfFinancing"
            inputLabel="Scenario.labels.economical.costOfFinancing"
            errorMessage="Scenario.messages.der.costOfFinancing"
            contextHelpMsg="wizard_storage.wizard_storage_cost_of_financing"
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            [smallerChart]="true"
            data-testid="storage-yearly-cost-of-financing"
          >
          </prosumer-yearly-chart-input>

          <prosumer-yearly-chart-input
            fxFlex="19%"
            formControlName="yearlyCostOfDecommissioning"
            errorMessage="Scenario.messages.der.costOfDecommissioning"
            inputLabel="Scenario.labels.economical.costOfDecommissioning"
            contextHelpMsg="wizard_storage.wizard_storage_cost_of_decommissioning"
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            [smallerChart]="true"
            [isNullable]="true"
            [minValue]="undefined"
            data-testid="storage-yearly-cost-of-decommissioning"
          >
          </prosumer-yearly-chart-input>
        </div>
      </div>
    </mat-expansion-panel>

    <!-- Operational Cost Parameters -->
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
        <mat-panel-title>
          <h3 data-testid="operational-cost-title">
            {{ 'Scenario.labels.operationalCost.parameters' | translate }}
            <prosumer-tooltip-anchor
              [message]="
                'wizard_storage.wizard_storage_operational_cost_parameters'
              "
            ></prosumer-tooltip-anchor>
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <prosumer-operating-cost-table
        formControlName="operatingCostProfiles"
        [startYear]="data?.startYear"
        [endYear]="data?.endYear"
        [mode]="data?.mode"
        [formType]="formType"
        [inputEnergyVectorsOptions]="inputEnergyVectorOptions$ | async"
        [projectId]="projectId"
        [caseId]="caseId"
        [scenarioId]="scenarioId"
      ></prosumer-operating-cost-table>
    </mat-expansion-panel>
  </div>

  <button #submit type="submit" [hidden]="true"></button>
</form>
