import { Coerce } from 'prosumer-app/core/utils';
import {
  BaseComponent,
  convertDataValuesToString,
  FormFieldErrorMessageMap,
  FormFieldOption,
  FormService,
} from 'prosumer-libs/eyes-shared';
import {
  EnergyGridConnection,
  EnergyGridLimit,
  EnergyGridLimitInterval,
} from 'prosumer-scenario/models';
import { VariationFinder } from 'prosumer-scenario/services';
import { GenericExistsValidator } from 'prosumer-shared/validators';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, take } from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { EnergyGridLimitDialogData } from './energy-grid-limit-dialog.model';
import { EnergyGridLimitValidator } from './energy-grid-limit-dialog.validator';

@Component({
  selector: 'prosumer-energy-grid-limits-form-dialog',
  templateUrl: './energy-grid-limit-dialog.component.html',
  styleUrls: ['./energy-grid-limit-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnergyGridLimitDialogComponent
  extends BaseComponent
  implements OnInit
{
  energyGridLimitForm: UntypedFormGroup;
  isMultiNode: boolean;
  marketOptions: Array<FormFieldOption<string>> = [];
  nodeOptions: Array<FormFieldOption<any>> = [];
  scenarioName: string;

  submitted$ = new BehaviorSubject<boolean>(false);
  errorMessages: FormFieldErrorMessageMap =
    this._formService.getErrorMessageMap('Scenario.messages');

  get marketCtrl() {
    return this.energyGridLimitForm.controls.market;
  }
  get nodesCtrl() {
    return this.energyGridLimitForm.controls.generics;
  }
  get energyGridLimitIntervalsCtrl() {
    return this.energyGridLimitForm.controls.energyGridLimitIntervals;
  }
  get startYearCtrl() {
    return this.energyGridLimitForm.controls.startYear;
  }
  get endYearCtrl() {
    return this.energyGridLimitForm.controls.endYear;
  }
  get isFormValid() {
    return [this.energyGridLimitForm.valid, !this._hasInvalidInterval].every(
      Boolean,
    );
  }
  get isFormPristine() {
    return this.energyGridLimitForm.pristine;
  }

  _hasInvalidInterval = false;
  hasInvalidInterval(value: boolean) {
    this._hasInvalidInterval = value;
  }

  get invalidInterval(): boolean {
    return this._hasInvalidInterval;
  }

  allValid$: Observable<boolean>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EnergyGridLimitDialogData,
    public dialogRef: MatDialogRef<EnergyGridLimitDialogComponent>,
    private _formBuilder: UntypedFormBuilder,
    private _formService: FormService,
    private readonly varFinder: VariationFinder,
  ) {
    super();
    this.energyGridLimitForm = this.initForm();
    this.allValid$ = this.selectAllValid();
  }

  formatFormValues() {
    const values = this.energyGridLimitForm.getRawValue();
    values.nodes = values['generics']['generics']
      ? values['generics']['generics']
      : values['generics'] || [];
    const { market, nodes, energyGridLimitIntervals } = values;

    return {
      ...(!!values.id && { id: values.id }),
      market,
      nodes,
      energyGridLimitIntervals: energyGridLimitIntervals
        ? this._cureLimitInterval(energyGridLimitIntervals).map((interval) =>
            convertDataValuesToString(interval, ['startYear', 'endYear']),
          )
        : [],
    };
  }

  onClose(): void {
    this.dialogRef.close();
  }

  private initFormData() {
    this.isMultiNode = this.data.isMultiNode;
    this.nodesCtrl.patchValue(
      { generics: this.data.editData.nodes },
      { emitEvent: false },
    );

    if (this.data.editData.id) {
      this.energyGridLimitForm.controls.id.patchValue(this.data.editData.id, {
        emitEvent: false,
      });
    }

    if (this.data.mode !== 'add') {
      this.marketCtrl.patchValue(this.data.editData.market);
      this.energyGridLimitIntervalsCtrl.patchValue(
        this.data.editData.energyGridLimitIntervals,
        { emitEvent: false },
      );
    }
    this.setMarketNodeValidator();
    combineLatest([
      this.energyGridLimitForm.get('generics').valueChanges,
      this.energyGridLimitForm.get('energyGridLimitIntervals').valueChanges,
    ])
      .pipe(debounceTime(400))
      .subscribe(() => {
        this.nodesCtrl.clearAsyncValidators();
        this.energyGridLimitIntervalsCtrl.clearAsyncValidators();
      });
  }

  private initForm() {
    return this._formBuilder.group({
      id: [''],
      market: ['', Validators.required],
      generics: [undefined, Validators.required],
      energyGridLimitIntervals: [],
      startYear: this.data.startYear,
      endYear: this.data.endYear,
    });
  }

  private initOptions() {
    if (this.data.marketOptions) {
      this.data.marketOptions
        .map((market) => this.toWithVariationInName(market))
        .forEach((option) => this.marketOptions.push(option));
    }
    this.marketCtrl.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((market) => {
        this.updateNodeOptions(market);
      });
  }

  private setMarketNodeValidator(): void {
    const skipValue = this.data.mode === 'edit' ? this.data.editData : null;
    this.marketCtrl.setAsyncValidators(
      GenericExistsValidator.filterchipMultiDataExistsValidator(
        this.data.existingEnergyGridLimits$,
        {
          market: { control: this.marketCtrl as UntypedFormControl },
        },
        { control: this.nodesCtrl as UntypedFormControl, dataKey: 'nodes' },
        skipValue,
      ),
    );
    this.addSubscription(
      this.energyGridLimitForm
        .get('generics')
        .valueChanges.subscribe((value) => {
          this.marketCtrl.updateValueAndValidity();
          if (!value?.generics?.length) {
            this.nodesCtrl.markAsPristine();
          }
        }),
    );
  }

  private updateNodeOptions(market) {
    this.data.existingEnergyGridConnections$
      .pipe(take(1))
      .subscribe((gridList) => {
        const grid = gridList.find((grid) => grid.id === market);
        this.nodeOptions = this.data.nodeOptions.filter(
          (node) =>
            grid &&
            (JSON.stringify(grid.nodes) === '["ALL"]' ||
              grid.nodes.includes(node.value)),
        );
        if (!!market && market !== this.data.editData.market) {
          if (this.data.isMultiNode) {
            this.nodesCtrl.patchValue([]);
          }
        }
      });
  }

  ngOnInit(): void {
    this.initOptions();
    this.initFormData();
  }

  onSaveSuccess(): void {
    this.dialogRef.close();
  }

  onSaveAttempt(): void {
    this.submitted$.next(true);
  }

  private toWithVariationInName(
    here: FormFieldOption<string> & { object?: unknown },
  ): FormFieldOption<string> {
    return {
      ...here,
      name: this.concatenateVariationName(here.name, here.object),
    };
  }

  private concatenateVariationName(
    name: string,
    grid: EnergyGridConnection,
  ): string {
    return `${name} (${this.varFinder.getVariationName(
      grid.scenarioVariation,
    )})`;
  }

  private selectAllValid(): Observable<boolean> {
    return this.energyGridLimitForm.valueChanges.pipe(
      map((value) => this.areIntervalsValid(value)),
      map((intervalsValid) => this.areAllValid(intervalsValid)),
    );
  }

  private areAllValid(intervals: boolean): boolean {
    return [intervals, this.energyGridLimitForm.valid].every(Boolean);
  }

  private areIntervalsValid(form: EnergyGridLimit): boolean {
    return EnergyGridLimitValidator.areIntervalsValid(
      Coerce.toArray(this._cureLimitInterval(form.energyGridLimitIntervals)),
    );
  }

  private _cureLimitInterval(
    energyGridLimitIntervals: any,
  ): EnergyGridLimitInterval[] {
    if (energyGridLimitIntervals && energyGridLimitIntervals.intervals)
      return energyGridLimitIntervals.intervals;
    return energyGridLimitIntervals;
  }
}
