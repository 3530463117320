import { Injectable } from '@angular/core';
import { CashflowCategory, CashflowData } from '@prosumer/results/models';
import { ResultsExtractorService } from '@prosumer/results/state';

import { Observable } from 'rxjs';

import {
  GroupedSlicesStrategy,
  ReducedResultNameValues,
  ReducedSliceData,
} from '../bar-and-area-results';
import { ResultNameValue, ResultVisualizerSlice } from '../results-visualizer';

type ReducedCashflowData = { [key: string]: CashflowData[] };

@Injectable()
export class CashflowResultsService extends GroupedSlicesStrategy<CashflowData> {
  constructor(private results: ResultsExtractorService) {
    super();
  }

  getSlices(): ResultVisualizerSlice[] {
    return [{ name: 'name' }, { name: 'type' }, { name: 'node' }];
  }

  getYearsToOptimizeStream(): Observable<number[]> {
    return this.results.getOptimizedYearsStream();
  }

  getAllYearsStream(): Observable<number[]> {
    return this.results.getAllYearsStream();
  }

  getFilterKeys(): string[] {
    return ['type', 'name', 'node', 'category'];
  }

  getResultDataStream(): Observable<CashflowData[]> {
    return this.results.getCashflowStream();
  }

  buildSliceData(raw: CashflowData[]): ReducedSliceData {
    return {
      name: this.reduceCapexOpex(this.reduceDataByAssetName(raw)),
      type: this.reduceCapexOpex(this.reduceDataByAssetType(raw)),
      node: this.reduceCapexOpex(this.reduceDataByNode(raw)),
    };
  }

  private reduceDataByAssetName(data: CashflowData[]): ReducedCashflowData {
    return this.reduceData(data, 'name');
  }

  private reduceDataByAssetType(data: CashflowData[]): ReducedCashflowData {
    return this.reduceData(data, 'type');
  }

  private reduceDataByNode(data: CashflowData[]): ReducedCashflowData {
    return this.reduceData(data, 'node');
  }

  private reduceCapexOpex(data: ReducedCashflowData): ReducedResultNameValues {
    const reduceCapexOpex = Object.entries(data).reduce(
      (acc, [type, cashflows]) => {
        const capex = this.concatenateNameAndCategory(
          type,
          CashflowCategory.CAPEX,
        );
        const opex = this.concatenateNameAndCategory(
          type,
          CashflowCategory.OPEX,
        );
        const financing = this.concatenateNameAndCategory(
          type,
          CashflowCategory.FINANCING,
        );
        const decommissioning = this.concatenateNameAndCategory(
          type,
          CashflowCategory.DECOMISSIONING,
        );
        const decommissioningEOL = this.concatenateNameAndCategory(
          type,
          CashflowCategory.DECOMISSIONING_EOL,
        );
        acc[capex] = this.createNameValuesForCapexOpex(
          cashflows,
          CashflowCategory.CAPEX,
        );
        acc[opex] = this.createNameValuesForCapexOpex(
          cashflows,
          CashflowCategory.OPEX,
        );
        acc[financing] = this.createNameValuesForCapexOpex(
          cashflows,
          CashflowCategory.FINANCING,
        );
        acc[decommissioning] = this.createNameValuesForCapexOpex(
          cashflows,
          CashflowCategory.DECOMISSIONING,
        );
        acc[decommissioningEOL] = this.createNameValuesForCapexOpex(
          cashflows,
          CashflowCategory.DECOMISSIONING_EOL,
        );
        return acc;
      },
      {},
    );

    return this.filterReducedResultNameValues(reduceCapexOpex);
  }

  private filterReducedResultNameValues(
    data: ReducedResultNameValues,
  ): ReducedResultNameValues {
    const filteredData = Object.entries(data).reduce(
      (acc, [name, yearValues]) => {
        if (yearValues.every((year) => !!!year.value)) {
          return acc;
        }
        acc[name] = yearValues;
        return acc;
      },
      {},
    );
    return filteredData;
  }

  private createNameValuesForCapexOpex(
    list: CashflowData[],
    category: CashflowCategory,
  ): ResultNameValue[] {
    const years = this.collectYears(list);
    return years.map((year) => ({
      name: String(year),
      value: this.sumOpexOrCapex(list, category, year),
    }));
  }

  private concatenateNameAndCategory(
    name: string,
    category: CashflowCategory,
  ): string {
    return `${name}_${category}`;
  }

  private sumOpexOrCapex(
    values: CashflowData[],
    category: CashflowCategory,
    year: number,
  ): number {
    return values
      .filter((value) => value.year === year)
      .filter((value) => value.category === category)
      .reduce((sum, curr) => sum + curr.value, 0);
  }

  private reduceData(data: CashflowData[], key: string): ReducedCashflowData {
    return data.reduce((acc, curr) => {
      const value = curr[key];
      const running = acc[value] || [];
      acc[value] = [...running, curr];
      return acc;
    }, {});
  }

  private collectYears(data: CashflowData[]): number[] {
    return this.removeDuplicates((data || []).map((single) => single.year));
  }

  private removeDuplicates<T>(list: T[]): T[] {
    return Array.from(new Set(list));
  }

  filterOutZeroValues(data: CashflowData[]): CashflowData[] {
    const dataWithValues = data.filter((datum) => !!datum['value']);
    return data.filter(
      (datum) => !!datum['value'] || this.isDataInArray(datum, dataWithValues),
    );
  }

  private isDataInArray(datum: CashflowData, data: CashflowData[]): boolean {
    const dataWithoutValueAndYear = data.map(
      ({ value, year, ...rest }) => rest,
    );
    const { value, year, ...datumWithoutValueAndYear } = datum;

    return !!dataWithoutValueAndYear.find(
      (x) => JSON.stringify(x) === JSON.stringify(datumWithoutValueAndYear),
    );
  }
}
