<div
  *ngIf="data && data.length > 0"
  fxLayout="column"
  fxLayoutAlign="start center"
  fxLayoutGap="30px"
>
  <mat-card-title>
    {{ chartTitle }}
    <prosumer-tooltip-anchor
      [message]="'output_main.output_main_monthly_production'"
    ></prosumer-tooltip-anchor>
  </mat-card-title>
  <ngx-charts-bar-vertical-stacked
    [results]="data"
    [view]="[700, 450]"
    [xAxis]="true"
    [yAxis]="true"
    [legend]="true"
    [xAxisLabel]="chartXAxisLabel"
    [yAxisLabel]="chartYAxisLabel"
    [showXAxisLabel]="true"
    [showYAxisLabel]="true"
    [showGridLines]="true"
    [showDataLabel]="true"
    legendPosition="right"
    [xAxisTickFormatting]="chartXAxisFormatting"
    [scheme]="highContrastScheme"
    [barPadding]="10"
    (activate)="getSeriesMembers($event)"
    (select)="onSelect($event)"
  >
    <ng-template #tooltipTemplate let-model="model">
      <prosumer-series-tooltip
        [currentSeriesData]="model"
        [seriesDataList]="tooltipData"
        [unit]="chartYAxisLabel"
        [titleFormat]="chartTooltipTitleFormat"
        format="1.1-1"
      >
      </prosumer-series-tooltip>
    </ng-template>
  </ngx-charts-bar-vertical-stacked>
</div>
