import moment from 'moment';
import { TraditionalDispatchAdapter } from 'prosumer-app/+scenario/containers/simulation-results/dispatch/adapters';
import { dayUtils } from 'prosumer-app/shared';
import { PipeUtils } from 'prosumer-core/utils';
import { BehaviorSubject, Observable } from 'rxjs';

import { coerceNumberProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ResultsPerceptionService } from '@prosumer/results/components/case-results-perception';
import { VisualizerData } from '@prosumer/results/components/results-visualizer';

import {
  CompareDispatchChartData,
  CompareDispatchChartSeriesData,
  CompareDispatchData,
} from '../compare-dispatch.model';
import { groupDataSetByDayAndHour } from '../utils';

@Component({
  selector: 'prosumer-compare-weekly',
  templateUrl: './weekly.component.html',
  styleUrls: ['./weekly.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompareDispatchWeeklyComponent
  extends TraditionalDispatchAdapter
  implements OnInit
{
  private chartDataSubject = new BehaviorSubject<VisualizerData[]>([]);
  chartData: Array<any>;
  weekly: CompareDispatchData;
  @Input() customColors: any;
  @Input() set data(weekly: CompareDispatchData) {
    this.weekly = weekly;
    if (!!this.weekly) {
      this.chartData = this.mapToChartData(this.weekly);
      this.chartDataSubject.next(this.chartData);
    }
  }

  constructor(
    private _translate: TranslateService,
    private _perception: ResultsPerceptionService,
  ) {
    super(_perception);
  }

  ngOnInit(): void {
    this.initializeChartDataAndColors();
  }

  mapToChartData(resultData: CompareDispatchData): CompareDispatchChartData[] {
    return (resultData.dataList || []).map((result) => {
      const dataSet = this.generateSeriesDataList(
        result.values,
        resultData.start,
        result.year,
      );
      const groupedDataSet = groupDataSetByDayAndHour(dataSet);
      const series = [];
      Array.from(Array(168).keys()).forEach((i) => {
        const weeklyDataSet = groupedDataSet.filter(
          (data: any) => data && data.newIndex === i + 1,
        );
        if (weeklyDataSet.length > 0) {
          const weeklyData =
            weeklyDataSet.length &&
            weeklyDataSet
              .map((hData: any) => ({
                ...hData,
                testIndex: '' + hData.newIndex,
                name: '' + (i + 1),
              }))
              .reduce((prev, curr) => ({
                ...curr,
                value: prev.value + curr.value,
              }));
          series.push({ ...weeklyData, value: weeklyData.average });
        } else {
          series.push({
            hour: i,
            value: 0.0,
            average: 0.0,
            day: Math.trunc((i + 1) / 24),
            name: (i + 1).toString(),
            newIndex: i + 1,
          });
        }
      });
      return {
        name: result.equipment,
        series: series ? series : undefined,
      };
    });
  }

  generateSeriesDataList(
    values: number[],
    start?: number,
    year?: string,
  ): CompareDispatchChartSeriesData[] {
    if (!values || values.length === 0) {
      return [];
    }

    return values.map((value, index) => ({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      hour: moment({
        y: +coerceNumberProperty(year),
        M: 0,
        d: 1,
        h: 0,
        m: 0,
        s: 0,
        ms: 0,
      })
        .hour(index + (start - 1) * 24)
        .get('h'),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      moment: moment({
        y: coerceNumberProperty(year),
        M: 0,
        d: 1,
        h: 0,
        m: 0,
        s: 0,
        ms: 0,
      }).hour(index),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      day: moment({
        y: coerceNumberProperty(year),
        M: 0,
        d: 1,
        h: 0,
        m: 0,
        s: 0,
        ms: 0,
      })
        .hour(index + (start - 1) * 24)
        .get('d'),
      value,
    }));
  }

  formatTick(tick: string): string {
    return dayUtils.prependDay(Number(tick));
  }
  getChartName(): string {
    return `Weekly Dispatch (${this.weekly.name})`;
  }
  getNgxChartsDataStream(): Observable<VisualizerData[]> {
    return this.chartDataSubject.asObservable().pipe(PipeUtils.filterOutEmpty);
  }
}
