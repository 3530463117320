import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FuelsListerDirective } from './fuels-lister.directive';

@NgModule({
  declarations: [FuelsListerDirective],
  imports: [CommonModule],
  exports: [FuelsListerDirective],
})
export class FuelsListerModule {}
