<div fxLayout="column">
  <form [formGroup]="scenarioVariationsForm" (ngSubmit)="onAddVariation()">
    <div fxLayoutAlign="start" fxLayoutGap="5px" *ngIf="mode !== 'read'">
      <div fxFlex="33%">
        <mat-form-field
          *ngIf="!isViewOnly"
          class="eyes-maximize-width"
          appearance="outline"
          [@fadeIn]
        >
          <mat-label> Scenario Variations </mat-label>
          <input
            matInput
            formControlName="name"
            [placeholder]="
              'Scenario.placeholders.general.scenarioVariations' | translate
            "
            data-testid="scenario-variations-input"
          />
          <mat-error
            *ngFor="
              let error of getErrors(
                scenarioVariationsForm?.controls?.name?.errors
              )
            "
            [innerHtml]="scenarioVariationsErrorMessages[error]"
          >
          </mat-error>
        </mat-form-field>
      </div>
      <div class="custom-div-button" fxFlex="7%">
        <button
          mat-icon-button
          *ngIf="!isViewOnly && (loading$ | async) !== true"
          [disabled]="
            scenarioVariationsForm?.controls?.name?.value.trim() === '' ||
            getErrors(scenarioVariationsForm?.controls?.name?.errors)?.length >
              0 ||
            scenarioVariationsForm?.invalid
          "
          data-testid="scenario-variations-add-button"
          class="mt-0 h-10 w-10 p-2"
          color="primary"
          type="submit"
        >
          <mat-icon aria-hidden="false" matPrefix>add_circle</mat-icon>
        </button>
        <mat-spinner
          matPrefix
          *ngIf="loading$ | async"
          [diameter]="spinnerDiameter"
          [color]="spinnerColor"
          data-testid="spinner"
        ></mat-spinner>
      </div>
      <div fxFlex="60%"></div>
    </div>
  </form>
  <div>
    <div fxFlex="40%">
      <div
        class="custom-div-table"
        *ngIf="(scenarioVariationsList$ | async)?.length > 0"
      >
        <prosumer-sparkline-table
          [hideAdd]="true"
          [hideEdit]="true"
          [saving]="saving"
          [loading]="loading"
          [hideDelete]="isViewOnly"
          [columnsDef]="variationsColumnDef"
          [data]="scenarioVariationsList$ | async"
          [disabled]="scenarioVariationsForm?.disabled"
          data-testid="general-scenario-variations-table"
          (delete)="onDeleteVariation($event)"
        >
        </prosumer-sparkline-table>
      </div>
    </div>
    <div fxFlex="60%"></div>
  </div>
</div>
