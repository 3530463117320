import { Component } from '@angular/core';
import { EquipmentResultsService } from '@prosumer/results/components/equipment-results';
import { EquipmentResult } from '@prosumer/results/models';
import { ComparisonController } from '../base';
import { COMP_VIZ_SERVICE } from '../comparison-visualizer';
import { EquipmentComparisonService } from './equipment-comparison.service';

@Component({
  selector: 'prosumer-equipment-comparison',
  templateUrl: './equipment-comparison.component.html',
  styleUrls: ['./equipment-comparison.component.scss'],
  providers: [
    { provide: COMP_VIZ_SERVICE, useClass: EquipmentComparisonService },
    EquipmentResultsService,
  ],
})
export class EquipmentComparisonComponent extends ComparisonController<EquipmentResult> {}
