import moment, { Moment, unitOfTime } from 'moment';

const INTERVALS: Record<string, boolean> = {
  year: true,
  quarter: true,
  month: true,
  week: true,
  day: true,
  hour: true,
  minute: true,
  second: true,
};

export class DateRange {
  start: Moment;
  end: Moment;

  constructor(
    start: Moment | string | [Moment | string, Moment | string],
    end?: Moment | string,
  ) {
    let s: Moment | string | undefined = start as any;
    let e: Moment | string | undefined = end;

    if (Array.isArray(start)) {
      [s, e] = start;
    } else if (typeof start === 'string' && !end) {
      [s, e] = isoSplit(start);
    }

    this.start = s ? moment(s) : moment(-8640000000000000); // Minimum date if start is missing
    this.end = e ? moment(e) : moment(8640000000000000); // Maximum date if end is missing
  }

  adjacent(other: DateRange): boolean {
    return (
      (this.start.isSame(other.end) &&
        other.start.valueOf() <= this.start.valueOf()) ||
      (this.end.isSame(other.start) &&
        other.end.valueOf() >= this.end.valueOf())
    );
  }

  add(other: DateRange): DateRange | null {
    if (this.overlaps(other)) {
      return new DateRange(
        moment.min(this.start, other.start),
        moment.max(this.end, other.end),
      );
    }
    return null;
  }

  *by(
    interval: unitOfTime.DurationConstructor,
    options: { excludeEnd?: boolean; step?: number } = {},
  ): IterableIterator<Moment> {
    const step = options.step ?? 1;
    const end = options.excludeEnd
      ? this.end.clone().subtract(1, interval)
      : this.end;
    let current = this.start.clone();

    while (current.isBefore(end) || current.isSame(end)) {
      yield current.clone();
      current.add(step, interval);
    }
  }

  clone(): DateRange {
    return new DateRange(this.start.clone(), this.end.clone());
  }

  contains(
    date: Moment | DateRange,
    options: { excludeStart?: boolean; excludeEnd?: boolean } = {},
  ): boolean {
    const excludeStart = options.excludeStart || false;
    const excludeEnd = options.excludeEnd || false;

    const start = this.start.valueOf();
    const end = this.end.valueOf();
    const dateStart =
      date instanceof DateRange ? date.start.valueOf() : date.valueOf();
    const dateEnd =
      date instanceof DateRange ? date.end.valueOf() : date.valueOf();

    return (
      (start < dateStart || (start <= dateStart && !excludeStart)) &&
      (end > dateEnd || (end >= dateEnd && !excludeEnd))
    );
  }

  overlaps(other: DateRange): boolean {
    return this.intersect(other) !== null;
  }

  intersect(other: DateRange): DateRange | null {
    const maxStart = moment.max(this.start, other.start);
    const minEnd = moment.min(this.end, other.end);

    if (maxStart.isBefore(minEnd) || maxStart.isSame(minEnd)) {
      return new DateRange(maxStart, minEnd);
    }
    return null;
  }

  valueOf(): number {
    return this.end.valueOf() - this.start.valueOf();
  }
}

export function extendMoment(momentInstance) {
  momentInstance.range = function (
    start: Moment | string | [Moment | string, Moment | string],
    end?: Moment | string,
  ): DateRange {
    if (typeof start === 'string' && INTERVALS.hasOwnProperty(start)) {
      return new DateRange(
        momentInstance().startOf(start),
        momentInstance().endOf(start),
      );
    }
    return new DateRange(start, end);
  };

  momentInstance.fn.within = function (range: DateRange): boolean {
    return range.contains(this);
  };

  return momentInstance;
}

function isoSplit(isoString: string): [string, string] {
  return isoString.split('/') as [string, string];
}
