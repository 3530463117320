<mat-card appearance="outlined" fxFlex fxLayout="column" fxLayoutGap="10px">
  <div
    class="prosumer-compare-main-row"
    *ngFor="let row of kpiList; let i = index"
    fxLayout="row"
    fxLayoutGap="20px"
    [ngClass]="{ header: i === 0 }"
  >
    <div
      class="prosumer-compare-main-cell kpi-header-cell"
      fxFlex
      [ngClass]="{ 'eyes-bold-text': i === 0 }"
    >
      {{ row?.name }}
      <prosumer-tooltip-anchor
        *ngIf="i"
        [message]="row?.tooltip"
        class="attached-component"
      >
      </prosumer-tooltip-anchor>
    </div>

    <div
      *ngFor="let value of row?.values"
      class="prosumer-compare-main-cell kpi-value-cell"
      fxFlex
      fxLayout="column"
      fxLayoutAlign="center"
      fxLayoutGap="5px"
    >
      <div
        *ngIf="i && row.key !== 'co2Emission'; else default"
        class="kpi-value"
        [ngClass]="{ 'kpi-big-font': i !== 0 }"
      >
        {{ value[1] | metric: 1 }} k
      </div>

      <ng-template #default>
        <div
          class="scenario-name"
          *ngIf="row.key === 'co2Emission'"
          [ngClass]="{ 'eyes-bold-text': i === 0, 'kpi-big-font': i !== 0 }"
          [prosumerTooltip]="value[1] | number: '1.0-0'"
        >
          {{ value[1] | number: '1.0-0' }}
        </div>
        <div
          class="scenario-name"
          *ngIf="row.key !== 'co2Emission'"
          [ngClass]="{ 'eyes-bold-text': i === 0, 'kpi-big-font': i !== 0 }"
          [prosumerTooltip]="value[1]"
          data-testid="scenario-name-column"
        >
          {{ value[1] }}
        </div>
      </ng-template>

      <div
        *ngIf="value[0]; else placeholder"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="3px"
        [ngClass]="{
          'kpi-percent-positive': value[0] > 0,
          'kpi-percent-negative': value[0] <= 0
        }"
      >
        <mat-icon aria-hidden="false" matPrefix *ngIf="value[0] < 0"
          >arrow_upward</mat-icon
        >
        <mat-icon aria-hidden="false" matPrefix *ngIf="value[0] >= 0"
          >arrow_downward</mat-icon
        >
        <span>{{ value[0] | absoluteValuePipe | number: '1.2-2' }} %</span>
      </div>

      <ng-template #placeholder>
        <div *ngIf="i !== 0" class="kpi-placeholder">&nbsp;</div>
      </ng-template>
    </div>
  </div>
</mat-card>
