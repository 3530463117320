<div fxLayout="column">
  <prosumer-margin-table
    [formControl]="marginsControl"
    [startYear]="startYear"
    [endYear]="endYear"
    [energyVectors]="energyVectors"
    [binToDelete]="binToDeleteControl"
    [scenarioIdentity]="scenarioIdentity"
    [hideAdd]="hideAdd"
    [hideEdit]="hideEdit"
    [hideDelete]="hideDelete"
    [hideView]="hideView"
  >
  </prosumer-margin-table>
  <prosumer-activation-table
    [formControl]="activationsControl"
    [hideAdd]="hideAdd"
    [hideEdit]="hideEdit"
    [hideDelete]="hideDelete"
    [hideView]="hideView"
  >
  </prosumer-activation-table>
  <prosumer-equipment-table
    [formControl]="equipmentsControl"
    [energyVectors]="energyVectors"
    [equipments]="equipments"
    [hideAdd]="hideAdd"
    [hideEdit]="hideEdit"
    [hideDelete]="hideDelete"
    [hideView]="hideView"
  >
  </prosumer-equipment-table>
  <prosumer-market-table
    [formControl]="marketsControl"
    [markets]="markets"
    [energyVectors]="energyVectors"
    [hideAdd]="hideAdd"
    [hideEdit]="hideEdit"
    [hideDelete]="hideDelete"
    [hideView]="hideView"
  >
  </prosumer-market-table>
  <prosumer-reserve-network-table
    [formControl]="networksControl"
    [energyVectors]="energyVectors"
    [hideAdd]="hideAdd"
    [hideEdit]="hideEdit"
    [hideDelete]="hideDelete"
    [hideView]="hideView"
  >
  </prosumer-reserve-network-table>
</div>
