<mat-card
  appearance="outlined"
  *ngIf="isErrorShown$ | async; else showChart"
  fxLayout="row"
  fxLayoutAlign="center center"
  class="dispatch-error mat-elevation-z8"
  [@fadeIn]
>
  <div
    class="error-container mat-error"
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <mat-icon aria-hidden="false">error_outline</mat-icon>
    <span>You can only compare 2 scenarios in Dispatch</span>
    <span>Please select both scenarios you want to compare</span>
  </div>
</mat-card>

<ng-template #showChart>
  <mat-card
    appearance="outlined"
    fxLayout="column"
    fxLayoutGap="10px"
    [@fadeIn]
  >
    <div
      class="dispatch-type-toggle-container"
      fxLayout="row"
      fxLayoutAlign="center"
    >
      <mat-button-toggle-group
        name="typeToggle"
        aria-label="Type Toggle"
        fxLayout="row wrap"
        fxLayoutAlign="end center"
        [formControl]="typeToggleControl"
      >
        <mat-button-toggle
          *ngFor="let option of typeOptions"
          [value]="option?.value"
          [attr.data-testid]="'dispatch-type-' + option?.value"
        >
          {{ option?.name | translate }}
          <prosumer-tooltip-anchor
            [tooltipPosition]="option?.tooltipPosition"
            [message]="option?.tooltip"
          ></prosumer-tooltip-anchor>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div
      class="filter-container"
      fxLayout="row"
      fxLayoutAlign="space-between"
      fxLayoutGap="20px"
      [@fadeIn]
    >
      <div
        class="chips-filter-container"
        fxFlex="calc(50% - 20px)"
        fxLayout="row wrap"
        fxLayoutGap="5px"
        fxLayoutAlign="space-between"
      >
        <mat-form-field fxFlex appearance="outline" class="eyes-spacer-half">
          <mat-label>{{
            'Scenario.labels.compare.general.year' | translate
          }}</mat-label>

          <mat-select
            [formControl]="yearSelectControl"
            [hideSingleSelectionIndicator]="true"
            data-testid="dispatch-control-year"
          >
            <mat-option *ngFor="let year of yearOptions" [value]="year">{{
              year
            }}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field fxFlex appearance="outline" class="eyes-spacer-half">
          <mat-label>{{
            'Scenario.labels.compare.general.node' | translate
          }}</mat-label>

          <mat-select
            [formControl]="nodeSelectControl"
            [hideSingleSelectionIndicator]="true"
            data-testid="dispatch-control-node"
          >
            <mat-option *ngFor="let node of nodeOptions" [value]="node">{{
              node
            }}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field fxFlex appearance="outline" class="eyes-spacer-half">
          <mat-label>{{
            'Scenario.labels.compare.general.energyVector' | translate
          }}</mat-label>

          <mat-select
            [formControl]="energyVectorSelectControl"
            [hideSingleSelectionIndicator]="true"
            data-testid="dispatch-control-vector"
          >
            <mat-option
              *ngFor="let energyVector of energyVectorOptions"
              [value]="energyVector"
              >{{ energyVector }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>

      <div
        class="result-type-toggle-container"
        fxFlex="calc(50% - 20px)"
        fxLayoutAlign="end"
      >
        <mat-button-toggle-group
          name="resultTypeToggle"
          fxLayout="row wrap"
          fxLayoutAlign="end center"
          [formControl]="resultTypeToggleControl"
        >
          <mat-button-toggle
            *ngFor="let option of resultTypeOptions"
            [value]="option?.value"
            [attr.data-testid]="'dispatch-result-' + option?.value"
          >
            {{ option?.name | translate }}
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>

    <div
      class="chart-slider-container"
      fxLayout="column"
      class="eyes-spacer"
      [ngSwitch]="typeValue$ | async"
      [@fadeIn]
    >
      <ng-container *ngSwitchCase="'raw'">
        <div
          class="chart-container eyes-spacer-top eyes-spacer"
          fxLayout="row wrap"
          fxLayoutAlign="space-evenly"
          fxLayoutGap="20px"
        >
          <ng-container
            *ngIf="(resultTypeValue$ | async) === storageType; else defaultRaw"
          >
            <prosumer-compare-raw-storage
              [fxFlex]="flexWidthBasedOnDataLength$ | async"
              *ngFor="let data of filteredData$ | async"
              [customColors]="customColors$ | async"
              [@fadeIn]
              [caseId]="caseId"
              [data]="data"
            ></prosumer-compare-raw-storage>
          </ng-container>

          <ng-template #defaultRaw>
            <prosumer-compare-dispatch-raw
              [fxFlex]="flexWidthBasedOnDataLength$ | async"
              *ngFor="let data of filteredData$ | async"
              [customColors]="customColors$ | async"
              [@fadeIn]
              [caseId]="caseId"
              [data]="data"
            >
            </prosumer-compare-dispatch-raw>
          </ng-template>
        </div>

        <prosumer-year-slider
          [@fadeIn]
          [year]="yearValue$ | async"
          [initStart]="sliderStart$ | async"
          [initEnd]="sliderEnd$ | async"
          (startChange)="onSliderStartChange($event)"
          (endChange)="onSliderEndChange($event)"
        >
        </prosumer-year-slider>
      </ng-container>

      <ng-container *ngSwitchCase="'daily'">
        <div
          class="chart-container eyes-spacer-top eyes-spacer"
          fxLayout="row wrap"
          fxLayoutAlign="center stretch"
          fxLayoutGap="16px"
        >
          <ng-container
            *ngIf="
              (resultTypeValue$ | async) === storageType;
              else defaultDaily
            "
          >
            <prosumer-compare-daily-storage
              [fxFlex]="flexWidthBasedOnDataLength$ | async"
              *ngFor="let data of filteredData$ | async"
              [customColors]="storageColors$ | async"
              [@fadeIn]
              [caseId]="caseId"
              [data]="data"
            ></prosumer-compare-daily-storage>
          </ng-container>

          <ng-template #defaultDaily>
            <prosumer-compare-daily
              [fxFlex]="flexWidthBasedOnDataLength$ | async"
              *ngFor="let data of filteredData$ | async"
              [customColors]="customColors$ | async"
              [@fadeIn]
              [data]="data"
              [caseId]="caseId"
            ></prosumer-compare-daily>
          </ng-template>
        </div>

        <prosumer-year-slider
          [@fadeIn]
          [type]="typeValue$ | async"
          [year]="yearValue$ | async"
          [initStart]="sliderStart$ | async"
          [initEnd]="sliderEnd$ | async"
          (startChange)="onSliderStartChange($event)"
          (endChange)="onSliderEndChange($event)"
        >
        </prosumer-year-slider>
      </ng-container>

      <ng-container *ngSwitchCase="'weekly'">
        <div
          class="chart-container eyes-spacer-top eyes-spacer"
          fxLayout="row wrap"
          fxLayoutAlign="center stretch"
          fxLayoutGap="16px"
        >
          <ng-container
            *ngIf="
              (resultTypeValue$ | async) === storageType;
              else defaultWeekly
            "
          >
            <prosumer-compare-weekly-storage
              [fxFlex]="flexWidthBasedOnDataLength$ | async"
              *ngFor="let data of filteredData$ | async"
              [customColors]="storageColors$ | async"
              [@fadeIn]
              [data]="data"
              [caseId]="caseId"
            ></prosumer-compare-weekly-storage>
          </ng-container>

          <ng-template #defaultWeekly>
            <prosumer-compare-weekly
              [fxFlex]="flexWidthBasedOnDataLength$ | async"
              *ngFor="let data of filteredData$ | async"
              [customColors]="customColors$ | async"
              [@fadeIn]
              [data]="data"
              [caseId]="caseId"
            ></prosumer-compare-weekly>
          </ng-template>
        </div>

        <prosumer-year-slider
          [@fadeIn]
          [type]="typeValue$ | async"
          [year]="yearValue$ | async"
          [initStart]="sliderStart$ | async"
          [initEnd]="sliderEnd$ | async"
          (startChange)="onSliderStartChange($event)"
          (endChange)="onSliderEndChange($event)"
        >
        </prosumer-year-slider>
      </ng-container>

      <ng-container *ngSwitchCase="'monthly'">
        <div
          class="chart-container eyes-spacer-top eyes-spacer"
          fxLayout="row wrap"
          fxLayoutAlign="space-evenly"
          fxLayoutGap="20px"
        >
          <ng-container
            *ngIf="
              (resultTypeValue$ | async) === storageType;
              else defaultMonthly
            "
          >
            <prosumer-compare-monthly-storage
              *ngFor="let data of filteredData$ | async"
              [fxFlex]="flexWidthBasedOnDataLength$ | async"
              [customColors]="storageColors$ | async"
              [@fadeIn]
              [caseId]="caseId"
              [data]="data"
            ></prosumer-compare-monthly-storage>
          </ng-container>
          <ng-template #defaultMonthly>
            <prosumer-compare-dispatch-monthly
              [fxFlex]="flexWidthBasedOnDataLength$ | async"
              *ngFor="let data of filteredData$ | async"
              [customColors]="customColors$ | async"
              [@fadeIn]
              [year]="yearValue$ | async"
              [data]="data"
              [caseId]="caseId"
            >
            </prosumer-compare-dispatch-monthly>
          </ng-template>
        </div>
      </ng-container>
    </div>
  </mat-card>
</ng-template>
