<div
  class="main-output-card"
  fxLayout="column"
  fxLayoutAlign="start stretch"
  fxLayoutGap="16px"
>
  <prosumer-costs></prosumer-costs>
  <prosumer-main-output-results
    [caseId]="caseId"
    [scenarioName]="scenarioName"
  ></prosumer-main-output-results>
</div>
