export interface HasTDBProfileFilters {
  readonly tdbFilters: Partial<TdbActiveFilters>;
}

export interface TdbActiveFilters extends Record<string, string | number> {
  readonly filterDate: string;
  readonly updatedAt: string;
  readonly geography: string;
  readonly scenario: string;
  readonly unit: string;
}

export enum TDBGranularity {
  yearly = 'yearly',
  hourly = 'hourly',
}
