import { Injectable } from '@angular/core';
import { FormFieldOption } from 'prosumer-app/libs/eyes-shared';
import { EnergyVectorQuery } from 'prosumer-app/stores/energy-vector';
import { EquipmentQuery } from 'prosumer-app/stores/equipment';
import { LoadQuery } from 'prosumer-app/stores/load';
import {
  Netting,
  NettingQuery,
  NettingStore,
} from 'prosumer-app/stores/netting';
import { NodeQuery } from 'prosumer-app/stores/node';
import {
  TaxAndSubsidy,
  TaxAndSubsidyQuery,
  TaxAndSubsidyStore,
} from 'prosumer-app/stores/tax-and-subsidy';
import { Observable } from 'rxjs';
import {
  OptionsForNettingDialogData,
  OptionsForTaxAndSubData,
} from './netting-form.types';

@Injectable({
  providedIn: 'root',
})
export class NettingFormService {
  constructor(
    private readonly taxAndSubStore: TaxAndSubsidyStore,
    private readonly taxAndSubs: TaxAndSubsidyQuery,
    private readonly nettingStore: NettingStore,
    private readonly nettings: NettingQuery,
    private readonly nodes: NodeQuery,
    private readonly vector: EnergyVectorQuery,
    private readonly equipment: EquipmentQuery,
    private readonly load: LoadQuery,
  ) {}

  deleteTaxAndSub(id: string): Observable<unknown> {
    return this.taxAndSubStore.deleteOne(id);
  }

  deleteNetting(id: string): Observable<unknown> {
    return this.nettingStore.deleteOne(id);
  }

  selectActiveNettings(): Observable<Netting[]> {
    return this.nettings.selectAllForActiveScenario();
  }

  selectActiveTaxAndSubs(): Observable<TaxAndSubsidy[]> {
    return this.taxAndSubs.selectAllForActiveScenario();
  }

  prepOptionsForNettingDialog(): OptionsForNettingDialogData {
    return {
      nodeOptions: this.getNodeOptions(),
      energyVectorOptions: this.getVectorOptions(),
      loads: this.load.getActiveLoads(),
      equipments: this.equipment.getActiveEquipments(),
      endUseLoads$: this.nettings.selectAllForActiveScenario(),
    };
  }

  prepOptionsFoTaxAndSubsidyDialog(): OptionsForTaxAndSubData {
    return {
      nodeOptions: this.getNodeOptions(),
      nettingOptions: this.getNettingOptions(),
    };
  }

  private getVectorOptions(): FormFieldOption<string>[] {
    return this.vector.getVectorOptions();
  }

  private getNodeOptions(): FormFieldOption<string>[] {
    return this.nodes.getActiveOptions() as FormFieldOption<string>[];
  }

  private getNettingOptions(): FormFieldOption<string>[] {
    return this.nettings.getNettingOptions() as FormFieldOption<string>[];
  }
}
