import { Injectable } from '@angular/core';
import { StoreConfig } from '@datorama/akita';
import { ScenarioDetailStore, ScenarioDetailType } from '../scenario-detail';
import { ReserveNetwork } from './reserve-network.state';
import { FrequencyControlMapper } from 'prosumer-app/+scenario/services/mappers';

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: ScenarioDetailType.reserveNetwork })
export class ReserveNetworkStore extends ScenarioDetailStore<ReserveNetwork> {
  toBE(from: any): ReserveNetwork {
    return FrequencyControlMapper.srNetworkToBe(from);
  }
}
