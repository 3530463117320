import { InjectionToken } from '@angular/core';
import { ResultsData, ValueType } from '@prosumer/results/models';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { BaseResultsVisualizerService } from './results-visualizer.base';
import {
  ResultVisualizerSlice,
  VisualizerData,
} from './results-visualizer.model';

export const RESULT_VIZ_SERVICE = new InjectionToken(
  'results-visualizer-service',
);

export abstract class ResultsVisualizerService<
  T extends ResultsData = ResultsData,
> extends BaseResultsVisualizerService<T> {
  private selectedSliceSubject = new BehaviorSubject<string>(undefined);
  private optimizedSubject = new BehaviorSubject<boolean>(false);
  isOverallSubject$ = new BehaviorSubject<boolean>(false);

  get selectedSlice(): string {
    return this.selectedSliceSubject.value;
  }

  abstract getSlices(): ResultVisualizerSlice[];
  abstract getYearsToOptimizeStream(): Observable<number[]>;

  abstract mapRawDataToIncremental(raw: T[], slice: string): VisualizerData[];
  abstract optimizeYearsForIncremental(
    data: VisualizerData[],
    years: number[],
  ): VisualizerData[];
  abstract mapRawDataToCumulative(raw: T[], slice: string): VisualizerData[];
  abstract optimizeYearsForCumulative(
    data: VisualizerData[],
    years: number[],
  ): VisualizerData[];

  getIncrementalDataStream(): Observable<VisualizerData[]> {
    return this.getIncrementalChartSourceStream().pipe(
      map(([data, slice]) => this.mapRawDataToIncremental(data, slice)),
      withLatestFrom(this.getYearsToOptimizeStream()),
      map(([data, years]) =>
        this.resolveOptimizationForIncremental(data, years),
      ),
    );
  }

  getCumulativeDataStream(): Observable<VisualizerData[]> {
    return this.getCumulativeChartSourceStream().pipe(
      map(([data, slice]) => this.mapRawDataToCumulative(data, slice)),
      withLatestFrom(this.getYearsToOptimizeStream()),
      map(([data, years]) =>
        this.resolveOptimizationForCumulative(data, years),
      ),
    );
  }

  setSelectedSlice(slice: string): void {
    this.selectedSliceSubject.next(slice);
    this.isOverallSubject$.next(slice === 'overall');
  }

  setOptimized(optimized: boolean): void {
    this.optimizedSubject.next(optimized);
  }

  private resolveOptimizationForCumulative(
    data: VisualizerData[],
    years: number[],
  ): VisualizerData[] {
    return this.shouldOptimize(years)
      ? this.optimizeYearsForCumulative(data, years)
      : data;
  }

  private resolveOptimizationForIncremental(
    data: VisualizerData[],
    years: number[],
  ): VisualizerData[] {
    return this.shouldOptimize(years)
      ? this.optimizeYearsForIncremental(data, years)
      : data;
  }

  private shouldOptimize(years: number[]): boolean {
    return years.length > 0 && this.optimizedSubject.value;
  }

  private getIncrementalChartSourceStream(): Observable<[T[], string]> {
    return combineLatest([
      this.getIncrementalSourceStream(),
      this.selectedSliceSubject,
      this.optimizedSubject,
    ]).pipe(
      filter(([, slice]) => !!slice),
      map(([data, slice]) => [data, slice]),
    );
  }

  private getCumulativeChartSourceStream(): Observable<[T[], string]> {
    return combineLatest([
      this.getCumulativeSourceStream(),
      this.selectedSliceSubject,
      this.optimizedSubject,
    ]).pipe(
      filter(([, slice]) => !!slice),
      map(([data, slice]) => [data, slice]),
    );
  }

  private getIncrementalSourceStream(): Observable<T[]> {
    return this.getValueTypeData$(ValueType.INCREMENTAL);
  }

  private getCumulativeSourceStream(): Observable<T[]> {
    return this.getValueTypeData$(ValueType.CUMULATIVE);
  }

  private getValueTypeData$(type: ValueType): Observable<T[]> {
    return this.getFilteredRawDataStream().pipe(
      map((data) => data.filter((datum) => datum.valueType === type)),
    );
  }
}
