/* eslint-disable @typescript-eslint/naming-convention */
export interface ChartJSMeta {
  readonly name?: string;
  readonly orientation?: ChartOrientation;
}

export type ChartColors = { [key: string]: string };
export enum ChartOrientation {
  HORIZONTAL,
  VERTICAL,
}

export enum ColorShade {
  LIGHTEN,
  DARKEN,
}
