import { Injectable } from '@angular/core';
import { StoreConfig } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CascadableDetailStore } from '../cascadable-detail';
import { ScenarioDetailInfo, ScenarioDetailType } from '../scenario-detail';
import { NodeInfo } from './node.state';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: ScenarioDetailType.node, idKey: 'nodeId' })
export class NodeStore extends CascadableDetailStore<NodeInfo> {
  getNodes(): Observable<unknown> {
    const data = { dataType: ScenarioDetailType.node };
    return this.handleAdd(
      this.get({ key: 'getDetailByDataType', data, body: undefined }).pipe(
        map((data: ScenarioDetailInfo<NodeInfo>) => data.details),
        tap((data: NodeInfo[]) => {
          if (
            !!data &&
            data.length > 0 &&
            data.every((datum) => datum.scenarioUuid)
          ) {
            this.remove(
              ({ scenarioUuid }) => scenarioUuid === data[0].scenarioUuid,
            );
          }
        }),
      ),
    );
  }

  createNode(name: string) {
    return this.post({
      key: 'createDetail',
      data: {},
      body: { nodeId: undefined, name },
    }).pipe(
      tap((node: NodeInfo) => {
        this.add(node);
      }),
    );
  }

  deleteNode(nodeId: string) {
    return this.deleteOne(nodeId);
  }

  private handleAdd($: Observable<unknown>): Observable<unknown> {
    return $.pipe(tap((data: NodeInfo) => this.add(data)));
  }

  protected getDataUuid(data: Record<string, unknown>): unknown {
    return data['nodeId'];
  }
}
