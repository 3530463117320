<form [formGroup]="form" (ngSubmit)="onClickNext()">
  <!-- Limits -->
  <mat-expansion-panel [expanded]="expanded">
    <mat-expansion-panel-header
      [collapsedHeight]="collapsedHeight"
      [expandedHeight]="expandedHeight"
    >
      <mat-panel-title>
        {{
          'Scenario.labels.wizard.optimzationCockpit.headers.limits' | translate
        }}
        <prosumer-tooltip-anchor
          [message]="'wizard_optimization_cockpit.wizard_limits_section'"
          [attr.data-testid]="'prosumer-limits-tooltip'"
        ></prosumer-tooltip-anchor>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <prosumer-limits-form-component
      [scenarioIdentity]="scenarioIdentity"
      [attr.data-testid]="'optimization-cockpit-limits-form'"
      [isViewOnly]="isViewOnly || mode === 'read'"
    ></prosumer-limits-form-component>
    <prosumer-variations-limits-form
      class="custom-limits-form"
      *ngFor="let variation of scenarioVariations$ | ngrxPush"
      [scenarioVariation]="variation"
      [isViewOnly]="isViewOnly || mode === 'read'"
      [attr.data-testid]="'optimization-cockpit-variations-limits-form'"
    ></prosumer-variations-limits-form>
  </mat-expansion-panel>
  <!-- Options-->
  <mat-expansion-panel>
    <mat-expansion-panel-header
      [collapsedHeight]="collapsedHeight"
      [expandedHeight]="expandedHeight"
    >
      <mat-panel-title>
        {{
          'Scenario.labels.wizard.optimzationCockpit.headers.options'
            | translate
        }}
        <prosumer-tooltip-anchor
          [message]="'wizard_optimization_cockpit.wizard_options_section'"
          [attr.data-testid]="'prosumer-options-tooltip'"
        ></prosumer-tooltip-anchor>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <prosumer-options-form
      [scenarioIdentity]="scenarioIdentity"
      [attr.data-testid]="'optimization-cockpit-options-form'"
      [isViewOnly]="isViewOnly || mode === 'read'"
    ></prosumer-options-form>
  </mat-expansion-panel>
  <!-- Custom Options-->
  <mat-expansion-panel>
    <mat-expansion-panel-header
      [collapsedHeight]="collapsedHeight"
      [expandedHeight]="expandedHeight"
    >
      <mat-panel-title>
        {{
          'Scenario.labels.wizard.optimzationCockpit.headers.customOptions'
            | translate
        }}
        <prosumer-tooltip-anchor
          [message]="
            'wizard_optimization_cockpit.wizard_custom_options_section'
          "
          [attr.data-testid]="'prosumer-custom-options-tooltip'"
        ></prosumer-tooltip-anchor>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <prosumer-custom-options-form-table
      [scenarioIdentity]="scenarioIdentity"
      [attr.data-testid]="'optimization-cockpit-custom-options-form'"
      [isViewOnly]="isViewOnly || mode === 'read'"
    ></prosumer-custom-options-form-table>
  </mat-expansion-panel>

  <prosumer-stepper-nav
    [disabled]="disabled"
    [previousDisabled]="previousDisabled"
    [previousLabel]="previousLabel"
    [previousColor]="previousColor"
    [nextDisabled]="nextDisabled"
    [nextLabel]="nextLabel"
    [nextColor]="nextColor"
    [reverseButtons]="reverseButtons"
    (previous)="onClickPrevious()"
  ></prosumer-stepper-nav>
</form>
