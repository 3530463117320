import { CommoditiesForm } from 'prosumer-app/+scenario/models';
import { Utils } from 'prosumer-core/utils';

import { CompletionStrategy } from './completion-strategy';

export class CommoditiesCompletion extends CompletionStrategy<CommoditiesForm> {
  isFull(form: CommoditiesForm): boolean {
    return this.getFieldsEvaluation(form).every(Boolean);
  }

  isHalf(form: CommoditiesForm): boolean {
    return this.getFieldsEvaluation(form).some(Boolean);
  }

  private getFieldsEvaluation(form: CommoditiesForm): boolean[] {
    return [
      this.hasItems(form.grids),
      this.hasItems(form.connectionsEmissions),
      this.hasItems(form.limits),
      this.hasItems(form.fuels),
      !!form.co2Price,
      !!form.co2Scope2Price,
      !!form.co2Scope3Price,
    ];
  }

  private hasItems(list: unknown[]): boolean {
    return !!Utils.resolveToEmptyArray(list).length;
  }
}
