import { Injectable } from '@angular/core';
import { ActiveKeeperService } from 'prosumer-app/services/active-keeper';
import { ScenarioDetailQuery } from 'prosumer-app/stores/scenario-detail';
import { ReserveNetwork } from './reserve-network.state';
import { ReserveNetworkStore } from './reserve-network.store';

@Injectable({
  providedIn: 'root',
})
export class ReserveNetworkQuery extends ScenarioDetailQuery<ReserveNetwork> {
  constructor(store: ReserveNetworkStore, keeper: ActiveKeeperService) {
    super(store, keeper);
  }
}
