import { Library } from 'prosumer-app/+scenario/models';
import { ProfileType } from 'prosumer-app/+scenario/types';
import { convertDataValuesToString } from 'prosumer-app/libs/eyes-shared';
import {
  getLibraryObject,
  getLibrarySourceType,
  mapYearlyValuesToBackend,
  mapYearlyValuesToFrontend,
} from 'prosumer-app/shared';
import { LineInfo } from 'prosumer-app/stores/line';

const CONVERSION_EXCEPTIONS = ['technicalLife', 'depreciationTime'];

export class LineMapper {
  static toBE = (line: Record<string, any>): LineInfo =>
    convertDataValuesToString(
      {
        scenarioUuid: line.scenarioUuid,
        bidirectional: line.bidirectional,
        buildCost: mapYearlyValuesToBackend(line.yearlyBuildCost),
        buildEmissionsIndivisible: mapYearlyValuesToBackend(
          line.yearlyBuildEmissionsIndivisible,
        ),
        buildEmissionsKwm: mapYearlyValuesToBackend(
          line.yearlyBuildEmissionsKwm,
        ),
        capacityExpansion: line.capacityExpansion,
        depreciationTime: mapYearlyValuesToBackend(line.yearlyDepreciationTime),
        destinationNodeId: line.destinationNodeId,
        distance: line.distance,
        efficiency: mapYearlyValuesToBackend(line.yearlyEfficiency),
        energyVectorId: line.energyVectorId,
        existingAsset: line.existingAsset,
        fomCharge: mapYearlyValuesToBackend(line.yearlyFomCharge),
        fomInstall: mapYearlyValuesToBackend(line.yearlyFomInstall),
        forcedInvestment: line.forcedInvestment,
        indivisibleCost: mapYearlyValuesToBackend(line.yearlyIndivisibleCost),
        libraryId: LineMapper.deduceLibraryId(line.library),
        lineId: line.lineId,
        minSize: mapYearlyValuesToBackend(line.yearlyMinSize),
        maxSize: mapYearlyValuesToBackend(line.yearlyMaxSize),
        name: line.name,
        originNodeId: line.originNodeId,
        technicalLife: mapYearlyValuesToBackend(line.yearlyTechnicalLife),
      },
      CONVERSION_EXCEPTIONS,
    );

  private static deduceLibraryId(library: Library): string {
    return library ? library.id : 'custom';
  }

  static toFE = (
    line: LineInfo,
    startYear: number,
    endYear: number,
  ): Record<string, unknown> => {
    if (!!line) {
      return {
        name: line.name,
        energyVectorId: line.energyVectorId,
        originNodeId: line.originNodeId,
        destinationNodeId: line.destinationNodeId,
        distance: line.distance,
        bidirectional: line.bidirectional,
        library: getLibraryObject(line.libraryId),
        sourceType: getLibrarySourceType(line.libraryId as ProfileType),
        yearlyEfficiency: mapYearlyValuesToFrontend(
          line.efficiency,
          startYear,
          endYear,
        ),
        yearlyBuildEmissionsKwm: mapYearlyValuesToFrontend(
          line.buildEmissionsKwm,
          startYear,
          endYear,
        ),
        yearlyBuildEmissionsIndivisible: mapYearlyValuesToFrontend(
          line.buildEmissionsIndivisible,
          startYear,
          endYear,
        ),
        yearlyMinSize: mapYearlyValuesToFrontend(
          line.minSize,
          startYear,
          endYear,
        ),
        yearlyMaxSize: mapYearlyValuesToFrontend(
          line.maxSize,
          startYear,
          endYear,
        ),
        yearlyBuildCost: mapYearlyValuesToFrontend(
          line.buildCost,
          startYear,
          endYear,
        ),
        yearlyIndivisibleCost: mapYearlyValuesToFrontend(
          line.indivisibleCost,
          startYear,
          endYear,
        ),
        yearlyFomCharge: mapYearlyValuesToFrontend(
          line.fomCharge,
          startYear,
          endYear,
        ),
        yearlyFomInstall: mapYearlyValuesToFrontend(
          line.fomInstall,
          startYear,
          endYear,
        ),
        yearlyTechnicalLife: mapYearlyValuesToFrontend(
          line.technicalLife,
          startYear,
          endYear,
        ),
        yearlyDepreciationTime: mapYearlyValuesToFrontend(
          line.depreciationTime,
          startYear,
          endYear,
        ),
        forcedInvestment: line.forcedInvestment,
        existingAsset: line.existingAsset,
        capacityExpansion: line.capacityExpansion,
      };
    }
    return {};
  };
}
