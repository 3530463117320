import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmissionComparisonComponent } from './emission-comparison.component';
import { ComparisonVisualizerModule } from '../comparison-visualizer';
import { StackedBarChartjsModule } from 'prosumer-shared/modules/chartjs/stacked-bar-chartjs';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [EmissionComparisonComponent],
  imports: [
    CommonModule,
    ComparisonVisualizerModule,
    StackedBarChartjsModule,
    TranslateModule,
  ],
  exports: [EmissionComparisonComponent],
})
export class EmissionComparisonModule {}
