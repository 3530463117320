<form [formGroup]="form" (ngSubmit)="onClickNext()">
  <!-- Short Circuit -->
  <mat-expansion-panel [expanded]="expanded">
    <mat-expansion-panel-header
      [collapsedHeight]="collapsedHeight"
      [expandedHeight]="expandedHeight"
    >
      <mat-panel-title data-testid="short-circuit-title">
        {{
          'Scenario.labels.wizard.frequencyControl.headers.shortCircuit'
            | translate
        }}
        <prosumer-tooltip-anchor
          [message]="'wizard_frequency_control.short_circuit' | translate"
          data-testid="short-circuit-tooltip"
        >
        </prosumer-tooltip-anchor>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <prosumer-short-circuit-form
      [startYear]="startYear"
      [endYear]="endYear"
      [hideAdd]="canNotAdd()"
      [hideEdit]="canNotEdit()"
      [hideDelete]="canNotDelete()"
      [hideView]="canNotView()"
    >
    </prosumer-short-circuit-form>
  </mat-expansion-panel>

  <!-- Spinning Reserves -->
  <mat-expansion-panel [expanded]="expanded">
    <mat-expansion-panel-header
      [collapsedHeight]="collapsedHeight"
      [expandedHeight]="expandedHeight"
    >
      <mat-panel-title>
        {{
          'Scenario.labels.wizard.frequencyControl.headers.spinningReserves'
            | translate
        }}
        <prosumer-tooltip-anchor
          [message]="'wizard_frequency_control.reserve' | translate"
          data-testid="spinning-reserves-tooltip"
        ></prosumer-tooltip-anchor>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <prosumer-spinning-reserves-form
      formControlName="spinningReserve"
      [startYear]="startYear"
      [endYear]="endYear"
      [energyVectors]="energyVectors"
      [equipments]="equipments"
      [markets]="markets"
      [scenarioIdentity]="scenarioIdentity"
      [hideAdd]="canNotAdd()"
      [hideEdit]="canNotEdit()"
      [hideDelete]="canNotDelete()"
      [hideView]="canNotView()"
    ></prosumer-spinning-reserves-form>
  </mat-expansion-panel>

  <prosumer-stepper-nav
    [disabled]="disabled"
    [previousDisabled]="previousDisabled"
    [previousLabel]="previousLabel"
    [previousColor]="previousColor"
    [nextDisabled]="nextDisabled"
    [nextLabel]="nextLabel"
    [nextColor]="nextColor"
    [reverseButtons]="reverseButtons"
    (previous)="onClickPrevious()"
  >
  </prosumer-stepper-nav>
</form>
