import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ScenarioVariationUpdaterDirective } from './scenario-variation-updater.directive';

@NgModule({
  declarations: [ScenarioVariationUpdaterDirective],
  imports: [CommonModule],
  exports: [ScenarioVariationUpdaterDirective],
})
export class ScenarioVariationUpdaterModule {}
