import { TitleCasePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Color, ColorHelper, ScaleType } from '@swimlane/ngx-charts';
import { ENGIE_DEFAULT_SCHEME } from 'prosumer-app/app.references';
import { BaseComponent, contains } from 'prosumer-app/libs/eyes-shared';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { addBallotBox, updateLegendFilter } from '../../shared/helper';
import { EquipmentService } from '../equipment.service';

@Component({
  selector: 'prosumer-incremental-visualization',
  templateUrl: './incremental-visualization.component.html',
  styleUrls: ['./incremental-visualization.component.scss'],
})
export class IncrementalVisualizationComponent
  extends BaseComponent
  implements OnInit
{
  @Input() chartDimensions = [1000, 480];
  @Input() chartScheme = ENGIE_DEFAULT_SCHEME;
  @Input() chartXAxisLabel = 'year';
  @Input() toolTipYAxisLabel = 'kW';
  @Input() chartShowXLabel = true;
  @Input() chartShowYLabel = true;
  @Input() chartShowXAxis = true;
  @Input() chartShowYAxis = true;
  @Input() chartShowLegend = true;

  data$: Observable<any[]>;
  chartYAxisLabel$: Observable<string>;
  chartTitle$: Observable<string>;
  tooltipData: Array<any> = [];
  legendFilter$ = new BehaviorSubject<Array<string>>([]);

  @Input() chartTooltipTitleFormat = (value: any) =>
    this._titleCase.transform(value.series);

  constructor(
    private _titleCase: TitleCasePipe,
    private _equipmentService: EquipmentService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.data$ = combineLatest([
      this._equipmentService.chartData$,
      this.legendFilter$,
    ]).pipe(
      map(([data, legendFilter]) =>
        data.map((d) => ({
          ...d,
          series: d.series.map((s) => ({
            name: addBallotBox(legendFilter, s.name),
            value: contains(legendFilter, s.name) ? 0 : s.value,
          })),
        })),
      ),
    );
    this.chartTitle$ = this._equipmentService.chartTitle$;
    this.chartYAxisLabel$ = this._equipmentService.yAxisLabel$;
  }

  getSeriesMembers(seriesModel: any) {
    this.data$.forEach((yearSeries) => {
      const filtered = yearSeries.filter(
        (yearBox) => yearBox.name === seriesModel.value.series,
      );
      if (filtered && filtered[0] && filtered[0].series) {
        this.tooltipData = filtered[0].series.map((item) => ({
          series: item.name,
          value: item.value,
          name: filtered[0].name,
          current: seriesModel.value.name === filtered[0].name,
          color: new ColorHelper(
            this.chartScheme as Color,
            ScaleType.Ordinal,
            filtered[0].series.map((d) => d.name),
          ).getColor(item.name),
        }));
      }
    });
  }

  onSelect(selected: string) {
    updateLegendFilter(this.legendFilter$, selected);
  }
}
