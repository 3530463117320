import { Injectable } from '@angular/core';
import { StoreConfig } from '@datorama/akita';
import { ScenarioDetailStore, ScenarioDetailType } from '../scenario-detail';
import { ReserveEquipment } from './reserve-equipment.state';

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: ScenarioDetailType.reserveEquipment })
export class ReserveEquipmentStore extends ScenarioDetailStore<ReserveEquipment> {
  toBE(from: ReserveEquipment): ReserveEquipment {
    return {
      ...from,
      requirement: String(from.requirement),
    };
  }
}
