<prosumer-page>
  <prosumer-progress-card>
    <div
      class="prosumer-container"
      fxLayout="column"
      fxLayoutGap="10px"
      fxLayoutAlign="center center"
    >
      <div class="prosumer-running-icon">
        <img [src]="runningIcon" alt="Prosumer Running Icon" />
      </div>
      <h1 class="mat-headline-4">Prosumer is running...</h1>
      <div class="mat-body-2">Your scenario(s) is(are) being simulated.</div>
      <div class="mat-body-2">
        Notification(s) will be sent to your inbox once simulation(s) is(are)
        done.
      </div>
      <div class="prosumer-button-container">
        <prosumer-button
          data-testid="simulation-running-proceed"
          (click)="onOK()"
          color="primary"
          label="Ok"
        ></prosumer-button>
      </div>
    </div>
  </prosumer-progress-card>
</prosumer-page>
