<prosumer-page *ngrxLet="{ params$, uploading: uploading$ } as vm">
  <prosumer-progress-card [showProgress]="vm.uploading">
    <div fxLayout="row wrap-reverse">
      <prosumer-scenario-upload-form
        [isLoading]="vm.uploading"
        [isFormSubmitted]="formSubmitted()"
        [columnDef]="scenarioInputColumnDef"
        (save)="onCreate($event)"
        (cancelClicked)="onCancelClicked()"
        (allUploadsSucceeded)="onUploadsSuccessAll()"
      ></prosumer-scenario-upload-form>
      <prosumer-upload-form-list
        fxFlex="400px"
        [acceptedFiles]="'.gms'"
        [columnDef]="gamsColumnDef"
        [multipleFiles]="true"
        [disabledTable]="formSubmitted()"
        [disabledFileCtrl]="formSubmitted()"
        [formTitle]="'Scenario.upload.gams.formTitle' | translate"
        [infoMessage]="'Scenario.messages.gamsUploadWarning' | translate"
        (valueChanged)="gamsFilesChanged($event)"
      ></prosumer-upload-form-list>
    </div>
  </prosumer-progress-card>
</prosumer-page>
