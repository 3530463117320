import { Injectable } from '@angular/core';
import { ActiveKeeperService } from '../active-keeper';
import { EndpointProvider } from '../endpoint-provider';
import {
  ListSubResources,
  SingleSubResource,
} from './scenario-endpoint.models';

@Injectable({
  providedIn: 'root',
})
export class ScenarioEndpointProvider {
  constructor(
    private readonly endpoints: EndpointProvider,
    private readonly keeper: ActiveKeeperService,
  ) {}

  forSubResources(params: ListSubResources): string {
    return this.endpoints.forSubResources({
      ...params,
      ...this.keeper.getActiveEntities(),
    });
  }

  forSubResource(params: SingleSubResource): string {
    return this.endpoints.forSubResource({
      ...params,
      ...this.keeper.getActiveEntities(),
    });
  }
}
