<prosumer-stacked-bar-chartjs
  *ngIf="type === 'incremental'; else cumulative"
  scenarioName="{{ scenarioName }}"
  resultsName="{{ resultsName }}"
  [data]="barChartJSData$ | async"
  [colors]="colorMap"
  [id]="resultsName + 'stacked-bar'"
  (legendSelect)="legendSelect?.emit($event)"
  scenarioName="{{ scenarioName }}"
  resultsName="{{ resultsName + '_incremental' }}"
></prosumer-stacked-bar-chartjs>

<ng-template #cumulative>
  <prosumer-stacked-area-chartjs
    [data]="chartJSData$ | async"
    [colors]="colorMap"
    [id]="resultsName + 'stacked-area'"
    (legendSelect)="legendSelect?.emit($event)"
    scenarioName="{{ scenarioName }}"
    resultsName="{{ resultsName + '_cumulative' }}"
  ></prosumer-stacked-area-chartjs>
</ng-template>
