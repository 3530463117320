<prosumer-dialog-div
  [formGroup]="duplicableForm"
  [loading]="loading$ | ngrxPush"
  [okLabel]="'Scenario.labels.general.duplicate' | translate"
  [disabled]="duplicableForm.pristine || duplicableForm.invalid"
  title="Duplicate Market Limits"
  (cancel)="onCancel()"
  (ok)="onOk()"
>
  <div fxLayout="row">
    <mat-form-field
      fxFlex="100%"
      appearance="outline"
      class="market-form-field"
      data-testid="market-limit-duplicate-marketSelector"
    >
      <mat-label>{{
        'Scenario.labels.energyGridLimits.market' | translate
      }}</mat-label>
      <mat-select
        formControlName="market"
        [hideSingleSelectionIndicator]="true"
        [placeholder]="
          'Scenario.placeholders.energyGridLimits.market' | translate
        "
      >
        <mat-option
          *ngFor="let market of markets$ | ngrxPush"
          [value]="market.id"
        >
          {{ market.marketName }}
        </mat-option>
      </mat-select>
      <mat-error
        [innerHTML]="
          'Scenario.messages.energyGridLimits.market.allOptionsApplied'
            | translate
        "
      ></mat-error>
    </mat-form-field>
  </div>
  <div fxLayout="row">
    <prosumer-generic-filterchip-component
      *ngIf="data.isMultiNode"
      [placeholder]="'Scenario.placeholders.energyGridLimits.node' | translate"
      [label]="'Scenario.labels.energyGridLimits.node' | translate"
      fxFlex="100%"
      [required]="true"
      [tooltip]="'wizard_commodities.wizard_market_limits_node' | translate"
      formControlName="generics"
      [enableSelectAll]="true"
      [genericOptions]="nodes$ | async"
      data-testid="market-limit-duplicate-nodeSelector"
      [setSubmitted]="submitted$ | async"
      [errorMessage]="errorMessages['node']"
    ></prosumer-generic-filterchip-component>
  </div>
</prosumer-dialog-div>
