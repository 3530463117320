import { Injectable } from '@angular/core';
import { VariationFinder } from 'prosumer-app/+scenario/services';
import { EquipmentInfo, EquipmentQuery } from 'prosumer-app/stores/equipment';
import {
  MobilityRoute,
  MobilityRouteQuery,
  MobilityRouteStore,
} from 'prosumer-app/stores/mobility-route';
import {
  MobilityStation,
  MobilityStationQuery,
  MobilityStationStore,
} from 'prosumer-app/stores/mobility-station';
import {
  VehiclesDispatch,
  VehiclesDispatchQuery,
  VehiclesDispatchStore,
} from 'prosumer-app/stores/vehicles-dispatch';
import { NodeQuery } from 'prosumer-app/stores/node';
import {
  OptionsForRouteDialogData,
  OptionsForDispatchDialogData,
  OptionsForStationDialogData,
} from './mobility-form.types';
import { Observable, forkJoin } from 'rxjs';
import { switchMap } from 'rxjs/operators';

type NameValue = { name: string; value: string };
type StationWithNodes = NameValue & { nodes: unknown[] };
type DispatchWithVehicleId = NameValue & { vehicleId: string };

@Injectable({ providedIn: 'root' })
export class MobilityFormService {
  constructor(
    private readonly routes: MobilityRouteQuery,
    private readonly nodes: NodeQuery,
    private readonly dispatches: VehiclesDispatchQuery,
    private readonly equipments: EquipmentQuery,
    private readonly stations: MobilityStationQuery,
    private readonly varFinder: VariationFinder,
    private readonly stationStore: MobilityStationStore,
    private readonly routeStore: MobilityRouteStore,
    private readonly dispatchStore: VehiclesDispatchStore,
  ) {}

  deleteAssociation(associationId: string): Observable<unknown> {
    return this.stationStore.deleteOne(associationId);
  }

  deleteRoute(routeId: string): Observable<unknown> {
    return this.routeStore
      .deleteOne(routeId)
      .pipe(switchMap(() => this.postDeleteRoute()));
  }

  deleteDispatch(dispatchId: string): Observable<unknown> {
    return this.dispatchStore
      .deleteOne(dispatchId)
      .pipe(switchMap(() => this.stationStore.improvedGetAll()));
  }

  selectActiveMobilityRoutes(): Observable<MobilityRoute[]> {
    return this.routes.selectAllForActiveScenario();
  }

  selectActiveVehicleDispatch(): Observable<VehiclesDispatch[]> {
    return this.dispatches.selectAllForActiveScenario();
  }

  selectActiveMobilityStations(): Observable<MobilityStation[]> {
    return this.stations.selectAllForActiveScenario();
  }

  prepOptionsForMobilityRouteDialog(): OptionsForRouteDialogData {
    return {
      nodeOptions: this.nodes.getActiveOptions(),
      existingRoutesData$: this.selectActiveMobilityRoutes(),
    };
  }

  prepOptionsForDispatchDialog(): OptionsForDispatchDialogData {
    return {
      routes: this.getActiveRouteInNameValues(),
      vehicles: this.getVehiclesInNameValues(),
      currentDataList$: this.selectActiveVehicleDispatch(),
      existingList: this.dispatches.getAllForActiveScenario(),
    };
  }

  prepOptionsForStationDialog(): OptionsForStationDialogData {
    return {
      currentAssocList: this.stations.getAllForActiveScenario(),
      currentDataList$: this.selectActiveMobilityStations(),
      stationNodeOptions: this.nodes.getActiveOptions(),
      vehicles: this.getVehiclesInNameValues(),

      stations: this.getStationsWithNodes(),
      vehicleNameOptions: this.getDispatchesWithVehicleId(),
    };
  }

  private getDispatchesWithVehicleId(): DispatchWithVehicleId[] {
    return this.toDispatchesWithVehicleId(
      this.dispatches.getAllForActiveScenario(),
    );
  }

  private toDispatchesWithVehicleId(
    dispatches: VehiclesDispatch[],
  ): DispatchWithVehicleId[] {
    return dispatches.map((dispatch) => this.toDispatchWithVehicleId(dispatch));
  }

  private toDispatchWithVehicleId(
    dispatch: VehiclesDispatch,
  ): DispatchWithVehicleId {
    const { id, vehicleId, vehicleName } = dispatch;
    return { name: vehicleName, value: id, vehicleId };
  }

  private getStationsWithNodes(): StationWithNodes[] {
    return this.toStationsWithNodeses(
      this.equipments.getAllWithType('station'),
    );
  }

  private toStationsWithNodeses(
    equipments: EquipmentInfo[],
  ): StationWithNodes[] {
    return equipments.map((equipment) => this.toStationWithNodes(equipment));
  }

  private toStationWithNodes(equipment: EquipmentInfo): StationWithNodes {
    const { nodes, name, equipmentId } = equipment;
    return { name, value: equipmentId, nodes };
  }

  private getVehiclesInNameValues(): NameValue[] {
    return this.equipmentsToNameValues(
      this.equipments.getAllWithType('vehicle'),
    );
  }

  private equipmentsToNameValues(equipments: EquipmentInfo[]): NameValue[] {
    return equipments.map((equipment) => this.equipmentToNameValue(equipment));
  }

  private equipmentToNameValue(equipment: EquipmentInfo): NameValue {
    return {
      name: this.buildEquipmentNameWithVariation(equipment),
      value: equipment.id,
    };
  }

  private buildEquipmentNameWithVariation(equipment: EquipmentInfo): string {
    return `${equipment.name} (${this.varFinder.getVariationName(
      equipment.scenarioVariation,
    )})`;
  }

  private getActiveRouteInNameValues(): NameValue[] {
    return this.routesToNameValues(this.routes.getAllForActiveScenario());
  }

  private routesToNameValues(routes: MobilityRoute[]): NameValue[] {
    return routes.map((route) => this.routeToNameValue(route));
  }

  private routeToNameValue(route: MobilityRoute): NameValue {
    return {
      name: route.name,
      value: route.id,
    };
  }

  private postDeleteRoute(): Observable<unknown> {
    return forkJoin([
      this.dispatchStore.improvedGetAll(),
      this.stationStore.improvedGetAll(),
    ]);
  }
}
