<button
  *ngrxLet="upserting$ as upserting"
  data-testid="saveBtn"
  mat-flat-button
  color="primary"
  [class.no-click]="upserting"
  [class.spinner]="upserting"
  [disabled]="disabled || !valid"
  (click)="onClick()"
>
  {{ 'Generic.labels.ok' | translate }}
</button>
