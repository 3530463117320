<div fxAlign="start" fxLayout="column" fxLayoutGap="4px">
  <prosumer-slide-toggle
    fxFlex="100%"
    label="Scenario.labels.tdb.toggleBtn"
    data-testid="egc-dialog-tdb-toggle-button"
    [isViewOnly]="isTdbApiResponseLoading$ | async"
    [incomingControlValue]="isTdbModuleEnabled | async"
    (valueChanged)="toggleTDBModule($event)"
  ></prosumer-slide-toggle>

  <span *ngIf="!!profileFiltersSourceDetails" class="reference-info">
    <span>*Reference:</span>
    <span class="data">{{ profileFiltersSourceDetails }}</span>
  </span>

  <form [formGroup]="tdbRenewablesForm">
    <mat-expansion-panel *ngIf="isTdbModuleEnabled | async" [expanded]="true">
      <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
        <mat-panel-title>
          <h3>
            {{ 'Scenario.labels.tdb.filter' | translate }}
            <prosumer-tooltip-anchor
              [message]="'wizard_commodities.wizard_tdb_title'"
            ></prosumer-tooltip-anchor>
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div fxLayout="row wrap" fxLayoutGap="10px" fxAlign="start">
        <!-- Transversal Database Geography -->
        <prosumer-select
          fxFlex="33%"
          [placeholder]="
            'Scenario.placeholders.energyGridConnections.tdbGeography'
              | translate
          "
          [tooltip]="'wizard_commodities.wizard_tdb_geography'"
          [label]="
            'Scenario.labels.energyGridConnections.tdbGeography' | translate
          "
          [control]="tdbRenewablesForm?.controls?.geography"
          [options]="tdbGeographyOptions$ | async"
          [disabled]="isTdbApiResponseLoading$ | async"
          data-testid="tdb-commodity-module-select-geography"
        >
        </prosumer-select>
        <!-- Transversal Database Technologies -->
        <prosumer-select
          fxFlex="33%"
          [placeholder]="
            'Scenario.placeholders.energyGridConnections.tdbTechnology'
              | translate
          "
          [tooltip]="'wizard_commodities.wizard_tdb_technology'"
          [label]="
            'Scenario.labels.energyGridConnections.tdbTechnology' | translate
          "
          [control]="tdbRenewablesForm?.controls?.technology"
          [options]="tdbTechnologyOptions$ | async"
          [disabled]="isTdbApiResponseLoading$ | async"
          data-testid="tdb-commodity-module-select-technology"
        >
        </prosumer-select>
      </div>
    </mat-expansion-panel>
  </form>
</div>
