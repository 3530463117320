import { DetailEntityDuplicator } from 'prosumer-app/+scenario/directives/detail-duplicator';
import { NotificationsService } from 'prosumer-app/shared/services/notification';
import { DuplicateDetailData } from 'prosumer-app/stores';
import { LineQuery, LineStore } from 'prosumer-app/stores/line';

import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'prosumer-lines-duplicate-dialog',
  templateUrl: './lines-duplicate-dialog.component.html',
  styleUrls: ['./lines-duplicate-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinesDuplicateDialogComponent extends DetailEntityDuplicator {
  title = 'Line';

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly data: DuplicateDetailData,
    readonly dialogRef: MatDialogRef<LinesDuplicateDialogComponent>,
    readonly lineStore: LineStore,
    readonly lineQuery: LineQuery,
    readonly notifs: NotificationsService,
  ) {
    super(data, lineStore, lineQuery, dialogRef, notifs);
  }
}
