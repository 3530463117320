import { Injectable } from '@angular/core';
import { ActiveKeeperService } from 'prosumer-app/services/active-keeper';
import { ScenarioDetailQuery } from '../scenario-detail';
import { MobilityRoute } from './mobility-route.state';
import { MobilityRouteStore } from './mobility-route.store';

@Injectable({
  providedIn: 'root',
})
export class MobilityRouteQuery extends ScenarioDetailQuery<MobilityRoute> {
  constructor(
    readonly store: MobilityRouteStore,
    readonly keeper: ActiveKeeperService,
  ) {
    super(store, keeper);
  }
}
