import { Injectable } from '@angular/core';
import { StoreConfig } from '@datorama/akita';
import { NettingMapper } from 'prosumer-app/+scenario/services/mappers';
import { CascadableDetailStore } from '../cascadable-detail';
import { ScenarioDetailType } from '../scenario-detail';
import { Netting } from './netting.state';

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: ScenarioDetailType.netting })
export class NettingStore extends CascadableDetailStore<Netting> {
  getDataUuid(netting: Record<string, unknown>): unknown {
    return netting['id'];
  }

  toFE(from: unknown): Netting {
    return NettingMapper.nettingToFE(from);
  }

  toBE(from: Netting): unknown {
    return NettingMapper.nettingToBE(from);
  }
}
