import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { CaseResultsPerceptionModule } from '@prosumer/results/components/case-results-perception';
import { ResultsPerceptionDialogComponent } from './results-perception-dialog.component';

@NgModule({
  declarations: [ResultsPerceptionDialogComponent],
  imports: [
    CommonModule,
    CaseResultsPerceptionModule,
    FlexLayoutModule,
    MatButtonModule,
  ],
  exports: [ResultsPerceptionDialogComponent],
})
export class ResultsPerceptionDialogModule {}
