import { SankeyChartModule } from 'prosumer-shared/modules/charts/components/sankey-chart';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { FlowResultsEditorContainerComponent } from '../flow-results-editor-container/flow-results-editor-container.component';
import { FlowResultsComponent } from './flow-results.component';

@NgModule({
  declarations: [FlowResultsComponent],
  imports: [
    CommonModule,
    TranslateModule,
    SankeyChartModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatSelectModule,
    MatTooltipModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FlowResultsEditorContainerComponent,
  ],
  exports: [FlowResultsComponent],
})
export class FlowResultsModule {}
