import { CompletionStrategy } from './completion-strategy';
import { EquipmentForm } from '../../models/equipments.model';
export class EquipmentCompletion extends CompletionStrategy<EquipmentForm> {
  isFull(form: EquipmentForm): boolean {
    return this.getRequired(form).every(Boolean);
  }
  isHalf(form: EquipmentForm): boolean {
    return false;
  }
  private getRequired(form: EquipmentForm): boolean[] {
    return [!!form.equipments.length];
  }
}
