<prosumer-sparkline-table
  data-testid="netting-table"
  [data]="data"
  [columnsDef]="columnsDef"
  [searchInput]="true"
  (add)="onAdd()"
  (delete)="onDelete($event)"
  (edit)="onEdit($event)"
  [references]="references"
  [hideAdd]="isViewOnly"
  [hideEdit]="isViewOnly"
  [hideDelete]="isViewOnly"
  [hideView]="!isViewOnly"
>
</prosumer-sparkline-table>
