import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslateModule } from '@ngx-translate/core';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { TooltipModule } from 'prosumer-app/libs/eyes-shared';
import { SimpleChipsModule } from 'prosumer-shared/components/simple-chips';
import { ResultsTabulatorModule } from '../results-tabulator';
import { ResultsVisualizerComponent } from './results-visualizer.component';

@NgModule({
  declarations: [ResultsVisualizerComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    SimpleChipsModule,
    ReactiveFormsModule,
    MatButtonToggleModule,
    TranslateModule,
    NgxChartsModule,
    MatSlideToggleModule,
    MatIconModule,
    TooltipModule,
    ResultsTabulatorModule,
  ],
  exports: [ResultsVisualizerComponent],
})
export class ResultsVisualizerModule {}
