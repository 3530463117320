import moment from 'moment';
import { DEFAULT_DEBOUNCE_TIME } from 'prosumer-app/app.references';
import { contains, FormFieldOption } from 'prosumer-app/libs/eyes-shared';
import { ResultStore } from 'prosumer-app/stores';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ResultsPerceptionService } from '@prosumer/results/components/case-results-perception';
import { ResultNameValue } from '@prosumer/results/components/results-visualizer';

import { StorageDispatchAdapter } from '../adapters';
import { DispatchService } from '../dispatch.service';

const STORAGE_CHARGE = 'Storage Charge';
const STORAGE_DISCH = 'Storage Discharge';

@Component({
  selector: 'prosumer-storage-daily-dispatch',
  templateUrl: './storage-daily-dispatch.component.html',
  styleUrls: ['./storage-daily-dispatch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StorageDailyDispatchComponent
  extends StorageDispatchAdapter
  implements OnInit
{
  storageCharge$: Observable<any>;
  loading$ = new BehaviorSubject<boolean>(false);
  storageControl = new UntypedFormControl('');
  storages$: Observable<Array<string>> = of([]);
  _storageOptions;
  legendFilter$ = new BehaviorSubject<Array<string>>([]);

  @Output() dataLoaded = new EventEmitter<Array<any>>();
  @Input() scenarioName: string;
  @Input() set storageOptions(storageOptions: Array<FormFieldOption<string>>) {
    this._storageOptions = storageOptions;
    if (storageOptions && storageOptions.length > 0) {
      // update the storage control value on storage option change
      this.storageControl.setValue(storageOptions[0].value);
      this.loadStorages();
    }
  }
  get storageOptions(): Array<FormFieldOption<string>> {
    return this._storageOptions;
  }

  constructor(
    private _dispatchService: DispatchService,
    private _resultStore: ResultStore,
    perception: ResultsPerceptionService,
  ) {
    super(perception);
  }

  ngOnInit() {
    this.storageControl.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => this.loadStorages());
    this.initializeChartDataAndColors();
  }

  /**
   * Based on the storage selected, the chart is loaded with the data from result store
   */
  loadStorages() {
    this.loading$.next(true);
    // for battery cahrge, inverse the values with (*-1) so that the bar charges would match storage soc
    this.storageCharge$ = combineLatest([
      this._dispatchService.year$,
      this._dispatchService.node$,
      this._dispatchService.energyVector$,
      combineLatest([
        this._dispatchService.minDate$,
        this._dispatchService.maxDate$,
      ]).pipe(
        debounceTime(DEFAULT_DEBOUNCE_TIME),
        distinctUntilChanged(),
        takeUntil(this.componentDestroyed$),
      ),
      this.legendFilter$,
    ]).pipe(
      switchMap(([year, node, energyVector, [minDay, maxDay], legendFilter]) =>
        this._resultStore
          .getStorageCharge$(
            year,
            node,
            energyVector,
            this.storageControl.value,
          )
          .pipe(
            map((filteredData) => {
              const minDate =
                // eslint-disable-next-line @typescript-eslint/naming-convention
                year && minDay
                  ? moment({
                      y: +year,
                      M: 0,
                      d: 1,
                      h: 0,
                      m: 0,
                      s: 0,
                      ms: 0,
                    }).dayOfYear(minDay)
                  : undefined;
              const maxDate =
                year && maxDay
                  ? // eslint-disable-next-line @typescript-eslint/naming-convention
                    moment({
                      y: +year,
                      M: 0,
                      d: 1,
                      h: 23,
                      m: 59,
                      s: 59,
                      ms: 999,
                    }).dayOfYear(maxDay)
                  : undefined;
              const dataSet = !!!filteredData?.values
                ? []
                : (filteredData.values as Array<any>)
                    .map((value, index) => ({
                      // eslint-disable-next-line @typescript-eslint/naming-convention
                      hour: moment({
                        y: +year,
                        M: 0,
                        d: 1,
                        h: 0,
                        m: 0,
                        s: 0,
                        ms: 0,
                      })
                        .hour(index)
                        .get('h'),
                      // eslint-disable-next-line @typescript-eslint/naming-convention
                      name: moment({
                        y: +year,
                        M: 0,
                        d: 1,
                        h: 0,
                        m: 0,
                        s: 0,
                        ms: 0,
                      })
                        .hour(index)
                        .get('h'),
                      // eslint-disable-next-line @typescript-eslint/naming-convention
                      moment: moment({
                        y: +year,
                        M: 0,
                        d: 1,
                        h: 0,
                        m: 0,
                        s: 0,
                        ms: 0,
                      }).hour(index),
                      value: value * -1,
                    }))
                    .filter((dataSetItem) =>
                      minDate && maxDate
                        ? minDate.isSameOrBefore(dataSetItem.moment) &&
                          maxDate.isSameOrAfter(dataSetItem.moment)
                        : true,
                    );
              if (dataSet && dataSet instanceof Array && dataSet.length > 0) {
                const series = [];
                Array.from(Array(24).keys()).forEach((hour) => {
                  const hourDataSet = dataSet.filter(
                    (data) => data && data.hour === hour,
                  );
                  const hourData = hourDataSet
                    .map((data) => ({ ...data, name: '' + (hour + 1) }))
                    .reduce(
                      (prev, curr) => ({
                        ...curr,
                        value: prev.value + curr.value,
                      }),
                      { value: 0 },
                    );
                  let value = hourData.value / (hourDataSet.length || 1);

                  if (contains(legendFilter, STORAGE_CHARGE) && value > 0) {
                    value = 0;
                  }
                  if (contains(legendFilter, STORAGE_DISCH) && value < 0) {
                    value = 0;
                  }

                  series.push({ ...hourData, value });
                });
                return series;
              }
              return [];
            }),
            takeUntil(this.componentDestroyed$),
          ),
      ),
      tap((data) => {
        this.dataLoaded.emit(data);
        this.loading$.next(false);
      }),
      takeUntil(this.componentDestroyed$),
    );
  }

  formatTick = (tick: string) => tick;

  getChartName(): string {
    return `Storage Daily Dispatch (${this.storageControl.value})`;
  }

  getNgxChartsDataStream(): Observable<ResultNameValue[]> {
    return this.storageCharge$;
  }
}
