import { TopologyForm } from 'prosumer-app/+scenario/models';
import { Utils } from 'prosumer-core/utils';

import { CompletionStrategy } from './completion-strategy';

export class TopologyCompletion extends CompletionStrategy<TopologyForm> {
  isFull(form: TopologyForm): boolean {
    return this.getFieldsEvaluation(form).every(Boolean);
  }
  isHalf(form: TopologyForm): boolean {
    return this.getFieldsEvaluation(form).some(Boolean);
  }

  private getFieldsEvaluation(form: TopologyForm): boolean[] {
    return [this.hasItems(form.lines), this.hasItems(form.nodes)];
  }

  private hasItems(list: unknown[]): boolean {
    return !!Utils.resolveToEmptyArray(list).length;
  }
}
