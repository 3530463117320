import {
  BaseComponent,
  CustomValidators,
  FormFieldErrorMessageMap,
  FormFieldOption,
} from 'prosumer-app/libs/eyes-shared';
import { RegulationStore } from 'prosumer-app/stores/regulation';
import { provideUpserter } from 'prosumer-app/stores/scenario-detail';
import { YearlyValues } from 'prosumer-shared/models/yearly-values.model';
import {
  convertToYearlyValues,
  expandYearlyValuesIfApplicable,
} from 'prosumer-shared/utils/time-dependent.util';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';

import { MeteringDialogData } from './metering-dialog-model';

@UntilDestroy()
@Component({
  selector: 'prosumer-metering-dialog',
  templateUrl: './metering-dialog.component.html',
  styleUrls: ['./metering-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideUpserter(RegulationStore)],
})
export class MeteringDialogComponent extends BaseComponent implements OnInit {
  isViewOnly: boolean;

  meteringDialogForm: UntypedFormGroup = this._formBuilder.group({
    id: '',
    technologyId: [{ value: '' }],
    netting: [{ value: 'instantaneous' }],
    yearlyAutoConsumptionTax: [
      convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
      Validators.required,
    ],
    yearlyGenerationTariff: [
      convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
      Validators.required,
    ],
    yearlyFeedInTariff: [
      convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
      Validators.required,
    ],
    yearlyFeedInPremium: [
      convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
      Validators.required,
    ],
  });

  technologyOptions: Array<FormFieldOption<any>> = [];
  nettingOptions: Array<FormFieldOption<any>> = [];

  errorMessages: FormFieldErrorMessageMap = {
    technology: {
      required: this._translate.instant(
        'Scenario.messages.metering.technology.required',
      ),
      dataExist: this._translate.instant(
        'Scenario.messages.metering.technology.dataExist',
      ),
    },
    netting: {
      required: this._translate.instant(
        'Scenario.messages.metering.netting.required',
      ),
    },
    autoConsumptionTax: {
      invalid: this._translate.instant(
        'Scenario.messages.metering.autoconsumptionTax.invalid',
      ),
      required: this._translate.instant(
        'Scenario.messages.metering.autoconsumptionTax.required',
      ),
    },
    generationTariff: {
      invalid: this._translate.instant(
        'Scenario.messages.metering.generationTariff.invalid',
      ),
      required: this._translate.instant(
        'Scenario.messages.metering.generationTariff.required',
      ),
    },
    feedInTariff: {
      invalid: this._translate.instant(
        'Scenario.messages.metering.feedInTariff.invalid',
      ),
      required: this._translate.instant(
        'Scenario.messages.metering.feedInTariff.required',
      ),
    },
    feedInPremium: {
      invalid: this._translate.instant(
        'Scenario.messages.metering.feedInPremium.invalid',
      ),
      required: this._translate.instant(
        'Scenario.messages.metering.feedInPremium.required',
      ),
    },
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MeteringDialogData,
    public dialogRef: MatDialogRef<MeteringDialogData>,
    private _formBuilder: UntypedFormBuilder,
    private _translate: TranslateService,
    private _changeDetector: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit() {
    if (!this.data) {
      return;
    }

    this.setValidator();
    this.initOptions();
    this.initData();
    this.take1FromFirstFormChangeForManualChangeDetection();
  }

  initData() {
    this.meteringDialogForm.patchValue({
      id: this.data.id,
      technologyId: this.data.technologyId,
      netting: this.data.netting,
      yearlyAutoConsumptionTax: this.sanitizeYearlyValues(
        this.data.yearlyAutoConsumptionTax,
      ),
      yearlyGenerationTariff: this.sanitizeYearlyValues(
        this.data.yearlyGenerationTariff,
      ),
      yearlyFeedInTariff: this.sanitizeYearlyValues(
        this.data.yearlyFeedInTariff,
      ),
      yearlyFeedInPremium: this.sanitizeYearlyValues(
        this.data.yearlyFeedInPremium,
      ),
    });
    this.isViewOnly = this.data.isViewOnly || false;
  }

  private sanitizeYearlyValues(values: YearlyValues): YearlyValues {
    return expandYearlyValuesIfApplicable(values, this.getStartEndPeriod());
  }

  private getStartEndPeriod(): [number, number] {
    return [this.data.startYear, this.data.endYear];
  }

  initOptions() {
    if (this.data.technologyOptions) {
      this.data.technologyOptions.forEach((option) =>
        this.technologyOptions.push(option),
      );
    }

    if (this.data.nettingOptions) {
      this.nettingOptions = this.data.nettingOptions;
    }
  }

  setValidator(): void {
    this.meteringDialogForm.controls.technologyId.setAsyncValidators(
      CustomValidators.dataExist(
        this.data.existingMeterings$,
        'technologyId',
        this.data.meteringData,
      ),
    );
  }

  onSaveSuccess(): void {
    this.dialogRef.close();
  }

  onSaveAttempt(): void {
    this._changeDetector.detectChanges();
  }

  private take1FromFirstFormChangeForManualChangeDetection(): void {
    this.meteringDialogForm.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this._changeDetector.detectChanges();
      });
  }
}
