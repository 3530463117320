<form #ngForm [formGroup]="compareForm" (ngSubmit)="onConfirm()">
  <h2 mat-dialog-title>
    <mat-icon class="align-bottom" aria-hidden="false" color="primary"
      >compare_arrows</mat-icon
    >
    <span class="eyes-primary">{{
      'Scenario.dialog.compare.title' | translate
    }}</span>
  </h2>

  <div class="mat-body" mat-dialog-content>
    <p>
      <b>{{ 'Scenario.dialog.compare.selectScenarios' | translate }}</b>
    </p>

    <mat-selection-list
      (selectionChange)="onSelectedChange($event)"
      formControlName="selectedScenarios"
      #scenarioList
      cdkScrollable
    >
      <mat-list-option
        #option
        *ngFor="let scenario of scenarioOptions$ | async"
        [disabled]="
          !option?.selected &&
          scenarioList?.selectedOptions?.selected?.length === 5
        "
        [ngClass]="{ selected: option?.selected }"
        [value]="scenario"
        [attr.data-testid]="'compare-list-option-' + scenario.id"
      >
        {{ scenario?.name }}
      </mat-list-option>
    </mat-selection-list>

    <p class="mat-caption eyes-placeholder">
      <span
        >{{ 'Scenario.dialog.compare.selectedScenarios' | translate }}
        {{ scenarioList?.selectedOptions?.selected?.length }}</span
      >
      <span
        *ngIf="scenarioList?.selectedOptions?.selected?.length === 5"
        [@fadeIn]
      >
        ({{ 'Scenario.dialog.compare.max' | translate }})</span
      >
    </p>

    <mat-error
      *ngIf="
        (compareForm?.dirty || (submitted$ | async)) &&
        (selectedScenariosControl?.errors?.required ||
          selectedScenariosControl?.errors?.min)
      "
      class="eyes-error"
      [innerHTML]="
        'Scenario.dialog.compare.errors.selectedScenarios.required' | translate
      "
      [@fadeIn]
    >
    </mat-error>

    <p *ngIf="!!referenceIdControl.value" class="eyes-spacer-top" [@fadeIn]>
      <b>{{ 'Scenario.dialog.compare.selectReference' | translate }}</b>
    </p>

    <mat-form-field
      *ngIf="!!referenceIdControl.value"
      class="eyes-maximize-width"
      appearance="outline"
      [@fadeIn]
    >
      <mat-label>{{
        'Scenario.dialog.compare.referenceScenario' | translate
      }}</mat-label>

      <mat-select formControlName="referenceId">
        <mat-option
          *ngFor="let scenario of selectedScenariosControl.value"
          [value]="scenario?.id"
          >{{ scenario?.name }}</mat-option
        >
      </mat-select>

      <mat-error
        *ngFor="let error of referenceIdControl?.errors | keyvalue"
        [ngSwitch]="error.key"
      >
        <div
          *ngSwitchCase="'required'"
          [innerHtml]="
            'Scenario.dialog.compare.errors.referenceId.required' | translate
          "
        ></div>
      </mat-error>
    </mat-form-field>
  </div>

  <div mat-dialog-actions fxLayout="row">
    <button
      mat-flat-button
      type="submit"
      color="primary"
      [disabled]="compareForm.invalid"
    >
      {{ 'Generic.labels.ok' | translate }}
    </button>

    <button mat-flat-button type="button" color="" (click)="onClose()">
      {{ 'Generic.labels.cancel' | translate }}
    </button>
  </div>
</form>
