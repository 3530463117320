import { Injectable } from '@angular/core';
import { ActiveKeeperService } from 'prosumer-app/services/active-keeper';
import { ScenarioDetailQuery } from '../scenario-detail';
import { PowerNeeded } from './power-needed.state';
import { PowerNeededStore } from './power-needed.store';

@Injectable({
  providedIn: 'root',
})
export class PowerNeededQuery extends ScenarioDetailQuery<PowerNeeded> {
  constructor(store: PowerNeededStore, keeper: ActiveKeeperService) {
    super(store, keeper);
  }
}
