import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { LetDirective, PushPipe } from '@ngrx/component';
import { ScenarioUpdaterModule } from 'prosumer-app/shared/directives/scenario-updater';
import { SharedModule } from 'prosumer-app/shared/shared.module';
import { CommoditiesEmissionsComponent } from './commodities-emissions.component';

@NgModule({
  declarations: [CommoditiesEmissionsComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    SharedModule,
    LetDirective,
    PushPipe,
    ScenarioUpdaterModule,
  ],
  exports: [CommoditiesEmissionsComponent],
})
export class CommoditiesEmissionsModule {}
