import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ExportTableDirective } from './export-table.directive';

@NgModule({
  declarations: [ExportTableDirective],
  imports: [CommonModule],
  exports: [ExportTableDirective],
})
export class ExportTableDirectiveModule {}
