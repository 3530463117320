import { Injectable } from '@angular/core';
import { ActiveKeeperService } from 'prosumer-app/services/active-keeper';
import { Observable } from 'rxjs';
import { WithProfilesCreator } from '../creator';
import { HasLegacyProfiles, ProfileStore } from '../profile';
import { MobilityRoute } from './mobility-route.state';
import { MobilityRouteStore } from './mobility-route.store';

type RouteWithProfile = MobilityRoute & HasLegacyProfiles;
@Injectable({
  providedIn: 'root',
})
export class MobilityRouteCreator extends WithProfilesCreator<RouteWithProfile> {
  constructor(
    private readonly routes: MobilityRouteStore,
    readonly profiles: ProfileStore,
    readonly keeper: ActiveKeeperService,
  ) {
    super(profiles, keeper);
  }

  createEntity(data: MobilityRoute): Observable<unknown> {
    return this.routes.create(data);
  }
  updateEntity(data: MobilityRoute): Observable<unknown> {
    return this.routes.edit(data.id, data);
  }

  create(data: MobilityRoute): Observable<unknown> {
    return super.create(this.mapToWithProfile(data));
  }
  edit(id: string, data: MobilityRoute): Observable<unknown> {
    return super.edit(id, this.mapToWithProfile(data));
  }

  private mapToWithProfile(data: MobilityRoute): RouteWithProfile {
    return {
      ...data,
      profiles: data.profileRoutes,
    };
  }
}
