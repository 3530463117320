<prosumer-comparison-visualizer
  (typeChange)="onTypeChange($event)"
  (sliceSelect)="onSliceSelect($event)"
>
  <prosumer-stacked-bar-chartjs
    [id]="'compare-emission'"
    [data]="chartData$ | async"
    [colors]="caseColorMap$ | async"
    [xAxisName]="'Result.labels.' + selectedSlice | translate"
    [yAxisName]="'Result.labels.tonsCO2' | translate"
    resultsName="compare_emission"
  ></prosumer-stacked-bar-chartjs>
</prosumer-comparison-visualizer>
