import { TdbCommodityOptionalFiltersComponent } from 'prosumer-app/shared/modules/tdb/components/tdb-commodity-optional-filters/tdb-commodity-optional-filters.component';
import { EyesSharedModule } from 'prosumer-libs/eyes-shared/eyes-shared.module';
import { SharedModule } from 'prosumer-shared/shared.module';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LetDirective, PushPipe } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';

import { TdbCommodityPricesComponent } from './tdb-commodity-prices.component';

@NgModule({
  declarations: [TdbCommodityPricesComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    MatExpansionModule,
    ReactiveFormsModule,
    LetDirective,
    PushPipe,
    MatTooltipModule,
    EyesSharedModule,
    SharedModule,
    TdbCommodityOptionalFiltersComponent,
  ],
  exports: [TdbCommodityPricesComponent],
})
export class TdbCommodityPricesModule {}
