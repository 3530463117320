import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Input,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

import {
  BaseComponent,
  KiloPipe,
  contains,
  fadeInAnimation,
  getKeys,
} from 'prosumer-app/libs/eyes-shared';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';

import {
  COMPARE_EQUIPMENT_RESULTS_VALUE_OPTIONS,
  DEFAULT_LOAD_CHART_DEBOUNCE_TIME,
  ENGIE_DEFAULT_SCHEME,
} from 'prosumer-app/app.references';
import { CompareEquipmentResultItem, Scenario } from 'prosumer-scenario/models';

const DEFAULT_BAR_WIDTH = 13;
const DEFAULT_SPACING = 8.1;
const DEFAULT_OFFSET = 74.6;
const DEFAULT_WIDTH_OFFSET = 100;

/**
 * Component used for equipment comparison
 */
@Component({
  selector: 'prosumer-compare-equipment',
  templateUrl: './compare-equipment.component.html',
  styleUrls: ['./compare-equipment.component.scss'],
  animations: [fadeInAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompareEquipmentComponent
  extends BaseComponent
  implements AfterViewInit
{
  constructor(
    private formBuilder: UntypedFormBuilder,
    private elementRef: ElementRef,
    private _kiloPipe: KiloPipe,
  ) {
    super();
  }

  equipmentValueOptions = COMPARE_EQUIPMENT_RESULTS_VALUE_OPTIONS;

  @Input() equipmentResults: Array<CompareEquipmentResultItem>;
  @Input() scenarios: Array<Scenario>;

  @Input() chartScheme = ENGIE_DEFAULT_SCHEME;
  @Input() customColors = [];

  // Node options in the input chips to filter the equipment results
  @Input() nodeOptions: Array<any>;

  // Type options in the input chips to filter the equipment results
  @Input() typeOptions: Array<any>;

  // Energy vector options in the input chips to filter the equipment results in the charts
  @Input() energyVectorOptions: Array<any>;

  // Value options in the button toggle to show what equipment result value to show in the charts
  @Input() valueOptions: Array<any> = getKeys(this.equipmentValueOptions).map(
    (key) => ({
      key,
      value: this.equipmentValueOptions[key].label,
      context: this.equipmentValueOptions[key].context,
    }),
  );

  valueToggleControl = this.formBuilder.control('sizekW');

  nodeFilters$ = new BehaviorSubject(null);
  typeFilters$ = new BehaviorSubject(null);
  energyVectorFilters$ = new BehaviorSubject(null);

  chartWidth$ = new BehaviorSubject(0);
  chartHeight$ = new BehaviorSubject(0);

  valueToggle$ = this.valueToggleControl.valueChanges.pipe(
    startWith(this.valueToggleControl.value),
    this.takeUntilShare(),
  );

  /**
   * Filters the equipment results whenever the value toggle, node, type, and energy vector filters change;
   * Also calculates the chart height on change
   */
  filteredData$ = combineLatest([
    this.valueToggle$,
    this.nodeFilters$,
    this.typeFilters$,
    this.energyVectorFilters$,
  ]).pipe(
    debounceTime(DEFAULT_LOAD_CHART_DEBOUNCE_TIME),
    distinctUntilChanged(),
    switchMap(([value, nodes, types, energyVectors]) =>
      of(
        this.mapToChartData(
          this.scenarios,
          this.filterEquipmentResults(this.equipmentResults, {
            nodes,
            types,
            energyVectors,
          }),
          value,
        ),
      ),
    ),
    tap((results) =>
      this.setChartHeight(
        this.calculateHeight(this.scenarios.length, results.length),
      ),
    ),
    this.takeUntilShare(),
  );

  showChart$ = this.filteredData$.pipe(
    filter((data) => !!data && data.length > 0),
    this.takeUntilShare(),
  );

  /**
   * Calculates the chart width when resizing the browser
   */
  @HostListener('window:resize') onResize() {
    this.setChartWidth(this.calculateWidth());
  }

  ngAfterViewInit() {
    this.setChartWidth(this.calculateWidth());
  }

  /**
   * Maps the equipment results, scenarios, and values to the chart-compatible data
   *
   * @param scenarios - the list of scenarios for comparison
   * @param equipmentResults - the flattened equipment results
   * @param valueField - the field of the result to be mapped as the value
   */
  mapToChartData(
    scenarios: Array<Scenario>,
    equipmentResults: Array<CompareEquipmentResultItem>,
    valueField: string,
  ) {
    const equipmentNames = Array.from(
      new Set(equipmentResults.map((equipment) => equipment.equipmentName)),
    );
    return equipmentNames
      .map((name) => ({
        name,
        series: scenarios.map((scenario) => ({
          name: scenario.name,
          value: this.convertToKilo(
            (equipmentResults.find(
              (result) =>
                result.equipmentName === name && result.name === scenario.name,
            ) || {})[valueField] || 0,
            valueField,
          ),
        })),
      }))
      .filter(
        (value) => !value.series.every((seriesItem) => seriesItem.value === 0),
      );
  }

  convertToKilo(value, valueField) {
    if (valueField === 'totalDiscountedCost') {
      return this._kiloPipe.transform(value).toFixed(1);
    }
    return value;
  }

  /**
   * Filters the equipment results using the parameters
   *
   * @param results - the flattened equipment results
   * @param params - the filters for the results such as nodes, types, and energy vectors
   */
  filterEquipmentResults(
    results: Array<CompareEquipmentResultItem> = this.equipmentResults,
    params?: {
      nodes?: Array<string>;
      types?: Array<string>;
      energyVectors?: Array<string>;
    },
  ) {
    return results.filter(
      (filteredResult) =>
        (!!params && !!params.nodes && params.nodes.length > 0
          ? contains(params.nodes, filteredResult.node)
          : true) &&
        (!!params && !!params.types && params.types.length > 0
          ? contains(params.types, filteredResult.type)
          : true) &&
        (!!params && !!params.energyVectors && params.energyVectors.length > 0
          ? contains(params.energyVectors, filteredResult.inputEnergyVector) ||
            contains(params.energyVectors, filteredResult.outputEnergyVector)
          : true),
    );
  }

  setChartWidth(value: number) {
    this.chartWidth$.next(value);
  }

  setChartHeight(value: number) {
    this.chartHeight$.next(value);
  }

  calculateWidth(offset = DEFAULT_WIDTH_OFFSET) {
    return this.elementRef.nativeElement.clientWidth - offset;
  }

  /**
   * Calculates the height of the chart to show the bars properly
   *
   * @param scenarioLength - the length of the scenario
   * @param resultsLength - the length of the equipment results
   * @param barWidth - a constant expected bar width
   * @param spacing - a constant used to add space between bars
   * @param offset - a fixed constant to add in the calculated height
   */
  calculateHeight(
    scenarioLength: number,
    resultsLength: number,
    barWidth = DEFAULT_BAR_WIDTH,
    spacing = DEFAULT_SPACING,
    offset = DEFAULT_OFFSET,
  ) {
    return (
      ((barWidth + spacing) * scenarioLength + spacing) * resultsLength + offset
    );
  }

  onFilterNode(value: Array<string>) {
    this.nodeFilters$.next(value);
  }

  onFilterType(value: Array<string>) {
    this.typeFilters$.next(value);
  }

  onFilterEnergyVector(value: Array<string>) {
    this.energyVectorFilters$.next(value);
  }
}
