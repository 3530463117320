<div
  data-testid="perception-dialog"
  style="padding: 16px"
  fxLayout="column"
  fxLayoutAlign="space-between stretch"
  fxLayoutGap="16px"
>
  <prosumer-results-perception
    [caseObject]="data?.caseObject"
    (perceptionChange)="onPerceptionChange($event)"
  ></prosumer-results-perception>
  <div fxLayout="row" fxLayoutAlign="start end" fxLayoutGap="8px">
    <button
      data-testid="perception-submit"
      mat-flat-button
      class="eyes-button"
      color="primary"
      (click)="onApply()"
    >
      SAVE CHANGES
    </button>
    <button
      data-testid="perception-cancel"
      mat-flat-button
      class="eyes-button"
      color=""
      (click)="dialogRef?.close()"
    >
      CANCEL
    </button>
  </div>
</div>
