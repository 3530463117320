import moment from 'moment';
import { TraditionalDispatchAdapter } from 'prosumer-app/+scenario/containers/simulation-results/dispatch/adapters';
import { PipeUtils } from 'prosumer-core/utils';
import { BehaviorSubject, Observable } from 'rxjs';

import { coerceNumberProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ResultsPerceptionService } from '@prosumer/results/components/case-results-perception';
import { VisualizerData } from '@prosumer/results/components/results-visualizer';

import {
  CompareDispatchChartData,
  CompareDispatchData,
} from '../compare-dispatch.model';

@Component({
  selector: 'prosumer-compare-daily',
  templateUrl: './daily.component.html',
  styleUrls: ['./daily.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompareDispatchDailyComponent
  extends TraditionalDispatchAdapter
  implements OnInit
{
  private chartDataSubject = new BehaviorSubject<VisualizerData[]>([]);
  chartData: Array<any>;
  daily: CompareDispatchData;
  @Input() customColors: any;
  @Input() set data(daily: CompareDispatchData) {
    this.daily = daily;
    // istanbul ignore else
    if (!!this.daily) {
      this.chartData = this.mapToChartData(this.daily);
      this.chartDataSubject.next(this.chartData);
    }
  }

  constructor(
    private _translate: TranslateService,
    perception: ResultsPerceptionService,
  ) {
    super(perception);
  }

  ngOnInit(): void {
    this.initializeChartDataAndColors();
  }

  mapToChartData(
    dailyData: CompareDispatchData,
  ): Array<CompareDispatchChartData> {
    return (dailyData.dataList || []).map((data) => {
      const dataSet = data.values.map((value, index) => ({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        hour: moment({
          y: +coerceNumberProperty(data.year),
          M: 0,
          d: 1,
          h: 0,
          m: 0,
          s: 0,
          ms: 0,
        })
          .hour(index)
          .get('h'),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        name: moment({
          y: +coerceNumberProperty(data.year),
          M: 0,
          d: 1,
          h: 0,
          m: 0,
          s: 0,
          ms: 0,
        })
          .hour(index)
          .toISOString(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        moment: moment({
          y: +coerceNumberProperty(data.year),
          M: 0,
          d: 1,
          h: 0,
          m: 0,
          s: 0,
          ms: 0,
        }).hour(index),
        value,
      }));
      const series = [];
      Array.from(Array(24).keys()).forEach((hour) => {
        const hourDataSet = dataSet.filter(
          (hDataSet: any) => hDataSet && hDataSet.hour === hour,
        );
        const hourData =
          hourDataSet.length &&
          hourDataSet
            .map((hData: any) => ({ ...hData, name: '' + (hour + 1) }))
            .reduce((prev, curr) => ({
              ...curr,
              value: prev.value + curr.value,
            }));
        series.push({
          ...hourData,
          value: hourData.value / (hourDataSet.length || 1),
        });
      });
      return {
        name: data.equipment,
        series: series ? series : undefined,
      };
    });
  }

  formatXAxisTickLabel = (value: any): string => value;

  formatTooltipTitle = (obj: any) =>
    obj
      ? `${this._translate.instant('Result.labels.hour')} ${(obj || {}).name}`
      : 0;

  formatTick(tick: string): string {
    return tick;
  }
  getChartName(): string {
    return `Daily Dispatch (${this.daily.name})`;
  }
  getNgxChartsDataStream(): Observable<VisualizerData[]> {
    return this.chartDataSubject.asObservable().pipe(PipeUtils.filterOutEmpty);
  }
}
