<prosumer-results-visualizer
  scenarioName="{{ scenarioName }}"
  tableName="{{
    'Scenario.labels.simulationResults.tabs.co2Emissions' | translate
  }}"
  (typeChange)="onTypeChange($event)"
  (sliceSelect)="onSliceSelect($event)"
>
  <prosumer-bar-and-area-results
    [chartName]="'Result.slices.' + selectedSlice | translate"
    [type]="activeType"
    [yAxisLabel]="'Result.labels.emissionTonsCO2' | translate"
    [incremental]="incremental$ | async"
    [cumulative]="cumulative$ | async"
    [colorMap]="caseColorMap$ | async"
    (legendSelect)="onLegendSelect($event)"
    scenarioName="{{ scenarioName }}"
    resultsName="{{
      'Scenario.labels.simulationResults.tabs.co2Emissions' | translate
    }}"
  ></prosumer-bar-and-area-results>
</prosumer-results-visualizer>
