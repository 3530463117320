import moment from 'moment';
import {
  DEFAULT_DEBOUNCE_TIME,
  RESULTS_DISPATCH_DAY_FORMAT,
  RESULTS_DISPATCH_HOUR_FORMAT,
  RESULTS_DISPATCH_MONTH_FORMAT,
} from 'prosumer-app/app.references';
import { ResultStore } from 'prosumer-app/stores';
import { combineLatest, Observable } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ResultsPerceptionService } from '@prosumer/results/components/case-results-perception';
import { VisualizerData } from '@prosumer/results/components/results-visualizer';

import { TraditionalDispatchAdapter } from '../adapters/traditional-dispatch.adapter';
import { DispatchService } from '../dispatch.service';

@Component({
  selector: 'prosumer-raw-dispatch',
  templateUrl: './raw-dispatch.component.html',
  styleUrls: ['./raw-dispatch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RawDispatchComponent
  extends TraditionalDispatchAdapter
  implements OnInit
{
  constructor(
    private _dispatchService: DispatchService,
    private _resultStore: ResultStore,
    perception: ResultsPerceptionService,
  ) {
    super(perception);
  }

  @Input() type: 'production' | 'consumption';
  @Input() chartTitle: string;
  @Input() scenarioName: string;
  @Output() dataLoaded = new EventEmitter<Array<any>>();

  data$: Observable<any>;
  allData: any;

  ngOnInit() {
    this.data$ = combineLatest([
      this._resultStore.loading$,
      this._resultStore.isOutputSplit$,
      this._dispatchService.year$,
      this._dispatchService.node$,
      this._dispatchService.energyVector$,
      combineLatest([
        this._dispatchService.minDate$,
        this._dispatchService.maxDate$,
      ]).pipe(
        debounceTime(DEFAULT_DEBOUNCE_TIME),
        distinctUntilChanged(),
        takeUntil(this.componentDestroyed$),
      ),
    ]).pipe(
      filter(([loading, isSplit]) => !loading || !isSplit),
      switchMap(
        ([loading, isSplit, year, node, energyVector, [minDay, maxDay]]) =>
          this._resultStore
            .getRawDispatchByParams$(year, node, energyVector, this.type)
            .pipe(
              tap((data) => {
                this.allData = { dispatchData: data };
              }),
              map((filteredData) => {
                const minDate =
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  year && minDay
                    ? moment({
                        y: +year,
                        M: 0,
                        d: 1,
                        h: 0,
                        m: 0,
                        s: 0,
                        ms: 0,
                      }).dayOfYear(minDay)
                    : undefined;
                const maxDate =
                  year && maxDay
                    ? // eslint-disable-next-line @typescript-eslint/naming-convention
                      moment({
                        y: +year,
                        M: 0,
                        d: 1,
                        h: 23,
                        m: 59,
                        s: 59,
                        ms: 999,
                      }).dayOfYear(maxDay)
                    : undefined;
                return !filteredData || filteredData.length === 0
                  ? undefined
                  : filteredData.map((data) => ({
                      name: data.der,
                      series: data.values
                        .map((value, index) => ({
                          // eslint-disable-next-line @typescript-eslint/naming-convention
                          name: moment({
                            y: +year,
                            M: 0,
                            d: 1,
                            h: 0,
                            m: 0,
                            s: 0,
                            ms: 0,
                          })
                            .hour(index)
                            .toISOString(),
                          // eslint-disable-next-line @typescript-eslint/naming-convention
                          moment: moment({
                            y: +year,
                            M: 0,
                            d: 1,
                            h: 0,
                            m: 0,
                            s: 0,
                            ms: 0,
                          }).hour(index),
                          value,
                        }))
                        .filter((value) =>
                          minDate && maxDate
                            ? minDate.isSameOrBefore(value.moment) &&
                              maxDate.isSameOrAfter(value.moment)
                            : true,
                        ),
                    }));
              }),
              takeUntil(this.componentDestroyed$),
            ),
      ),
      tap((data) => {
        this.dataLoaded.emit(data);
      }),
      shareReplay(1),
      takeUntil(this.componentDestroyed$),
    );

    this.initializeChartDataAndColors();
  }

  formatTick(tick: string): string {
    return this.chartXAxisFormat(tick);
  }
  getChartName(): string {
    return this.chartTitle;
  }
  getNgxChartsDataStream(): Observable<VisualizerData[]> {
    return this.data$;
  }

  private chartXAxisFormat = (value: any) =>
    moment(value).format(
      `${RESULTS_DISPATCH_MONTH_FORMAT} ${RESULTS_DISPATCH_DAY_FORMAT} ${RESULTS_DISPATCH_HOUR_FORMAT}`,
    );
}
