<prosumer-progress-card [showProgress]="loading$ | async">
  <form [formGroup]="timePeriodsForm" (ngSubmit)="onConfirm()">
    <h2 class="mat-headline-6" mat-dialog-title>
      {{ data?.mode | titlecase }} Time Horizon
    </h2>
    <div class="mat-body" mat-dialog-content>
      <div fxLayout="column">
        <div fxLayout="row" fxLayoutGap="10px">
          <!-- Year -->
          <prosumer-select
            fxFlex="50%"
            [placeholder]="'Scenario.labels.timePeriods.year' | translate"
            [label]="'Scenario.labels.timePeriods.year' | translate"
            [options]="yearOptions"
            [control]="year"
            [errorMessageMap]="errorMessages['year']"
            [required]="true"
            (eyesSelectionChange)="setDefaultStartDate(year.value)"
            data-testid="timehorizon-dialog-year-select"
          >
          </prosumer-select>
          <!-- Weight -->
          <prosumer-input
            fxFlex="50%"
            [placeholder]="'Scenario.labels.timePeriods.weight' | translate"
            [label]="'Scenario.labels.timePeriods.weight' | translate"
            [control]="weight"
            [required]="true"
            [errorMessageMap]="errorMessages['weight']"
            [inputTooltip]="'wizard_time.wizard_time_weight'"
            data-testid="timehorizon-dialog-weight-input"
          >
          </prosumer-input>
        </div>
        <div fxLayout="row" fxLayoutGap="10px">
          <!-- Start Date -->
          <mat-form-field appearance="outline" fxFlex="50%">
            <input
              required
              matInput
              [formControl]="startDate"
              [matDatepicker]="startDatePicker"
              [placeholder]="
                'Scenario.labels.timePeriods.startDate' | translate
              "
              (dateChange)="setDefaultEndDate(startDate.value)"
              data-testid="timehorizon-dialog-startdate-input"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="startDatePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker
              #startDatePicker
              [startAt]="defaultStartDate"
            ></mat-datepicker>
            <mat-error
              *ngIf="startDate.hasError('required')"
              data-testid="timehorizon-dialog-startyear-required"
              >{{
                'Scenario.messages.timeHorizon.startYear.required' | translate
              }}</mat-error
            >
            <mat-error
              *ngIf="startDate.hasError('invalidYear')"
              data-testid="timehorizon-dialog-startyear-invalid"
              >{{
                'Scenario.messages.timeHorizon.startYear.optimized' | translate
              }}</mat-error
            >
            <mat-error
              *ngIf="startDate.hasError('beyondEndDate')"
              data-testid="timehorizon-dialog-startyear-beforeEnd"
              >{{
                'Scenario.messages.timeHorizon.startYear.startDateBeforeEndDate'
                  | translate
              }}</mat-error
            >
            <mat-error
              *ngIf="startDate.hasError('overlappingDates')"
              data-testid="timehorizon-dialog-startyear-overlap"
              >{{
                'Scenario.messages.timeHorizon.startYear.overlap' | translate
              }}</mat-error
            >
          </mat-form-field>
          <!-- End Date -->
          <mat-form-field appearance="outline" fxFlex="50%">
            <input
              required
              matInput
              [min]="startDate.value"
              [formControl]="endDate"
              [matDatepicker]="endDatePicker"
              [placeholder]="'Scenario.labels.timePeriods.endDate' | translate"
              data-testid="timehorizon-dialog-enddate-input"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="endDatePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker
              #endDatePicker
              [startAt]="defaultEndDate"
            ></mat-datepicker>
            <mat-error
              *ngIf="endDate.hasError('required')"
              data-testid="timehorizon-dialog-endyear-required"
            >
              {{ 'Scenario.messages.timeHorizon.endYear.required' | translate }}
            </mat-error>
            <mat-error
              *ngIf="
                startDate.hasError('invalidYear') ||
                endDate.hasError('invalidYear')
              "
              data-testid="timehorizon-dialog-endDate-invalid"
              >{{
                'Scenario.messages.timeHorizon.startYear.optimized' | translate
              }}</mat-error
            >
            <mat-error
              *ngIf="startDate.hasError('beyondEndDate')"
              data-testid="timehorizon-dialog-endDate-beforeEnd"
              >{{
                'Scenario.messages.timeHorizon.startYear.startDateBeforeEndDate'
                  | translate
              }}</mat-error
            >
            <mat-error
              *ngIf="startDate.hasError('overlappingDates')"
              data-testid="timehorizon-dialog-endDate-overlap"
              >{{
                'Scenario.messages.timeHorizon.startYear.overlap' | translate
              }}</mat-error
            >
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="10px" class="mb-4">
          <mat-label data-testid="timehorizon-dialog-numOfHr_label">{{
            'Scenario.labels.wizard.time.label.hour' | translate
          }}</mat-label>
          <mat-label data-testid="timehorizon-dialog-numOfHr_value">{{
            startDate.value | datestoHour: endDate.value || 0
          }}</mat-label>
        </div>
      </div>
    </div>
    <div mat-dialog-actions fxLayout="row">
      <button
        mat-flat-button
        type="submit"
        color="primary"
        [disabled]="
          timePeriodsForm?.pristine ||
          !timePeriodsForm?.valid ||
          (loading$ | async)
        "
        data-testid="timehorizon-dialog-confirm"
      >
        {{ data?.confirm || 'OK' }}
      </button>
      <button
        mat-flat-button
        type="button"
        color=""
        (click)="onClose()"
        [disabled]="loading$ | async"
        data-testid="timehorizon-dialog-cancel"
      >
        {{ data?.close || 'Cancel' }}
      </button>
    </div>
    <div>
      <mat-error *ngIf="errorMessage$.value" [innerHtml]="errorMessage$.value">
      </mat-error>
    </div>
  </form>
</prosumer-progress-card>
