import {
  MobilityForm,
  Routes,
  VehiclesDispatch,
} from 'prosumer-app/+scenario/models';
import { ScenarioCompletionService } from 'prosumer-app/+scenario/services/scenario-completion';
import {
  fadeInAnimation,
  StepFormComponent,
} from 'prosumer-app/libs/eyes-shared';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  Optional,
  Self,
} from '@angular/core';
import { UntypedFormBuilder, NgControl } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';

import { combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import { MobilityCompletion } from '../../services/completion-strategies/mobility-completion.strategy';
import { MobilityFormService } from './mobility-form.service';

@UntilDestroy()
@Component({
  selector: 'prosumer-mobility-form',
  templateUrl: './mobility-form.component.html',
  styleUrls: ['./mobility-form.component.scss'],
  animations: [fadeInAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobilityComponent extends StepFormComponent implements OnInit {
  @Input() startYear: number;
  @Input() endYear: number;
  @Input() isViewOnly: boolean;
  @Input() isMultiNode: boolean;

  _scenarioIdentity: any;
  get scenarioIdentity(): any {
    return this._scenarioIdentity;
  }
  @Input() set scenarioIdentity(_scenarioIdentity: any) {
    this._scenarioIdentity = _scenarioIdentity;
  }

  allMobilityRoutes$ = this.service.selectActiveMobilityRoutes();
  allVehicleDispatches$ = this.service.selectActiveVehicleDispatch();
  allMobilityStations$ = this.service.selectActiveMobilityStations();

  combineTheDetailsObservables = combineLatest([
    this.allMobilityRoutes$,
    this.allVehicleDispatches$,
    this.allMobilityStations$,
  ]);

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    public changeDetector: ChangeDetectorRef,
    public formBuilder: UntypedFormBuilder,
    private completion: ScenarioCompletionService,
    private service: MobilityFormService,
  ) {
    super(ngControl, changeDetector, formBuilder);
    this.subscribeToFormForCompletionTracking();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  writeValue(value: MobilityForm) {
    super.writeValue(value);
  }

  defineForm(): MobilityForm {
    return {};
  }

  onDeleteRoutes(route: Routes) {
    this.service.deleteRoute(route.id).pipe(take(1)).subscribe();
  }

  onDeleteVehicles(vehicle: VehiclesDispatch) {
    this.service.deleteDispatch(vehicle.id).pipe(take(1)).subscribe();
  }

  private subscribeToFormForCompletionTracking() {
    const strategy = new MobilityCompletion();
    this.combineTheDetailsObservables.subscribe(
      ([allMobilityRoutes, allVehicleDispatches, allMobilityStations]) => {
        const form = this.buildForm(
          allMobilityRoutes,
          allVehicleDispatches,
          allMobilityStations,
        );
        this.completion.setForMobility(strategy.determineStatus(form));
      },
    );
  }

  private buildForm(
    allMobilityRoutes,
    allVehicleDispatches,
    allMobilityStations,
  ) {
    return {
      routes: {
        routes: allMobilityRoutes,
      },
      vehicles: allVehicleDispatches,
      stationVehicleAssoc: allMobilityStations,
    };
  }
}
