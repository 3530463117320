import { Injectable } from '@angular/core';
import { StoreConfig } from '@datorama/akita';
import { ScenarioDetailStore, ScenarioDetailType } from '../scenario-detail';
import { SpinningReserveMarket } from './spinning-reserve-market.state';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: ScenarioDetailType.reserveMarket })
export class SpinningReserveMarketStore extends ScenarioDetailStore<SpinningReserveMarket> {
  toBE(from: SpinningReserveMarket): unknown {
    return {
      ...from,
      requirement: String(from.requirement),
      marketId: from.energyGridConnectionId,
    };
  }

  toFE(from: unknown): SpinningReserveMarket {
    return {
      ...(from as SpinningReserveMarket),
      energyGridConnectionId: from['marketId'],
    };
  }
}
