import { Injectable } from '@angular/core';
import { ResultVisualizerSlice } from '@prosumer/results/components/results-visualizer';
import { TopologyResultsService } from '@prosumer/results/components/topology-results';
import { TopologyResult } from '@prosumer/results/models';
import { ScenarioCompareFacadeService } from 'prosumer-app/+scenario/state/scenario-compare/scenario-compare-facade.service';

import { iif, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { ComparisonTray } from '../base';
import { ComparisonVisualizerService } from '../comparison-visualizer';
import { ComparisonPreparerService } from '../state';

@Injectable()
export class TopologyComparisonService extends ComparisonVisualizerService<TopologyResult> {
  constructor(
    private topologyService: TopologyResultsService,
    private preparer: ComparisonPreparerService,
    private compareFacadeService: ScenarioCompareFacadeService,
  ) {
    super();
  }

  getSlices(): ResultVisualizerSlice[] {
    return this.topologyService.getSlices();
  }

  getFilterKeys(): string[] {
    return this.topologyService.getFilterKeys();
  }

  getAllYearsStream(): Observable<number[]> {
    return this.preparer.getAllYears$();
  }

  getComparisonTray$(): Observable<ComparisonTray<TopologyResult>> {
    const isOutputSplit$ = this.compareFacadeService.isOutputSplit$;
    const topologiesResult$ = this.compareFacadeService.topologiesResult$;
    const preparedTopologiesResult$ = this.preparer.getTopologyTray$();
    return isOutputSplit$.pipe(
      mergeMap((isOutputSplit) =>
        iif(() => isOutputSplit, topologiesResult$, preparedTopologiesResult$),
      ),
    );
  }
}
