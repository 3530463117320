<div class="mat-card custom-card">
  @if (formTitle) {
    <div class="custom-card-title">
      {{ formTitle }}
    </div>
  }

  <file-upload
    [control]="fileUploadCtrl"
    [multiple]="multipleFiles"
    [accept]="acceptedFiles"
  ></file-upload>

  @if (infoMessage) {
    <mat-chip class="custom-chip" [disableRipple]="true">
      <div class="flex-container">
        <mat-icon>error</mat-icon>
        <span class="custom-chip-text">
          {{ infoMessage }}
        </span>
      </div>
    </mat-chip>
  }

  <prosumer-sparkline-table
    class="custom-sparkline-table"
    [columnsDef]="columnDef"
    [data]="tableData()"
    [disabled]="disabledTable"
    [hideAdd]="true"
    [hideEdit]="true"
    [hideDelete]="false"
    [hideView]="true"
    [searchInput]="false"
    [hideMessages]="hideTableMessages"
    [messages]="tableMessages"
    [hideHeader]="hideTableHeader"
    (delete)="onDelete($event)"
    (editableCellChanged)="onEditableCellChange($event)"
  >
  </prosumer-sparkline-table>
</div>
