import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';
import { Utils } from 'prosumer-app/core/utils';
import { API_CONFIG } from 'prosumer-app/libs/eyes-core';
import { ApiConfig, generateEndpoint } from 'prosumer-app/libs/eyes-shared';
import { Profile as LegacyProfile } from 'prosumer-scenario/models';
import { Observable, forkJoin } from 'rxjs';
import { defaultIfEmpty, tap } from 'rxjs/operators';

import {
  HasLegacyProfiles,
  HasProfiles,
  Profile,
  ProfileParents,
  ProfileState,
} from './profile.state';

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'profile' })
export class ProfileStore extends EntityStore<ProfileState> {
  constructor(
    @Inject(API_CONFIG) private api: ApiConfig,
    private http: HttpClient,
  ) {
    super();
  }

  upsertProfile(profile: Profile): Observable<unknown> {
    return this.http
      .put(this.buildEndpointForBinary(profile, false), profile)
      .pipe(tap(() => this.upsert(profile.id, profile)));
  }

  duplicateProfile(profile: Profile): Observable<unknown> {
    return this.http
      .put(this.buildEndpointForBinary(profile, true), {
        newLocalId: profile.newLocalId,
      })
      .pipe(tap(() => this.upsert(profile.id, profile)));
  }

  createByBatch(has: HasProfiles, isDuplicate: boolean): Observable<unknown> {
    const segregatedProfiles = this.segregateProfiles(this.pluckProfiles(has));
    return forkJoin([
      ...this.toUpsertProfiles(segregatedProfiles[0]),
      ...this.toDuplicateProfiles(segregatedProfiles[1], isDuplicate),
    ]).pipe(defaultIfEmpty({}));
  }

  legacyCreateByBatch(
    has: HasLegacyProfiles,
    parents: ProfileParents,
    isDuplicate: boolean = false,
  ): Observable<unknown> {
    return this.createByBatch(
      { profiles: this.toStateProfiles(has.profiles, parents) },
      isDuplicate,
    );
  }

  private toStateProfiles(
    has: LegacyProfile[],
    parents: ProfileParents,
  ): Profile[] {
    return Utils.resolveToEmptyArray(has).map((profile) =>
      this.toStateProfile(profile, parents),
    );
  }

  private toStateProfile(
    profile: LegacyProfile,
    parents: ProfileParents,
  ): Profile {
    return {
      id: this.getProfileId(profile),
      newLocalId: profile.localId,
      location: profile.location,
      data: profile.loadProfile,
      ...parents,
    };
  }

  private toUpsertProfiles(profiles: Profile[]): Observable<unknown>[] {
    return profiles.map((profile) => this.upsertProfile(profile));
  }

  private toDuplicateProfiles(
    profiles: Profile[],
    isDuplicate: boolean,
  ): Observable<unknown>[] {
    return profiles
      .filter(() => !!isDuplicate)
      .map((profile) => this.duplicateProfile(profile));
  }

  private segregateProfiles(profiles: Profile[]): [Profile[], Profile[]] {
    return [
      this.onlyProfilesWithActualData(profiles),
      this.onlyProfilesWithNoData(profiles),
    ];
  }

  private pluckProfiles(has: HasProfiles): Profile[] {
    return Utils.resolveToEmptyArray(has.profiles);
  }

  private onlyProfilesWithActualData(profiles: Profile[]): Profile[] {
    return profiles.filter((profile) => this.hasActualData(profile));
  }

  private onlyProfilesWithNoData(profiles: Profile[]): Profile[] {
    return profiles.filter((profile) => !this.hasActualData(profile));
  }

  private hasActualData(profile: Profile): boolean {
    return [profile.data, Utils.resolveToEmptyArray(profile.data).length].every(
      Boolean,
    );
  }

  private buildEndpointForBinary(
    profile: Profile,
    isDuplicate: boolean,
  ): string {
    return generateEndpoint(
      this.getBaseUrl(),
      this.getBinaryEndpoint(isDuplicate),
      profile.projectId,
      profile.caseId,
      profile.scenarioId,
      profile.location,
      profile.id,
    );
  }

  private getBinaryEndpoint(isDuplicate: boolean): string {
    if (!!isDuplicate) {
      return this.endpoints['scenario']['cloneBinary'];
    }
    return this.endpoints['scenario']['binary'];
  }

  private getBaseUrl(): string {
    return this.api.baseUrl;
  }

  private get endpoints(): Record<string, Record<string, string>> {
    return this.api.endpoints;
  }

  private getProfileId(profile: LegacyProfile): string {
    return profile.originalLocalId || profile.localId;
  }
}
