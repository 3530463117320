<div
  fxLayout="column"
  fxLayoutGap="10px"
  fxLayoutAlign="center center"
  class="sankeyCharDiv"
  data-testid="plotly-sankey"
>
  <plotly-plot
    [data]="plotlyData"
    [useResizeHandler]="true"
    [updateOnDataChange]="true"
    [revision]="plotlyRevision"
  ></plotly-plot>
</div>
