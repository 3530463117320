import moment from 'moment';
import { TimePeriodInfoService } from 'prosumer-app/+scenario/services/time-period-info';
import {
  BaseComponent,
  CustomValidators,
  FormFieldErrorMessageMap,
  FormFieldOption,
  FormService,
} from 'prosumer-app/libs/eyes-shared';
import {
  TimePeriodInfo,
  TimePeriodQuery,
} from 'prosumer-app/stores/time-periods';
import { extendMoment } from 'prosumer-libs/moment-range';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';

import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
} from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Period } from '../../../models';
import { TimePeriodsDialogData } from './time-horizon-dialog.model';

const _moment = extendMoment(moment);

const DATE_FORMAT = {
  parse: { dateInput: 'D/MM/YYYY' },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMM YYYY',
  },
};

@Component({
  selector: 'prosumer-time-horizon-dialog',
  templateUrl: './time-horizon-dialog.component.html',
  styleUrls: ['./time-horizon-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT }],
})
export class TimeHorizonDialogComponent
  extends BaseComponent
  implements OnInit
{
  timePeriodsForm: UntypedFormGroup = this._formBuilder.group({});
  year: UntypedFormControl = this.timePeriodsForm.get(
    'year',
  ) as UntypedFormControl;
  weight: UntypedFormControl = this.timePeriodsForm.get(
    'weight',
  ) as UntypedFormControl;
  startDate: UntypedFormControl = this.timePeriodsForm.get(
    'startDate',
  ) as UntypedFormControl;
  endDate: UntypedFormControl = this.timePeriodsForm.get(
    'endDate',
  ) as UntypedFormControl;
  yearOptions: Array<FormFieldOption<any>> = [];
  errorMessages: FormFieldErrorMessageMap =
    this._formService.getErrorMessageMap('Scenario.messages.timePeriods');
  defaultStartDate: Date;
  defaultEndDate: Date;

  loading$ = this.timePeriodQuery.selectLoading();
  errorMessage$ = new BehaviorSubject<string>('');

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TimePeriodsDialogData,
    public dialogRef: MatDialogRef<TimePeriodsDialogData>,
    private _formBuilder: UntypedFormBuilder,
    private _formService: FormService,
    private _changeDetector: ChangeDetectorRef,
    private timePeriodInfo: TimePeriodInfoService,
    private timePeriodQuery: TimePeriodQuery,
  ) {
    super();
  }

  ngOnInit() {
    if (!this.data) {
      return;
    }

    if (this.data.yearOptions) {
      this.data.yearOptions.forEach((option) => this.yearOptions.push(option));
    }

    // set initial value
    if (this.data.mode === 'add') {
      this.defaultStartDate = new Date(this.data.year, 0, 1);
      this.defaultEndDate = new Date(this.defaultStartDate);
      this.defaultEndDate.setDate(this.defaultStartDate.getDate() + 1);
    } else {
      this.defaultStartDate = new Date(this.data.startDate);
      this.defaultEndDate = new Date(this.data.endDate);
    }

    this.buildForm(this.data.mode === 'edit');
    this.year.patchValue(this.data.year);
    this.weight.patchValue(this.data.weight);
    this.startDate.patchValue(this.data.startDate);
    this.endDate.patchValue(this.data.endDate);
  }

  setDefaultStartDate(year: number) {
    this.startDate.patchValue('');
    this.endDate.patchValue('');
    this.defaultStartDate = new Date(year, 0, 1);
  }

  setDefaultEndDate(startDateValue: moment.Moment) {
    const baseDate = startDateValue?.clone();
    this.defaultEndDate = baseDate
      ? baseDate.add(1, 'day').toDate()
      : this.defaultEndDate;
  }

  buildForm(isEditMode?: boolean) {
    this.timePeriodsForm = this._formBuilder.group({
      year: [{ value: this.data.year }],
      startDate: '',
      endDate: '',
      weight: '',
    });

    this.year = this.timePeriodsForm.get('year') as UntypedFormControl;
    this.weight = this.timePeriodsForm.get('weight') as UntypedFormControl;
    this.startDate = this.timePeriodsForm.get(
      'startDate',
    ) as UntypedFormControl;
    this.endDate = this.timePeriodsForm.get('endDate') as UntypedFormControl;

    this.weight.setValidators(CustomValidators.betweenZeroAndOne());

    this.startDate.setValidators(this.beyondEndDate(this.endDate));
    this.addSubscription(
      this.endDate.valueChanges.subscribe(() =>
        this.startDate.updateValueAndValidity(),
      ),
    );

    this.endDate.setAsyncValidators(this.checkOptimizedYears());
    this.startDate.setAsyncValidators(
      this.checkOverlappingDates(
        this.data.existingTimePeriods$,
        'startDate',
        'endDate',
        this.endDate,
        this.data.timePeriodsData,
      ),
    );
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    this._changeDetector.detectChanges();
    if (this.timePeriodsForm.invalid) {
      return;
    }

    this.errorMessage$.next('');
    const timePeriodData = this.mapDatesToString(
      this.timePeriodsForm.getRawValue(),
    );
    if (this.data.mode === 'add') {
      this.createTimePeriod(timePeriodData);
    } else {
      this.updateTimePeriod(timePeriodData);
    }
  }

  beyondEndDate(endDateControl: UntypedFormControl): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const validYears = [];
      this.yearOptions.forEach((year) => validYears.push(year.value));
      if (control && control.value && endDateControl.value) {
        const startDate = new Date(control.value);
        const endDate = new Date(endDateControl.value);
        if (!validYears.find((year) => year === startDate.getFullYear())) {
          return { invalidYear: { value: control.value } };
        }
        if (startDate >= endDate) {
          return { beyondEndDate: { value: control.value } };
        }
        return null;
      }
      return null;
    };
  }

  checkOptimizedYears(): AsyncValidatorFn {
    return (
      control: AbstractControl,
    ): Observable<{ [key: string]: any } | null> => {
      const validYears = [];
      this.yearOptions.forEach((year) => validYears.push(year.value));
      if (control && control.value) {
        const currentDate = new Date(control.value);
        if (!validYears.find((year) => year === currentDate.getFullYear())) {
          return of({ invalidYear: { value: control.value } });
        }
        return of(null);
      }
      return of(null);
    };
  }

  checkOverlappingDates(
    sourceListData$: Observable<Array<Period>>,
    startDateKey: string,
    endDateKey: string,
    otherFieldControl: UntypedFormControl,
    skipValues?: { [field: string]: any },
  ): AsyncValidatorFn {
    return (
      control: AbstractControl,
    ): Observable<{ [key: string]: any } | null> => {
      if (!sourceListData$) {
        return of(null);
      }
      return sourceListData$.pipe(
        take(1),
        mergeMap((sourceData) => {
          if (control && control.value) {
            let exist = false;
            if (skipValues) {
              sourceData = sourceData
                .filter(
                  (data) => !(data[startDateKey] === skipValues[startDateKey]),
                )
                .filter(
                  (data) => !(data[endDateKey] === skipValues[endDateKey]),
                );
            }
            exist = sourceData.some((data) => {
              if (_moment.isMoment(otherFieldControl.value)) {
                const rangeToCheck = _moment.range(
                  control.value,
                  otherFieldControl.value,
                );
                const dataRange = _moment.range(
                  data[startDateKey],
                  data[endDateKey],
                );
                // end date of period1 can overlap with start date of period2
                return rangeToCheck.overlaps(dataRange);
              }
            });
            if (exist) {
              return of({ overlappingDates: { value: control.value } });
            }
          }
          return of(null);
        }),
      );
    };
  }

  private createTimePeriod(data: TimePeriodInfo) {
    this.timePeriodInfo.createTimePeriod(data).subscribe(
      () => this.dialogRef.close(),
      (error: HttpErrorResponse) =>
        this.errorMessage$.next(error.error?.error ?? error.message),
    );
  }

  private updateTimePeriod(data: TimePeriodInfo) {
    this.timePeriodInfo
      .updateTimePeriod({ ...data, periodId: this.data.periodId })
      .subscribe(
        () => this.dialogRef.close(),
        (error: HttpErrorResponse) =>
          this.errorMessage$.next(error.error?.error ?? error.message),
      );
  }

  private mapDatesToString(data: TimePeriodInfo): TimePeriodInfo {
    return {
      ...data,
      startDate: _moment(data.startDate).format('YYYY-MM-DD'),
      endDate: _moment(data.endDate).format('YYYY-MM-DD'),
    };
  }
}
