import { Directive, EventEmitter, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FuelInfo, FuelQuery } from 'prosumer-app/stores/fuel';

@UntilDestroy()
@Directive({
  selector: '[prosumerFuelsLister]',
})
export class FuelsListerDirective implements OnInit {
  @Output() fuelsChange = new EventEmitter<FuelInfo[]>();

  constructor(private readonly fuels: FuelQuery) {}

  ngOnInit(): void {
    this.subscribeToActiveFuelForPropagation();
  }

  private subscribeToActiveFuelForPropagation(): void {
    this.fuels
      .selectActiveFuels()
      .pipe(untilDestroyed(this))
      .subscribe((fuels) => this.fuelsChange.emit(fuels));
  }
}
