import { Injectable } from '@angular/core';
import { StoreConfig } from '@datorama/akita';
import { FrequencyControlMapper } from 'prosumer-app/+scenario/services/mappers';
import { ScenarioDetailStore, ScenarioDetailType } from '../scenario-detail';
import { PowerNeeded, PowerNeededIncoming } from './power-needed.state';

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: ScenarioDetailType.powerNeeded })
export class PowerNeededStore extends ScenarioDetailStore<PowerNeeded> {
  toBE(from: PowerNeeded): unknown {
    return FrequencyControlMapper.powerNeededToBE(from);
  }

  toFE(from: PowerNeededIncoming): PowerNeeded {
    return FrequencyControlMapper.powerNeededToFE(from);
  }
}
