<div fxLayout="column">
  <prosumer-sc-power-needed-table
    [startYear]="startYear"
    [endYear]="endYear"
    [hideAdd]="hideAdd"
    [hideEdit]="hideEdit"
    [hideDelete]="hideDelete"
    [hideView]="hideView"
  ></prosumer-sc-power-needed-table>

  <prosumer-sc-equipment-table
    [startYear]="startYear"
    [endYear]="endYear"
    [hideAdd]="hideAdd"
    [hideEdit]="hideEdit"
    [hideDelete]="hideDelete"
    [hideView]="hideView"
  ></prosumer-sc-equipment-table>
</div>
