import { ResultVisualizerSlice } from '../results-visualizer';

export const TOPOLOGY_SLICE_KEYS = [
  'size_kw',
  'invest_cost',
  'oper_cost',
  'total_cost',
  'yearly_flow_ab',
  'yearly_flow_ba',
];

export const TOPOLOGY_SLICES: ResultVisualizerSlice[] = [
  {
    name: 'size_kw',
    unit: 'kW',
    tooltip: 'output_topology.output_topology_size_kw',
  },
  {
    name: 'invest_cost',
    unit: 'k€',
    tooltip: 'output_topology.output_topology_inv_cost',
  },
  {
    name: 'oper_cost',
    unit: 'k€',
    tooltip: 'output_topology.output_topology_om_cost',
  },
  {
    name: 'total_cost',
    unit: 'k€',
    tooltip: 'output_topology.output_topology_total_cost',
  },
  {
    name: 'yearly_flow_ab',
    unit: 'kWh',
    tooltip: 'output_topology.output_topology_flow_o_to_d',
  },
  {
    name: 'yearly_flow_ba',
    unit: 'kWh',
    tooltip: 'output_topology.output_topology_flow_d_to_o',
  },
];
