import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { DetailEntityDuplicator } from 'prosumer-app/+scenario/directives/detail-duplicator/detail-entity.duplicator';
import { NotificationsService } from 'prosumer-app/shared/services/notification';
import { DuplicateDetailData } from 'prosumer-app/stores';
import {
  EnergyGridConnectionQuery,
  EnergyGridConnectionStore,
} from 'prosumer-app/stores/energy-grid-connection';

@Component({
  selector: 'prosumer-egc-duplicate-dialog',
  templateUrl: './egc-duplicate-dialog.component.html',
  styleUrls: ['./egc-duplicate-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EgcDuplicateDialogComponent extends DetailEntityDuplicator {
  constructor(
    @Inject(MAT_DIALOG_DATA) readonly data: DuplicateDetailData,
    readonly dialogRef: MatDialogRef<EgcDuplicateDialogComponent>,
    readonly egcStore: EnergyGridConnectionStore,
    readonly egcQuery: EnergyGridConnectionQuery,
    readonly notifs: NotificationsService,
  ) {
    super(data, egcStore, egcQuery, dialogRef, notifs);
  }
}
