import { FuelMapper } from 'prosumer-app/+scenario/services/mappers/fuel.mapper';
import { StoreApiService } from 'prosumer-app/services/store-api';
import { Observable } from 'rxjs';
import { finalize, tap, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { StoreConfig } from '@datorama/akita';

import { ScenarioDetailStore, ScenarioDetailType } from '../scenario-detail';
import { ScenarioGenericQuery } from '../scenario-generic';
import { FuelInfo, CreateFormFuel, FuelBE } from './fuel.state';
import { Fuel as FuelFE } from 'prosumer-app/+scenario';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: ScenarioDetailType.fuel, idKey: 'id' })
export class FuelStore extends ScenarioDetailStore<FuelInfo> {
  constructor(
    api: StoreApiService,
    private generic: ScenarioGenericQuery,
  ) {
    super(api);
  }

  createFuel(data: CreateFormFuel): Observable<FuelInfo> {
    this.setLoading(true);
    return this.handleAdd(
      this.post({
        key: 'createDetail',
        data: {},
        body: this.injectDataType(this.toBE(data)),
      }).pipe(map((data: FuelInfo) => this.toFE(data))),
    ).pipe(finalize(() => this.setLoading(false)));
  }

  deleteFuel(id: string): Observable<unknown> {
    this.upsertLoading(id, true);
    return this.delete({
      key: 'deleteDetail',
      data: { id },
      body: undefined,
    }).pipe(finalize(() => this.upsertLoading(id, false)));
  }

  getFuels(): Observable<FuelInfo[]> {
    return this.getAll(ScenarioDetailType.fuel);
  }

  toFE(from: unknown): FuelInfo {
    const [startYear, endYear] = this.generic.getActivePeriod();
    return FuelMapper.mapToFrontend(from, startYear, endYear);
  }

  toBE(from: CreateFormFuel): FuelBE {
    return FuelMapper.mapToBackend(from);
  }

  protected getDataUuid(data: Record<string, unknown>): string {
    return data['id'] as string;
  }

  private injectDataType(data: FuelBE | FuelFE): unknown {
    return { ...data, dataType: ScenarioDetailType.fuel };
  }

  private handleAdd($: Observable<FuelInfo>): Observable<FuelInfo> {
    return $.pipe(tap((data: FuelInfo) => this.add(data)));
  }
}
