import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseComponent } from 'prosumer-app/libs/eyes-shared';
import { CompareMainResult } from 'prosumer-scenario/models';

@Component({
  selector: 'prosumer-compare-main',
  templateUrl: './compare-main.component.html',
  styleUrls: ['./compare-main.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompareMainComponent extends BaseComponent {
  kpiList: Array<any>;
  kpiIndicators = [
    { name: 'KEY PERFORMANCE INDICATORS', key: 'name', tooltip: '' },
    {
      name: 'Total Discounted Cost (€)',
      key: 'totalDistributedCost',
      tooltip: 'output_main.output_main_total_discounted_cost',
    },
    {
      name: ' CAPEX First Year (€)',
      key: 'capexFirstYear',
      tooltip: 'output_main.output_main_discounted_capex',
    },
    {
      name: 'OPEX First Year (€)',
      key: 'opexFirstYear',
      tooltip: 'output_main.output_main_final_opex',
    },
    {
      name: 'CO2 Emissions (Tons)',
      key: 'co2Emission',
      tooltip: 'output_main.output_main_co2_emissions_kpi',
    },
  ];

  @Input() set result(results: Array<CompareMainResult>) {
    this.kpiList = [];
    const resultList = [...results];
    const refResult = resultList.shift();
    this.kpiIndicators.map((indicators) => {
      const referenceValue = refResult[indicators.key];
      const values = [[undefined, referenceValue]];
      values.push(
        ...resultList.map((result) => [
          this.computePercentDiff(referenceValue, result[indicators.key]),
          result[indicators.key],
        ]),
      );
      this.kpiList.push({
        ...indicators,
        values,
      });
    });
  }

  constructor() {
    super();
  }

  computePercentDiff(refValue: number, value: number) {
    const diff = refValue - value;
    return diff === 0 ? 0 : (diff / refValue) * 100;
  }
}
