import {
  FrequencyControlForm,
  ShortCircuit,
  SpinningReserve,
} from 'prosumer-app/+scenario';
import { Utils } from 'prosumer-core/utils';

import { CompletionStrategy } from './completion-strategy';

export class FrequencyControlCompletion extends CompletionStrategy<FrequencyControlForm> {
  isFull(form: FrequencyControlForm): boolean {
    return this.getRequiredConditions(form).every(Boolean);
  }
  isHalf(form: FrequencyControlForm): boolean {
    return this.getRequiredConditions(form).some(Boolean);
  }

  private getRequiredConditions(form: FrequencyControlForm): boolean[] {
    return [
      ...this.getShortCircuitConditions(form.shortCircuit),
      ...this.getReserveConditions(form.spinningReserve),
    ];
  }

  private getShortCircuitConditions(circuit: ShortCircuit): boolean[] {
    return [
      this.hasItems(circuit.energyVectors),
      this.hasItems(circuit.equipments),
    ];
  }

  private getReserveConditions(reserve: SpinningReserve): boolean[] {
    return [
      this.hasItems(reserve.activations),
      this.hasItems(reserve.equipments),
      this.hasItems(reserve.margins),
      this.hasItems(reserve.markets),
      this.hasItems(reserve.networks),
    ];
  }

  private hasItems(list: unknown[]): boolean {
    return !!Utils.resolveToEmptyArray(list).length;
  }
}
