<form fxLayout="column" [formGroup]="vehicleForm">
  <div fxLayout="column">
    <!-- General Parameters -->
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
        <mat-panel-title>
          <h3 data-testid="general-params-title">
            {{ 'Scenario.labels.general.parameters' | translate }}
            <prosumer-tooltip-anchor
              [message]="'wizard_vehicle.wizard_vehicle_general_parameters'"
              data-testid="general-params-title-tooltip"
            ></prosumer-tooltip-anchor>
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="column">
        <div fxLayout="row" fxLayoutGap="10px">
          <!-- Vehicle Name -->
          <prosumer-input
            fxFlex="33%"
            module="Scenario"
            formFieldName="der.name"
            [attr.data-testid]="'vehicle-name'"
            [inputTooltip]="'wizard_vehicle.wizard_vehicle_name'"
            [control]="vehicleForm?.controls?.name"
            [required]="true"
          ></prosumer-input>
          <!-- Associated Vector -->
          <prosumer-select
            fxFlex="33%"
            [placeholder]="
              'Scenario.placeholders.general.associatedEnergyVector' | translate
            "
            [label]="
              'Scenario.labels.general.associatedEnergyVector' | translate
            "
            [options]="outputEnergyVectorOptions"
            [control]="vehicleForm?.controls?.energyVector"
            [required]="true"
            [errorMessageMap]="errorMessages?.energyVector"
            [tooltip]="'wizard_vehicle.wizard_vehicle_associated_vector'"
            data-testid="associated-vector"
          ></prosumer-select>
          <!-- Scenario Variation -->
          <prosumer-select
            fxFlex="33%"
            *ngIf="scenarioVariationOptions.length > 1"
            [placeholder]="
              'Scenario.placeholders.der.scenarioVariation' | translate
            "
            [label]="'Scenario.labels.der.scenarioVariation' | translate"
            [options]="scenarioVariationOptions"
            [control]="vehicleForm?.controls?.scenarioVariation"
            [errorMessageMap]="errorMessages['scenarioVariation']"
            data-testid="vehicle-variation"
          ></prosumer-select>
        </div>
        <prosumer-radio-button
          [label]="'Scenario.labels.general.source' | translate"
          [options]="profileOptions"
          [control]="vehicleForm?.controls?.sourceType"
          [labelTooltip]="'wizard_vehicle.wizard_vehicle_source'"
          [attr.data-testid]="'vehicle-source-toggle'"
        ></prosumer-radio-button>
      </div>
    </mat-expansion-panel>

    <!-- Technical Parameters -->
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
        <mat-panel-title>
          <h3 data-testid="tech-param-title">
            {{ 'Scenario.labels.technical.parameters' | translate }}
            <prosumer-tooltip-anchor
              [message]="'wizard_vehicle.wizard_vehicle_technical_parameters'"
              data-testid="tech-param-title-tooltip"
            ></prosumer-tooltip-anchor>
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="column">
        <div fxLayout="row" fxLayoutGap="10px">
          <prosumer-yearly-chart-input
            fxFlex="24%"
            formControlName="yearlyCapacityLoss"
            inputLabel="Scenario.labels.technical.capacityLossKW"
            errorMessage="Scenario.messages.der.vehicles.capacityLoss"
            contextHelpMsg="To be added help text here."
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            [contextHelpMsg]="'wizard_vehicle.wizard_vehicle_capacity_loss_kw'"
            data-testid="yearly-capacity-loss"
          ></prosumer-yearly-chart-input>

          <prosumer-yearly-chart-input
            fxFlex="24%"
            formControlName="yearlySecondCapacityLoss"
            inputLabel="Scenario.labels.technical.capacityLossKWh"
            errorMessage="Scenario.messages.der.vehicles.capacityLossEnergy"
            contextHelpMsg="To be added help text here."
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            [contextHelpMsg]="'wizard_vehicle.wizard_vehicle_capacity_loss_kw2'"
            data-testid="yearly-second-capacity-loss"
          ></prosumer-yearly-chart-input>

          <prosumer-yearly-chart-input
            fxFlex="24%"
            formControlName="yearlyEfficiency2nd"
            inputLabel="Scenario.labels.technical.efficiencyKwh"
            errorMessage="Scenario.messages.der.vehicles.efficiency"
            contextHelpMsg="To be added help text here."
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            [contextHelpMsg]="'wizard_vehicle.wizard_vehicle_efficiency'"
            data-testid="yearly-efficiency"
          ></prosumer-yearly-chart-input>

          <prosumer-yearly-chart-input
            fxFlex="24%"
            formControlName="yearlyRoundTripEfficiency"
            inputLabel="Scenario.labels.technical.roundTripEfficiency"
            errorMessage="Scenario.messages.der.vehicles.roundTripEfficiency"
            contextHelpMsg="To be added help text here."
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            [contextHelpMsg]="
              'wizard_vehicle.wizard_vehicle_roundtrip_efficiency'
            "
            data-testid="yearly-roundtrip-efficiency"
          ></prosumer-yearly-chart-input>
        </div>

        <div fxLayout="row" fxLayoutGap="10px">
          <prosumer-yearly-chart-input
            fxFlex="24%"
            formControlName="yearlyMaxDoD"
            inputLabel="Scenario.labels.technical.maxDoD"
            errorMessage="Scenario.messages.der.vehicles.maxDoD"
            contextHelpMsg="To be added help text here."
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            [contextHelpMsg]="'wizard_vehicle.wizard_vehicle_max_dod'"
            data-testid="yearly-max-dod"
          ></prosumer-yearly-chart-input>

          <prosumer-yearly-chart-input
            fxFlex="24%"
            formControlName="yearlyDissipationRate"
            inputLabel="Scenario.labels.technical.dissipationRate"
            errorMessage="Scenario.messages.der.vehicles.dissipationRate"
            contextHelpMsg="To be added help text here."
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            [contextHelpMsg]="'wizard_vehicle.wizard_vehicle_dissipation_rate'"
            data-testid="yearly-dissipation-rate"
          ></prosumer-yearly-chart-input>

          <prosumer-yearly-chart-input
            fxFlex="24%"
            formControlName="yearlyAgingFactor"
            inputLabel="Scenario.labels.technical.agingFactor"
            errorMessage="Scenario.messages.der.vehicles.agingFactor"
            contextHelpMsg="To be added help text here."
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            [contextHelpMsg]="'wizard_vehicle.wizard_vehicle_ageing_factor'"
            data-testid="yearly-aging-factor"
          ></prosumer-yearly-chart-input>
          <div fxFlex="24%"></div>
        </div>
      </div>
    </mat-expansion-panel>

    <!-- Scope3 Emissions Parameters -->
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
        <mat-panel-title>
          <h3 data-testid="emissions-param-title">
            {{ 'Scenario.labels.co2Emissions.parameters' | translate }}
            <prosumer-tooltip-anchor
              [message]="
                'wizard_vehicle.wizard_vehicle_scope3emissions_parameters'
              "
              data-testid="emissions-param-title-tooltip"
            ></prosumer-tooltip-anchor>
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="column">
        <div fxLayout="row" fxLayoutGap="10px">
          <!-- Build emissions kW -->
          <prosumer-yearly-chart-input
            formControlName="yearlyBuildEmissionsKw"
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            [errorMessage]="'Scenario.messages.der.buildEmissionsKw'"
            [inputLabel]="
              'Scenario.labels.co2Emissions.buildEmissionsKw' | translate
            "
            fxFlex="24%"
            [contextHelpMsg]=""
            data-testid="build-emissions-kw"
          ></prosumer-yearly-chart-input>
          <!-- Build emissions kWh -->
          <prosumer-yearly-chart-input
            formControlName="yearlyBuildEmissionsKwh"
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            [errorMessage]="'Scenario.messages.der.buildEmissionsKwh'"
            [inputLabel]="
              'Scenario.labels.co2Emissions.buildEmissionsKwh' | translate
            "
            fxFlex="24%"
            [contextHelpMsg]=""
            data-testid="build-emissions-kwh"
          ></prosumer-yearly-chart-input>
          <!-- Build emissions indivisible -->
          <prosumer-yearly-chart-input
            formControlName="yearlyBuildEmissionsIndivisible"
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            [errorMessage]="'Scenario.messages.der.buildEmissionsIndivisible'"
            [inputLabel]="
              'Scenario.labels.co2Emissions.buildEmissionsIndivisible'
                | translate
            "
            fxFlex="24%"
            [contextHelpMsg]=""
            data-testid="build-emissions-indivisible"
          ></prosumer-yearly-chart-input>
          <div fxFlex="24%"></div>
        </div>
      </div>
    </mat-expansion-panel>

    <!-- Sizing Parameters -->
    <mat-expansion-panel [expanded]="true" class="sizing-parameters">
      <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
        <mat-panel-title>
          <h3 data-testid="sizing-param-title">
            {{ 'Scenario.labels.sizing.parameters' | translate }}
            <prosumer-tooltip-anchor
              [message]="'wizard_vehicle.wizard_vehicle_sizing_parameters'"
              data-testid="sizing-param-title-tooltip"
            ></prosumer-tooltip-anchor>
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div fxLayout="column">
        <div fxLayout="row" fxLayoutGap="10px">
          <prosumer-yearly-chart-input
            fxFlex="24%"
            formControlName="yearlyMinPower"
            inputLabel="Scenario.labels.sizing.minPower"
            errorMessage="Scenario.messages.der.minPower"
            contextHelpMsg="To be added help text here."
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            [contextHelpMsg]="'wizard_vehicle.wizard_vehicle_minimum_power'"
            data-testid="yearly-min-power"
          ></prosumer-yearly-chart-input>

          <prosumer-yearly-chart-input
            fxFlex="24%"
            formControlName="yearlyMaxPower"
            inputLabel="Scenario.labels.sizing.maxPower"
            errorMessage="Scenario.messages.der.maxPower"
            contextHelpMsg="To be added help text here."
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            [contextHelpMsg]="'wizard_vehicle.wizard_vehicle_maximum_power'"
            data-testid="yearly-max-power"
          ></prosumer-yearly-chart-input>

          <prosumer-yearly-chart-input
            fxFlex="24%"
            formControlName="yearlyMinEnergy"
            inputLabel="Scenario.labels.sizing.minEnergy"
            errorMessage="Scenario.messages.der.minEnergy"
            contextHelpMsg="To be added help text here."
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            [contextHelpMsg]="'wizard_vehicle.wizard_vehicle_minimum_energy'"
            data-testid="yearly-min-energy"
          ></prosumer-yearly-chart-input>

          <prosumer-yearly-chart-input
            fxFlex="24%"
            formControlName="yearlyMaxEnergy"
            inputLabel="Scenario.labels.sizing.maxEnergy"
            errorMessage="Scenario.messages.der.maxEnergy"
            contextHelpMsg="To be added help text here."
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            [contextHelpMsg]="'wizard_vehicle.wizard_vehicle_maximum_energy'"
            data-testid="yearly-max-energy"
          ></prosumer-yearly-chart-input>
        </div>

        <div fxLayout="row" fxLayoutGap="10px">
          <!-- Forced Investment -->
          <prosumer-checkbox
            fxFlex="24%"
            [label]="'Scenario.labels.sizing.forcedInvestment' | translate"
            [control]="vehicleForm?.controls?.forcedInvestment"
            [errorMessageMap]="errorMessages?.forcedInvestment"
            [tooltip]="'wizard_vehicle.wizard_vehicle_forced_investment'"
            data-testid="forced-investment-option"
          ></prosumer-checkbox>

          <!-- Existing Asset -->
          <prosumer-checkbox
            fxFlex="24%"
            [label]="'Scenario.labels.sizing.existingAsset' | translate"
            [control]="vehicleForm?.controls?.existingAsset"
            [tooltip]="'wizard_vehicle.wizard_vehicle_existing_asset'"
            [errorMessageMap]="errorMessages?.existingAsset"
            data-testid="existing-asset-option"
          ></prosumer-checkbox>

          <!-- Capacity Expansion -->
          <prosumer-checkbox
            fxFlex="24%"
            [label]="'Scenario.labels.sizing.capacityExpansion' | translate"
            [control]="vehicleForm?.controls?.capacityExpansion"
            [tooltip]="'wizard_vehicle.wizard_vehicle_capacity_expansion'"
            data-testid="capacity-expansion-option"
          ></prosumer-checkbox>
          <div fxFlex="24%"></div>
        </div>
      </div>
    </mat-expansion-panel>
    <!-- Economical Parameters -->
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
        <mat-panel-title>
          <h3 data-testid="economical-param-title">
            {{ 'Scenario.labels.economical.parameters' | translate }}
            <prosumer-tooltip-anchor
              [message]="'wizard_vehicle.wizard_vehicle_economical_parameters'"
              data-testid="economical-param-title-tooltip"
            ></prosumer-tooltip-anchor>
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div fxLayout="column">
        <div fxLayout="row" fxLayoutGap="10px">
          <prosumer-yearly-chart-input
            fxFlex="24%"
            formControlName="yearlyBuildCost"
            inputLabel="Scenario.labels.economical.buildCost"
            errorMessage="Scenario.messages.der.buildCost"
            contextHelpMsg="To be added help text here."
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            [contextHelpMsg]="'wizard_vehicle.wizard_vehicle_build_cost_kw'"
            data-testid="yearly-build-cost"
          ></prosumer-yearly-chart-input>

          <prosumer-yearly-chart-input
            fxFlex="24%"
            formControlName="yearlySecondBuildCost"
            inputLabel="Scenario.labels.economical.buildCostKwH"
            errorMessage="Scenario.messages.der.buildCost"
            contextHelpMsg="To be added help text here."
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            [contextHelpMsg]="'wizard_vehicle.wizard_vehicle_build_cost_kwh'"
            data-testid="yearly-second-build-cost"
          ></prosumer-yearly-chart-input>

          <prosumer-yearly-chart-input
            fxFlex="24%"
            formControlName="yearlyIndivisibleCost"
            inputLabel="Scenario.labels.economical.indivisibleCost"
            errorMessage="Scenario.messages.der.indivisibleCost"
            contextHelpMsg="To be added help text here."
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            [contextHelpMsg]="'wizard_vehicle.wizard_vehicle_indivisible_cost'"
            data-testid="yearly-indivisible-cost"
          ></prosumer-yearly-chart-input>

          <prosumer-yearly-chart-input
            fxFlex="24%"
            formControlName="yearlyFOAndMCharge"
            inputLabel="Scenario.labels.economical.fOAndMCharge"
            errorMessage="Scenario.messages.der.fOAndMCharge"
            contextHelpMsg="To be added help text here."
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            [contextHelpMsg]="'wizard_vehicle.wizard_vehicle_fom_charge_kw'"
            data-testid="yearly-FOAndMCharge"
          ></prosumer-yearly-chart-input>
        </div>

        <div fxLayout="row" fxLayoutGap="10px">
          <prosumer-yearly-chart-input
            fxFlex="24%"
            formControlName="yearlyFOAndMInstall"
            inputLabel="Scenario.labels.economical.fOAndMPerInstall"
            errorMessage="Scenario.messages.der.fOAndMPerInstall"
            contextHelpMsg="To be added help text here."
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            [contextHelpMsg]="'wizard_vehicle.wizard_vehicle_fom_per_install'"
            data-testid="yearly-FOAndMInstall"
          ></prosumer-yearly-chart-input>

          <prosumer-yearly-chart-input
            fxFlex="24%"
            formControlName="yearlyFOAndMChargeKWh"
            inputLabel="Scenario.labels.economical.fOAndMChargeKwh"
            errorMessage="Scenario.messages.der.fOAndMChargeKwh"
            contextHelpMsg="To be added help text here."
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            [contextHelpMsg]="'wizard_vehicle.wizard_vehicle_fom_charge_kwh'"
            data-testid="yearly-FOAndChargeKWh"
          ></prosumer-yearly-chart-input>

          <prosumer-yearly-chart-input
            fxFlex="24%"
            formControlName="yearlyTechnicalLife"
            inputLabel="Scenario.labels.economical.technicalLife"
            errorMessage="Scenario.messages.der.technicalLife"
            contextHelpMsg="To be added help text here."
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            [contextHelpMsg]="'wizard_vehicle.wizard_vehicle_technical_life'"
            data-testid="yearly-technical-life"
          ></prosumer-yearly-chart-input>

          <prosumer-yearly-chart-input
            fxFlex="24%"
            formControlName="yearlyDepreciationTime"
            inputLabel="Scenario.labels.economical.depreciationTime"
            errorMessage="Scenario.messages.der.depreciationTime"
            contextHelpMsg="To be added help text here."
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            [contextHelpMsg]="'wizard_vehicle.wizard_vehicle_depreciation_time'"
            data-testid="yearly-depreciation-time"
          ></prosumer-yearly-chart-input>
        </div>
      </div>
    </mat-expansion-panel>

    <!-- Operational Cost Parameters -->
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
        <mat-panel-title>
          <h3 data-testid="operational-cost-title">
            {{ 'Scenario.labels.operationalCost.parameters' | translate }}
            <prosumer-tooltip-anchor
              [message]="
                'wizard_vehicle.wizard_vehicle_operational_cost_parameters'
              "
            ></prosumer-tooltip-anchor>
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <prosumer-operating-cost-table
        formControlName="operatingCostProfiles"
        [startYear]="data?.startYear"
        [endYear]="data?.endYear"
        [mode]="data?.mode"
        [formType]="formType"
        [inputEnergyVectorsOptions]="energyVectorOptions$ | async"
        [outputEnergyVectorsOptions]="energyVectorOptions$ | async"
        [projectId]="projectId"
        [caseId]="caseId"
        [scenarioId]="scenarioId"
      ></prosumer-operating-cost-table>
    </mat-expansion-panel>
  </div>

  <button #submit type="submit" [hidden]="true"></button>
</form>
