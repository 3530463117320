import { Coerce } from 'prosumer-core/utils/coercion.util';

export const ANY_VALUE = 'Any';
export const mapKeysTDBTechDataToForm = (data) => ({
  ...data,
  secondCapacityLoss: data.capacityLoss2,
  roundTripEfficiency: data.efficiency,
  maxDoD: data.maxDod,
  chargeDischargeRateFactor: data.chargingDischargingRateFactor,
  agingFactor: data.ageingFactor,
  secondBuildCost: data.buildCost2,
  minDownTime: data.minDowntime,
  minUpTime: data.minUptime,
});

export const getTDBTechFilterFromData = (
  data: Record<string, unknown>,
): Record<string, unknown> => ({
  category: Coerce.toString(data.technologyCategory, ANY_VALUE),
  subcategory: Coerce.toString(data.technologySubcategory, ANY_VALUE),
  subsubcategory: Coerce.toString(data.technologySubsubcategory, ANY_VALUE),
  region: Coerce.toString(data.region, ANY_VALUE),
  subregion: Coerce.toString(data.subregion, ANY_VALUE),
  country: Coerce.toString(data.country, ANY_VALUE),
  minpower: String(Coerce.toString(data.minPower, ANY_VALUE)),
  maxpower: String(Coerce.toString(data.maxPower, ANY_VALUE)),
});
