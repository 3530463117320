import { Injectable } from '@angular/core';
import { ActiveKeeperService } from 'prosumer-app/services/active-keeper';
import { ScenarioDetailQuery } from '../scenario-detail';
import { TaxAndSubsidy } from './tax-and-subsidy.state';
import { TaxAndSubsidyStore } from './tax-and-subsidy.store';

@Injectable({ providedIn: 'root' })
export class TaxAndSubsidyQuery extends ScenarioDetailQuery<TaxAndSubsidy> {
  constructor(
    readonly store: TaxAndSubsidyStore,
    readonly keeper: ActiveKeeperService,
  ) {
    super(store, keeper);
  }
}
