import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { LineStore } from 'prosumer-app/stores/line';
import { ScenarioDetailType } from 'prosumer-app/stores';

@Injectable({
  providedIn: 'root',
})
export class LineInfoService {
  constructor(private readonly store: LineStore) {}

  getLines(): Observable<unknown> {
    return this.store.getLines({ dataType: ScenarioDetailType.line });
  }

  createLine(data: Record<string, unknown>): Observable<unknown> {
    return this.store.createLine({ ...data });
  }

  updateLine(data: Record<string, unknown>): Observable<unknown> {
    return this.store.updateLine({ ...data });
  }

  deleteLine(id: string): Observable<unknown> {
    return this.store.deleteLine(id);
  }

  removeLinesFromStore(ids: string[]): void {
    ids.map((id) => {
      this.store.remove(id);
    });
  }
}
