import { ComparisonVisualizerService } from 'prosumer-app/comparison/comparison-visualizer/comparison-visualizer.service';
import { COMP_VIZ_SERVICE } from 'prosumer-app/comparison/comparison-visualizer/comparison-visualizer.tokens';
import { Utils } from 'prosumer-core/utils';
import {
  BarDatum,
  StackedBarMeta,
} from 'prosumer-shared/modules/chartjs/stacked-bar-chartjs/stacked-bar-chartjs.model';
import { BehaviorSubject, iif, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { Directive, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ResultsPerceptionService } from '@prosumer/results/components/case-results-perception/results-perception.service';
import {
  ResultNameValue,
  ResultsVisualizationType,
  ResultsVisualizerController,
  VisualizerData,
} from '@prosumer/results/components/results-visualizer';

import { ComparisonData } from './comparison-models';

@Directive()
export abstract class ComparisonController<T extends ComparisonData>
  extends ResultsVisualizerController<T>
  implements OnInit
{
  private selectedTypeSubject = new BehaviorSubject<ResultsVisualizationType>(
    ResultsVisualizationType.INCREMENTAL,
  );
  chartData$: Observable<StackedBarMeta>;

  constructor(
    @Inject(COMP_VIZ_SERVICE)
    private comparisonService: ComparisonVisualizerService<T>,
    perception: ResultsPerceptionService,
    private translate: TranslateService,
  ) {
    super(comparisonService, perception);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.chartData$ = this.getChartDataStream();
  }

  getLegendNames$(): Observable<string[]> {
    return this.comparisonService
      .getComparisonTray$()
      .pipe(map((tray) => Object.keys(tray)));
  }

  onTypeChange(changedTo: ResultsVisualizationType): void {
    this.selectedTypeSubject.next(changedTo);
  }

  private getChartDataStream(): Observable<StackedBarMeta> {
    return this.selectedTypeSubject.pipe(
      mergeMap((type) =>
        iif(
          () => type === ResultsVisualizationType.INCREMENTAL,
          this.incremental$,
          this.cumulative$,
        ),
      ),
      map(this.mapVisualizerDataToStackedBarMeta.bind(this)),
    );
  }

  private mapVisualizerDataToStackedBarMeta(
    data: VisualizerData[],
  ): StackedBarMeta {
    return {
      name: this.getChartName(),
      axisTicks: this.collectTicks(data),
      data: data.map(this.mapVisualizerDataToBarDatum.bind(this)),
    };
  }

  private mapVisualizerDataToBarDatum(data: VisualizerData): BarDatum {
    const { name, series } = data;
    return { name, values: this.reduceSeriesToNameValues(series), group: name };
  }

  private reduceSeriesToNameValues(series: ResultNameValue[]): {
    [name: string]: number;
  } {
    return series.reduce((acc, curr) => {
      acc[curr.name] = curr.value;
      return acc;
    }, {});
  }

  private getChartName(): string {
    const resolvedName = this.translate.instant(
      `Result.slices.${this.comparisonService.selectedSlice}`,
    );
    return this.comparisonService.selectedSlice === 'overall'
      ? `${resolvedName} (${this.selectedTypeSubject.value})`
      : `${resolvedName} for ${this.comparisonService.activeYear} (${this.selectedTypeSubject.value})`;
  }

  private collectTicks(data: VisualizerData[]): string[] {
    const allTicks = data.reduce(
      (ticks, single) => [...ticks, ...single.series.map((s) => s.name)],
      [],
    );
    return Utils.removeDuplicates(allTicks).sort();
  }
}
