<div>
  <prosumer-select
    *ngIf="isVariationDropdownVisible | ngrxPush"
    class="prosumer-scenario-variation-option"
    [placeholder]="
      'Scenario.labels.simulationResults.scenarioVariation' | translate
    "
    [label]="'Scenario.labels.simulationResults.scenarioVariation' | translate"
    [options]="scenarioVariationOptions"
    data-testid="results-variation-option"
    data-testid="scenario-variation-dropdown"
    [control]="scenarioVariationForm?.controls?.scenarioVariation"
    (eyesSelectionChange)="onVariationChange($event)"
  ></prosumer-select>
</div>
