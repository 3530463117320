import { MobilityMapper } from 'prosumer-app/+scenario/services/mappers';

import { Injectable } from '@angular/core';
import { StoreConfig } from '@datorama/akita';

import { CascadableDetailStore } from '../cascadable-detail';
import { ScenarioDetailType } from '../scenario-detail';
import { MobilityRoute, MobilityRouteBE } from './mobility-route.state';

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: ScenarioDetailType.mobilityRoute })
export class MobilityRouteStore extends CascadableDetailStore<MobilityRoute> {
  toFE(from: unknown): MobilityRoute {
    return MobilityMapper.routeToFE(from as MobilityRouteBE);
  }

  toBE(from: MobilityRoute): unknown {
    return MobilityMapper.routeToBE(from);
  }
}
