import { Injectable } from '@angular/core';
import { StoreConfig } from '@datorama/akita';
import { FrequencyControlMapper } from 'prosumer-app/+scenario/services/mappers';
import { ScenarioDetailStore, ScenarioDetailType } from '../scenario-detail';
import {
  SpinningReserveMargin,
  SpinningReserveMarginIncoming,
  SpinningReserveMarginOutgoing,
} from './spinning-reserve-margin.state';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: ScenarioDetailType.srMargin })
export class SpinningReserveMarginStore extends ScenarioDetailStore<SpinningReserveMargin> {
  toBE(from: SpinningReserveMargin): SpinningReserveMarginOutgoing {
    return FrequencyControlMapper.srMarginToBE(from);
  }

  toFE(from: SpinningReserveMarginIncoming): SpinningReserveMargin {
    return FrequencyControlMapper.srMarginToFE(from);
  }
}
