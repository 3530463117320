import { Injectable } from '@angular/core';
import { StoreConfig } from '@datorama/akita';
import { EmissionMapper } from 'prosumer-app/+scenario/services/mappers/emission.mapper';
import { StoreApiService } from 'prosumer-app/services/store-api';
import { ScenarioDetailStore, ScenarioDetailType } from '../scenario-detail';
import { EGCEmission, EGCEmissionBE } from './egc-emission.state';

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: ScenarioDetailType.egcEmission })
export class EGCEmissionStore extends ScenarioDetailStore<EGCEmission> {
  constructor(api: StoreApiService) {
    super(api);
  }

  getDataUuid(data: Record<string, string>): string {
    return data['id'];
  }

  toBE(from: EGCEmission): EGCEmissionBE {
    return EmissionMapper.mapToBE(from);
  }

  toFE(from: EGCEmissionBE): EGCEmission {
    return EmissionMapper.mapToFE(from);
  }
}
