import { TooltipModule } from 'prosumer-libs/eyes-shared';
import { PieChartjsModule } from 'prosumer-shared/modules/chartjs/pie-chartjs';
import { StackedAreaChartjsModule } from 'prosumer-shared/modules/chartjs/stacked-area-chartjs';
import { StackedBarChartjsModule } from 'prosumer-shared/modules/chartjs/stacked-bar-chartjs';
import { ChartsModule } from 'prosumer-shared/modules/charts';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreModule, FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { MainOutputResultsComponent } from './main-output-results.component';
import { SizingAndCostsResultsComponent } from './sizing-and-costs-results';
import { TotalDiscountedCostComponent } from './total-discounted-cost';
import { TotalEmissionsComponent } from './total-emissions-results';

@NgModule({
  declarations: [
    MainOutputResultsComponent,
    TotalDiscountedCostComponent,
    TotalEmissionsComponent,
    SizingAndCostsResultsComponent,
  ],
  imports: [
    CoreModule,
    CommonModule,
    FlexLayoutModule,
    ChartsModule,
    MatButtonToggleModule,
    MatIconModule,
    TooltipModule,
    ReactiveFormsModule,
    MatCardModule,
    NgxChartsModule,
    StackedAreaChartjsModule,
    StackedBarChartjsModule,
    PieChartjsModule,
    TranslateModule,
  ],
  exports: [
    MainOutputResultsComponent,
    TotalDiscountedCostComponent,
    TotalEmissionsComponent,
    SizingAndCostsResultsComponent,
  ],
})
export class MainOutputResultsModule {}
