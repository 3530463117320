<div fxLayout="column">
  <prosumer-chart-exporter
    [id]="id"
    [chartInstance]="chartInstance"
    [allData]="allData"
    [scenarioName]="scenarioName"
    [resultsName]="resultsName"
    [legendShowing]="isLegendShowing"
    [enableXlsxExport]="enableXlsxExport"
    (legendToggle)="onLegendToggle()"
  >
  </prosumer-chart-exporter>
  <canvas [id]="id" [attr.data-testid]="id"></canvas>
  <prosumer-custom-legend
    *ngIf="isLegendShowing"
    [details]="legendDetails$ | ngrxPush"
    (mouseclickItem)="onLegendDataToggle($event)"
  ></prosumer-custom-legend>
</div>
