import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ProjectDurationDetectorDirective } from './project-duration-detector.directive';

@NgModule({
  declarations: [ProjectDurationDetectorDirective],
  imports: [CommonModule],
  exports: [ProjectDurationDetectorDirective],
})
export class ProjectDurationDetectorModule {}
