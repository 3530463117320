<prosumer-stacked-bar-chartjs
  [data]="chartData$ | async"
  [colors]="colors"
  [id]="'total-emissions-stacked-bar'"
  [emitLegendClicks]="false"
  [xAxisName]="'Result.labels.years' | translate"
  [yAxisName]="'Result.labels.kgCo2' | translate"
  scenarioName="{{ scenarioName }}"
  resultsName="Total Emissions per Year"
></prosumer-stacked-bar-chartjs>
