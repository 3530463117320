import { ActiveKeeperService } from 'prosumer-app/services/active-keeper';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, mergeMap } from 'rxjs/operators';

import { HasLegacyProfiles, ProfileStore } from '../profile';
import { DetailEntity, Upserter } from '../scenario-detail';
import { CreatableParents } from './creator.models';
import { mapSickProfiles } from 'prosumer-app/shared';

export abstract class WithProfilesCreator<T extends Partial<HasLegacyProfiles>>
  implements Upserter
{
  private readonly loading = new BehaviorSubject<boolean>(false);

  constructor(
    readonly profiles: ProfileStore,
    readonly keeper: ActiveKeeperService,
  ) {}

  abstract createEntity(data: T): Observable<unknown>;
  abstract updateEntity(data: T): Observable<unknown>;

  selectLoading(): Observable<boolean> {
    return this.loading.asObservable();
  }

  create(data: T): Observable<unknown> {
    this.loading.next(true);
    data = mapSickProfiles(data);
    return this.forkJoinProfiles(data).pipe(
      mergeMap(() => this.createEntity(data)),
      finalize(() => this.loading.next(false)),
    );
  }

  edit(id: string, data: DetailEntity): Observable<unknown> {
    return this.update({ ...data, id } as unknown as T);
  }

  update(data: T): Observable<unknown> {
    this.loading.next(true);
    data = mapSickProfiles(data);
    return this.forkJoinProfiles(data).pipe(
      mergeMap(() => this.updateEntity(data)),
      finalize(() => this.loading.next(false)),
    );
  }

  private forkJoinProfiles(data: T): Observable<unknown> {
    return this.profiles.legacyCreateByBatch(
      data as HasLegacyProfiles,
      this.buildParentsFromActive(),
    );
  }

  private buildParentsFromActive(): CreatableParents {
    const entities = this.keeper.getActiveEntities();
    return {
      caseId: entities.case,
      scenarioId: entities.scenario,
      projectId: entities.project,
    };
  }
}
