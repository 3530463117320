import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { ExportTableDirectiveModule } from 'prosumer-shared/directives/export-table';
import { ResultsTabulatorComponent } from './results-tabulator.component';

@NgModule({
  declarations: [ResultsTabulatorComponent],
  imports: [
    CommonModule,
    MatTableModule,
    TranslateModule,
    FlexLayoutModule,
    MatMenuModule,
    MatIconModule,
    MatSortModule,
    ExportTableDirectiveModule,
  ],
  exports: [ResultsTabulatorComponent],
})
export class ResultsTabulatorModule {}
