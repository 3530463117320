import { Directive, Input, OnInit } from '@angular/core';
import { ResultsData } from '@prosumer/results/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  PerceptionMap,
  ResultsPerceptionService,
} from '../case-results-perception';
import {
  ResultsVisualizationType,
  VisualizerData,
} from './results-visualizer.model';
import { ResultsVisualizerService } from './results-visualizer.service';

@Directive()
export abstract class ResultsVisualizerController<
  T extends ResultsData = ResultsData,
> implements OnInit
{
  @Input() caseId: string;
  @Input() scenarioName: string;
  @Input() tableName: string;

  private localService: ResultsVisualizerService<T>;
  private localPerceptionService: ResultsPerceptionService;

  activeType: ResultsVisualizationType;
  incremental$: Observable<VisualizerData[]>;
  cumulative$: Observable<VisualizerData[]>;
  caseColorMap$: Observable<PerceptionMap>;
  selectedSliceSubject$ = new BehaviorSubject<string>(undefined);

  get selectedSlice(): string {
    return this.selectedSliceSubject$.value;
  }

  abstract getLegendNames$(): Observable<string[]>;

  constructor(
    service: ResultsVisualizerService<T>,
    perceptionService?: ResultsPerceptionService,
  ) {
    this.localService = service;
    this.localPerceptionService = perceptionService;
  }

  ngOnInit(): void {
    this.incremental$ = this.localService.getIncrementalDataStream();
    this.cumulative$ = this.localService.getCumulativeDataStream();

    this.caseColorMap$ = this.localPerceptionService?.getPerceptionMapStream(
      this.caseId,
    );
    this.subscribeToResultsDataStreamForLegendNamesFeeding();
  }

  onTypeChange(type: ResultsVisualizationType): void {
    this.activeType = type;
  }

  onSliceSelect(slice: string): void {
    this.selectedSliceSubject$.next(slice);
  }

  onLegendSelect(name: string): void {
    this.localService.updateSecondaryFilter(name);
  }

  private subscribeToResultsDataStreamForLegendNamesFeeding(): void {
    this.getLegendNames$()
      .pipe(take(1))
      .subscribe((data) =>
        this.localPerceptionService.registerLegendNames(this.caseId, data),
      );
  }
}
