<prosumer-comparison-visualizer
  (typeChange)="onTypeChange($event)"
  (sliceSelect)="onSliceSelect($event)"
>
  <prosumer-stacked-bar-chartjs
    [id]="'compare-equipment'"
    [data]="chartData$ | async"
    [colors]="caseColorMap$ | async"
    [yAxisName]="'Result.units.' + selectedSlice | translate"
    [xAxisName]="'Scenario.labels.simulationResults.tabs.equipment' | translate"
    resultsName="compare_equipment"
  ></prosumer-stacked-bar-chartjs>
</prosumer-comparison-visualizer>
