<prosumer-dialog-div
  [formGroup]="duplicableForm"
  [loading]="loading$ | ngrxPush"
  [okLabel]="'Scenario.labels.general.duplicate' | translate"
  [disabled]="duplicableForm.pristine"
  (ok)="onOk()"
  (cancel)="onCancel()"
  title="Duplicate Energy Grid Connections Emissions"
  fxLayout="column"
>
  <mat-form-field appearance="outline" data-testid="marketSelector" fxFlex>
    <mat-label>{{
      'Scenario.labels.energyGridConnectionsEmissions.marketName' | translate
    }}</mat-label>
    <mat-select
      [placeholder]="
        'Scenario.placeholders.energyGridConnectionsEmissions.marketName'
          | translate
      "
      [hideSingleSelectionIndicator]="true"
      formControlName="marketName"
    >
      <mat-option
        *ngFor="let market of markets$ | ngrxPush"
        [value]="market.id"
      >
        {{ market.marketName }}
      </mat-option>
    </mat-select>
    <mat-error
      [innerHTML]="
        'Scenario.messages.connectionsEmissions.alreadyExist' | translate
      "
    ></mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline" data-testid="scopeSelector" fxFlex>
    <mat-label>{{
      'Scenario.labels.energyGridConnectionsEmissions.scope' | translate
    }}</mat-label>
    <mat-select
      [placeholder]="
        'Scenario.placeholders.energyGridConnectionsEmissions.scope' | translate
      "
      [hideSingleSelectionIndicator]="true"
      formControlName="scope"
    >
      <mat-option *ngFor="let scope of scopes" [value]="scope.value">
        {{ scope.name }}
      </mat-option>
    </mat-select>
    <mat-error
      [innerHTML]="
        'Scenario.messages.connectionsEmissions.alreadyExist' | translate
      "
    ></mat-error>
  </mat-form-field>

  <mat-form-field
    appearance="outline"
    data-testid="nodeSelector"
    *ngIf="data.isMultiNode"
  >
    <mat-label>{{
      'Scenario.labels.energyGridConnectionsEmissions.node' | translate
    }}</mat-label>
    <mat-select
      [placeholder]="
        'Scenario.placeholders.energyGridConnectionsEmissions.node' | translate
      "
      [hideSingleSelectionIndicator]="true"
      formControlName="node"
    >
      <mat-option *ngFor="let node of nodes$ | ngrxPush" [value]="node.value">
        {{ node.name }}
      </mat-option>
    </mat-select>
    <mat-error
      [innerHTML]="
        'Scenario.messages.connectionsEmissions.alreadyExist' | translate
      "
    ></mat-error>
  </mat-form-field>
</prosumer-dialog-div>
