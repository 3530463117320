import { Utils } from 'prosumer-core/utils';
import { PieMeta } from 'prosumer-shared/modules/chartjs/pie-chartjs/pie-chartjs.model';
import {
  BarDatum,
  StackedBarMeta,
} from 'prosumer-shared/modules/chartjs/stacked-bar-chartjs';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { Component, Input, OnInit } from '@angular/core';

import {
  PerceptionMap,
  ResultsPerceptionService,
} from '../case-results-perception';
import { EmissionsDirection } from '../emissions-direction';
import { ResultsTabulatorData } from '../results-tabulator';
import {
  ResultNameValue,
  VisualizerData,
  VisualizerFilter,
} from '../results-visualizer';
import { EnergyBalanceResultsService } from './energy-balance-results.service';

interface EnergyBalanceMetadata {
  readonly total$: Observable<number>;
  readonly table$: Observable<ResultsTabulatorData[]>;
  readonly pie$: Observable<PieMeta>;
  readonly bar$: Observable<StackedBarMeta>;
  readonly direction: EmissionsDirection;
}

@Component({
  selector: 'prosumer-energy-balance-results',
  templateUrl: './energy-balance-results.component.html',
  styleUrls: ['./energy-balance-results.component.scss'],
  providers: [EnergyBalanceResultsService],
})
export class EnergyBalanceResultsComponent implements OnInit {
  private readonly direction = new BehaviorSubject<EmissionsDirection>(
    EmissionsDirection.production,
  );
  @Input() caseId: string;
  @Input() scenarioName: string;

  readonly metadata$: Observable<EnergyBalanceMetadata>;

  filters$: Observable<VisualizerFilter[]>;
  productionTotal$: Observable<number>;
  consumptionTotal$: Observable<number>;
  productionTable$: Observable<ResultsTabulatorData[]>;
  consumptionTable$: Observable<ResultsTabulatorData[]>;
  yearsToShow$: Observable<number[]>;

  colorMap$: Observable<PerceptionMap>;
  consumptionPie$: Observable<PieMeta>;
  productionPie$: Observable<PieMeta>;
  productionBar$: Observable<StackedBarMeta>;
  consumptionBar$: Observable<StackedBarMeta>;

  constructor(
    private service: EnergyBalanceResultsService,
    private perceptionService: ResultsPerceptionService,
  ) {
    this.metadata$ = this.selectMetadataBasedOnDirection();
  }

  ngOnInit(): void {
    this.filters$ = this.service.getAvailableFiltersStream();
    this.productionBar$ = this.getBarStream(
      this.service.getProductionBarStream(),
    );
    this.productionPie$ = this.getPieStream(
      this.service.getProductionPieStream(),
    );
    this.productionTotal$ = this.service.getTotalProduction$();
    this.productionTable$ = this.getProductionTableStream();

    this.consumptionBar$ = this.getBarStream(
      this.service.getConsumptionBarStream(),
    );
    this.consumptionPie$ = this.getPieStream(
      this.service.getConsumptionPieStream(),
    );
    this.consumptionTotal$ = this.service.getTotalConsumption$();
    this.consumptionTable$ = this.getConsumptionTableStream();

    this.colorMap$ = this.perceptionService.getPerceptionMapStream(this.caseId);
    this.yearsToShow$ = this.service.getAllYearsStream();

    this.quicklySubscribeToDataStreamsForPerceptionRegistration();
  }

  onFilterChange(name: string, active: string[]): void {
    this.service.setActiveFilter(name, active);
  }

  onDirectionChange(direction: EmissionsDirection): void {
    this.direction.next(direction);
  }

  private selectMetadataBasedOnDirection(): Observable<EnergyBalanceMetadata> {
    return this.direction
      .asObservable()
      .pipe(map((direction) => this.determineMetadataFromDirection(direction)));
  }

  private determineMetadataFromDirection(
    direction: EmissionsDirection,
  ): EnergyBalanceMetadata {
    return direction === EmissionsDirection.production
      ? this.buildProductionMetadata()
      : this.buildConsumptionMetadata();
  }

  private buildProductionMetadata(): EnergyBalanceMetadata {
    return {
      bar$: this.getBarStream(this.service.getProductionBarStream()),
      pie$: this.getPieStream(this.service.getProductionPieStream()),
      table$: this.getProductionTableStream(),
      total$: this.service.getTotalProduction$(),
      direction: EmissionsDirection.production,
    };
  }

  private buildConsumptionMetadata(): EnergyBalanceMetadata {
    return {
      bar$: this.getBarStream(this.service.getConsumptionBarStream()),
      pie$: this.getPieStream(this.service.getConsumptionPieStream()),
      table$: this.getConsumptionTableStream(),
      total$: this.service.getTotalConsumption$(),
      direction: EmissionsDirection.consumption,
    };
  }

  private getBarStream(
    bar$: Observable<VisualizerData[]>,
  ): Observable<StackedBarMeta> {
    return bar$.pipe(
      filter((bar) => !!bar[0]),
      map(
        (bar) =>
          ({
            axisTicks: Utils.removeDuplicates(
              bar[0].series.map((siri) => siri.name),
            ),
            data: this.mapVisualizerDataToStackedBarMetaData(bar),
          }) as StackedBarMeta,
      ),
    );
  }

  private mapVisualizerDataToStackedBarMetaData(
    data: VisualizerData[],
  ): BarDatum[] {
    return data.map((b) => ({
      name: b.name,
      values: b.series.reduce((acc, curr) => {
        acc[curr.name] = curr.value;
        return acc;
      }, {}),
    }));
  }

  private getPieStream(
    pie$: Observable<ResultNameValue[]>,
  ): Observable<PieMeta> {
    return pie$.pipe(map((slices) => ({ slices })));
  }
  private getConsumptionTableStream(): Observable<ResultsTabulatorData[]> {
    return this.consumptionBar$.pipe(map((data) => data.data));
  }

  private getProductionTableStream(): Observable<ResultsTabulatorData[]> {
    return this.productionBar$.pipe(map((data) => data.data));
  }

  private quicklySubscribeToDataStreamsForPerceptionRegistration(): void {
    combineLatest([this.getConsumptionNames(), this.getProductionNames()])
      .pipe(
        map(([production, consumption]) =>
          Utils.removeDuplicates([...production, ...consumption]),
        ),
        take(1),
      )
      .subscribe((names) =>
        this.perceptionService.registerLegendNames(this.caseId, names),
      );
  }

  private getConsumptionNames(): Observable<string[]> {
    return this.service
      .getConsumptionPieStream()
      .pipe(map((results) => results.map((result) => result.name)));
  }

  private getProductionNames(): Observable<string[]> {
    return this.service
      .getProductionPieStream()
      .pipe(map((results) => results.map((result) => result.name)));
  }
}
