import { Injectable } from '@angular/core';
import { ActiveKeeperService } from 'prosumer-app/services/active-keeper';
import { Observable } from 'rxjs';
import { WithProfilesCreator } from '../creator';
import { ProfileStore } from '../profile';
import { SpinningReserveMargin } from './spinning-reserve-margin.state';
import { SpinningReserveMarginStore } from './spinning-reserve-margin.store';

@Injectable({
  providedIn: 'root',
})
export class ReserveMarginCreator extends WithProfilesCreator<SpinningReserveMargin> {
  constructor(
    private readonly store: SpinningReserveMarginStore,
    readonly profiles: ProfileStore,
    readonly keeper: ActiveKeeperService,
  ) {
    super(profiles, keeper);
  }

  createEntity(data: SpinningReserveMargin): Observable<unknown> {
    return this.store.create(data);
  }
  updateEntity(data: SpinningReserveMargin): Observable<unknown> {
    return this.store.edit(data.id, data);
  }
}
