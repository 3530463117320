import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { CustomLegendComponent } from './custom-legend.component';

@NgModule({
  declarations: [CustomLegendComponent],
  imports: [
    MatCheckboxModule,
    CommonModule,
    MatMenuModule,
    MatIconModule,
    FlexLayoutModule,
  ],
  exports: [CustomLegendComponent],
})
export class CustomLegendModule {}
