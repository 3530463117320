import { Coerce } from 'prosumer-app/core/utils';
import {
  EGCEmission,
  EGCEmissionIdentifier,
} from 'prosumer-app/stores/egc-emission';
import {
  MarketLimit,
  MarketLimitIdentifier,
} from 'prosumer-app/stores/market-limit';
import { Duplicable, HasName } from 'prosumer-app/stores/scenario-detail';

import { AbstractControl, ValidatorFn } from '@angular/forms';

export class DuplicationValidators {
  static uniqueEGCEmission(existing: EGCEmission[]): ValidatorFn {
    return (control: AbstractControl) =>
      DuplicationValidators.isEGCEmissionUnique(control.value, existing)
        ? null
        : { duplicateEGCEmission: true };
  }

  static uniqueCombination(existing: unknown[]): ValidatorFn {
    return (control: AbstractControl) =>
      DuplicationValidators.isCombinationUnique(control.value, existing)
        ? null
        : { duplicateCombination: true };
  }

  static isMarketLimitUnique(
    value: MarketLimit,
    existing: MarketLimit[],
  ): boolean {
    const existingInIdentifiers =
      DuplicationValidators.toMarketLimitIdentifiers(existing);
    return DuplicationValidators.isMarketLimitCombinationUnique(
      value,
      existingInIdentifiers,
    );
  }

  static isMarketLimitCombinationUnique(
    value: MarketLimit,
    existing: MarketLimit[],
  ): boolean {
    for (const existingValue of existing) {
      if (value.market === existingValue.market) {
        if (value.nodes.toString() === 'ALL') {
          return false;
        }
        for (const node of value.nodes) {
          if (existingValue.nodes.includes(node)) {
            return false;
          }
        }
      }
    }
    return true;
  }

  static isEGCEmissionUnique(
    value: EGCEmission,
    existing: EGCEmission[],
  ): boolean {
    const existingInIdentifiers = DuplicationValidators.toIdentifiers(existing);
    return DuplicationValidators.isCombinationUnique(
      value,
      existingInIdentifiers,
    );
  }

  private static toIdentifiers(from: EGCEmission[]): EGCEmissionIdentifier[] {
    return Coerce.toArray(from).map((item) =>
      DuplicationValidators.pickEGCEmissionIDKeys(item),
    );
  }

  private static toMarketLimitIdentifiers(
    from: MarketLimit[],
  ): MarketLimitIdentifier[] {
    return Coerce.toArray(from).map((item) =>
      DuplicationValidators.pickEGCLimitIDKeys(item),
    );
  }

  private static pickEGCEmissionIDKeys(
    from: EGCEmission,
  ): EGCEmissionIdentifier {
    return { marketName: from.marketName, node: from.node, scope: from.scope };
  }

  private static pickEGCLimitIDKeys(from: MarketLimit): MarketLimitIdentifier {
    return { market: from.market, nodes: from.nodes };
  }

  private static isCombinationUnique(
    value: unknown,
    existing: unknown[],
  ): boolean {
    return !DuplicationValidators.existingToCombinations(existing).includes(
      DuplicationValidators.buildCombination(value),
    );
  }

  private static existingToCombinations(existing: unknown[]): string[] {
    return Coerce.toArray(existing).map((item) =>
      DuplicationValidators.buildCombination(item),
    );
  }

  private static buildCombination(value: unknown): string {
    return Object.values(value).sort().join('_');
  }

  static uniqueName(existing: HasName[]): ValidatorFn {
    return (control: AbstractControl) =>
      DuplicationValidators.isUniqueName(existing, control.value)
        ? null
        : { duplicateName: true };
  }

  static uniqueNameAndVariation(existing: Duplicable[]): ValidatorFn {
    return (control: AbstractControl) =>
      DuplicationValidators.isNameAndVariationUnique(existing, control.value)
        ? null
        : { duplicateNameAndVariation: true };
  }

  static isValid(value: Duplicable, existing: Duplicable[]): boolean {
    return [
      this.isNameTruthy(value),
      DuplicationValidators.isNameAndVariationUnique(existing, value),
    ].every(Boolean);
  }

  static isNameAndVariationUnique(
    existing: Duplicable[],
    value: Duplicable,
  ): boolean {
    return !DuplicationValidators.combineNameAndVariations(existing).includes(
      DuplicationValidators.combineNameAndVariation(value),
    );
  }

  private static isNameTruthy(dupicable: Duplicable): boolean {
    return !!dupicable.name;
  }

  private static combineNameAndVariations(data: Duplicable[]): string[] {
    return data.map((item) =>
      DuplicationValidators.combineNameAndVariation(item),
    );
  }

  private static combineNameAndVariation(data: Duplicable): string {
    return `${data.name}_${DuplicationValidators.decideVariationName(data)}`;
  }

  private static isUniqueName(existing: HasName[], name: string): boolean {
    return !existing.find((item) => item.name === name);
  }

  private static decideVariationName(data: Duplicable): string {
    if (!data.scenarioVariation) {
      return 'basecase';
    } else {
      return data.scenarioVariation;
    }
  }
}
