import { Injectable } from '@angular/core';
import { EquipmentResult } from '@prosumer/results/models';
import { ResultsExtractorService } from '@prosumer/results/state';

import { Observable } from 'rxjs';

import { FlattenedSlicesStrategy } from '../bar-and-area-results';
import { ResultVisualizerSlice } from '../results-visualizer';

@Injectable()
export class EquipmentResultsService extends FlattenedSlicesStrategy<EquipmentResult> {
  constructor(private results: ResultsExtractorService) {
    super();
  }

  getSlices(): ResultVisualizerSlice[] {
    return [
      {
        name: 'size_kw',
        unit: 'kW',
        tooltip: 'output_equipment.output_equipment_size_kw',
      },
      {
        name: 'size_kwh',
        unit: 'kWh',
        tooltip: 'output_equipment.output_equipment_size_kwh',
      },
      {
        name: 'invest_cost',
        unit: 'k€',
        tooltip: 'output_equipment.output_equipment_inv_cost',
      },
      {
        name: 'oper_cost',
        unit: 'k€',
        tooltip: 'output_equipment.output_equipment_om_cost',
      },
      {
        name: 'total_cost',
        unit: 'k€',
        tooltip: 'output_equipment.output_equipment_total_cost',
      },
      {
        name: 'yearly_cons',
        unit: 'kWh',
        tooltip: 'output_equipment.output_equipment_consumption',
      },
      {
        name: 'yearly_prod',
        unit: 'kWh',
        tooltip: 'output_equipment.output_equipment_production',
      },
    ];
  }
  getYearsToOptimizeStream(): Observable<number[]> {
    return this.results.getOptimizedYearsStream();
  }
  getFilterKeys(): string[] {
    return ['node', 'type', 'input_fluid', 'output_fluid'];
  }
  getResultDataStream(): Observable<EquipmentResult[]> {
    return this.results.getEquipmentStream();
  }
  getAllYearsStream(): Observable<number[]> {
    return this.results.getAllYearsStream();
  }
}
