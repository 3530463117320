import { EnergyVectorCircuitInfo } from 'prosumer-app/+scenario/models';
import { DialogService } from 'prosumer-app/libs/eyes-core';
import { ColumnDefinition, rowAnimation } from 'prosumer-app/libs/eyes-shared';
import { ReferencesBuilder } from 'prosumer-app/services/references-builder';
import { PowerNeeded } from 'prosumer-app/stores/power-needed/power-needed.state';
import { FrequencyControlFormService } from 'prosumer-scenario/components/frequency-control-form/frequency-control-form.service';
import { OptionsForPowerNeededDialogData } from 'prosumer-scenario/components/frequency-control-form/frequency-control-form.types';
import {
  ScPowerNeededFormDialogComponent,
  SCPowerNeededFormDialogData,
} from 'prosumer-scenario/components/frequency-control-form/short-circuit/sc-power-needed-form-dialog';
import { ManagedDataService } from 'prosumer-shared/services/managed-data/managed-data.service';
import { convertToYearlyValues } from 'prosumer-shared/utils';
import { take } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

type CommondDialogData = OptionsForPowerNeededDialogData & { width: number };

@Component({
  selector: 'prosumer-sc-power-needed-table',
  templateUrl: './sc-power-needed-table.component.html',
  styleUrls: ['./sc-power-needed-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ManagedDataService],
  animations: [rowAnimation],
})
export class SCPowerNeededTableComponent {
  readonly columnsDef: ColumnDefinition = {
    energyVectorId: {
      name: 'Energy Vector',
      type: 'reference',
      referenceKey: 'energyVectors',
      sortable: true,
      toolTip: 'wizard_frequency_control.short_circuit_energy_vector',
    },
    neededPower: {
      name: 'Power Needed [kW]',
      type: 'chart',
      toolTip: 'wizard_frequency_control.short_circuit_power_needed',
    },
    placeholder1: {},
    placeholder2: {},
    placeholder3: {},
    actions: {
      name: 'Actions',
      type: 'action',
    },
  };

  @Input() energyVectors: unknown[] = [];

  @Input() startYear: number;
  @Input() endYear: number;

  @Input() hideAdd: boolean;
  @Input() hideEdit: boolean;
  @Input() hideDelete: boolean;
  @Input() hideView: boolean;

  readonly powers$ = this.service.selectPowersNeeded();
  readonly references$ = this.references.selectRefs();

  constructor(
    public dialogService: DialogService,
    private readonly service: FrequencyControlFormService,
    private readonly references: ReferencesBuilder,
  ) {}

  onAdd() {
    this.dialogService.openDialog(ScPowerNeededFormDialogComponent, {
      ...this.getCommonData(),
      mode: 'add',
      startYear: this.startYear,
      endYear: this.endYear,
      powerNeeded: {
        energyVectorId: '',
        neededPower: convertToYearlyValues(
          '0.00',
          this.startYear,
          this.endYear,
        ),
      },
      disableClose: true,
    } as SCPowerNeededFormDialogData);
  }

  onViewEdit(data: EnergyVectorCircuitInfo) {
    this.dialogService.openDialog(ScPowerNeededFormDialogComponent, {
      ...this.getCommonData(),
      mode: this.getViewEditMode(),
      powerNeeded: data,
      startYear: this.startYear,
      endYear: this.endYear,
      disableClose: true,
    } as SCPowerNeededFormDialogData);
  }

  private getCommonData(): CommondDialogData {
    return {
      width: 600,
      ...this.service.prepOptionsForPowerNeededDialog(),
    };
  }

  onDelete(data: PowerNeeded) {
    this.service.deletePowerNeeded(data.id).pipe(take(1)).subscribe();
  }

  private getViewEditMode = (): string => (this.hideView ? 'edit' : 'view');
}
