import { Injectable } from '@angular/core';
import { ActiveKeeperService } from 'prosumer-app/services/active-keeper';
import { ScenarioDetailQuery } from '../scenario-detail';
import { MobilityStation } from './mobility-station.state';
import { MobilityStationStore } from './mobility-station.store';

@Injectable({ providedIn: 'root' })
export class MobilityStationQuery extends ScenarioDetailQuery<MobilityStation> {
  constructor(
    readonly store: MobilityStationStore,
    readonly keeper: ActiveKeeperService,
  ) {
    super(store, keeper);
  }
}
