<div
  fxLayout="column"
  fxLayoutAlign="start stretch"
  fxLayoutGap="16px"
  class="energy-balance-container"
>
  <!-- feel hurts -->
  <div
    fxFlex
    fxLayout="row wrap"
    fxLayoutAlign="start start"
    fxLayoutGap="16px"
  >
    <prosumer-chips
      fxFlex
      *ngFor="let filter of filters$ | async"
      [label]="
        ('Result.labels.filterBy' | translate) +
        ' ' +
        ('Result.labels.' + filter?.label | translate)
      "
      [options]="filter?.options"
      (chips)="onFilterChange(filter?.label, $event)"
      [attr.data-testid]="
        'energy-balance-filter-' +
        ('Result.labels.' + filter?.label | translate)
      "
    ></prosumer-chips>
  </div>

  <div fxLayout="column" *ngrxLet="metadata$ as metadata">
    <!-- direction toggle and sigma freud -->
    <div fxLayout="row" fxLayoutGap="16px">
      <prosumer-emissions-direction
        (directionChange)="onDirectionChange($event)"
      >
      </prosumer-emissions-direction>
      <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px">
        <span class="balance-total-caption">∑</span>
        <div
          fxLayout="row"
          fxLayoutAlign="end end"
          fxLayoutGap="4px"
          *ngrxLet="metadata.total$ as sum"
        >
          <span
            class="balance-total-value"
            [class.color-prod]="metadata.direction === 'production'"
            [class.color-con]="metadata.direction === 'consumption'"
          >
            {{ sum | number: '1.0-0' }}
          </span>
          <span class="balance-total-caption">{{
            'Result.units.energyBalance' | translate
          }}</span>
        </div>
      </div>
    </div>

    <div
      fxLayout="column"
      fxLayoutGap="16px"
      class="process-card"
      [class.bg-prod]="metadata.direction === 'production'"
      [class.bg-con]="metadata.direction === 'consumption'"
    >
      <!-- pie and bar -->
      <div
        fxLayout="row"
        fxLayoutAlign="space-between stretch"
        fxLayoutGap="32px"
      >
        <prosumer-pie-chartjs
          fxFlex="30"
          [id]="'productionPie'"
          [colors]="colorMap$ | ngrxPush"
          [data]="metadata.pie$ | ngrxPush"
          [emitLegendClicks]="false"
        ></prosumer-pie-chartjs>
        <prosumer-stacked-bar-chartjs
          fxFlex
          [id]="'productionBar'"
          [colors]="colorMap$ | ngrxPush"
          [data]="metadata.bar$ | ngrxPush"
          [emitLegendClicks]="false"
          [xAxisName]="'Result.labels.years' | translate"
          [yAxisName]="'Result.labels.kWh' | translate"
        ></prosumer-stacked-bar-chartjs>
      </div>
      <prosumer-results-tabulator
        [data]="metadata.table$ | ngrxPush"
        [years]="yearsToShow$ | ngrxPush"
        exportName="{{
          scenarioName +
            '_' +
            ('Scenario.labels.simulationResults.tabs.energyBalance' | translate)
        }}"
      ></prosumer-results-tabulator>
    </div>
  </div>
</div>
