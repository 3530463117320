import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { EnergyGridLimitInterval } from 'prosumer-app/+scenario/models';
import { BaseComponent } from 'prosumer-app/libs/eyes-shared';
import { EnergyGridLimitIntervalDialogModel } from './energy-grid-limit-interval-dialog.model';

@Component({
  selector: 'prosumer-energy-grid-limit-interval-dialog',
  templateUrl: './energy-grid-limit-interval-dialog.component.html',
  styleUrls: ['./energy-grid-limit-interval-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnergyGridLimitIntervalDialogComponent
  extends BaseComponent
  implements OnInit
{
  form: UntypedFormGroup;
  disableAdd: boolean;

  get intervalsFormArray(): UntypedFormArray {
    return this.form.get('intervals') as UntypedFormArray;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EnergyGridLimitIntervalDialogModel,
    public dialogRef: MatDialogRef<EnergyGridLimitIntervalDialogModel>,
    public formBuilder: UntypedFormBuilder,
  ) {
    super();

    if (this.data.intervals && this.data.intervals.length > 0) {
      this.initForm(this.data.intervals);
    } else if (this.data.startYear && this.data.endYear) {
      this.initForm([
        {
          startYear: this.data.startYear,
          endYear: this.data.endYear,
        },
      ]);
    } else {
      this.initForm();
    }

    this.intervalsFormArray.patchValue(
      this.generateIntervalsWithOptions(
        this.intervalsFormArray.getRawValue(),
        this.data.startYear,
        this.data.endYear,
      ),
    );
  }

  ngOnInit() {
    this.checkLastIfEndYear();
  }

  initForm(intervals?: Array<EnergyGridLimitInterval>): void {
    this.form = this.createMainForm(intervals);
  }

  createMainForm(intervals?: Array<EnergyGridLimitInterval>): UntypedFormGroup {
    return this.formBuilder.group({
      intervals: this.createIntervalsFormArray(intervals),
    });
  }

  createIntervalsFormArray(
    intervals?: Array<EnergyGridLimitInterval>,
  ): UntypedFormArray {
    const intervalsFormArray = this.formBuilder.array([]);

    if (!!intervals) {
      intervals.forEach((interval) =>
        intervalsFormArray.push(
          this.createIntervalForm(
            interval.startYear,
            interval.endYear,
            interval.minPower,
            interval.maxPower,
            interval.minEnergy,
            interval.maxEnergy,
            interval.minCapacity,
            interval.maxCapacity,
          ),
        ),
      );
    }

    return intervalsFormArray;
  }

  createIntervalForm(
    startYear: number,
    endYear: number,
    minPower?: string,
    maxPower?: string,
    minEnergy?: string,
    maxEnergy?: string,
    minCapacity?: string,
    maxCapacity?: string,
  ): UntypedFormGroup {
    const intervalForm = this.formBuilder.group({
      startYear: this.formBuilder.control({
        value: '',
        disabled: startYear === this.data.startYear,
      }),
      endYear: this.formBuilder.control({ value: '', disabled: true }),
      yearOptions: this.formBuilder.control(''),
      minPower: this.formBuilder.control(''),
      maxPower: this.formBuilder.control(''),
      minEnergy: this.formBuilder.control(''),
      maxEnergy: this.formBuilder.control(''),
      minCapacity: this.formBuilder.control(''),
      maxCapacity: this.formBuilder.control(''),
    });

    intervalForm.patchValue({
      startYear,
      endYear,
      minPower,
      maxPower,
      minEnergy,
      maxEnergy,
      minCapacity,
      maxCapacity,
    });

    return intervalForm;
  }

  generateIntervalsWithOptions(
    intervals: Array<EnergyGridLimitInterval>,
    startYear: number,
    endYear: number,
  ) {
    const intervalsWithOptions: Array<EnergyGridLimitInterval> = [];
    intervals.forEach((interval, index) => {
      const refStartYear =
        index > 0 ? intervals[index - 1].startYear + 1 : startYear;
      intervalsWithOptions.push({
        ...interval,
        yearOptions: this.generateOptions(refStartYear, interval.endYear),
      });
    });
    return intervalsWithOptions;
  }

  adjustPreviousEndDate(
    intervals: Array<EnergyGridLimitInterval>,
    adjustedInterval: EnergyGridLimitInterval,
    adjustedIndex: number,
  ) {
    if (adjustedIndex > 0) {
      const previousIndex = adjustedIndex - 1;
      return intervals.map((interval, index) => {
        if (index === previousIndex) {
          return {
            ...interval,
            endYear: adjustedInterval.startYear - 1,
          };
        }
        return interval;
      });
    }
    return intervals;
  }

  generateOptions(startYear: number, endYear: number): Array<number> {
    return Array.from(Array(endYear - startYear + 1)).map(
      (_, index) => startYear + index,
    );
  }

  checkLastIfEndYear(): void {
    if (!!this.intervalsFormArray) {
      const currentInterval = this.intervalsFormArray.at(
        this.intervalsFormArray.length - 1,
      );
      if (!!currentInterval) {
        this.disableAdd =
          this.intervalsFormArray.at(this.intervalsFormArray.length - 1).value
            .startYear === this.data.endYear;
      }
    }
  }

  onAddInterval(): void {
    const intervals: Array<EnergyGridLimitInterval> =
      this.intervalsFormArray.getRawValue();
    const lastInterval = [...intervals].pop();
    const newIntervalFormGroup = this.createIntervalForm(
      lastInterval.startYear + 1,
      lastInterval.endYear,
    );
    const newInterval = newIntervalFormGroup.getRawValue();
    intervals.push(newInterval);

    this.intervalsFormArray.push(newIntervalFormGroup);
    this.intervalsFormArray.patchValue(
      this.generateIntervalsWithOptions(
        this.adjustPreviousEndDate(
          intervals,
          newInterval,
          intervals.length - 1,
        ),
        this.data.startYear,
        this.data.endYear,
      ),
    );
    this.intervalsFormArray.markAsDirty();
    this.checkLastIfEndYear();
  }

  onChangeInterval(
    changedInterval: EnergyGridLimitInterval,
    index: number,
  ): void {
    this.intervalsFormArray.patchValue(
      this.generateIntervalsWithOptions(
        this.adjustPreviousEndDate(
          this.intervalsFormArray.getRawValue(),
          changedInterval,
          index,
        ),
        this.data.startYear,
        this.data.endYear,
      ),
    );
    this.checkLastIfEndYear();
  }

  onDeleteInterval(
    toDeleteInterval: EnergyGridLimitInterval,
    index: number,
  ): void {
    const intervals: Array<EnergyGridLimitInterval> =
      this.intervalsFormArray.getRawValue();

    let refInterval: EnergyGridLimitInterval;
    if (index !== this.intervalsFormArray.length - 1) {
      refInterval = intervals[index + 1];
    } else {
      refInterval = { ...toDeleteInterval, startYear: this.data.endYear + 1 };
    }

    this.intervalsFormArray.removeAt(index);
    this.intervalsFormArray.patchValue(
      this.generateIntervalsWithOptions(
        this.adjustPreviousEndDate(
          this.intervalsFormArray.getRawValue(),
          refInterval,
          index,
        ),
        this.data.startYear,
        this.data.endYear,
      ),
    );
    this.intervalsFormArray.markAsDirty();
    this.checkLastIfEndYear();
  }

  onConfirm(): void {
    if (this.intervalsFormArray.valid) {
      this.dialogRef.close(this.intervalsFormArray.getRawValue());
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
