import {
  ENGIE_DEFAULT_SCHEME,
  generateShadedScheme,
} from 'prosumer-app/app.references';
import {
  BaseComponent,
  contains,
  InversePipe,
  JsonKeyToLabelPipe,
  KiloPipe,
} from 'prosumer-app/libs/eyes-shared';
import { ResultStore } from 'prosumer-app/stores';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import {
  addBallotBox,
  removeBallotBox,
  updateLegendFilter,
} from '../shared/helper';

@Component({
  selector: 'prosumer-cash-flow',
  templateUrl: './cash-flow.component.html',
  styleUrls: ['./cash-flow.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CashFlowComponent extends BaseComponent implements OnInit {
  data: Array<any> = [];
  tooltipData: Array<any> = [];
  readonly chartID = 'cashflow';

  highContrastScheme = ENGIE_DEFAULT_SCHEME;
  legendFilter$ = new BehaviorSubject<Array<string>>([]);

  constructor(
    private _resultStore: ResultStore,
    private _kiloPipe: KiloPipe,
    private _inversePipe: InversePipe,
    private _jsonKeyToLabelPipe: JsonKeyToLabelPipe,
  ) {
    super();
  }

  ngOnInit() {
    combineLatest([
      this._resultStore.cashflow$.pipe(take(1)),
      this._resultStore.chartData$(this.chartID).pipe(take(1)),
      this.legendFilter$,
    ])
      .pipe(
        takeUntil(this.componentDestroyed$),
        map((combinedData) => ({
          fresh: combinedData[0],
          cached: combinedData[1],
          filter: combinedData[2],
        })),
      )
      .subscribe(({ cached, fresh, filter }) => {
        if (cached) {
          this.data = cached.map((c) => ({
            ...c,
            series: c.series.map((s) => ({
              name: addBallotBox(filter, removeBallotBox(s.name)),
              value: contains(filter, removeBallotBox(s.name)) ? 0 : s.value,
            })),
          }));
        } else {
          const d = [];
          fresh.forEach((cf) => {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const formatted_series = [];
            cf['series'].forEach((serie) => {
              const name = this._jsonKeyToLabelPipe.transform(serie['name']);
              formatted_series.push({
                name: addBallotBox(filter, name),
                value: contains(filter, name)
                  ? 0
                  : Number(
                      this._inversePipe
                        .transform(this._kiloPipe.transform(serie['value']))
                        .toFixed(1),
                    ),
              });
            });

            d.push({
              name: cf['name'],
              series: formatted_series,
            });
          });
          this.data = d;
          this.highContrastScheme = generateShadedScheme(d.length);
          if (filter.length === 0) {
            this._resultStore.updateChartDataMapping(this.chartID, d);
          }
        }
      });
  }

  getSeriesMembers(seriesModel: any) {
    this.data.forEach((seriesObject) => {
      if (seriesObject['name'] === seriesModel.value.series) {
        this.tooltipData = seriesObject['series'];
        return;
      }
    });
  }

  onSelect(selected: string) {
    updateLegendFilter(this.legendFilter$, selected);
  }
}
