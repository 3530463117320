<form fxLayout="column" [formGroup]="converterForm">
  <div fxLayout="column">
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
        <mat-panel-title>
          <h3>
            {{ 'Scenario.labels.general.parameters' | translate }}
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="column">
        <div fxLayout="row" fxLayoutGap="10px">
          <!-- Converter Name -->
          <prosumer-input
            fxFlex="50%"
            module="Scenario"
            formFieldName="der.name"
            [attr.data-testid]="'converter-name'"
            [inputTooltip]="'wizard_converter.wizard_converter_name'"
            [control]="converterForm?.controls?.name"
            [required]="true"
          >
          </prosumer-input>
          <!-- Scenario Variation -->
          <prosumer-select
            fxFlex="50%"
            *ngIf="scenarioVariationOptions.length > 1"
            [placeholder]="
              'Scenario.placeholders.der.scenarioVariation' | translate
            "
            [label]="'Scenario.labels.der.scenarioVariation' | translate"
            [options]="scenarioVariationOptions"
            [control]="converterForm?.controls?.scenarioVariation"
            [errorMessageMap]="errorMessages['scenarioVariation']"
            data-testid="converter-variation"
          ></prosumer-select>
        </div>
        <!-- Node -->
        <prosumer-node-filterchip-component
          *ngIf="isMultiNode"
          [required]="true"
          [nodeOptions]="nodeOptions"
          formControlName="nodes"
          [errorMessage]="errorMessages?.node"
          [setSubmitted]="submitted$ | async"
          data-testid="converter-node"
        ></prosumer-node-filterchip-component>
        <prosumer-radio-button
          [isViewOnly]="isViewOnly"
          [label]="'Scenario.labels.general.source' | translate"
          [options]="sourceOptions"
          [control]="converterForm?.controls?.sourceType"
          [labelTooltip]="'wizard_converter.wizard_converter_source'"
          data-testid="source-options"
        ></prosumer-radio-button>
        <div>
          <prosumer-tdb-technology-filters
            *ngIf="converterForm?.controls?.sourceType?.value === 'tdb'"
            (loadingState)="handleTechnologyFiltersLoading($event)"
            (dataSelected)="handleTdbTechnologyData($event)"
            [extraDataQueryParams]="extraQueryParamsTDB"
            [technologyType]="derTypes.converter"
          ></prosumer-tdb-technology-filters>
        </div>
        <div
          *ngIf="converterForm?.controls?.sourceType?.value === 'library'"
          fxLayout="column"
        >
          <div
            *ngIf="(library$ | async)?.length > 0"
            fxLayout="row"
            fxLayoutGap="10px"
            fxLayoutAlign="space-around"
          >
            <prosumer-filter-chips
              fxFlex="19%"
              filterBy="id"
              filterTitle="ID"
              [dataSource]="library"
              (filters)="updateFilter($event, filterForm?.controls?.id)"
            ></prosumer-filter-chips>
            <prosumer-filter-chips
              fxFlex="19%"
              filterBy="value"
              [isReference]="true"
              referenceField="value"
              filterTitle="Input Energy Vector"
              [dataSource]="scenarioEnergyVectorOptions$ | ngrxPush"
              (filters)="
                updateFilter($event, filterForm?.controls?.inputEnergyVector)
              "
            ></prosumer-filter-chips>
            <prosumer-filter-chips
              fxFlex="19%"
              filterBy="value"
              [isReference]="true"
              referenceField="value"
              filterTitle="Output Energy Vector"
              [dataSource]="scenarioEnergyVectorOptions$ | ngrxPush"
              (filters)="
                updateFilter($event, filterForm?.controls?.outputEnergyVector)
              "
            ></prosumer-filter-chips>
            <div fxFlex="19%"></div>
            <div fxFlex="19%"></div>
          </div>

          <prosumer-sparkline-table
            maxHeight="275px"
            [isViewOnly]="isViewOnly"
            [columnsDef]="columnsDef"
            [data]="library"
            [selected]="selectedLibraryId$ | async"
            [loading]="libraryService.loading$ | async"
            [error]="libraryService.error$ | async"
            [messages]="messages"
            [hideAdd]="true"
            [hideEdit]="true"
            [hideDelete]="true"
            (selectionChanged)="onSelect($event)"
            data-testid="library-table"
          >
          </prosumer-sparkline-table>

          <!-- Using class 'hidden' will use a special mat-form-field to be used when displaying only the error message -->
          <mat-form-field class="hidden">
            <input matInput [formControl]="converterForm?.controls?.library" />
            <mat-error
              *ngIf="converterForm?.controls?.library?.errors?.required"
              [innerHTML]="'Scenario.messages.der.library.required' | translate"
            ></mat-error>
          </mat-form-field>
        </div>

        <span
          *ngIf="!!data.equipment.tdbTechnologyFilter"
          class="reference-info"
        >
          <span>*Reference: </span>
          <span class="data">{{
            data.equipment.tdbTechnologyFilter | referenceStr
          }}</span>
        </span>

        <!-- banner -->
        <prosumer-unsaved-entity-banner
          [entityList]="energyVectorsTemp$ | ngrxPush"
          (addButtonClicked)="onAssociateEVButtonClick($event)"
        ></prosumer-unsaved-entity-banner>

        <div class="effiency-matrix-container eyes-spacer-top-half" fxFlex>
          <!-- TODO -->
          <prosumer-efficiency-matrix
            formControlName="efficiencyMatrix"
            [isViewOnly]="isViewOnly"
            [energyVectorOptions]="eVOptionsWithTemp$ | ngrxPush"
            [startYear]="converterForm?.controls?.startYear?.value"
            [endYear]="converterForm?.controls?.endYear?.value"
            [overrideDeleteEnergyVector]="true"
            [confirmDeleteEnergyVector]="confirmDeleteEnergyVector$ | async"
            (inputEnergyVectorDeleted)="onInputEnergyVectorDelete($event)"
            (outputEnergyVectorDeleted)="onOutputEnergyVectorDelete($event)"
            (replaceEvSelection)="onReplaceEvSelection($event)"
          >
          </prosumer-efficiency-matrix>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
        <mat-panel-title>
          <h3>
            {{ 'Scenario.labels.technical.parameters' | translate }}
            <prosumer-tooltip-anchor
              [message]="
                'wizard_converter.wizard_converter_technical_parameters'
              "
              data-testid="technical-tooltip"
            ></prosumer-tooltip-anchor>
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="column">
        <div fxLayout="row" fxLayoutGap="10px">
          <prosumer-yearly-chart-input
            [formControl]="converterForm?.controls?.yearlyCapacityLoss"
            [startYear]="converterForm?.controls?.startYear?.value"
            [endYear]="converterForm?.controls?.endYear?.value"
            [errorMessage]="'Scenario.messages.der.capacityLoss'"
            [contextHelpMsg]="'wizard_converter.wizard_converter_capacity_loss'"
            [inputLabel]="'Scenario.labels.technical.capacityLoss' | translate"
            fxFlex="24%"
            data-testid="capacity-loss"
          ></prosumer-yearly-chart-input>
          <prosumer-yearly-chart-input
            *ngIf="isMultiNode"
            [formControl]="converterForm?.controls?.yearlyFootprint"
            [startYear]="converterForm?.controls?.startYear?.value"
            [endYear]="converterForm?.controls?.endYear?.value"
            [errorMessage]="'Scenario.messages.der.footprint'"
            [contextHelpMsg]="'wizard_converter.wizard_converter_footprint'"
            [inputLabel]="'Scenario.labels.technical.footprint' | translate"
            fxFlex="24%"
            data-testid="footprint"
          ></prosumer-yearly-chart-input>
          <div fxFlex="24%"></div>
          <div fxFlex="24%"></div>
        </div>
      </div>
    </mat-expansion-panel>

    <!-- Scope3 Emissions Parameters -->
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
        <mat-panel-title>
          <h3>
            {{ 'Scenario.labels.co2Emissions.parameters' | translate }}
            <prosumer-tooltip-anchor
              [message]="
                'wizard_converter.wizard_converter_scope3emissions_parameters'
              "
              data-testid="co2Emission-tooltip"
            ></prosumer-tooltip-anchor>
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="column">
        <div fxLayout="row" fxLayoutGap="10px">
          <!-- Build emissions kW -->
          <prosumer-yearly-chart-input
            [formControl]="converterForm?.controls?.yearlyBuildEmissionsKw"
            [startYear]="converterForm?.controls?.startYear?.value"
            [endYear]="converterForm?.controls?.endYear?.value"
            [errorMessage]="'Scenario.messages.der.buildEmissionsKw'"
            [inputLabel]="
              'Scenario.labels.co2Emissions.buildEmissionsKw' | translate
            "
            fxFlex="24%"
            [contextHelpMsg]=""
            data-testid="build-emission-kw"
          ></prosumer-yearly-chart-input>
          <!-- Build emissions indivisible -->
          <prosumer-yearly-chart-input
            [formControl]="
              converterForm?.controls?.yearlyBuildEmissionsIndivisible
            "
            [startYear]="converterForm?.controls?.startYear?.value"
            [endYear]="converterForm?.controls?.endYear?.value"
            [errorMessage]="'Scenario.messages.der.buildEmissionsIndivisible'"
            [inputLabel]="
              'Scenario.labels.co2Emissions.buildEmissionsIndivisible'
                | translate
            "
            fxFlex="24%"
            [contextHelpMsg]=""
            data-testid="build-emission-indivisible"
          ></prosumer-yearly-chart-input>
          <div fxFlex="24%"></div>
          <div fxFlex="24%"></div>
        </div>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="true" class="sizing-parameters">
      <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
        <mat-panel-title>
          <h3>
            {{ 'Scenario.labels.sizing.parameters' | translate }}
            <prosumer-tooltip-anchor
              [message]="'wizard_converter.wizard_converter_sizing_parameters'"
              data-testid="sizing-tooltip"
            ></prosumer-tooltip-anchor>
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="column">
        <div fxLayout="row" fxLayoutGap="10px">
          <prosumer-yearly-chart-input
            [formControl]="converterForm?.controls?.yearlyMinPower"
            [startYear]="converterForm?.controls?.startYear?.value"
            [endYear]="converterForm?.controls?.endYear?.value"
            [errorMessage]="'Scenario.messages.der.minPower'"
            [contextHelpMsg]="'wizard_converter.wizard_converter_minimum_power'"
            [inputLabel]="'Scenario.labels.sizing.minPower' | translate"
            fxFlex="24%"
            data-testid="min-power"
          ></prosumer-yearly-chart-input>
          <prosumer-yearly-chart-input
            [formControl]="converterForm?.controls?.yearlyMaxPower"
            [startYear]="converterForm?.controls?.startYear?.value"
            [endYear]="converterForm?.controls?.endYear?.value"
            [errorMessage]="'Scenario.messages.der.maxPower'"
            [contextHelpMsg]="'wizard_converter.wizard_converter_maximum_power'"
            [inputLabel]="'Scenario.labels.sizing.maxPower' | translate"
            fxFlex="24%"
            data-testid="max-power"
          ></prosumer-yearly-chart-input>
          <div fxFlex="24%"></div>
          <div fxFlex="24%"></div>
        </div>
        <div fxLayout="row" fxLayoutGap="10px">
          <!-- Forced Investment -->
          <prosumer-checkbox
            fxFlex="24%"
            [label]="'Scenario.labels.sizing.forcedInvestment' | translate"
            [control]="converterForm?.controls?.forcedInvestment"
            [errorMessageMap]="errorMessages?.forcedInvestment"
            [tooltip]="'wizard_converter.wizard_converter_forced_investment'"
            data-testid="is-force-investment"
          ></prosumer-checkbox>
          <!-- Existing Asset -->
          <prosumer-checkbox
            fxFlex="24%"
            [label]="'Scenario.labels.sizing.existingAsset' | translate"
            [control]="converterForm?.controls?.existingAsset"
            [tooltip]="'wizard_converter.wizard_converter_existing_asset'"
            [errorMessageMap]="errorMessages?.existingAsset"
            data-testid="is-existing-asset"
          ></prosumer-checkbox>
          <!-- Capacity Expansion -->
          <prosumer-checkbox
            fxFlex="24%"
            [label]="'Scenario.labels.sizing.capacityExpansion' | translate"
            [control]="converterForm?.controls?.capacityExpansion"
            [tooltip]="'wizard_converter.wizard_converter_capacity_expansion'"
            data-testid="allow-capacity"
          ></prosumer-checkbox>
        </div>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
        <mat-panel-title>
          <h3>
            {{ 'Scenario.labels.economical.parameters' | translate }}
            <prosumer-tooltip-anchor
              [message]="
                'wizard_converter.wizard_converter_economical_parameters'
              "
              data-testid="economical-tooltip"
            ></prosumer-tooltip-anchor>
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="column">
        <div fxLayout="row" fxLayoutGap="10px">
          <prosumer-yearly-chart-input
            [formControl]="converterForm?.controls?.yearlyBuildCost"
            [startYear]="converterForm?.controls?.startYear?.value"
            [endYear]="converterForm?.controls?.endYear?.value"
            [errorMessage]="'Scenario.messages.der.buildCost'"
            [contextHelpMsg]="'wizard_converter.wizard_converter_build_cost_kW'"
            [inputLabel]="'Scenario.labels.economical.buildCost' | translate"
            fxFlex="19%"
            data-testid="build-cost"
          ></prosumer-yearly-chart-input>
          <prosumer-yearly-chart-input
            [formControl]="converterForm?.controls?.yearlyIndivisibleCost"
            [startYear]="converterForm?.controls?.startYear?.value"
            [endYear]="converterForm?.controls?.endYear?.value"
            [errorMessage]="'Scenario.messages.der.indivisibleCost'"
            [contextHelpMsg]="
              'wizard_converter.wizard_converter_indivisible_cost'
            "
            [inputLabel]="
              'Scenario.labels.economical.indivisibleCost' | translate
            "
            fxFlex="19%"
            data-testid="indivisible-cost"
          ></prosumer-yearly-chart-input>
          <prosumer-yearly-chart-input
            [formControl]="converterForm?.controls?.yearlyFOAndMCharge"
            [startYear]="converterForm?.controls?.startYear?.value"
            [endYear]="converterForm?.controls?.endYear?.value"
            [errorMessage]="'Scenario.messages.der.fOAndMCharge'"
            [contextHelpMsg]="'wizard_converter.wizard_converter_fom_charge_kW'"
            [inputLabel]="'Scenario.labels.economical.fOAndMCharge' | translate"
            fxFlex="19%"
            data-testid="fom-per-charge"
          ></prosumer-yearly-chart-input>
          <div fxFlex="19%"></div>
          <div fxFlex="19%"></div>
        </div>
        <div fxLayout="row" fxLayoutGap="10px">
          <prosumer-yearly-chart-input
            [formControl]="converterForm?.controls?.yearlyFOAndMInstall"
            [startYear]="converterForm?.controls?.startYear?.value"
            [endYear]="converterForm?.controls?.endYear?.value"
            [errorMessage]="'Scenario.messages.der.fOAndMPerInstall'"
            [contextHelpMsg]="
              'wizard_converter.wizard_converter_fom_per_install'
            "
            [inputLabel]="
              'Scenario.labels.economical.fOAndMPerInstall' | translate
            "
            fxFlex="19%"
            data-testid="fom-per-install"
          ></prosumer-yearly-chart-input>
          <prosumer-yearly-chart-input
            [formControl]="converterForm?.controls?.yearlyTechnicalLife"
            [startYear]="converterForm?.controls?.startYear?.value"
            [endYear]="converterForm?.controls?.endYear?.value"
            [errorMessage]="'Scenario.messages.der.technicalLife'"
            [contextHelpMsg]="
              'wizard_converter.wizard_converter_technical_life'
            "
            [inputLabel]="
              'Scenario.labels.economical.technicalLife' | translate
            "
            fxFlex="19%"
            data-testid="technical-life"
          ></prosumer-yearly-chart-input>
          <prosumer-yearly-chart-input
            [formControl]="converterForm?.controls?.yearlyDepreciationTime"
            [startYear]="converterForm?.controls?.startYear?.value"
            [endYear]="converterForm?.controls?.endYear?.value"
            [errorMessage]="'Scenario.messages.der.depreciationTime'"
            [contextHelpMsg]="
              'wizard_converter.wizard_converter_depreciation_time'
            "
            [inputLabel]="
              'Scenario.labels.economical.depreciationTime' | translate
            "
            fxFlex="19%"
            data-testid="depreciation-time"
          ></prosumer-yearly-chart-input>
          <prosumer-yearly-chart-input
            fxFlex="19%"
            formControlName="yearlyCostOfFinancing"
            inputLabel="Scenario.labels.economical.costOfFinancing"
            errorMessage="Scenario.messages.der.costOfFinancing"
            contextHelpMsg="wizard_converter.wizard_converter_cost_of_financing"
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            [smallerChart]="true"
            data-testid="converter-yearly-cost-of-financing"
          ></prosumer-yearly-chart-input>
          <prosumer-yearly-chart-input
            fxFlex="19%"
            formControlName="yearlyCostOfDecommissioning"
            errorMessage="Scenario.messages.der.costOfDecommissioning"
            inputLabel="Scenario.labels.economical.costOfDecommissioning"
            contextHelpMsg="wizard_converter.wizard_converter_cost_of_decommissioning"
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            [smallerChart]="true"
            [isNullable]="true"
            [minValue]="undefined"
            data-testid="converter-yearly-cost-of-decommissioning"
          ></prosumer-yearly-chart-input>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
        <mat-panel-title>
          <h3 data-testid="operational-cost-title">
            {{ 'Scenario.labels.operationalCost.parameters' | translate }}
            <prosumer-tooltip-anchor
              [message]="
                'wizard_converter.wizard_converter_operational_cost_parameters'
              "
              data-testid="operational-tooltip"
            ></prosumer-tooltip-anchor>
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <prosumer-operating-cost-table
        formControlName="operatingCostProfiles"
        [startYear]="converterForm?.controls?.startYear?.value"
        [endYear]="converterForm?.controls?.endYear?.value"
        [mode]="data?.mode"
        [formType]="formType"
        [inputEnergyVectorsOptions]="inputEnergyVectorOptions$ | async"
        [outputEnergyVectorsOptions]="outputEnergyVectorOptions$ | async"
        [projectId]="projectId"
        [caseId]="caseId"
        [scenarioId]="scenarioId"
      ></prosumer-operating-cost-table>
    </mat-expansion-panel>
  </div>

  <button #submit type="submit" [hidden]="true"></button>
</form>
