import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'prosumer-short-circuit-form',
  templateUrl: './short-circuit-form.component.html',
  styleUrls: ['./short-circuit-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShortCircuitFormComponent {
  @Input() startYear: number;
  @Input() endYear: number;

  @Input() hideAdd: boolean;
  @Input() hideEdit: boolean;
  @Input() hideDelete: boolean;
  @Input() hideView: boolean;

  constructor() {}
}
