<button
  data-testid="okBtn"
  mat-flat-button
  color="primary"
  [class.no-click]="upserting$ | ngrxPush"
  [class.spinner]="upserting$ | ngrxPush"
  [disabled]="disabled"
  (click)="onClick()"
>
  {{ 'Generic.labels.ok' | translate }}
</button>
