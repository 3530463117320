import { FilledStatus } from '../scenario-completion';

export abstract class CompletionStrategy<T> {
  abstract isFull(form: T): boolean;
  abstract isHalf(form: T): boolean;

  determineStatus(form: T): FilledStatus {
    return this.getFilledStatuses()[this.findIndexOfFormStatus(form)];
  }

  private findIndexOfFormStatus(form: T): number {
    return this.getCompletionStatuses(form).findIndex(Boolean);
  }

  private getCompletionStatuses(form: T): boolean[] {
    return [this.isFull(form), this.isHalf(form), this.isEmpty()];
  }

  private isEmpty(): boolean {
    return true;
  }

  private getFilledStatuses(): FilledStatus[] {
    return [FilledStatus.full, FilledStatus.half, FilledStatus.empty];
  }
}
