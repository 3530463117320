import { Injectable } from '@angular/core';
import { ActiveKeeperService } from 'prosumer-app/services/active-keeper';
import { ScenarioDetailQuery } from '../scenario-detail';
import { SpinningReserveMarket } from './spinning-reserve-market.state';
import { SpinningReserveMarketStore } from './spinning-reserve-market.store';

@Injectable({ providedIn: 'root' })
export class SpinningReserveMarketQuery extends ScenarioDetailQuery<SpinningReserveMarket> {
  constructor(store: SpinningReserveMarketStore, keeper: ActiveKeeperService) {
    super(store, keeper);
  }
}
