import {
  EnergyVector,
  Generator,
  Library,
  ParameterType,
} from 'prosumer-app/+scenario/models';
import {
  EnergyVectorCascaderService,
  NodeCascaderService,
} from 'prosumer-app/+scenario/services';
import { ProfileType } from 'prosumer-app/+scenario/types';
import { DER_TYPES } from 'prosumer-app/app.references';
import { LoggerService } from 'prosumer-app/libs/eyes-core';
import {
  BaseComponent,
  ColumnDefinition,
  CustomValidators,
  doNothing,
  FormFieldErrorMessageMap,
  FormFieldOption,
  FormService,
  getKeys,
} from 'prosumer-app/libs/eyes-shared';
import {
  convertToYearlyValues,
  getSelectedEnergyVector,
  NameValidator,
} from 'prosumer-app/shared';
import { getTDBTechFilterFromData } from 'prosumer-app/shared/modules/tdb/mappers';
import { LibraryService } from 'prosumer-app/stores';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import {
  filter,
  map,
  shareReplay,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { TitleCasePipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { EquipmentFormDialogData } from '../equipment-dialog.model';

const NON_LIBRARY_SOURCETYPES = ['custom', 'tdb'];

@Component({
  selector: 'prosumer-generator',
  templateUrl: './generator.component.html',
  styleUrls: ['./generator.component.scss'],
  providers: [EnergyVectorCascaderService, NodeCascaderService, TitleCasePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneratorComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  @ViewChild('submit', { read: ElementRef }) submitButton: ElementRef;

  submitted$ = new BehaviorSubject<boolean>(false);

  scenarioId: string;
  caseId: string;
  projectId: string;

  @Input() nodeOptions: Array<FormFieldOption<string>> = [];
  @Input() energyVectorOptions: Array<EnergyVector> = [];

  nodeWarningShown$ = new BehaviorSubject<boolean>(false);
  evWarningShown$ = new BehaviorSubject<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  readonly REQUIRED_EFFICIENCY_PERCENTAGES = [0, 1];

  yearlyFields = {
    capacityLoss: 'yearlyCapacityLoss',
    individualSize: 'yearlyIndividualSize',
    pmin: 'yearlyPMin',
    efficiencyPmin: 'yearlyEfficiencyPMin',
    efficiencyPmax: 'yearlyEfficiencyPMax',
    startUpLoss: 'yearlyStartUpLoss',
    idleLoss: 'yearlyIdleLoss',
    minUpTime: 'yearlyMinUpTime',
    minDownTime: 'yearlyMinDownTime',
    minRunningTime: 'yearlyMinRunningTime',
    minPower: 'yearlyMinPower',
    maxPower: 'yearlyMaxPower',
    buildCost: 'yearlyBuildCost',
    indivisibleCost: 'yearlyIndivisibleCost',
    fOAndMCharge: 'yearlyFOAndMCharge',
    fOAndMPerInstall: 'yearlyFOAndMInstall',
    technicalLife: 'yearlyTechnicalLife',
    depreciationTime: 'yearlyDepreciationTime',
    runningCost: 'yearlyRunningCost',
    buildEmissionsKw: 'yearlyBuildEmissionsKw',
    buildEmissionsIndivisible: 'yearlyBuildEmissionsIndivisible',
    costOfFinancing: 'yearlyCostOfFinancing',
    costOfDecommissioning: 'yearlyCostOfDecommissioning',
  };

  defaultValues = {
    efficiencyCurve: [],
    // Yearly Values
    yearlyCapacityLoss: convertToYearlyValues(
      '0.0',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlyIndividualSize: convertToYearlyValues(
      '0.0',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlyPMin: convertToYearlyValues(
      '0.3',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlyEfficiencyPMin: convertToYearlyValues(
      '1.0',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlyEfficiencyPMax: convertToYearlyValues(
      '1.0',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlyMinUpTime: convertToYearlyValues(
      '0',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlyMinDownTime: convertToYearlyValues(
      '0',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlyMinRunningTime: convertToYearlyValues(
      '0',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlyStartUpLoss: convertToYearlyValues(
      '0.0',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlyIdleLoss: convertToYearlyValues(
      '0.0',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlyMinPower: !!!this.data
      ? {}
      : convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
    yearlyMaxPower: !!!this.data
      ? {}
      : convertToYearlyValues(
          '100000.0',
          this.data.startYear,
          this.data.endYear,
        ),
    yearlyBuildCost: !!!this.data
      ? {}
      : convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
    yearlyIndivisibleCost: !!!this.data
      ? {}
      : convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
    yearlyFOAndMCharge: !!!this.data
      ? {}
      : convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
    yearlyFOAndMInstall: !!!this.data
      ? {}
      : convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
    yearlyRunningCost: !!!this.data
      ? {}
      : convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
    yearlyTechnicalLife: !!!this.data
      ? {}
      : convertToYearlyValues('20', this.data.startYear, this.data.endYear),
    yearlyDepreciationTime: !!!this.data
      ? {}
      : convertToYearlyValues('0', this.data.startYear, this.data.endYear),
    yearlyBuildEmissionsKw: !!!this.data
      ? {}
      : convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
    yearlyBuildEmissionsIndivisible: !!!this.data
      ? {}
      : convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
    yearlyFootprint: !!!this.data
      ? {}
      : convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
    yearlyCostOfFinancing: !!!this.data
      ? {}
      : convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
    yearlyCostOfDecommissioning: null,
    // !!!this.data
    // ? {}
    // : convertToYearlyValues(null, this.data.startYear, this.data.endYear),
  };

  generatorForm: UntypedFormGroup = this.formBuilder.group({
    type: 'generator',
    // General Parameters
    name: '',
    nodes: [],
    scenarioVariation: 'basecase',
    inputEnergyVector: '',
    energyVector: '',
    outputEnergyVector: '',
    // Efficiency Curve Section
    efficiencyCurve: this.defaultValues.efficiencyCurve,
    // Sizing Parameters
    forcedInvestment: false,
    existingAsset: false,
    capacityExpansion: false,
    id: '',
    sourceType: 'library',
    library: [{ value: '' }, Validators.required],
    // Yearly Values
    yearlyCapacityLoss: [
      this.defaultValues.yearlyCapacityLoss,
      Validators.required,
    ],
    yearlyIndividualSize: [
      this.defaultValues.yearlyIndividualSize,
      Validators.required,
    ],
    yearlyPMin: [this.defaultValues.yearlyPMin, Validators.required],
    yearlyEfficiencyPMin: [
      this.defaultValues.yearlyEfficiencyPMin,
      Validators.required,
    ],
    yearlyEfficiencyPMax: [
      this.defaultValues.yearlyEfficiencyPMax,
      Validators.required,
    ],
    yearlyMinUpTime: [this.defaultValues.yearlyMinUpTime, Validators.required],
    yearlyMinDownTime: [
      this.defaultValues.yearlyMinDownTime,
      Validators.required,
    ],
    yearlyMinRunningTime: [
      this.defaultValues.yearlyMinRunningTime,
      Validators.required,
    ],
    yearlyStartUpLoss: [
      this.defaultValues.yearlyStartUpLoss,
      Validators.required,
    ],
    yearlyIdleLoss: [this.defaultValues.yearlyIdleLoss, Validators.required],
    yearlyMinPower: [this.defaultValues.yearlyMinPower, Validators.required],
    yearlyMaxPower: [this.defaultValues.yearlyMaxPower, Validators.required],
    yearlyBuildCost: [this.defaultValues.yearlyBuildCost, Validators.required],
    yearlyIndivisibleCost: [
      this.defaultValues.yearlyIndivisibleCost,
      Validators.required,
    ],
    yearlyFOAndMCharge: [
      this.defaultValues.yearlyFOAndMCharge,
      Validators.required,
    ],
    yearlyFOAndMInstall: [
      this.defaultValues.yearlyFOAndMInstall,
      Validators.required,
    ],
    yearlyRunningCost: [
      this.defaultValues.yearlyRunningCost,
      Validators.required,
    ],
    yearlyTechnicalLife: [
      this.defaultValues.yearlyTechnicalLife,
      Validators.required,
    ],
    yearlyDepreciationTime: [
      this.defaultValues.yearlyDepreciationTime,
      Validators.required,
    ],
    yearlyBuildEmissionsKw: [
      this.defaultValues.yearlyBuildEmissionsKw,
      Validators.required,
    ],
    yearlyBuildEmissionsIndivisible: [
      this.defaultValues.yearlyBuildEmissionsIndivisible,
      Validators.required,
    ],
    yearlyFootprint: [this.defaultValues.yearlyFootprint, Validators.required],
    yearlyCostOfFinancing: [
      this.defaultValues.yearlyCostOfFinancing,
      Validators.required,
    ],
    yearlyCostOfDecommissioning: [
      this.defaultValues.yearlyCostOfDecommissioning,
    ],
    operatingCostProfiles: [null],
    tdbTechnologyFilter: [null],
  });

  nameCtrl: UntypedFormControl = this.generatorForm.get(
    'name',
  ) as UntypedFormControl;
  scenarioVariationCtrl: UntypedFormControl = this.generatorForm.get(
    'scenarioVariation',
  ) as UntypedFormControl;
  errorMessages: FormFieldErrorMessageMap =
    this._formService.getErrorMessageMap('Scenario.messages.der');

  scenarioVariationOptions: Array<FormFieldOption<any>> = [];
  equipmentOptions: Array<FormFieldOption<ProfileType>> = [];
  library$: Observable<Array<Library>>;
  efficiencyCurve$: Observable<any>;
  isCurvePossible$: Observable<any>;
  viewInitialized: boolean; // Used for change issues in energy vector

  isMultiNode: boolean;

  columnsDef: ColumnDefinition = {
    selection: {
      type: 'selection',
      flex: '50px',
    },
    id: {
      name: 'ID',
      flex: '20',
      sortable: true,
    },
    description: {
      name: 'Description',
      flex: 'calc(30% - 50px)',
      sortable: true,
    },
    buildCost: {
      name: 'Build Cost [€/kW]',
      flex: '10',
      sortable: true,
      alignment: 'flex-end',
    },
    technicalLife: {
      name: 'Technical Life [year]',
      flex: '10',
      sortable: true,
      alignment: 'flex-end',
    },
    pmin: {
      name: 'PMin [-]',
      flex: '10',
      sortable: true,
      alignment: 'flex-end',
    },
    efficiencyPmin: {
      name: 'Efficiency @ Pmin [-]',
      flex: '10',
      sortable: true,
      alignment: 'flex-end',
    },
    efficiencyPmax: {
      name: 'Efficiency @ Pmax [-]',
      flex: '10',
      sortable: true,
      alignment: 'flex-end',
    },
  };

  get messages() {
    return {
      loading: 'Scenario.messages.library.loading',
      noRecords: this.generatorForm?.controls?.inputEnergyVector?.value
        ? 'Scenario.messages.library.noLibraryForEnergyVector'
        : 'Scenario.messages.library.noEnergyVectorSelected',
      noResults: 'Scenario.messages.library.noLibraryForEnergyVector',
      error: 'Scenario.messages.library.error',
    };
  }

  formType: string = this.generatorForm.get('type').value;
  outputEnergyVectorOptions$ = new BehaviorSubject<EnergyVector[]>([]);
  inputEnergyVectorOptions$ = new BehaviorSubject<EnergyVector[]>([]);
  isFormLoading$ = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EquipmentFormDialogData<Generator>,
    public libraryService: LibraryService,
    public formBuilder: UntypedFormBuilder,
    public changeDetector: ChangeDetectorRef,
    private _formService: FormService,
    private _logger: LoggerService,
    private _cascaderService: EnergyVectorCascaderService,
    private _nodeCascaderService: NodeCascaderService,
  ) {
    super();
  }

  ngOnInit(): void {
    if (!this.data) {
      return;
    }
    this.isMultiNode = this.data.isMultiNode;
    this.initScenarioEntity();
    this.initOptions();
    this.initValidators();
    this.initInputOutputValueChanges();
    this.initNodeValueChange();
    this.initPatchValue();
    this.initObservers();
  }

  ngAfterViewInit(): void {
    this.viewInitialized = true;
  }

  initScenarioEntity(): void {
    this.projectId = this.data.scenarioIdentity.projectId;
    this.caseId = this.data.scenarioIdentity.caseId;
    this.scenarioId = this.data.scenarioIdentity.scenarioId;
  }

  /**
   * Initializes form validators.
   */
  initValidators(): void {
    const skipEquipment =
      this.data.mode === 'edit' ? this.data.equipment : null;
    this.nameCtrl.setAsyncValidators(
      CustomValidators.dataExist(this.data.equipment$, 'name', skipEquipment, {
        scenarioVariation: this.scenarioVariationCtrl,
      }),
    );
    this.scenarioVariationCtrl.valueChanges
      .pipe(this.takeUntil())
      .subscribe(() => this.nameCtrl.updateValueAndValidity());

    this.generatorForm.controls.name.setValidators([
      NameValidator.validWithCore(),
    ]);

    // Apply same value validator for existing asset and forced investment
    this.generatorForm.controls.existingAsset.setValidators([
      Validators.required,
      CustomValidators.sameValue(this.generatorForm.controls.forcedInvestment),
    ]);
    this.generatorForm.controls.forcedInvestment.valueChanges
      .pipe(this.takeUntil())
      .subscribe(() =>
        this.generatorForm.controls.existingAsset.updateValueAndValidity(),
      );
  }

  /**
   * Initializes options.
   */
  initOptions(): void {
    if (this.data.outputEnergyVectorOptions) {
      this.data.outputEnergyVectorOptions.forEach((option) =>
        this.energyVectorOptions.push(option),
      );
    }

    if (this.data.profileOptions) {
      this.data.profileOptions.forEach((option) =>
        this.equipmentOptions.push(option),
      );
    }

    if (this.data.scenarioVariationOptions) {
      this.scenarioVariationOptions.push(...this.data.scenarioVariationOptions);
    }
  }

  /**
   * Patches value to the form.
   */
  initPatchValue(): void {
    this.generatorForm.patchValue({
      ...this.data.equipment,
      type: 'generator',
    });
  }

  /**
   * Initializes the observer of the source type.
   */
  initFormSourceTypeObserver(): void {
    this.generatorForm.controls.sourceType.valueChanges
      .pipe(
        startWith(
          this.data && this.data.equipment
            ? this.data.equipment.sourceType
            : 'library',
        ),
        takeUntil(this.componentDestroyed$),
      )
      .subscribe((sourceType) => {
        if (NON_LIBRARY_SOURCETYPES.includes(sourceType)) {
          this.generatorForm.controls.library.clearValidators();
          this.generatorForm.controls.library.patchValue(undefined);
        } else {
          this.generatorForm.controls.library.setValidators(
            Validators.required,
          );
          this.generatorForm.controls.library.patchValue(
            (this.data.equipment || ({} as any)).library || undefined,
          );
        }
      });
  }

  /**
   * Initializes library observable.
   */
  initLibraryObservable(): void {
    this.library$ = combineLatest([
      this.generatorForm.controls.inputEnergyVector.valueChanges.pipe(
        // filter(energyVector => energyVector && energyVector.length > 0),
        startWith(
          this.data && this.data.equipment
            ? this.data.equipment.inputEnergyVector
            : undefined,
        ),
        getSelectedEnergyVector(
          ((this.data || ({} as any)).equipment || ({} as any))
            .inputEnergyVector,
          of(this.energyVectorOptions),
        ),
      ),
      this.generatorForm.controls.outputEnergyVector.valueChanges.pipe(
        // filter(energyVector => energyVector && energyVector.length > 0),
        startWith(
          this.data && this.data.equipment
            ? this.data.equipment.outputEnergyVector
            : undefined,
        ),
        getSelectedEnergyVector(
          ((this.data || ({} as any)).equipment || ({} as any))
            .outputEnergyVector,
          of(this.energyVectorOptions),
        ),
      ),
    ]).pipe(
      tap(() => {
        // Checks if the view is already initialized before doing the clean-up to the library field
        // This is to resolve the issue in edit mode where the library is not selected even we selected one before
        if (this.viewInitialized) {
          this.generatorForm.controls.library.patchValue(undefined);
          this.changeDetector.markForCheck();
        }
      }),
      switchMap(([inputEnergyVector, outputEnergyVector]) =>
        this.libraryService.getLibraryList$(
          'generator',
          inputEnergyVector,
          outputEnergyVector,
        ),
      ),
      takeUntil(this.componentDestroyed$),
      shareReplay(1),
    );
  }

  /**
   * Initializes the form library observer.
   */
  initFormLibraryObserver(): void {
    // Patches the fields based on the library selected
    this.generatorForm.controls.library.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((library: Library) => {
        this._logger.debug(library);

        if (!library) {
          return;
        }
        // Yearly fields library handling
        getKeys(this.yearlyFields).forEach((key) => {
          this.patchControlWithValue(
            this.generatorForm.controls[this.yearlyFields[key]],
            !!library[key]
              ? convertToYearlyValues(
                  library[key],
                  this.data.startYear,
                  this.data.endYear,
                )
              : null,
            this.defaultValues[this.yearlyFields[key]],
          );
        });
      });
  }

  initEfficiencyCurveObservable(): void {
    this.efficiencyCurve$ = this.generatorForm
      .get('efficiencyCurve')
      .valueChanges.pipe(
        filter((curve) => !!curve),
        this.takeUntilShare(),
      );
    this.isCurvePossible$ = this.efficiencyCurve$.pipe(
      map((curve) => curve.items || []),
      map(
        (points) =>
          points.length === 0 ||
          this.REQUIRED_EFFICIENCY_PERCENTAGES.every((req) =>
            points
              .map((point) => Number(point.percentageOfCapacity))
              .includes(req),
          ),
      ),
    );
    // Subscription to efficiency curve control for manual validation :(
    this.isCurvePossible$.subscribe((isIt) =>
      isIt
        ? doNothing()
        : this.generatorForm
            .get('efficiencyCurve')
            .setErrors({ impossible: true } as ValidationErrors),
    );
  }

  initObservers(): void {
    this.initFormSourceTypeObserver();
    this.initLibraryObservable();
    this.initFormLibraryObserver();
    this.initEfficiencyCurveObservable();
  }

  initInputOutputValueChanges(): void {
    const revertVectorValue = (
      currentProfileOptions,
      currentEnergyVector,
      currentLibrary,
      isInputVector,
    ) => {
      if (!!currentProfileOptions && currentProfileOptions.length === 0) {
        doNothing();
      } else {
        if (isInputVector) {
          this.generatorForm.controls.inputEnergyVector.patchValue(
            currentEnergyVector,
          );
        } else {
          this.generatorForm.controls.outputEnergyVector.patchValue(
            currentEnergyVector,
          );
        }

        this.generatorForm.controls.library.patchValue(currentLibrary);
      }
    };

    this.generatorForm
      .get('inputEnergyVector')
      .valueChanges.pipe(takeUntil(this.componentDestroyed$))
      .subscribe((value) => {
        // TODO: remove duplication with outputEV
        // show warning dialog only once
        if (this.evWarningShown$.value && this.generatorForm.dirty) {
          return;
        }

        const currentEnergyVector =
          this.generatorForm.value['inputEnergyVector'];
        const currentLibrary = this.generatorForm.value['library'];
        const currentProfileOptions = this.inputEnergyVectorOptions$.value;

        let associatedTableVectors = [];
        const operatingCostProfileCtrlVal =
          this.generatorForm.controls.operatingCostProfiles.value;
        if (
          !!operatingCostProfileCtrlVal &&
          !!operatingCostProfileCtrlVal.operatingCostProfiles
        ) {
          associatedTableVectors =
            operatingCostProfileCtrlVal.operatingCostProfiles
              .filter((profile) => profile.parameterType === ParameterType.FDOC)
              .map((profile) => profile.energyVectorId);
        }

        const isOPAffected = this._cascaderService.isOperatingCostAffected(
          [currentEnergyVector],
          associatedTableVectors,
        );
        const isNettingAffected = this._cascaderService.isNettingAffected(
          this.generatorForm.value['id'],
          [currentEnergyVector],
          this.data.currentNetting,
        );

        this._cascaderService
          .showCascadingWarningDialog([isOPAffected, isNettingAffected])
          .subscribe((isOk) => {
            if (isOk) {
              this.inputEnergyVectorOptions$.next(
                this.energyVectorOptions.filter(
                  (options) => options.value === value,
                ),
              );

              if (isNettingAffected) {
                this.evWarningShown$.next(true);
              }
            } else {
              revertVectorValue(
                currentProfileOptions,
                currentEnergyVector,
                currentLibrary,
                true,
              );
            }
          });
      });

    this.generatorForm
      .get('outputEnergyVector')
      .valueChanges.pipe(takeUntil(this.componentDestroyed$))
      .subscribe((value) => {
        if (this.evWarningShown$.value && this.generatorForm.dirty) {
          return;
        }
        const currentEnergyVector =
          this.generatorForm.value['outputEnergyVector'];
        const currentLibrary = this.generatorForm.value['library'];
        const currentProfileOptions = this.outputEnergyVectorOptions$.value;

        let associatedTableVectors = [];
        const operatingCostProfileCtrlVal =
          this.generatorForm.controls.operatingCostProfiles.value;
        if (
          !!operatingCostProfileCtrlVal &&
          !!operatingCostProfileCtrlVal.operatingCostProfiles
        ) {
          associatedTableVectors =
            operatingCostProfileCtrlVal.operatingCostProfiles
              .filter((profile) => profile.parameterType === ParameterType.PDOC)
              .map((profile) => profile.energyVectorId);
        }

        const isOPAffected = this._cascaderService.isOperatingCostAffected(
          [currentEnergyVector],
          associatedTableVectors,
        );
        const isNettingAffected = this._cascaderService.isNettingAffected(
          this.generatorForm.value['id'],
          [currentEnergyVector],
          this.data.currentNetting,
        );
        this._cascaderService
          .showCascadingWarningDialog([isOPAffected, isNettingAffected])
          .subscribe((isOk) => {
            if (isOk) {
              this.outputEnergyVectorOptions$.next(
                this.energyVectorOptions.filter(
                  (options) => options.value === value,
                ),
              );
            } else {
              revertVectorValue(
                currentProfileOptions,
                currentEnergyVector,
                currentLibrary,
                false,
              );
            }
          });
      });
  }

  initNodeValueChange(): void {
    this.generatorForm
      .get('nodes')
      .valueChanges.pipe(takeUntil(this.componentDestroyed$))
      .subscribe((value) => {
        // show warning dialog only once
        if (this.nodeWarningShown$.value && this.generatorForm.dirty) {
          return;
        }
        const isNettingAffected = this._nodeCascaderService.isNettingAffected(
          this.generatorForm.value['id'],
          value.nodes,
          this.data.currentNetting,
        );

        if (isNettingAffected) {
          this.nodeWarningShown$.next(true);
        }
        this._nodeCascaderService
          .showCascadingWarningDialog([isNettingAffected])
          .subscribe((isOk) => {
            // TODO: implement revert option
          });
      });
  }

  onConfirm(): Generator {
    this.submit();
    this.submitted$.next(true);
    const efficiencyControl = this.generatorForm.get('efficiencyCurve');
    const efficiencyCurve =
      (efficiencyControl.value || {}).items || efficiencyControl.value;
    if (this.generatorForm.valid) {
      this.generatorForm.controls.energyVector.patchValue(
        this.generatorForm.controls.inputEnergyVector.value,
      );
      const generatorFormValue = this.generatorForm.getRawValue();
      const formattedForm = {
        ...generatorFormValue,
        nodes: generatorFormValue['nodes']
          ? generatorFormValue['nodes']['nodes'] ||
            generatorFormValue['nodes'] ||
            []
          : generatorFormValue['nodes'],
        efficiencyCurve,
        operatingCostProfiles: generatorFormValue?.operatingCostProfiles
          ? generatorFormValue.operatingCostProfiles['operatingCostProfiles']
          : null,
      };
      return formattedForm;
    }
  }

  /**
   * Updates the library value when selecting a library from the table
   *
   * @param libraryId - the library id selected
   */
  onSelect(libraryId: string): void {
    this.library$.pipe(take(1)).subscribe((library) => {
      if (!!library && library.length > 0) {
        this.generatorForm.controls.library.patchValue(
          library.find(({ id }) => id === libraryId),
        );
        this.generatorForm.markAsDirty();
      }
    });
  }

  submit(): void {
    if (!!this.submitButton && !!this.submitButton.nativeElement) {
      this.submitButton.nativeElement.click();
    }
  }

  get derTypes() {
    return DER_TYPES;
  }

  handleTechnologyFiltersLoading(isLoading: boolean): void {
    this.isFormLoading$.next(isLoading);
  }

  handleTdbTechnologyData(data: Record<string, string | number>): void {
    getKeys(this.yearlyFields).forEach((key) => {
      this.patchControlWithValue(
        this.generatorForm.controls[this.yearlyFields[key]],
        !!data[key]
          ? convertToYearlyValues(
              data[key],
              this.data.startYear,
              this.data.endYear,
            )
          : null,
        this.defaultValues[this.yearlyFields[key]],
      );
    });
    this.patchTDBTechFilters(data);
  }

  private patchTDBTechFilters(data: Record<string, unknown>): void {
    this.patchControlWithValue(
      this.generatorForm.controls.tdbTechnologyFilter,
      getTDBTechFilterFromData(data),
    );
  }

  get extraQueryParamsTDB(): Record<string, string> {
    return {
      startYear: String(this.data.startYear),
      endYear: String(this.data.endYear),
    };
  }
}
