import { Injectable } from '@angular/core';
import { FormFieldOption } from 'prosumer-app/libs/eyes-shared';
import {
  EGCEmission,
  EGCEmissionQuery,
  EGCEmissionStore,
} from 'prosumer-app/stores/egc-emission';
import {
  EnergyGridConnectionInfo,
  EnergyGridConnectionQuery,
  EnergyGridConnectionStore,
} from 'prosumer-app/stores/energy-grid-connection';
import { EnergyVectorQuery } from 'prosumer-app/stores/energy-vector';
import { FuelQuery, FuelStore } from 'prosumer-app/stores/fuel';
import {
  MarketLimit,
  MarketLimitQuery,
  MarketLimitStore,
} from 'prosumer-app/stores/market-limit';
import { NodeQuery } from 'prosumer-app/stores/node';
import { ScenarioVariationQuery } from 'prosumer-app/stores/scenario-variation';
import { Observable, combineLatest, forkJoin } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
  OptionsForEGCDialogData,
  OptionsForEmissionsDialogData,
  OptionsForFuelDialogData,
  OptionsForLimitDialogData,
  SCOPE_OPTIONS,
  TYPE_OPTIONS,
} from './commodities-form.types';

@Injectable({
  providedIn: 'root',
})
export class CommoditiesFormService {
  constructor(
    private readonly nodes: NodeQuery,
    private readonly vector: EnergyVectorQuery,
    private readonly variations: ScenarioVariationQuery,
    private readonly fuels: FuelQuery,
    private readonly emissions: EGCEmissionQuery,
    private readonly egcs: EnergyGridConnectionQuery,
    private readonly limits: MarketLimitQuery,
    private readonly egcStore: EnergyGridConnectionStore,
    private readonly egcEmissionStore: EGCEmissionStore,
    private readonly limitStore: MarketLimitStore,
    private readonly fuelStore: FuelStore,
  ) {}

  deleteEGC(id: string): Observable<unknown> {
    return this.egcStore
      .deleteOne(id)
      .pipe(switchMap(() => this.postDeleteEGC()));
  }

  deleteFuel(id: string): Observable<unknown> {
    return this.fuelStore.deleteOne(id);
  }

  deleteLimit(id: string): Observable<unknown> {
    return this.limitStore.deleteOne(id);
  }

  deleteEmission(id: string): Observable<unknown> {
    return this.egcEmissionStore.deleteOne(id);
  }

  retrieveEGC(id: string): Observable<EnergyGridConnectionInfo> {
    return this.egcStore.getSingleEGC(id);
  }

  getEmission(id: string): Observable<EGCEmission> {
    return this.egcEmissionStore.improvedGetSingle(id);
  }

  getLimit(id: string): Observable<MarketLimit> {
    return this.limitStore.improvedGetSingle(id);
  }

  prepOptionsForEGCDialog(): OptionsForEGCDialogData {
    return {
      deliveryOptions: this.getVectorOptions(),
      nodeOptions: this.getNodeOptions(),
      typeOptions: TYPE_OPTIONS,
      scenarioVariationOptions: this.getVariationOptions(),
      existingEnergyGridConnections$: this.egcs.selectActiveEGCs(),
    };
  }

  prepOptionsForFuelDialog(): OptionsForFuelDialogData {
    return {
      energyVectorOptions: this.getVectorOptions(),
      nodeOptions: this.getNodeOptions(),
      existingFuels$: this.fuels.selectActiveFuels(),
    };
  }

  prepForLimitDialogData(): OptionsForLimitDialogData {
    return {
      nodeOptions: this.getNodeOptions(),
      marketOptions: this.buildMarketOptions(),
      existingEnergyGridConnections$: this.egcs.selectActiveEGCs(),
      existingEnergyGridLimits$: this.limits.selectAllForActiveScenario(),
    };
  }

  prepForEmissionsDialogData(): OptionsForEmissionsDialogData {
    return {
      nodeOptions: this.getNodeOptions(),
      scopeOptions: SCOPE_OPTIONS,
      marketOptions: this.buildMarketOptions(),
      existingEnergyGridConnections$: this.egcs.selectActiveEGCs(),
      existingEnergyGridConnectionsEmissions$:
        this.emissions.selectAllForActiveScenario(),
    };
  }

  selectAcitveOptions(): Observable<any[]> {
    return combineLatest([
      this.nodes.selectActiveNodes(),
      this.vector.selectActiveVectors(),
      this.variations.selectActiveVariation(),
    ]);
  }

  selectEGCEmissions(): Observable<EGCEmission[]> {
    return this.emissions.selectAllForActiveScenario();
  }

  selectMarketLimits(): Observable<MarketLimit[]> {
    return this.limits.selectAllForActiveScenario();
  }

  selectEGCs(): Observable<EnergyGridConnectionInfo[]> {
    return this.egcs.selectActiveEGCs();
  }

  postUpdateEGC(): Observable<unknown> {
    return this.postDeleteEGC();
  }

  private postDeleteEGC(): Observable<unknown> {
    return forkJoin([
      this.egcEmissionStore.improvedGetAll(),
      this.limitStore.improvedGetAll(),
    ]);
  }

  private buildMarketOptions(): FormFieldOption<string>[] {
    return this.egcsToMarketOptions(this.egcs.getAllForActiveScenario());
  }

  private egcsToMarketOptions(
    egcs: EnergyGridConnectionInfo[],
  ): FormFieldOption<string>[] {
    return egcs.map((egc) => this.egcToMarketOption(egc));
  }

  private egcToMarketOption(
    egc: EnergyGridConnectionInfo,
  ): FormFieldOption<string> {
    return {
      name: egc.marketName,
      value: egc.id,
      object: egc,
    } as unknown as FormFieldOption<string>;
  }

  private getVectorOptions(): FormFieldOption<string>[] {
    return this.vector.getVectorOptions();
  }

  private getNodeOptions(): FormFieldOption<string>[] {
    return this.nodes.getActiveOptions() as FormFieldOption<string>[];
  }

  private getVariationOptions(): FormFieldOption<string>[] {
    return this.variations.getActiveOptionsWithBasecase() as FormFieldOption<string>[];
  }
}
