import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators';

import { HttpErrorResponse } from '@angular/common/http';
import { NotificationsService } from 'prosumer-app/shared/services/notification';
import { EquipmentQuery, EquipmentStore } from 'prosumer-app/stores/equipment';
import {
  Duplicable,
  DuplicateDetailData,
} from 'prosumer-app/stores/scenario-detail';

@Component({
  selector: 'prosumer-equipment-duplicate-dialog',
  templateUrl: './equipment-duplicate-dialog.component.html',
  styleUrls: ['./equipment-duplicate-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EquipmentDuplicateDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) private readonly data: DuplicateDetailData,
    private dialogRef: MatDialogRef<EquipmentDuplicateDialogComponent>,
    private readonly equipments: EquipmentStore,
    private readonly equipmentQuery: EquipmentQuery,
    private readonly notifs: NotificationsService,
  ) {}

  loading$ = this.equipmentQuery.selectLoading();

  onDuplicate(formData: Duplicable): void {
    this.duplicateEquipment(formData); // idk elp
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  private duplicateEquipment(data: Duplicable): void {
    this.equipments
      .duplicate(this.getOriginId(), data)
      .pipe(take(1))
      .subscribe(
        (response) => this.dialogRef.close(response),
        (error: HttpErrorResponse) =>
          this.notifs.showError(this.getErrorMessage(error)),
      );
  }

  private getOriginId(): string {
    return this.data.origin.id;
  }

  private getErrorMessage(error: HttpErrorResponse): string {
    return error.error?.error ?? error.message;
  }
}
