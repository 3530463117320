import { Coerce } from 'prosumer-core/utils';
import { of } from 'rxjs';

import {
  AbstractControl,
  AsyncValidatorFn,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { LinksResultEnhanced } from '@prosumer/results/models';

const PAIR_ERROR = ['source', 'target'];
const REQUIRED_ATTRIBUTES = ['source', 'target', 'value'];

export const ERROR_KEY_REQUIRED = 'required';
export const ERROR_KEY_CIRCULAR = 'selfFulfilling';
export const ERROR_KEY_UNIQUE = 'duplicateCombination';

export class LinksFlowResultsEditorValidator {
  static valid(id: string): AsyncValidatorFn {
    return (form: AbstractControl) => {
      const next = { ...form.value, id };
      let validityErrors: ValidationErrors | null = null;
      if (!!form.parent) {
        const existing = form.parent.value;
        const required = LinksFlowResultsEditorValidator.required(form);
        const circular = LinksFlowResultsEditorValidator.circular(next);
        const unique = LinksFlowResultsEditorValidator.unique(existing, next);
        validityErrors = { ...unique, ...required, ...circular };
      }
      // if (!!form.parent) {
      //   return form.parent.valueChanges.pipe(
      //     debounceTime(500),
      //     startWith(form.parent.value),
      //     filter(() => form.touched),
      //     // map((links) => links.filter((link) => link.id !== id)),
      //     // take(1),
      //     map((links) => {
      //       const required = LinksFlowResultsEditorValidator.required(form);
      //       const circular = LinksFlowResultsEditorValidator.circular(next);
      //       const unique = LinksFlowResultsEditorValidator.unique(links, next);
      //       validityErrors = { ...unique, ...required, ...circular };
      //       console.log(validityErrors);
      //       return validityErrors;
      //     }),
      //     tap((validityErrors) => console.log([id, validityErrors])),
      //   );
      // }
      return of(validityErrors);
    };
  }

  private static required(form: AbstractControl): ValidationErrors | null {
    const ctrls = Coerce.toObject((form as FormGroup).controls);
    const ctrlErrors = Object.entries<AbstractControl>(ctrls)
      .filter(([key]) => REQUIRED_ATTRIBUTES.includes(key))
      .map(([key, ctrl]) => [key, Validators.required(ctrl)])
      .filter(([, validation]) => !!validation)
      .map(([key]) => key);
    if (ctrlErrors.length > 0) {
      return { [ERROR_KEY_REQUIRED]: ctrlErrors };
    }
    return null;
  }

  private static circular(
    modLink: LinksResultEnhanced,
  ): ValidationErrors | null {
    const isDefault = LinksFlowResultsEditorValidator.isNonDefault(modLink);
    if ([isDefault, modLink.source === modLink.target].every(Boolean)) {
      return { [ERROR_KEY_CIRCULAR]: PAIR_ERROR };
    }
    return null;
  }

  private static unique(
    existing: LinksResultEnhanced[],
    modLink: LinksResultEnhanced,
  ): ValidationErrors | null {
    // const { id: mId, source: mSource, target: mTarget } = modLink;
    const existingLinks = existing.find((ref) =>
      LinksFlowResultsEditorValidator.isDuplicate(ref, modLink),
    );
    // .filter(({ id }) => id !== mId)
    // .filter(() => LinksFlowResultsEditorValidator.isDefault(modLink))
    // .filter(({ source }) => source === mSource)
    // .filter(({ target }) => target === mTarget);
    if (!!existingLinks) {
      return { [ERROR_KEY_UNIQUE]: PAIR_ERROR };
    }
    return null;
  }

  private static isDuplicate(
    refLink: LinksResultEnhanced,
    modLink: LinksResultEnhanced,
  ): boolean {
    return [
      refLink.id !== modLink.id,
      refLink.source === modLink.source,
      refLink.target === modLink.target,
      LinksFlowResultsEditorValidator.isNonDefault(modLink),
    ].every(Boolean);
  }

  private static isNonDefault(link: LinksResultEnhanced): boolean {
    return [!!link.source, !!link.target].every(Boolean);
  }
}
