import { Component, OnInit } from '@angular/core';
import { VisualizerData } from '@prosumer/results/components/results-visualizer';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { EquipmentService } from '../equipment.service';
import { ChartData, NameValue } from './cumulative-visualization.model';

type LegendStatus = { [name: string]: boolean };
@Component({
  selector: 'prosumer-cumulative-visualization',
  templateUrl: './cumulative-visualization.component.html',
  styleUrls: ['./cumulative-visualization.component.scss'],
})
export class CumulativeVisualizationComponent implements OnInit {
  private legendStatusSubject = new BehaviorSubject<LegendStatus>({});
  private localEquipmentChartDataSubject = new BehaviorSubject<ChartData[]>([]);

  chartData$: Observable<VisualizerData[]>;
  constructor(public equipmentService: EquipmentService) {}

  ngOnInit(): void {
    this.chartData$ = this.getLegendFilteredStream();

    this.subscribeToEquipmentChartDataForLocalSync();
    this.quicklySubscribeToChartDataForLegendInit();
  }

  onSelect(data: unknown): void {
    if (typeof data === 'string') {
      this.toggleLegendStatus(data);
    }
  }

  private subscribeToEquipmentChartDataForLocalSync(): void {
    this.equipmentService.chartData$
      .pipe(filter((data) => !!data))
      .subscribe((data) => this.localEquipmentChartDataSubject.next(data));
  }

  private toggleLegendStatus(name: string): void {
    const value = this.legendStatusSubject.value;
    const sanitized = this.cleanupSiriName(name);

    value[sanitized] = !value[sanitized];
    this.legendStatusSubject.next(value);
  }

  private quicklySubscribeToChartDataForLegendInit(): void {
    this.getChartDataStream()
      .pipe(take(1))
      .subscribe((data) => this.initializeLegendStatus(data));
  }

  private getLegendFilteredStream(): Observable<ChartData[]> {
    return combineLatest([
      this.legendStatusSubject,
      this.getChartDataStream(),
    ]).pipe(
      map(([legend, data]) =>
        data.map((datum) => this.mutateDatumBasedOnLegendStatus(datum, legend)),
      ),
    );
  }

  private mutateDatumBasedOnLegendStatus(
    datum: ChartData,
    legend: LegendStatus,
  ): ChartData {
    const isShowing = legend[datum.name];
    return {
      name: this.mutateSiriNameBasedOnShowing(datum.name, isShowing),
      series: datum.series.map((siri) =>
        this.mutateSiriBasedOnShowing(siri, isShowing),
      ),
    };
  }

  private mutateSiriNameBasedOnShowing(name: string, showing: boolean): string {
    return showing ? `\u2611 ${name}` : `\u2610 ${name}`;
  }

  private cleanupSiriName(name: string): string {
    return name.replace(/\u2610|\u2611/g, '').trim();
  }

  private mutateSiriBasedOnShowing(
    siri: NameValue,
    showing: boolean,
  ): NameValue {
    return { ...siri, value: showing ? siri.value : 0 };
  }

  private getChartDataStream(): Observable<ChartData[]> {
    return this.localEquipmentChartDataSubject.pipe(
      map((data) => this.mapStackedBarChartDataToAreaChart(data)),
    );
  }

  private mapStackedBarChartDataToAreaChart(data: ChartData[]): ChartData[] {
    const assetNames: string[] = this.extractAssetNames(data);
    const years: string[] = this.extractYears(data);
    return assetNames.map((name) => ({
      name,
      series: years.map((year) => this.createSeriesValue(name, year, data)),
    }));
  }

  private createSeriesValue(
    asset: string,
    year: string,
    reference: ChartData[],
  ): NameValue {
    const matchedSeries = this.findMatchingSeries(year, reference);
    const matchedValue = this.findMatchingSeriesValue(asset, matchedSeries);
    return { name: year, value: matchedValue };
  }

  private findMatchingSeries(year: string, data: ChartData[]): NameValue[] {
    return data.find((datum) => datum.name === year)?.series;
  }

  private findMatchingSeriesValue(asset: string, series: NameValue[]): number {
    return series.find((siri) => siri.name === asset)?.value;
  }

  private extractAssetNames(data: ChartData[]): string[] {
    return (data[0] || {}).series?.map((siri) => siri.name);
  }

  private extractYears(data: ChartData[]): string[] {
    return data.map((datum) => datum.name);
  }

  private initializeLegendStatus(reference: ChartData[]): void {
    const status = reference.reduce((acc, curr) => {
      acc[curr.name] = true;
      return acc;
    }, {});
    this.legendStatusSubject.next(status);
  }
}
