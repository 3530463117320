import { Observable } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { StoreConfig } from '@datorama/akita';

import { LineMapper } from 'prosumer-app/+scenario/services/mappers/line.mapper';
import {
  ScenarioDetailInfo,
  ScenarioDetailStore,
  ScenarioDetailType,
} from '../scenario-detail';
import { LineInfo, YearValues } from './line.state';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: ScenarioDetailType.line, idKey: 'lineId' })
export class LineStore extends ScenarioDetailStore<LineInfo> {
  createLine(data: Record<string, unknown>): Observable<unknown> {
    this.setLoading(true);
    const body = LineMapper.toBE(data);
    return this.handleAdd(
      this.post({ key: 'createDetail', data: {}, body }).pipe(
        map((data: LineInfo) => this.parseStringYearValues(data)),
      ),
    ).pipe(finalize(() => this.setLoading(false)));
  }

  updateLine(data: Record<string, unknown>): Observable<unknown> {
    this.upsertLoading(this.getDataUuid(data), true);
    const body = LineMapper.toBE(data);
    return this.handleUpdate(
      this.patch({ key: 'updateDetail', data: { lineId: data.lineId }, body }),
    ).pipe(finalize(() => this.upsertLoading(body.lineId, false)));
  }

  deleteLine(lineId: string): Observable<unknown> {
    this.upsertLoading(lineId, true);
    return this.handleDelete(
      this.delete({ key: 'deleteDetail', data: { lineId }, body: undefined }),
      lineId,
    ).pipe(finalize(() => this.upsertLoading(lineId, false)));
  }

  getLines(data: Record<string, unknown>): Observable<unknown> {
    this.setLoading(true);
    return this.handleGetAll(
      this.get({ key: 'getDetailByDataType', data, body: undefined }).pipe(
        map((data: ScenarioDetailInfo<LineInfo>) =>
          data.details.map((item: LineInfo) =>
            this.parseStringYearValues(item),
          ),
        ),
        finalize(() => this.setLoading(false)),
      ),
    );
  }

  private handleGetAll($: Observable<unknown>): Observable<unknown> {
    return $.pipe(tap((lines: LineInfo[]) => this.set(lines)));
  }

  private handleAdd($: Observable<unknown>): Observable<unknown> {
    return $.pipe(tap((data: LineInfo) => this.add(data)));
  }

  private handleUpdate($: Observable<unknown>): Observable<unknown> {
    return $.pipe(
      tap((data: LineInfo) =>
        this.update(data.lineId, this.parseStringYearValues(data)),
      ),
    );
  }

  private handleDelete(
    $: Observable<unknown>,
    lineId: string,
  ): Observable<unknown> {
    return $.pipe(tap(() => this.remove(lineId)));
  }

  protected getDataUuid(data: Record<string, unknown>): string {
    return data['lineId'] as string;
  }

  private parseStringYearValues(line: LineInfo): LineInfo {
    return {
      ...line,
      efficiency: this.parseYearValues(line.efficiency),
      buildCost: this.parseYearValues(line.buildCost),
      buildEmissionsKwm: this.parseYearValues(line.buildEmissionsKwm),
      buildEmissionsIndivisible: this.parseYearValues(
        line.buildEmissionsIndivisible,
      ),
      minSize: this.parseYearValues(line.minSize),
      maxSize: this.parseYearValues(line.maxSize),
      indivisibleCost: this.parseYearValues(line.indivisibleCost),
      fomCharge: this.parseYearValues(line.fomCharge),
      fomInstall: this.parseYearValues(line.fomInstall),
      technicalLife: this.parseYearValues(line.technicalLife),
      depreciationTime: this.parseYearValues(line.depreciationTime),
    };
  }

  private parseYearValues(value: unknown): YearValues {
    return JSON.parse(String(value || '{}'));
  }
}
