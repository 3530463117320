import { FormFieldOption } from 'prosumer-app/libs/eyes-shared';
import { EnergyGridConnectionsEmissionsDialogData } from './energy-grid-connections-emissions-form-dialog';
import { EnergyGridConnectionsDialogData } from './energy-grid-connections-form-dialog';
import { EnergyGridLimitDialogData } from './energy-grid-limit-form-dialog';
import { FuelsFormDialogData } from './fuels-form-dialog';

export type OptionsForEGCDialogData = Pick<
  EnergyGridConnectionsDialogData,
  | 'deliveryOptions'
  | 'typeOptions'
  | 'nodeOptions'
  | 'existingEnergyGridConnections$'
  | 'scenarioVariationOptions'
>;
export type OptionsForFuelDialogData = Pick<
  FuelsFormDialogData,
  'energyVectorOptions' | 'nodeOptions' | 'existingFuels$'
>;
export type OptionsForEmissionsDialogData = Pick<
  EnergyGridConnectionsEmissionsDialogData,
  | 'nodeOptions'
  | 'scopeOptions'
  | 'marketOptions'
  | 'existingEnergyGridConnections$'
  | 'existingEnergyGridConnectionsEmissions$'
>;

export type OptionsForLimitDialogData = Pick<
  EnergyGridLimitDialogData,
  | 'nodeOptions'
  | 'marketOptions'
  | 'existingEnergyGridLimits$'
  | 'existingEnergyGridConnections$'
>;

type NameValRef = Record<string, string>;
export interface CommoditiesTableRefs {
  readonly nodes: NameValRef;
  readonly energyVectors: NameValRef;
  readonly scope: NameValRef;
  readonly types: NameValRef;
  readonly scenarioVariations: NameValRef;
  readonly marketName: NameValRef;
  readonly marketVariationAssociation: NameValRef;
}

export const TYPE_OPTIONS: FormFieldOption<string>[] = [
  { name: 'Buy', value: 'retail' },
  { name: 'Sell', value: 'wholesale' },
];

export const SCOPE_OPTIONS: FormFieldOption<string>[] = [
  { name: 'Scope 1', value: 'scope1' },
  { name: 'Scope 2', value: 'scope2' },
  { name: 'Scope 3', value: 'scope3' },
];
