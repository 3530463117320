import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DetailEntitiesGetterDirective } from './detail-entities-getter.directive';

@NgModule({
  declarations: [DetailEntitiesGetterDirective],
  imports: [CommonModule],
  exports: [DetailEntitiesGetterDirective],
})
export class DetailEntitiesGetterModule {}
