<prosumer-sparkline-table
  data-testid="customOptions"
  [columnsDef]="columnsDef"
  [data]="source$ | async"
  [searchInput]="true"
  [searchPredicate]="customOptionsSearchPredicate"
  (add)="onAdd()"
  (edit)="onEdit($event)"
  (delete)="onDelete($event)"
  [hideAdd]="isViewOnly"
  [hideEdit]="isViewOnly"
  [hideDelete]="isViewOnly"
  [hideView]="!isViewOnly"
>
</prosumer-sparkline-table>
