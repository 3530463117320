<prosumer-sparkline-table
  name="Scenario.labels.wizard.frequencyControl.headers.equipment"
  [columnsDef]="columnsDef"
  [references]="references$ | async"
  [data]="scEquipments$ | async"
  [noDash]="true"
  (add)="onAdd()"
  (edit)="onViewEdit($event)"
  (delete)="onDelete($event)"
  [hideAdd]="hideAdd"
  [hideEdit]="hideEdit"
  [hideDelete]="hideDelete"
  [hideView]="hideView"
  data-testid="sc-equipment-table"
></prosumer-sparkline-table>
