import { PlotlyViaCDNModule } from 'angular-plotly.js';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { SankeyChartComponent } from './sankey-chart.component';

PlotlyViaCDNModule.setPlotlyVersion('latest');
PlotlyViaCDNModule.loadViaCDN();

@NgModule({
  declarations: [SankeyChartComponent],
  imports: [
    CommonModule,
    PlotlyViaCDNModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
  ],
  exports: [SankeyChartComponent],
})
export class SankeyChartModule {}
