import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BarAndAreaResultsModule } from '../bar-and-area-results';
import { ResultsVisualizerModule } from '../results-visualizer';
import { EquipmentResultsComponent } from './equipment-results.component';

@NgModule({
  declarations: [EquipmentResultsComponent],
  imports: [
    CommonModule,
    ResultsVisualizerModule,
    BarAndAreaResultsModule,
    TranslateModule,
  ],
  exports: [EquipmentResultsComponent],
})
export class EquipmentResultsModule {}
