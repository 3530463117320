import { BaseControlComponent } from 'prosumer-libs/eyes-shared';
import { EnergyVector } from 'prosumer-scenario/models';
import { BehaviorSubject } from 'rxjs';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

const SPINNER_COLOR = 'accent';
const SPINNER_DIAMETER = 18;

@Component({
  selector: 'prosumer-energy-vector-list',
  templateUrl: './energy-vector-list.component.html',
  styleUrls: ['./energy-vector-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnergyVectorListComponent extends BaseControlComponent {
  hasCustomEV$ = new BehaviorSubject<boolean>(false);
  @Input() name: string;

  @Input() waitingResponse: Record<string, boolean> = {};
  _energyVectors: Array<EnergyVector>;

  @Input() set energyVectors(data: Array<EnergyVector>) {
    this._energyVectors = data?.filter((datum) => !!datum.custom) ?? [];
    this.hasCustomEV$.next(this._energyVectors.length > 0);
  }

  get energyVectors() {
    return this._energyVectors;
  }

  @Output() delete = new EventEmitter<EnergyVector>();

  deleteVisibility = {};

  onDelete(energyVector: EnergyVector) {
    this.delete.emit(energyVector);
  }

  onShowDelete(energyVector: EnergyVector) {
    this.deleteVisibility[energyVector.name + energyVector.type] = true;
  }

  onHideDelete(energyVector: EnergyVector) {
    this.deleteVisibility[energyVector.name + energyVector.type] = false;
  }

  get spinnerColor(): string {
    return SPINNER_COLOR;
  }

  get spinnerDiameter(): number {
    return SPINNER_DIAMETER;
  }
}
