import { Utils } from 'prosumer-core/utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Component, Inject } from '@angular/core';
import { TopologyResult } from '@prosumer/results/models';

import { ResultsPerceptionService } from '../case-results-perception';
import {
  RESULT_VIZ_SERVICE,
  ResultsVisualizerController,
} from '../results-visualizer';
import { TopologyResultsService } from './topology-results.service';

@Component({
  selector: 'prosumer-topology-results',
  templateUrl: './topology-results.component.html',
  styleUrls: ['./topology-results.component.scss'],
  providers: [
    { provide: RESULT_VIZ_SERVICE, useClass: TopologyResultsService },
  ],
})
export class TopologyResultsComponent extends ResultsVisualizerController<TopologyResult> {
  constructor(
    @Inject(RESULT_VIZ_SERVICE) service: TopologyResultsService,
    perceptionService: ResultsPerceptionService,
  ) {
    super(service, perceptionService);
  }

  getLegendNames$(): Observable<string[]> {
    return this.cumulative$.pipe(
      map((data) => data.map((datum) => datum.name)),
      map((data) => Utils.removeDuplicates(data)),
    );
  }
}
