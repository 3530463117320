import { EnergyVector, ProsumerView } from 'prosumer-app/+scenario/models';
import {
  EnergyVectorInfoService,
  VariationInfoService,
} from 'prosumer-app/+scenario/services';
import { GeneralCompletion } from 'prosumer-app/+scenario/services/completion-strategies/general-completion.strategy';
import {
  ScenarioCompletionService,
  ScenarioWizardStep,
} from 'prosumer-app/+scenario/services/scenario-completion';
import { ScenarioInfoService } from 'prosumer-app/+scenario/services/scenario-info';
import {
  PREDEFINED_ENERGY_VECTOR_MAP_LABEL,
  PREDEFINED_ENERGY_VECTORS,
} from 'prosumer-app/app.references';
import { DialogService } from 'prosumer-app/libs/eyes-core';
import {
  CustomValidators,
  fadeInAnimation,
  StepFormComponent,
  toTitleCase,
} from 'prosumer-app/libs/eyes-shared';
import { IconButtonToggle, NameValidator } from 'prosumer-app/shared';
import { UpdateStatus } from 'prosumer-app/shared/directives/scenario-updater';
import { ManagedDataService } from 'prosumer-app/shared/services/managed-data';
import { NotificationsService } from 'prosumer-app/shared/services/notification';
import { EnergyVectorQuery } from 'prosumer-app/stores/energy-vector';
import { ScenarioGenericQuery } from 'prosumer-app/stores/scenario-generic';
import { PipeUtils } from 'prosumer-core/utils';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';

import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  Optional,
  Self,
} from '@angular/core';
import {
  NgControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
  EnergyVectorDialogComponent,
  EnergyVectorFormDialogData,
} from './energy-vector-dialog';

@UntilDestroy()
@Component({
  selector: 'prosumer-general-form',
  templateUrl: './general-form.component.html',
  styleUrls: ['./general-form.component.scss'],
  animations: [fadeInAnimation],
  providers: [ManagedDataService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneralFormComponent
  extends StepFormComponent
  implements OnInit, AfterViewInit
{
  @Input() predefinedEnergyVectors: Array<IconButtonToggle<string>>;
  @Input() loadFormData: ScenarioWizardStep;

  energyVectorsForm: UntypedFormGroup;
  updateStatuses: Record<string, UpdateStatus>;
  view = ProsumerView.general;

  checkIfHasCustomEV$ = new BehaviorSubject<boolean>(false);
  readonly scenarioName$: Observable<string>;
  readonly economicsWacc$: Observable<number>;
  readonly energyVectors$: Observable<EnergyVector[]>;

  readonly energyVectorSaved$ = new BehaviorSubject<Record<string, string>>({});
  readonly energyVectorMessages$ = this.energyVectorSaved$.pipe(
    map((updateStatus) =>
      Object.values(updateStatus).filter((value) => !!value),
    ),
    this.takeUntilShare(),
  );
  waitingResponse$ = new BehaviorSubject<Record<string, boolean>>({});
  nextValidScenarioName$ = this.form.controls.name.valueChanges.pipe(
    PipeUtils.filterOutUndefined,
    tap(() => this.form.controls.name.markAsTouched()),
    filter((value) => NameValidator.isValidWithCore(value).length === 0),
  );

  get waccCtrl() {
    return this.form.controls.wacc;
  }

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    public changeDetector: ChangeDetectorRef,
    public formBuilder: UntypedFormBuilder,
    private _dialogService: DialogService,
    private readonly scenarioInfoService: ScenarioInfoService,
    private readonly query: ScenarioGenericQuery,
    private completion: ScenarioCompletionService,
    private readonly evInfoService: EnergyVectorInfoService,
    private readonly evQuery: EnergyVectorQuery,
    private variationService: VariationInfoService,
    public _notifications: NotificationsService,
  ) {
    super(ngControl, changeDetector, formBuilder);
    this.subscribeToFormForCompletionTracking();
    this.scenarioName$ = this.query.scenarioName$;
    this.economicsWacc$ = this.query.economicsWacc$;
    this.energyVectors$ = this.evQuery.energyVectors$;
  }

  ngOnInit() {
    this._dialogService.dialogOpened$.subscribe((dialogRef) => {
      if (
        dialogRef.componentInstance.constructor.name ===
        'EnergyVectorDialogComponent'
      ) {
        dialogRef.componentInstance.evInfoService = this.evInfoService;
      }
    });
    this.subToWaccChangeToShowErrorOnFirstTouch();
  }

  ngAfterViewInit() {
    this.energyVectorsForm = this.form.controls
      .energyVectors as UntypedFormGroup;
  }

  private subToWaccChangeToShowErrorOnFirstTouch() {
    this.waccCtrl.valueChanges
      .pipe(take(1))
      .subscribe((_) => this.waccCtrl.markAsTouched());
  }

  defineForm() {
    return {
      name: [undefined, [Validators.required, NameValidator.validWithCore()]],
      wacc: [
        undefined,
        [Validators.required, CustomValidators.betweenZeroAndOne()],
      ],
      energyVectors: this.createEnergyVectorsForm(),
      scenarioVariations: [undefined],
    };
  }

  createEnergyVectorsForm(): UntypedFormGroup {
    return this.formBuilder.group(
      {
        predefined: undefined,
        others: undefined,
      },
      { validator: this.atLeastOneRequired },
    );
  }

  atLeastOneRequired(group: UntypedFormGroup) {
    if (group) {
      const predefinedValue: Array<any> = group.controls.predefined.value;
      const othersValue: Array<any> = group.controls.others.value;
      if (
        (predefinedValue && predefinedValue.length > 0) ||
        (othersValue && othersValue.length > 0)
      ) {
        return null;
      }
    }
    return { required: true };
  }

  onAddCustomEnergyVector() {
    this._dialogService
      .openDialog(EnergyVectorDialogComponent, this.initDialogData())
      .pipe(this.takeUntilShare())
      .subscribe();
  }

  onDeleteCustomEnergyVector(data: EnergyVector) {
    this.vectorRequestStatus(data, true);
    this.evInfoService
      .deleteEnergyVector(data.value)
      .pipe(take(1))
      .subscribe(
        () => {
          this.vectorRequestStatus(data, false);
        },
        (error: HttpErrorResponse) => {
          this._notifications.showError(error.error?.error ?? error.message);
          this.vectorRequestStatus(data, false);
        },
      );
  }

  private vectorRequestStatus(energyVector: EnergyVector, loading: boolean) {
    this.waitingResponse$.next({
      ...this.waitingResponse$.value,
      [energyVector.value]: loading,
    });
  }

  onEnergyVectorSaved(data: Record<string, string>) {
    this.energyVectorSaved$.next({ ...this.energyVectorSaved$.value, ...data });
  }

  protected initDialogData(): EnergyVectorFormDialogData {
    return {
      mode: 'add',
      energyVectors$: this.energyVectors$,
      energyVectorTypeOptions: PREDEFINED_ENERGY_VECTORS.map((vector) => ({
        name: toTitleCase(PREDEFINED_ENERGY_VECTOR_MAP_LABEL[vector.value]),
        value: vector.value,
      })),
      width: 600,
      disableClose: true,
    };
  }

  private subscribeToFormForCompletionTracking(): void {
    const strategy = new GeneralCompletion();
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((form) => {
      this.completion.setForGeneral(strategy.determineStatus(form));
    });
  }

  onStatusChange(data: UpdateStatus) {
    this.updateStatuses = { ...this.updateStatuses, [data.key]: data };

    if (data.status === 'failed') {
      this.form.controls[data.key].setErrors({ saveError: true });
      this.form.controls[data.key].markAsTouched();
    }
  }
}
