import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FuelsGetterDirective } from './fuels-getter.directive';

@NgModule({
  declarations: [FuelsGetterDirective],
  imports: [CommonModule],
  exports: [FuelsGetterDirective],
})
export class FuelsGetterModule {}
