import { ChartData, ChartDataset, ChartOptions, ChartType } from 'chart.js';
import { randomIntFromInterval } from 'prosumer-app/libs/eyes-shared';
import { Utils } from 'prosumer-core/utils';
import { BehaviorSubject, Observable } from 'rxjs';
import { delay } from 'rxjs/operators';

import { Component, Input, OnInit } from '@angular/core';

import { ChartJSChart } from '../chartjs.base';
import { CustomLegend } from '../custom-legend/custom-legend.model';
import { AreaStack, StackedAreaData } from './stacked-area-chartjs.model';

@Component({
  selector: 'prosumer-stacked-area-chartjs',
  templateUrl: './stacked-area-chartjs.component.html',
  styleUrls: ['./stacked-area-chartjs.component.scss'],
})
export class StackedAreaChartjsComponent
  extends ChartJSChart<StackedAreaData>
  implements OnInit
{
  @Input() enableXlsxExport = false;
  @Input() allData: any;

  private legendDataSubject = new BehaviorSubject<CustomLegend>(undefined);

  get legendDetails() {
    return this.legendDataSubject.getValue();
  }
  legendDetails$ = this.selectDelayedLegendDetails();

  ngOnInit(): void {
    this.isCustomLegend = true;
  }
  getDemoData(): StackedAreaData {
    const YEARS = ['2021', '2022', '2023', '2024'];
    return {
      name: 'Stacked Area Sample Data',
      xAxisTicks: YEARS,
      stacks: [
        {
          label: 'My Data',
          points: YEARS.map(() => randomIntFromInterval(100, 1000)),
        },
      ],
    };
  }
  getDefaultID(): string {
    return 'stackedAreaChartJS';
  }
  getChartType(): ChartType {
    return 'line';
  }
  mapToChartJSData(data: StackedAreaData): ChartData {
    const labels = Utils.removeDuplicates(data.xAxisTicks);
    return {
      labels,
      datasets: data.stacks.map((stack, idx) =>
        this.mapToChartJSDataset(stack, idx),
      ),
    };
  }

  initializeCustomLegend(data): void {
    this.legendDataSubject.next({
      legendItems: data.options.plugins.legend.labels.generateLabels(data),
      canvasId: data.canvas.id,
    });
  }

  getAdditionalChartOptions(data: StackedAreaData): ChartOptions {
    return {
      scales: {
        y: { stacked: true, title: { text: data.yAxisLabel, display: true } },
        x: { title: { text: data.xAxisLabel, display: true } },
      },
      elements: {
        point: {
          hoverRadius: 4,
          hitRadius: 100,
        },
      },
    };
  }

  onLegendDataToggle({ index }) {
    this.chartInstance.setDatasetVisibility(
      index,
      !this.isDatasetVisible(index),
    );
    this.chartInstance.update();
  }

  private selectDelayedLegendDetails(): Observable<CustomLegend> {
    return this.legendDataSubject.pipe(delay(0));
  }

  private mapToChartJSDataset(stack: AreaStack, idx: number): ChartDataset {
    const { label, points } = stack;
    const color = this.resolveColorByIdx(idx);
    return {
      label,
      data: points,
      fill: true,
      backgroundColor: color,
      borderColor: super.translucinizeColor(color),
      pointRadius: 0,
    };
  }
}
