import { MobilityForm } from '../../models';
import { CompletionStrategy } from './completion-strategy';

export class MobilityCompletion extends CompletionStrategy<MobilityForm> {
  isFull(form: MobilityForm): boolean {
    return this.getRequired(form).every(Boolean);
  }

  isHalf(form: MobilityForm): boolean {
    return this.getRequired(form).some(Boolean);
  }

  private getRequired(form: MobilityForm): boolean[] {
    return [
      !!form.routes?.routes?.length,
      !!form.vehicles?.length,
      !!form.stationVehicleAssoc?.length,
    ];
  }
}
