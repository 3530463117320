import { DialogService } from 'prosumer-app/libs/eyes-core';
import {
  containsSubstring,
  FormFieldOption,
} from 'prosumer-app/libs/eyes-shared';
import {
  Equipment,
  Station,
  StationVehicleAssoc,
  Vehicle,
  VehiclesDispatch,
} from 'prosumer-scenario/models';
import { BehaviorSubject } from 'rxjs';

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import {
  ReferencesBuilder,
  TABLE_REF_KEYS,
} from 'prosumer-app/services/references-builder';
import { SparklineTableColumnDefinition } from 'prosumer-app/shared/components/sparkline-table/sparkline-table.model';
import { ScenarioDetailType } from 'prosumer-app/stores';
import { MobilityStationStore } from 'prosumer-app/stores/mobility-station';
import { switchMap, take } from 'rxjs/operators';
import { MobilityFormService } from '../mobility-form.service';
import {
  VehicleStationFormDialogComponent,
  VehicleStationFormDialogData,
} from '../vehicle-station-form-dialog';

@Component({
  selector: 'prosumer-vehicle-station-form-component',
  templateUrl: './vehicle-station-form.component.html',
  styleUrls: ['./vehicle-station-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleStationFormComponent {
  @Input() isMultiNode: boolean;
  @Input() isViewOnly: boolean;

  _scenarioIdentity: any;
  get scenarioIdentity(): any {
    return this._scenarioIdentity;
  }
  @Input() set scenarioIdentity(_scenarioIdentity: any) {
    this._scenarioIdentity = _scenarioIdentity;
  }

  _hasVehicleDispatch = false;

  get hasVehicleDispatch() {
    return this._hasVehicleDispatch;
  }

  @Input() set hasVehicleDispatch(value) {
    this._hasVehicleDispatch = value;
  }

  _vehiclesOptions$ = new BehaviorSubject<FormFieldOption<string>[]>([]);
  _stationsOptions$ = new BehaviorSubject<FormFieldOption<string>[]>([]);
  _vehicleNameOptions$ = new BehaviorSubject<FormFieldOption<string>[]>([]);
  _nodeOptions$ = new BehaviorSubject<FormFieldOption<string>[]>([]);
  _vehicleDispatchList: Array<VehiclesDispatch> = [];
  _equipmentList: Array<Equipment> = [];
  _customDispatchList: Array<{ id; name; vehicleId }> = [];

  @Input() set equipmentList(equipmentList: Equipment[]) {
    if (!!equipmentList) {
      const vehicles = (equipmentList || [])
        .filter((equip: Equipment) => equip.type === 'vehicle')
        .map((vehicle: Vehicle) => ({
          name: vehicle.name,
          value: vehicle.id,
          object: vehicle,
        }));
      const stations = (equipmentList || [])
        .filter((equip: Equipment) => equip.type === 'station')
        .map((station: Station) => ({
          name: station.name,
          value: station.id,
          nodes: station.nodes,
          object: station,
        }));
      this._vehiclesOptions$.next(vehicles);
      this._stationsOptions$.next(stations);
      this._equipmentList = equipmentList;
    }
  }

  @Input() set nodeOptions(nodeOptions) {
    this._nodeOptions$.next(nodeOptions);
  }

  get nodeOptions() {
    return this._nodeOptions$.getValue();
  }

  @Input() set vehicleDispatchList(value: VehiclesDispatch[]) {
    this._vehicleDispatchList = value;
    this.hasVehicleDispatch = value.length > 0;

    if (!!this.vehicleDispatchList) {
      const dispatchList = value.map((vehicle) => ({
        value: vehicle.id,
        name: vehicle.vehicleName,
        vehicleId: vehicle.vehicleId,
      }));
      this._customDispatchList = value.map((vehicle) => ({
        id: vehicle.id,
        name: vehicle.vehicleName,
        vehicleId: vehicle.vehicleId,
      }));

      this._vehicleNameOptions$.next(dispatchList);
    }
  }

  get vehicleDispatchList() {
    return this._vehicleDispatchList;
  }

  tableMetaData: SparklineTableColumnDefinition = {
    vehicleId: {
      name: 'Vehicle Technology',
      type: 'custom',
      referenceKey: TABLE_REF_KEYS.equipments,
      sortable: false,
      toolTip: 'wizard_mobility.wizard_mobility_station_vehicle_vehicle_type',
      cellTooltipReferenceKey: TABLE_REF_KEYS.equipments,
    },
    stationId: {
      name: 'Station',
      type: 'custom',
      referenceKey: TABLE_REF_KEYS.equipments,
      toolTip: 'wizard_mobility.wizard_mobility_station_vehicle_station',
      cellTooltipReferenceKey: TABLE_REF_KEYS.equipments,
    },
    vehicleNames: {
      name: 'Vehicle Name',
      type: 'referenceList',
      referenceKey: TABLE_REF_KEYS.dispatches,
      toolTip: 'wizard_mobility.wizard_mobility_station_vehicle_vehicle_name',
    },
    stationNodes: {
      name: 'Station Node',
      type: 'referenceList',
      referenceKey: TABLE_REF_KEYS.nodes,
      toolTip: 'wizard_mobility.wizard_mobility_station_vehicle_station_node',
    },
    actions: {
      name: 'Actions',
      type: 'action',
      sortable: true,
    },
  };

  /**
   * For table search filter, find matching energy vector from list
   */
  vehicleSearchPredicate = (data: StationVehicleAssoc, filter: string) => {
    const filteredEVs = this._vehiclesOptions$.value.filter((vehicle) =>
      containsSubstring(vehicle.name, filter, true),
    );
    return !!filteredEVs
      ? filteredEVs.some((vehicle) => data.vehicleId === vehicle.value)
      : false;
  };

  vehicleStationsList$ = this.service.selectActiveMobilityStations();
  readonly references$ = this.references.selectRefs();

  constructor(
    private dialog: DialogService,
    private readonly service: MobilityFormService,
    private readonly references: ReferencesBuilder,
    private readonly mobililtyStations: MobilityStationStore,
  ) {}

  generateDialogData(): VehicleStationFormDialogData {
    const dialogData = this.service.prepOptionsForStationDialog();
    return {
      width: '90%',
      mode: 'add',
      disableClose: true,
      currentData: undefined,
      isMultiNode: this.isMultiNode,
      id: undefined,
      vehicleId: '',
      stationId: '',
      vehicleNames: [],
      stationNodes: [],
      ...dialogData,
    };
  }

  onAdd(): void {
    this.dialog
      .openDialog(VehicleStationFormDialogComponent, this.generateDialogData())
      .subscribe();
  }

  onEdit(data: StationVehicleAssoc) {
    this.mobililtyStations
      .getSingle(ScenarioDetailType.mobilityStation, data.id)
      .pipe(
        take(1),
        switchMap((mobilityStation) =>
          this.dialog.openDialog(VehicleStationFormDialogComponent, {
            ...this.generateDialogData(),
            mode: 'edit',
            id: mobilityStation.id,
            vehicleId: mobilityStation.vehicleId,
            stationId: mobilityStation.stationId,
            vehicleNames: mobilityStation.vehicleNames,
            stationNodes: mobilityStation.stationNodes,
            currentData: mobilityStation,
            isViewOnly: this.isViewOnly,
            isMultiNode: this.isMultiNode,
          }),
        ),
      )
      .subscribe();
  }

  onDelete(data: StationVehicleAssoc) {
    this.service.deleteAssociation(data.id).pipe(take(1)).subscribe();
  }
}
