<div class="container" *ngrxLet="nodes$ as nodesListData">
  <form [formGroup]="sankeyDataFormGroup">
    <!-- Nodes -->
    <mat-card appearance="outlined">
      <mat-card-header>
        <mat-card-title>{{ 'Result.labels.nodes' | translate }}</mat-card-title>
        <mat-chip
          class="custom-chip"
          [disableRipple]="true"
          [class.custom-chip-visible]="nodeCtrls.dirty"
        >
          <mat-icon>error</mat-icon>
          <span class="custom-chip-text">{{
            'Result.messages.warnings.needToSave' | translate
          }}</span>
        </mat-chip>
      </mat-card-header>
      <!-- node - add button -->
      <mat-card-actions class="custom-card-action">
        <button
          mat-flat-button
          color="primary"
          type="button"
          (click)="onAddNode()"
          data-testid="flow-basic-editor-node-add"
        >
          <mat-icon aria-label="add">add</mat-icon>
          <span>{{ 'Result.labels.addNode' | translate }}</span>
        </button>
      </mat-card-actions>
      <mat-card-content>
        <mat-list role="list" class="custom-list" formArrayName="nodes">
          @for (ctrl of nodeCtrls.controls; track idx; let idx = $index) {
            <mat-list-item
              role="listitem"
              class="custom-list-item"
              [formGroup]="ctrl"
            >
              <!-- node - name -->
              <mat-form-field
                appearance="outline"
                class="custom-form-field-nodes"
              >
                <mat-label>
                  {{ 'Result.labels.columnName' | translate }}
                </mat-label>
                <input
                  matInput
                  formControlName="name"
                  [placeholder]="'Result.placeholder.node.name' | translate"
                  (input)="
                    onCtrlChange(
                      $event,
                      ctrl.get('id').getRawValue(),
                      'name',
                      'node'
                    )
                  "
                  data-testid="flow-basic-editor-node-name"
                />
                @if (ctrl?.get('name')?.invalid) {
                  <mat-error data-testid="flow-basic-editor-node-name-error">{{
                    'Result.messages.errors.' +
                      (ctrl.get('name').errors | keyvalue)[0].key | translate
                  }}</mat-error>
                }
                <!-- show them all errors in 1 row -->
                <!-- @if (ctrl?.get('name')?.invalid) {<mat-error> @for (e of ctrl?.get('name')?.errors | keyvalue;track e.key) {<span>{{ 'Result.messages.errors.' + e.key | translate }}</span>}</mat-error>} -->
              </mat-form-field>
              <!-- node - color picker -->
              <div class="container-color-picker">
                <input
                  formControlName="color"
                  [style.background]="ctrl.get('color').getRawValue()"
                  [colorPicker]="ctrl.get('color').getRawValue()"
                  [cpPosition]="'left'"
                  (colorPickerChange)="
                    onColorChange(
                      $event,
                      ctrl.get('id').getRawValue(),
                      'color',
                      'node'
                    )
                  "
                  data-testid="flow-basic-editor-node-colorpicker"
                />
                @if (!ctrl.get('color').getRawValue()) {
                  <span
                    class="hint"
                    data-testid="flow-basic-editor-node-colorpicker-hint"
                    >Edit color</span
                  >
                }
              </div>
              <!-- node - delete button -->
              <button
                mat-icon-button
                type="button"
                class="custom-btn delete"
                (click)="onDeleteNode(ctrl.get('id').getRawValue(), idx)"
                data-testid="flow-basic-editor-node-delete"
              >
                <mat-icon aria-label="delete" aria-hidden="false">
                  delete_forever
                </mat-icon>
              </button>
            </mat-list-item>
          } @empty {
            <mat-list-item
              role="listitem"
              data-testid="flow-basic-editor-node-list-empty"
            >
              {{ 'Result.messages.warnings.noNodes' | translate }}
            </mat-list-item>
          }
        </mat-list>
      </mat-card-content>
    </mat-card>
    <br />
    <!-- Links -->
    <mat-card appearance="outlined">
      <mat-card-header fxLayout="row">
        <mat-card-title>Links</mat-card-title>
        <mat-chip
          fxFlex
          class="custom-chip"
          [disableRipple]="true"
          [class.custom-chip-visible]="linkCtrls.dirty"
        >
          <mat-icon>error</mat-icon>
          <span class="custom-chip-text">{{
            'Result.messages.warnings.needToSave' | translate
          }}</span>
        </mat-chip>

        <prosumer-tooltip-button
          [matIconLabel]="'add'"
          [buttonText]="'Generic.labels.new' | translate"
          data-testid="sparkline-add-tooltip-button-component"
          (clickEvent)="onAddLink()"
        ></prosumer-tooltip-button>
        <!-- [disabledAddBtnMessage]="disabledAddBtnMessage" -->
        <!-- [isBtnDisabled]="disableNewButton || loading || saving" -->
        <!-- (clickEvent)="onAdd()" -->
      </mat-card-header>
      <mat-card-content>
        <mat-table
          multiTemplateDataRows
          [formArrayName]="'links'"
          [dataSource]="links$ | ngrxPush"
          class="flow-links mat-elevation-z8"
        >
          <!-- Source Column -->
          <ng-container matColumnDef="source">
            <th mat-header-cell *matHeaderCellDef>Source<span>*</span></th>
            <td
              mat-cell
              *matCellDef="let element; let idx = dataIndex"
              [class.hasSourceError]="hasSourceTargetError(idx, 'source')"
              [formGroupName]="idx"
            >
              <input formControlName="id" type="hidden" />
              <mat-form-field fxFlex appearance="outline">
                <mat-select
                  #matSelect
                  formControlName="source"
                  [value]="element.source"
                  [matTooltipPosition]="'above'"
                  [hideSingleSelectionIndicator]="true"
                  [matTooltip]="matSelect.triggerValue"
                  (selectionChange)="
                    onCtrlChange($event, element.id, 'source', 'link')
                  "
                >
                  @for (node of nodeOptions(); track node.name) {
                    <mat-option
                      [value]="node.name"
                      [matTooltip]="node.name"
                      [matTooltipPosition]="'right'"
                      >{{ node.name }}</mat-option
                    >
                  }
                  <mat-option hidden value="">{{ ' - select -' }}</mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </ng-container>

          <!-- Target Column -->
          <ng-container matColumnDef="target">
            <th mat-header-cell *matHeaderCellDef>Target<span>*</span></th>
            <td
              mat-cell
              *matCellDef="let element; let idx = dataIndex"
              [class.hasTargetError]="hasSourceTargetError(idx, 'target')"
            >
              <mat-form-field fxFlex appearance="outline" [formGroupName]="idx">
                <mat-select
                  #matSelect
                  formControlName="target"
                  [value]="element.target"
                  [matTooltipPosition]="'above'"
                  [hideSingleSelectionIndicator]="true"
                  [matTooltip]="matSelect.triggerValue"
                  (selectionChange)="
                    onCtrlChange($event, element.id, 'target', 'link')
                  "
                >
                  @for (node of nodeOptions(); track node.name) {
                    <mat-option
                      [value]="node.name"
                      [matTooltip]="node.name"
                      [matTooltipPosition]="'right'"
                      >{{ node.name }}</mat-option
                    >
                  }
                  <mat-option hidden value="">{{ ' - select -' }}</mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </ng-container>

          <!-- Value Column -->
          <ng-container matColumnDef="value">
            <th mat-header-cell *matHeaderCellDef>Value<span>*</span></th>
            <td
              mat-cell
              *matCellDef="let element; let idx = dataIndex"
              [class.hasValueError]="hasValueError(idx)"
            >
              <mat-form-field appearance="outline" [formGroupName]="idx">
                <input
                  matInput
                  formControlName="value"
                  (change)="onCtrlChange($event, element.id, 'value', 'link')"
                />
              </mat-form-field>
            </td>
          </ng-container>

          <!-- Color Column -->
          <ng-container matColumnDef="color">
            <th mat-header-cell *matHeaderCellDef>Color</th>
            <td
              mat-cell
              [formGroupName]="idx"
              *matCellDef="let element; let idx = dataIndex"
            >
              <input
                class="color-select"
                cpPosition="top-left"
                formControlName="color"
                [placeholder]="'Edit color'"
                [colorPicker]="element.color"
                [style.--color-picker]="element.color"
                (colorPickerChange)="
                  onColorChange($event, element.id, 'color', 'link')
                "
              />
            </td>
          </ng-container>

          <!-- Actions Column -->
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let element; let idx = dataIndex">
              <button
                mat-icon-button
                (click)="onDeleteLink(element.id)"
                data-testid="flow-basic-editor-link-delete"
                type="button"
                color="warn"
              >
                <mat-icon
                  aria-hidden="false"
                  aria-label="delete"
                  tooltipPosition="center"
                >
                  delete_forever
                </mat-icon>
              </button>
            </td>
          </ng-container>

          <!-- Errors Column -->
          <ng-container matColumnDef="errors">
            <td
              mat-cell
              *matCellDef="let element; let idx = dataIndex"
              [attr.colspan]="displayedColumns.length"
            >
              <div
                [@detailExpand]="
                  getLinkErrorKeys(idx).length ? 'expanded' : 'collapsed'
                "
              >
                <!-- @if (getLinkErrorKeys(idx).length > 0) { -->
                <mat-error class="row-errors" [formGroupName]="idx">
                  @for (key of getLinkErrorKeys(idx); track key) {
                    <span
                      >{{ 'Result.messages.errors.' + key | translate }};
                    </span>
                  }
                </mat-error>
                <!-- } -->
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: ['errors']"
            class="error-messages-expanded-table-row"
          ></tr>
        </mat-table>
      </mat-card-content>
    </mat-card>
    <!-- save button -->
    <div class="container-buttons">
      <button
        mat-flat-button
        color="primary"
        [disabled]="sankeyDataFormGroup.invalid || sankeyDataFormGroup.pristine"
        (click)="onSave()"
        data-testid="flow-basic-editor-save"
      >
        <mat-icon aria-label="save"> save </mat-icon>
        <span>{{ 'Result.labels.save' | translate }}</span>
      </button>
    </div>
  </form>
</div>
