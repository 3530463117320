export const TIME_HORIZON = {
  currentYear: new Date().getFullYear(),
  durationGap: 20,
  acceptableDebounce: 250, // milliseconds
  optimizationYearsLimit: 3,
  defaultHorizon: 'full',
  spinnerSizeYearsToOptimize: 22,
  spinnerColorYearsToOptimize: 'accent',
};

const cascadeTimeHorizon = {
  timePeriod: { displayString: 'Periods', listKey: null, format: 'count' },
  yearsToOptimize: {
    displayString: 'Years to Optimize',
    listKey: 'years',
    format: 'itemize',
  },
};

export const cascadeTimeHorizonMap = new Map(
  Object.entries(cascadeTimeHorizon),
);
