import { Injectable } from '@angular/core';
import { ActiveKeeperService } from 'prosumer-app/services/active-keeper';
import { ScenarioDetailQuery } from '../scenario-detail';
import { SpinningReserveActivation } from './spinning-reserve-activation.state';
import { SpinningReserveActivationStore } from './spinning-reserve-activation.store';

@Injectable({ providedIn: 'root' })
export class SpinningReserveActivationQuery extends ScenarioDetailQuery<SpinningReserveActivation> {
  constructor(
    store: SpinningReserveActivationStore,
    keeper: ActiveKeeperService,
  ) {
    super(store, keeper);
  }
}
