import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Injectable()
export class CO2EmissionsService {
  _asset$ = new BehaviorSubject<Array<any>>(undefined);
  _scope$ = new BehaviorSubject<Array<any>>(undefined);

  asset$ = this._asset$.asObservable().pipe(shareReplay(1));
  scope$ = this._scope$.asObservable().pipe(shareReplay(1));

  constructor() {}

  setAsset(value: Array<any>) {
    this._asset$.next(value);
  }

  setScope(value: Array<any>) {
    this._scope$.next(value);
  }
}
