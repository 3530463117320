import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Injectable()
export class DispatchService {
  _year$ = new BehaviorSubject<string>(undefined);
  _node$ = new BehaviorSubject<string>(undefined);
  _energyVector$ = new BehaviorSubject<string>(undefined);
  _minDate$ = new BehaviorSubject<number>(1);
  _maxDate$ = new BehaviorSubject<number>(7);

  year$ = this._year$.asObservable().pipe(shareReplay(1));
  node$ = this._node$.asObservable().pipe(shareReplay(1));
  energyVector$ = this._energyVector$.asObservable().pipe(shareReplay(1));
  minDate$ = this._minDate$.asObservable().pipe(shareReplay(1));
  maxDate$ = this._maxDate$.asObservable().pipe(shareReplay(1));

  constructor() {}

  setYear(value: string) {
    this._year$.next(value);
  }

  setNode(value: string) {
    this._node$.next(value);
  }

  setEnergyVector(value: string) {
    this._energyVector$.next(value);
  }

  setMinDate(value: number) {
    this._minDate$.next(value);
  }

  setMaxDate(value: number) {
    this._maxDate$.next(value);
  }
}
