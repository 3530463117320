<div
  *ngrxLet="storageOptions$ as storageOpts"
  fxLayout="column"
  fxLayoutAlign="start stretch"
  fxLayoutGap="16px"
>
  @if (storageOpts?.length) {
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="32px">
      <span class="storage-dispatch-title">{{
        'Result.labels.storage' | translate
      }}</span>
      <prosumer-select
        fxFlex
        [placeholder]="'Type'"
        [label]="'Type'"
        [control]="storageControl"
        [options]="storageOpts"
        [attr.data-testid]="'dispatch-raw-storage-filter'"
      ></prosumer-select>
    </div>

    <prosumer-stacked-bar-chartjs
      [data]="wrappedChartData$ | ngrxPush"
      [line]="lineData$ | ngrxPush"
      [colors]="wrappedColor$ | ngrxPush"
      [enableXlsxExport]="true"
      [scenarioName]="scenarioName"
      resultsName="storage_raw_dispatch"
    ></prosumer-stacked-bar-chartjs>
  }
</div>
