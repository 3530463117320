import { Injectable } from '@angular/core';
import { ResultsExtractorService } from '@prosumer/results/state';
import { Observable } from 'rxjs';
import { TopologyResult } from '../../models';
import { FlattenedSlicesStrategy } from '../bar-and-area-results';
import { ResultVisualizerSlice } from '../results-visualizer';
import { TOPOLOGY_SLICES } from './topology-results.tokens';

@Injectable()
export class TopologyResultsService extends FlattenedSlicesStrategy<TopologyResult> {
  constructor(private results: ResultsExtractorService) {
    super();
  }

  getAllYearsStream(): Observable<number[]> {
    return this.results.getAllYearsStream();
  }

  getYearsToOptimizeStream(): Observable<number[]> {
    return this.results.getOptimizedYearsStream();
  }
  getResultDataStream(): Observable<TopologyResult[]> {
    return this.results.getTopologyStream();
  }

  getFilterKeys(): string[] {
    return ['origin_node', 'destination_node', 'fluid'];
  }
  getSlices(): ResultVisualizerSlice[] {
    return TOPOLOGY_SLICES;
  }
}
