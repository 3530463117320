import { combineLatest, Observable } from 'rxjs';
import { map, mergeMap, startWith, withLatestFrom } from 'rxjs/operators';

import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { ResultsTabulatorData, YearlyValues } from '../results-tabulator';
import {
  ResultNameValue,
  ResultsVisualizationType,
  VisualizerData,
} from './results-visualizer.model';
import {
  RESULT_VIZ_SERVICE,
  ResultsVisualizerService,
} from './results-visualizer.service';
import { VisualizerComponent } from './visualizer.base';

@Component({
  selector: 'prosumer-results-visualizer',
  templateUrl: './results-visualizer.component.html',
  styleUrls: ['./results-visualizer.component.scss'],
})
export class ResultsVisualizerComponent
  extends VisualizerComponent
  implements OnInit
{
  @Input() title: string;
  @Input() scenarioName = 'scenario';
  @Input() tableName = 'table';

  optimizedYearsTooltip$: Observable<string>;
  resultsTabulee$: Observable<ResultsTabulatorData[]>;
  yearsToShow$: Observable<number[]>;

  showOptimizedControl: UntypedFormControl = new UntypedFormControl(false);

  constructor(
    @Inject(RESULT_VIZ_SERVICE) private service: ResultsVisualizerService,
  ) {
    super(service);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.optimizedYearsTooltip$ = this.getOptimizedYearsTooltipStream();
    this.resultsTabulee$ = this.getResultsTabuleeStream();
    this.yearsToShow$ = this.getYearsToShowStream();

    this.subscribeToOptimizedToggleForService();
  }

  private getResultsTabuleeStream(): Observable<ResultsTabulatorData[]> {
    return combineLatest([
      this.getResultTypeValueStream(),
      this.service.getIncrementalDataStream(),
      this.service.getCumulativeDataStream(),
      this.service.getSecondaryFilters$(),
    ]).pipe(
      map(([resultType, incremental, cumulative]) =>
        resultType === ResultsVisualizationType.CUMULATIVE
          ? cumulative
          : incremental,
      ),
      map((data) => this.mapVisualizerDataToTabulee(data)),
      withLatestFrom(this.service.getSecondaryFilters$()),
      map(([data, secondary]) =>
        this.applySecondaryFiltersToTabuleez(data, secondary),
      ),
    );
  }

  private applySecondaryFiltersToTabuleez(
    data: ResultsTabulatorData[],
    filters: string[],
  ): ResultsTabulatorData[] {
    return data.filter((datum) => !filters.includes(datum.name));
  }

  private mapVisualizerDataToTabulee(
    data: VisualizerData[],
  ): ResultsTabulatorData[] {
    return data.map((datum) => ({
      name: datum.name,
      values: this.reduceSeriesValuesToYearValues(datum.series),
    }));
  }

  private reduceSeriesValuesToYearValues(
    siriValues: ResultNameValue[],
  ): YearlyValues {
    return siriValues.reduce((acc, curr) => {
      acc[curr.name] = curr.value;
      return acc;
    }, {});
  }

  private getOptimizedYearsTooltipStream(): Observable<string> {
    return this.service
      .getYearsToOptimizeStream()
      .pipe(map((years) => years.join(', ')));
  }

  private subscribeToOptimizedToggleForService(): void {
    this.getShowOptimizedControlValueStream().subscribe((optimize) =>
      this.service.setOptimized(optimize),
    );
  }

  private getYearsToShowStream(): Observable<number[]> {
    return this.getShowOptimizedControlValueStream().pipe(
      mergeMap((optimized) =>
        optimized
          ? this.service.getYearsToOptimizeStream()
          : this.service.getAllYearsStream(),
      ),
    );
  }

  private getShowOptimizedControlValueStream(): Observable<boolean> {
    return this.showOptimizedControl.valueChanges.pipe(
      startWith(this.showOptimizedControl.value),
    );
  }
}
