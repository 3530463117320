import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NodesGetterDirective } from './nodes-getter.directive';

@NgModule({
  declarations: [NodesGetterDirective],
  imports: [CommonModule],
  exports: [NodesGetterDirective],
})
export class NodesGetterModule {}
