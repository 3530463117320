import { ActiveKeeperService } from 'prosumer-app/services/active-keeper';
import { Coerce } from 'prosumer-core/utils';
import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import { ScenarioDetailQuery } from '../scenario-detail';
import { EnergyGridConnectionInfo } from './energy-grid-connection.state';
import { EnergyGridConnectionStore } from './energy-grid-connection.store';

@Injectable({
  providedIn: 'root',
})
export class EnergyGridConnectionQuery extends ScenarioDetailQuery<EnergyGridConnectionInfo> {
  constructor(
    store: EnergyGridConnectionStore,
    readonly keeper: ActiveKeeperService,
  ) {
    super(store, keeper);
  }

  selectActiveEGCs(): Observable<EnergyGridConnectionInfo[]> {
    return this.selectAllForActiveScenario();
  }

  getNodesOfMarket(marketId: string): string[] {
    return Coerce.toArray(Coerce.toObject(this.getEntity(marketId)).nodes);
  }
}
