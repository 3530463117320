import { Component, Inject } from '@angular/core';
import { CashflowData } from '@prosumer/results/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResultsPerceptionService } from '../case-results-perception';
import {
  ResultsVisualizerController,
  RESULT_VIZ_SERVICE,
} from '../results-visualizer';
import { CashflowResultsService } from './cashflow-results.service';

@Component({
  selector: 'prosumer-cashflow-results',
  templateUrl: './cashflow-results.component.html',
  styleUrls: ['./cashflow-results.component.scss'],
  providers: [
    { provide: RESULT_VIZ_SERVICE, useClass: CashflowResultsService },
  ],
})
export class CashflowResultsComponent extends ResultsVisualizerController<CashflowData> {
  constructor(
    @Inject(RESULT_VIZ_SERVICE) private service: CashflowResultsService,
    perceptionService: ResultsPerceptionService,
  ) {
    super(service, perceptionService);
  }

  getLegendNames$(): Observable<string[]> {
    return this.service.getResultDataStream().pipe(
      map((data) => this.service.buildSliceData(data)),
      map((sliceData) =>
        Object.values(sliceData).reduce(
          (list, curr) => [...list, ...Object.keys(curr)],
          [],
        ),
      ),
    );
  }
}
