import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActiveKeeperService } from '../../services/active-keeper/active-keeper.service';
import { EGCEmission, EGCEmissionState } from './egc-emission.state';
import { EGCEmissionStore } from './egc-emission.store';

@Injectable({
  providedIn: 'root',
})
export class EGCEmissionQuery extends QueryEntity<EGCEmissionState> {
  constructor(
    store: EGCEmissionStore,
    private readonly keeper: ActiveKeeperService,
  ) {
    super(store);
  }

  selectAllForActiveScenario(): Observable<EGCEmission[]> {
    return this.selectAll().pipe(map((all) => this.onlyForActiveScenario(all)));
  }

  private onlyForActiveScenario(all: EGCEmission[]): EGCEmission[] {
    return all.filter(
      (egcEmission) =>
        egcEmission.scenarioUuid === this.keeper.getActiveScenario(),
    );
  }
}
