import { Injectable } from '@angular/core';
import { toObject } from 'prosumer-app/libs/eyes-shared';
import { ReferencesBuilder } from 'prosumer-app/services/references-builder';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CommoditiesTableRefs,
  SCOPE_OPTIONS,
  TYPE_OPTIONS,
} from './commodities-form.types';

@Injectable({
  providedIn: 'root',
})
export class CommoditiesReferencesBuilder {
  constructor(private readonly refs: ReferencesBuilder) {}

  selectRefs(): Observable<CommoditiesTableRefs> {
    return this.refs
      .selectRefs()
      .pipe(map((refs) => ({ ...refs, ...this.getRefSnapshot() })));
  }

  private getRefSnapshot(): Pick<
    CommoditiesTableRefs,
    'scope' | 'types' | 'marketVariationAssociation'
  > {
    return {
      scope: this.objectify(SCOPE_OPTIONS),
      types: this.objectify(TYPE_OPTIONS),
      marketVariationAssociation: {},
    };
  }

  private objectify<T>(list: T[]): Record<string, string> {
    return toObject(list, 'value', 'name');
  }
}
