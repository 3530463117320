import { Injectable } from '@angular/core';
import { StoreConfig } from '@datorama/akita';
import { NettingMapper } from 'prosumer-app/+scenario/services/mappers';
import { ScenarioDetailStore, ScenarioDetailType } from '../scenario-detail';
import { TaxAndSubsidy, TaxAndSubsidyBE } from './tax-and-subsidy.state';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: ScenarioDetailType.taxAndSubsidy })
export class TaxAndSubsidyStore extends ScenarioDetailStore<TaxAndSubsidy> {
  getDataUuid(taxAndSubsidy: Record<string, unknown>): unknown {
    return taxAndSubsidy['id'];
  }

  toFE(from: TaxAndSubsidyBE): TaxAndSubsidy {
    return NettingMapper.taxAndSubsidyToFE(from);
  }

  toBE(from: TaxAndSubsidy): TaxAndSubsidyBE {
    return NettingMapper.taxAndSubsidyToBE(from);
  }
}
