import { EgcDuplicateDialogComponent } from 'prosumer-app/+scenario/components/commodities-form/egc-duplicate-dialog';
import { EmissionsDuplicateDialogComponent } from 'prosumer-app/+scenario/components/emissions-duplicate-dialog';
import { EquipmentDuplicateDialogComponent } from 'prosumer-app/+scenario/components/equipment-form/equipment-duplicate-dialog';
import { LoadDuplicateDialogComponent } from 'prosumer-app/+scenario/components/loads-form/load-duplicate-dialog';
import { MarketLimitDuplicateDialogComponent } from 'prosumer-app/+scenario/components/market-limit-duplicate-dialog';
import { LinesDuplicateDialogComponent } from 'prosumer-app/+scenario/components/topology-form/lines-duplicate-dialog';
import {
  Duplicable,
  DuplicableDetailType,
  DuplicateDetailData,
  HasNodeTypeFlag,
  ScenarioDetailType,
} from 'prosumer-app/stores';
import { EGCEmissionQuery } from 'prosumer-app/stores/egc-emission';
import { EnergyGridConnectionQuery } from 'prosumer-app/stores/energy-grid-connection';
import { EquipmentQuery } from 'prosumer-app/stores/equipment';
import { LineQuery } from 'prosumer-app/stores/line';
import { LoadQuery } from 'prosumer-app/stores/load';
import { MarketLimitQuery } from 'prosumer-app/stores/market-limit';
import { Observable } from 'rxjs';

import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

type GetAllFn<T = unknown> = () => T[];
type Named<T = unknown> = T & { name: string };
@Injectable({ providedIn: 'root' })
export class DuplicationStarter {
  constructor(
    private readonly dialog: MatDialog,
    private readonly equipments: EquipmentQuery,
    private readonly loads: LoadQuery,
    private readonly egcs: EnergyGridConnectionQuery,
    private readonly emissions: EGCEmissionQuery,
    private readonly marketLimit: MarketLimitQuery,
    private readonly lineQuery: LineQuery,
  ) {}

  start<T>(dataType: DuplicableDetailType, data: T): Observable<unknown> {
    return this.dialog
      .open(
        this.getDialogClass(dataType),
        this.buildDialogConfig(dataType, data),
      )
      .afterClosed();
  }

  private getDialogClass(
    dataType: DuplicableDetailType,
  ): ComponentType<unknown> {
    return this.dataTypeDialogMap[dataType];
  }

  private get dataTypeDialogMap(): Record<
    DuplicableDetailType,
    ComponentType<unknown>
  > {
    return {
      [ScenarioDetailType.equipment]: EquipmentDuplicateDialogComponent,
      [ScenarioDetailType.load]: LoadDuplicateDialogComponent,
      [ScenarioDetailType.energyGridConnection]: EgcDuplicateDialogComponent,
      [ScenarioDetailType.egcEmission]: EmissionsDuplicateDialogComponent,
      [ScenarioDetailType.marketLimit]: MarketLimitDuplicateDialogComponent,
      [ScenarioDetailType.line]: LinesDuplicateDialogComponent,
    };
  }

  private buildDialogConfig<T>(
    dataType: DuplicableDetailType,
    data: T,
  ): MatDialogConfig<DuplicateDetailData> {
    return {
      data: this.buildDialogData(dataType, this.mapToNamed(dataType, data)),
    };
  }

  private buildDialogData(
    dataType: DuplicableDetailType,
    origin: Named,
  ): DuplicateDetailData {
    return {
      isMultiNode: this.extractNodeTypeFlag(origin),
      origin: origin as unknown as Duplicable,
      existing: this.getExisting(dataType),
    };
  }

  private getExisting(dataType: DuplicableDetailType): Duplicable[] {
    return this.dataTypeGetAllFnMap()
      [dataType]() // eslint-disable-line no-unexpected-multiline
      .map((detail) => this.mapToNamed(dataType, detail)) as Duplicable[];
  }

  private dataTypeGetAllFnMap(): Record<DuplicableDetailType, GetAllFn> {
    return {
      [ScenarioDetailType.equipment]: () => this.equipments.getAll(),
      [ScenarioDetailType.load]: () => this.loads.getAll(),
      [ScenarioDetailType.energyGridConnection]: () => this.egcs.getAll(),
      [ScenarioDetailType.egcEmission]: () => this.emissions.getAll(),
      [ScenarioDetailType.marketLimit]: () => this.marketLimit.getAll(),
      [ScenarioDetailType.line]: () => this.lineQuery.getAll(),
    };
  }

  private mapToNamed<T>(dataType: DuplicableDetailType, data: T): Named {
    return this.dataTypeMapperFnMap()[dataType](data);
  }

  private dataTypeMapperFnMap<T>(): Record<
    DuplicableDetailType,
    (data: T | Named) => Named
  > {
    return {
      [ScenarioDetailType.equipment]: (data: Named) => data,
      [ScenarioDetailType.load]: (data: Named) => data,
      [ScenarioDetailType.energyGridConnection]: (data: T) =>
        this.mapEgcToNamed(data),
      [ScenarioDetailType.egcEmission]: (data: Named) => data,
      [ScenarioDetailType.marketLimit]: (data: Named) => data,
      [ScenarioDetailType.line]: (data: Named) => data,
    };
  }

  private mapEgcToNamed<T>(data: T): Named {
    return { ...data, name: data['marketName'] };
  }

  private extractNodeTypeFlag<T>(data: T & Partial<HasNodeTypeFlag>): boolean {
    return data.isMultiNode;
  }
}
