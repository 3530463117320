<prosumer-sparkline-table
  [columnsDef]="tableMetaData"
  [data]="routesList$ | async"
  [searchInput]="true"
  (add)="onAddRoutes()"
  (edit)="onEditRoutes($event)"
  (delete)="onDeleteRoutes($event)"
  [hideAdd]="isViewOnly"
  [hideEdit]="isViewOnly"
  [hideDelete]="isViewOnly"
  [hideView]="!isViewOnly"
>
</prosumer-sparkline-table>
