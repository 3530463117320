<prosumer-progress-card [showProgress]="loading$ | async">
  <form (ngSubmit)="onSubmit()" [formGroup]="createScenarioForm">
    <h3 class="mat-headline-6" mat-dialog-title>
      <b>{{ 'Scenario.labels.main.create' | translate }}</b>
    </h3>

    <div class="mat-body" mat-dialog-content>
      <div fxLayout="column">
        <mat-form-field appearance="outline">
          <mat-label>{{
            'Scenario.labels.general.name' | translate
          }}</mat-label>
          <input
            matInput
            type="text"
            formControlName="name"
            data-testid="scenario-create-dialog-form-input-name"
            (click)="$event.stopPropagation()"
          />
          <mat-error
            *ngFor="let error of getErrors(createScenarioForm?.controls.name)"
            [innerHTML]="'Scenario.messages.name.' + error | translate"
          ></mat-error>
        </mat-form-field>
      </div>
    </div>

    <div mat-dialog-actions fxLayout="row">
      <button
        mat-flat-button
        type="submit"
        color="primary"
        [disabled]="isFormInvalid || (loading$ | async)"
        data-testid="scenario-create-dialog-form-submit"
      >
        <span>{{ 'Generic.labels.create' | translate }}</span>
      </button>

      <button
        mat-flat-button
        type="button"
        (click)="onCancel()"
        data-testid="scenario-create-dialog-form-cancel"
      >
        <span>{{ 'Generic.labels.cancel' | translate }}</span>
      </button>
    </div>
  </form>
</prosumer-progress-card>
