<form [formGroup]="fuelForm" (ngSubmit)="onConfirm()">
  <h2 mat-dialog-title>{{ data?.mode | titlecase }} Commodity (Fuel)</h2>
  <div class="overflow-hidden" fxLayout="column" mat-dialog-content>
    <!-- Fuel and Node Inputs-->
    <div fxLayout="row" fxLayoutGap="10px">
      <!-- Fuel Input -->
      <prosumer-select
        class="eyes-maximize-width"
        data-testid="fuel-form-dialog-energy-vector"
        [placeholder]="'Scenario.placeholders.fuels.fuel' | translate"
        [tooltip]="'wizard_commodities.wizard_commodities_fuel_vector'"
        [label]="'Scenario.labels.fuels.fuel' | translate"
        [errorMessageMap]="errorMessages['fuel']"
        [options]="data?.energyVectorOptions"
        [control]="fuelForm?.controls?.fuel"
        [required]="true"
      ></prosumer-select>
    </div>
    <div fxLayout="row" fxLayoutGap="10px">
      <!-- Node Input -->
      <prosumer-generic-filterchip-component
        [tooltip]="
          'wizard_commodities.wizard_commodities_fuel_node' | translate
        "
        [placeholder]="
          'Scenario.placeholders.energyGridLimits.node' | translate
        "
        [label]="'Scenario.labels.energyGridLimits.node' | translate"
        [attr.data-testid]="'fuel-form-dialog-node-input'"
        [required]="true"
        [enableSelectAll]="true"
        [errorMessage]="errorMessages.node"
        [genericOptions]="data?.nodeOptions"
        [setSubmitted]="submitted$ | async"
        formControlName="generics"
        class="eyes-maximize-width"
        *ngIf="data?.isMultiNode"
      ></prosumer-generic-filterchip-component>
    </div>
    <!-- Fuel Cost and CO2 Rate Inputs -->
    <div fxLayout="row wrap" fxLayoutGap="10px">
      <!-- Fuel Cost -->
      <prosumer-yearly-chart-input
        fxFlex="49%"
        formControlName="fuelCost"
        inputLabel="Scenario.labels.fuels.fuelCost"
        errorMessage="Scenario.messages.fuelCost"
        contextHelpMsg="wizard_commodities.wizard_commodities_fuel_cost"
        [attr.data-testid]="'fuel-form-dialog-fuel-cost'"
        [isViewOnly]="data?.isViewOnly"
        [startYear]="data?.startYear"
        [endYear]="data?.endYear"
      ></prosumer-yearly-chart-input>
      <!-- CO2 Scope 1 Emission Factor -->
      <prosumer-yearly-chart-input
        fxFlex="49%"
        formControlName="co2Rate"
        inputLabel="Scenario.labels.fuels.co2Rate"
        errorMessage="Scenario.messages.co2Rate"
        [attr.data-testid]="'fuel-form-dialog-co2-scope1-emission-factor'"
        [isViewOnly]="data?.isViewOnly"
        [startYear]="data?.startYear"
        [endYear]="data?.endYear"
      ></prosumer-yearly-chart-input>
    </div>
    <div fxLayout="row" fxLayoutGap="10px">
      <!-- CO2 scope3 emission factor-->
      <prosumer-yearly-chart-input
        fxFlex="49%"
        formControlName="co2Scope3EmissionFactor"
        inputLabel="Scenario.labels.fuels.co2Scope3EmissionFactor"
        errorMessage="Scenario.messages.co2Scope3EmissionFactor"
        [attr.data-testid]="'fuel-form-dialog-co2-scope3-emission-factor'"
        [isViewOnly]="data?.isViewOnly"
        [startYear]="data?.startYear"
        [endYear]="data?.endYear"
      ></prosumer-yearly-chart-input>
      <div fxFlex="49%"></div>
    </div>
  </div>
  <div mat-dialog-actions fxLayout="row">
    <button
      mat-flat-button
      type="submit"
      color="primary"
      data-testid="fuelform-dialog-ok"
      [class.spinner]="saving$ | ngrxPush"
      [class.unclickable]="saving$ | ngrxPush"
      [disabled]="!hasValidChanges() || (saving$ | ngrxPush)"
      *ngIf="!data?.isViewOnly"
    >
      {{ data?.confirm || 'OK' }}
    </button>
    <button
      color=""
      type="button"
      data-testid="fuelform-dialog-cancel"
      [disabled]="saving$ | ngrxPush"
      (click)="onClose()"
      mat-flat-button
    >
      <span *ngIf="!data?.isViewOnly; else closeButton">{{
        'Generic.labels.cancel' | translate
      }}</span>
      <ng-template #closeButton>
        <span>{{ 'Generic.labels.close' | translate }}</span>
      </ng-template>
    </button>
  </div>
</form>
