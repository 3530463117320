import { Injectable } from '@angular/core';
import { Load } from 'prosumer-app/+scenario/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, mergeMap } from 'rxjs/operators';
import { HasLegacyProfiles, ProfileStore } from '../profile';
import { LoadStore } from './load.store';
import { mapSickProfiles } from 'prosumer-app/shared';

export interface LoadParents {
  readonly projectId: string;
  readonly caseId: string;
  readonly scenarioId: string;
}

export type CreatorData = LoadParents & Load;

@Injectable({ providedIn: 'root' })
export class LoadCreator {
  private readonly loading = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly profiles: ProfileStore,
    private readonly loads: LoadStore,
  ) {}

  selectLoading(): Observable<boolean> {
    return this.loading.asObservable();
  }

  create(data: CreatorData, isDuplicate: boolean): Observable<unknown> {
    this.loading.next(true);
    data = mapSickProfiles(data);
    return this.forkJoinProfiles(data, isDuplicate).pipe(
      mergeMap(() => this.loads.createLoad(data)),
      finalize(() => this.loading.next(false)),
    );
  }

  update(data: CreatorData): Observable<unknown> {
    this.loading.next(true);
    data = mapSickProfiles(data);
    return this.forkJoinProfiles(data, false).pipe(
      mergeMap(() => this.loads.updateLoad(data)),
      finalize(() => this.loading.next(false)),
    );
  }

  private forkJoinProfiles(
    data: CreatorData,
    isDuplicate: boolean,
  ): Observable<unknown> {
    return this.profiles.legacyCreateByBatch(
      this.coerceToHasLegacyProfiles(data),
      this.pluckParents(data),
      isDuplicate,
    );
  }

  private coerceToHasLegacyProfiles(alleged: Load): HasLegacyProfiles {
    return alleged as HasLegacyProfiles;
  }

  private pluckParents(data: CreatorData): LoadParents {
    return {
      projectId: data.projectId,
      caseId: data.caseId,
      scenarioId: data.scenarioId,
    };
  }
}
