import { BINARY_LOCATIONS } from 'prosumer-app/app.references';
import {
  BaseComponent,
  FormFieldErrorMessageMap,
  FormFieldOption,
} from 'prosumer-app/libs/eyes-shared';
import { YearlyLoadsIntevalValidators } from 'prosumer-app/shared/components';
import { ProfileFormHelperService } from 'prosumer-app/shared/services';
import { NotificationsService } from 'prosumer-app/shared/services/notification';
import { mapYearlyDataToProfiles } from 'prosumer-app/shared/utils';
import { ScenarioBinStore } from 'prosumer-app/stores';
import { EGCEmissionCreator } from 'prosumer-app/stores/egc-emission';
import { AuthService } from 'prosumer-core/services/auth';
import {
  EnergyGridConnection,
  EnergyGridConnectionsEmissions,
  LibraryLoads,
  Profile,
} from 'prosumer-scenario/models';
import { VariationFinder } from 'prosumer-scenario/services';
import { YearlyLoadMessageConfig } from 'prosumer-shared/components/yearly-loads/yearly-loads.model';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { debounceTime, filter, take } from 'rxjs/operators';

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { EnergyGridConnectionsEmissionsDialogData } from './energy-grid-connections-emissions-dialog.model';

const MAX_LOADS = 8784;

@Component({
  selector: 'prosumer-energy-grid-connections-emissions-form-dialog',
  templateUrl: './energy-grid-connections-emissions-dialog.component.html',
  styleUrls: ['./energy-grid-connections-emissions-dialog.component.scss'],
  providers: [ProfileFormHelperService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnergyGridConnectionsEmissionsFormDialogComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  scenarioId: string;
  caseId: string;
  projectId: string;

  isMultiNode: boolean;
  energyGridConnectionsEmissionsForm: UntypedFormGroup =
    this._formBuilder.group({
      marketName: ['', [Validators.required]],
      node: [''],
      scope: ['', [Validators.required]],
      scenarioVariation: '',
      profiles: [],
      startYear: this.data.startYear,
      endYear: this.data.endYear,
    });
  marketName: UntypedFormControl = this.energyGridConnectionsEmissionsForm.get(
    'marketName',
  ) as UntypedFormControl;
  node: UntypedFormControl = this.energyGridConnectionsEmissionsForm.get(
    'node',
  ) as UntypedFormControl;
  scope: UntypedFormControl = this.energyGridConnectionsEmissionsForm.get(
    'scope',
  ) as UntypedFormControl;
  profiles: UntypedFormControl = this.energyGridConnectionsEmissionsForm.get(
    'profiles',
  ) as UntypedFormControl;
  scenarioVariation: UntypedFormControl =
    this.energyGridConnectionsEmissionsForm.get(
      'scenarioVariation',
    ) as UntypedFormControl;

  marketOptions: Array<FormFieldOption<any>> = [];
  nodeOptions: Array<FormFieldOption<any>> = [];
  scopeOptions: Array<FormFieldOption<any>> = [];

  submitted$ = new BehaviorSubject<boolean>(false);

  activeIndex = 0;
  errorMessages: FormFieldErrorMessageMap = {
    marketName: {
      alreadyExist: this._translate.instant(
        'Scenario.messages.connectionsEmissions.alreadyExist',
      ),
      required: this._translate.instant(
        'Scenario.messages.connectionsEmissions.required',
      ),
    },
    node: {
      alreadyExist: this._translate.instant(
        'Scenario.messages.node.alreadyExist',
      ),
      required: this._translate.instant('Scenario.messages.node.required'),
    },
    scope: {
      alreadyExist: this._translate.instant(
        'Scenario.messages.scope.alreadyExist',
      ),
      required: this._translate.instant('Scenario.messages.scope.required'),
    },
    scenarioVariation: {
      alreadyExist: this._translate.instant(
        'Scenario.messages.connectionsEmissions.alreadyExist',
      ),
    },
  };

  yearlyLoadMessage: YearlyLoadMessageConfig = {
    loadTypeLabel: 'Scenario.labels.energyGridConnectionsEmissions.profiles',
    loadTypeTooltip: '',
    loadsDataPlaceholder:
      'Scenario.placeholders.energyGridConnectionsEmissions.emissionsRate.custom.loads',
    loadsDataLabelCustom:
      'Scenario.labels.energyGridConnectionsEmissions.profiles',
    loadsDataRequired: 'Scenario.messages.loads.required',
  };
  binaryLoading$: Observable<boolean>;

  startYear = 0;
  endYear = 0;

  hasTdbAccess$: Observable<boolean>;
  readonly saving$: Observable<boolean> = this.creator.selectLoading();

  get profilesLocation() {
    return BINARY_LOCATIONS.ENERGY_GRID_CONNECTIONS_EMISSIONS;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: EnergyGridConnectionsEmissionsDialogData,
    public dialogRef: MatDialogRef<EnergyGridConnectionsEmissionsFormDialogComponent>,
    public _scenarioBinStore: ScenarioBinStore,
    public profileFormHelperService: ProfileFormHelperService,
    private _formBuilder: UntypedFormBuilder,
    private _translate: TranslateService,
    private readonly authService: AuthService,
    private readonly varFinder: VariationFinder,
    private readonly creator: EGCEmissionCreator,
    readonly notifService: NotificationsService,
  ) {
    super();
  }

  initOptions() {
    if (this.data.marketOptions) {
      this.data.marketOptions
        .map((market) => this.toWithVariationInName(market))
        .forEach((option) => this.marketOptions.push(option));
    }

    if (this.data.scopeOptions) {
      this.data.scopeOptions.forEach((option) =>
        this.scopeOptions.push(option),
      );
    }
  }

  combinationValidator() {
    if (
      this.marketName.value === '' ||
      this.node.value === '' ||
      this.scope.value === ''
    ) {
      return;
    }

    let alreadyExist = false;
    this.data.existingEnergyGridConnectionsEmissions$.forEach(
      (connectionEmission) => {
        alreadyExist = connectionEmission.some(
          ({ marketName, node, scope, id }) =>
            this.marketName.value === marketName &&
            this.node.value === node &&
            this.scope.value === scope &&
            this.data.id !== id,
        );
      },
    );

    if (alreadyExist) {
      this.marketName.setErrors({ alreadyExist: true });
      this.node.setErrors({ alreadyExist: true });
      this.scope.setErrors({ alreadyExist: true });
      this.scenarioVariation.setErrors({ alreadyExist: true });
    } else {
      this.marketName.setErrors(null);
      this.node.setErrors(null);
      this.scope.setErrors(null);
      this.scenarioVariation.setErrors(null);
    }
  }

  initData() {
    this.binaryLoading$ = this._scenarioBinStore.loading$;
    this.isMultiNode = this.data.isMultiNode;
    this.startYear = this.data.startYear;
    this.endYear = this.data.endYear;

    combineLatest([
      this.energyGridConnectionsEmissionsForm.get('marketName').valueChanges,
      this.energyGridConnectionsEmissionsForm.get('node').valueChanges,
      this.energyGridConnectionsEmissionsForm.get('scope').valueChanges,
      this.energyGridConnectionsEmissionsForm.get('scenarioVariation')
        .valueChanges,
    ])
      .pipe(debounceTime(400))
      .subscribe(() => {
        this.combinationValidator();
        this.energyGridConnectionsEmissionsForm.controls.marketName.clearAsyncValidators();
        this.energyGridConnectionsEmissionsForm.controls.node.clearAsyncValidators();
        this.energyGridConnectionsEmissionsForm.controls.scope.clearAsyncValidators();
        this.energyGridConnectionsEmissionsForm.controls.scenarioVariation.clearAsyncValidators();
      });

    this.energyGridConnectionsEmissionsForm.patchValue({
      marketName: this.data.marketName,
      node: this.data.node,
      scope: this.data.scope,
    });

    this.updateNodeOptions(this.data.marketName);

    if (this.shouldLoadProfile() && this.data.profiles !== undefined) {
      this.energyGridConnectionsEmissionsForm.controls.profiles.patchValue(
        this.data.profiles.map((profile) => ({
          ...profile,
          loadProfile: profile.loadProfile || [],
        })),
      );
      if (
        !!!this.data.profiles[0].loadProfile ||
        this.data.profiles[0].loadProfile.length === 0
      ) {
        this.loadBinaryData(this.data.profiles[0]);
      }
    }
  }

  initScenarioEntity() {
    this.projectId = this.data.scenarioIdentity.projectId;
    this.caseId = this.data.scenarioIdentity.caseId;
    this.scenarioId = this.data.scenarioIdentity.scenarioId;
  }

  private updateNodeOptions(market: string) {
    this.nodeOptions = [];
    this.data.existingEnergyGridConnections$.forEach((gridList) => {
      gridList.forEach((grid) => {
        if (grid.id === market) {
          if (grid.nodes.length === 1 && grid.nodes[0] === 'ALL') {
            this.data.nodeOptions.forEach((option) => {
              if (!this.nodeOptions.includes(option)) {
                this.nodeOptions.push(option);
              }
            });
          } else {
            this.data.nodeOptions.forEach((option) => {
              if (grid.nodes.includes(option.value)) {
                if (!this.nodeOptions.includes(option)) {
                  this.nodeOptions.push(option);
                }
              }
            });
          }
        }
      });
    });
  }

  ngOnInit() {
    if (!this.data) {
      return;
    }
    this.initScenarioEntity();
    this.initData();
    this.initOptions();

    this.subscribeToUserTDBAccess();
    this.marketName.valueChanges.subscribe((market) => {
      this.updateNodeOptions(market);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      this.isMultiNode
        ? this.node.patchValue('')
        : this.node.patchValue(
            this.data.nodeOptions.length > 0
              ? this.data.nodeOptions[0].value
              : '',
          );
    });
  }

  ngAfterViewInit(): void {
    this.energyGridConnectionsEmissionsForm.controls.profiles.setValidators([
      YearlyLoadsIntevalValidators.yearlyLoadsValid(false, true),
    ]);
    this.energyGridConnectionsEmissionsForm.controls.profiles.updateValueAndValidity();
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    this.combinationValidator();
    this.profileFormHelperService.setSubmitted();
    this.submitted$.next(true);
    if (this.isFormValid()) {
      this.upsertFnMap[this.data.mode]();
    }
  }

  private get upsertFnMap(): Record<string, () => void> {
    return {
      add: () => this.doCreate(),
      edit: () => this.doEdit(),
    };
  }

  private doCreate(): void {
    this.creator
      .create(this.energyGridConnectionsEmissionsForm.value)
      .pipe(take(1))
      .subscribe({
        next: (res) => this.dialogRef.close(),
        error: (error) =>
          this.notifService.showError(error.error?.error ?? error.message),
      });
  }

  private doEdit(): void {
    this.creator
      .update(this.buildEGCEmissionData())
      .pipe(take(1))
      .subscribe({
        next: (res) => this.dialogRef.close(),
        error: (error) =>
          this.notifService.showError(error.error?.error ?? error.message),
      });
  }

  private subscribeToUserTDBAccess() {
    this.hasTdbAccess$ = this.authService.hasT3AAccess();
  }

  private buildEGCEmissionData(): EnergyGridConnectionsEmissions {
    return {
      ...this.energyGridConnectionsEmissionsForm.value,
      id: this.data.id,
    };
  }

  isFormValid(): boolean {
    return this.energyGridConnectionsEmissionsForm.valid;
  }

  loadBinaryData(selectedProfile: Profile) {
    this.loadBinaryData$(selectedProfile)
      .pipe(take(1))
      .subscribe((loads) => {
        if (!!loads) {
          const profile = (loads || ({} as LibraryLoads)).data;
          this.profileFormHelperService.setProfile({
            index: this.activeIndex,
            profiles: profile,
            type: 'bin',
          });
        }
      });
  }

  loadBinaryData$(selectedProfile: Profile) {
    if (
      !!selectedProfile &&
      (!!!selectedProfile.loadProfile ||
        selectedProfile.loadProfile.length === 0)
    ) {
      return this._scenarioBinStore
        .get(
          this.projectId,
          this.caseId,
          this.scenarioId,
          selectedProfile.location,
          selectedProfile.originalLocalId || selectedProfile.localId,
          false,
        )
        .pipe(
          filter((loads) => !!loads),
          take(1),
        );
    }
    return of(null);
  }

  onSelectInterval(value: { selectedProfile: Profile; index: number }) {
    this.activeIndex = value.index;
    if (
      this.shouldLoadProfile() &&
      this.profileFormHelperService.forBinDownload(
        this.data.profiles,
        value.selectedProfile.localId,
      )
    ) {
      this.loadBinaryData(value.selectedProfile);
    }
  }

  shouldLoadProfile(): boolean {
    const isEditOrDuplicateMode = this.data.mode !== 'add';
    return isEditOrDuplicateMode;
  }

  private toWithVariationInName(
    here: FormFieldOption<string> & { object?: unknown },
  ): FormFieldOption<string> {
    return {
      ...here,
      name: this.concatenateVariationName(here.name, here.object),
    };
  }

  private concatenateVariationName(
    name: string,
    egc: EnergyGridConnection,
  ): string {
    return `${name} (${this.varFinder.getVariationName(
      egc.scenarioVariation,
    )})`;
  }

  onTDBDataFetch(yearlyData: Record<string, string | number>) {
    const profiles = mapYearlyDataToProfiles(
      yearlyData,
      this.data.startYear,
      this.data.endYear,
      this.profilesLocation,
    );
    this.energyGridConnectionsEmissionsForm.controls.profiles.patchValue(
      profiles,
    );
  }
}
