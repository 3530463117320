import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, take } from 'rxjs/operators';

type TriggerMode = 'add' | 'edit';

@Directive()
export abstract class EntityUpsertionTrigger<T> {
  private readonly upserting = new BehaviorSubject<boolean>(false);
  readonly upserting$ = this.upserting.asObservable();
  @Input() id: string;
  @Input() data: T;
  @Input() mode: TriggerMode = 'add';
  @Input() disabled = false;
  @Input() valid = true;
  @Output() ok = new EventEmitter<unknown>();
  @Output() attempt = new EventEmitter();

  abstract create(data: T): Observable<unknown>;
  abstract edit(id: string, data: T): Observable<unknown>;

  onClick(): void {
    this.attempt.emit();
    this.upsertIfValid();
  }

  private upsertIfValid(): void {
    if (this.valid) {
      this.upserting.next(true);
      this.upsertFnMap[this.mode]();
    }
  }

  private get upsertFnMap(): Record<TriggerMode, () => void> {
    return {
      add: () => this.doCreate(),
      edit: () => this.doEdit(),
    };
  }

  private doCreate(): void {
    this.create(this.data)
      .pipe(
        take(1),
        finalize(() => this.upserting.next(false)),
      )
      .subscribe((data) => this.onSuccess(data));
  }

  private doEdit(): void {
    this.edit(this.id, this.data)
      .pipe(
        take(1),
        finalize(() => this.upserting.next(false)),
      )
      .subscribe((data) => this.onSuccess(data));
  }

  private onSuccess(data: unknown): void {
    this.ok.emit(data);
  }
}
