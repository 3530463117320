import { BINARY_LOCATIONS } from 'prosumer-app/app.references';
import { Utils } from 'prosumer-app/core/utils';
import { ParameterType, Profile } from 'prosumer-scenario/models';
import { HasProfileGroup, ProfileGroup } from '../profile';
import { ScenarioDetailType } from '../scenario-detail';
import { Translator } from '../translator';
import {
  CreateOpCostBody,
  OpCostForm,
  OperationalCostBE,
  OperationalCostInfo,
} from './operational-cost.state';

export class OperationalCostTranslator
  implements Translator<OperationalCostInfo, OperationalCostBE>
{
  toFE(data: OperationalCostBE): OperationalCostInfo {
    return {
      ...data,
      id: data.operationalCostId,
      parameterType: data.parameterType?.toUpperCase(),
      profiles: this.mapProfiles(data),
    };
  }

  toBE(data: OperationalCostInfo): OperationalCostBE {
    return {
      ...data,
      profile: this.toProfileGroup(data),
      operationalCostId: data.id,
      dataType: ScenarioDetailType.opCosts,
    };
  }

  private toProfileGroup(data: OperationalCostInfo): ProfileGroup {
    return {
      name: this.getProfileGroupName(),
      profiles: this.translateProfiles(data.profiles),
    };
  }

  //TODO: New model to accomodate untracked values for year & duration
  private translateProfiles(profiles: Profile[]): Profile[] {
    return Utils.resolveToEmptyArray(profiles).map((profile) => ({
      ...profile,
      year: profile.startYear,
      duration: this.deduceDuration(profile.startYear, profile.endYear),
      yearlyLoad: this.deduceYearlyLoad(profile.yearlyLoad as number),
      isCustom: this.deduceIsCustom(profile.loadType),
    }));
  }

  private deduceIsCustom(loadType: string): boolean {
    return [loadType === undefined, loadType === 'custom'].some(Boolean);
  }

  private deduceDuration(startYear: number, endYear: number): number {
    return endYear - startYear + 1;
  }

  private deduceYearlyLoad(yearlyLoad: number): string | undefined {
    return yearlyLoad ? yearlyLoad.toString() : undefined;
  }

  private getProfileGroupName(): string {
    return 'Operational Cost Profile';
  }

  fromForm(form: OpCostForm): CreateOpCostBody {
    return {
      profiles: form.profiles as Profile[],
      parameterType: form.parameterType,
      energyVectorId: this.deduceVector(form),
    };
  }

  private deduceVector(form: OpCostForm): string {
    return form.parameterType === ParameterType.PDOC
      ? form.outputEnergyVector
      : form.inputEnergyVector;
  }

  //TODO: need to use BEModel for data that would be mapped to FE
  private mapProfiles(data: HasProfileGroup): Profile[] {
    return this.extractProfiles(data).map((profile) => ({
      ...profile,
      startYear: profile['year'],
      endYear: this.deduceEndYear(profile),
      location: this.resolveLocationIfApplicable(profile.location),
    }));
  }

  private extractProfiles(from: HasProfileGroup): Profile[] {
    return Utils.resolveToEmptyArray(
      Utils.resolveToEmptyObject(from.profile).profiles,
    ) as Profile[];
  }

  private deduceEndYear(profile: unknown): number {
    return profile['year'] + profile['duration'] - 1;
  }

  private resolveLocationIfApplicable(allegedLocation: string): string {
    return allegedLocation
      ? allegedLocation
      : BINARY_LOCATIONS.DER_OPERATING_COSTS;
  }
}
