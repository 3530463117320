import { ColorPickerModule } from 'ngx-color-picker';
import { Coerce } from 'prosumer-core/utils';
import { TooltipButtonModule } from 'prosumer-shared/components/tooltip-button';
import { debounceTime, map, Observable, Subject, tap } from 'rxjs';

import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LetDirective, PushPipe } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';
import {
  InputChangeHandling,
  LinksResultEnhanced,
  NodesResultEnhanced,
  SankeyEditorBasicDataType,
  SankeyEditorBasicInputTypes,
  SankeyResult,
  SankeyResultEnhanced,
} from '@prosumer/results/models';
import { FlowResultsEditorBasicHelperService } from '@prosumer/results/services/flow-results-editor-basic-helper';
import {
  ERROR_KEY_CIRCULAR,
  ERROR_KEY_REQUIRED,
  ERROR_KEY_UNIQUE,
  LinksFlowResultsEditorValidator,
  NodesFlowResultsEditorValidator,
} from '@prosumer/results/validators/flow-results-editor';

const DEBOUNCE_TIME = 600;

@UntilDestroy()
@Component({
  selector: 'prosumer-flow-results-editor-basic',
  standalone: true,
  imports: [
    MatCardModule,
    MatListModule,
    MatTableModule,
    MatSelectModule,
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    LetDirective,
    MatButtonModule,
    MatChipsModule,
    MatIconModule,
    TranslateModule,
    TooltipButtonModule,
    MatTooltipModule,
    PushPipe,
    ColorPickerModule,
  ],
  templateUrl: './flow-results-editor-basic.component.html',
  styleUrl: './flow-results-editor-basic.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'),
      ),
    ]),
  ],
})
export class FlowResultsEditorBasicComponent implements OnInit, OnDestroy {
  @Output() save = new EventEmitter<SankeyResultEnhanced>();
  @Input() set inputData(d: SankeyResult | SankeyResultEnhanced) {
    this.basicEditorHelper.setData(d);
  }
  displayedColumns = ['source', 'target', 'value', 'color', 'actions'];

  links$: Observable<LinksResultEnhanced[]> =
    this.basicEditorHelper.sankeyData$.pipe(
      untilDestroyed(this),
      map((d) => d.links),
      tap((links) => this.handleLinkControlCreation(links)),
    );
  nodes$: Observable<NodesResultEnhanced[]> =
    this.basicEditorHelper.sankeyData$.pipe(
      untilDestroyed(this),
      map((d) => d.nodes),
      tap((nodes) => this.deriveNodeOptions(nodes)),
      tap((nodes) => this.handleNodeCtrlCreation(nodes)),
    );
  nodeOptions = signal([]);

  ctrlChangeHandler$ = new Subject<InputChangeHandling>();
  sankeyDataFormGroup: UntypedFormGroup = this.fb.group({
    nodes: this.fb.array([]),
    links: this.fb.array([]),
  });
  get nodeCtrls() {
    return this.sankeyDataFormGroup.get('nodes') as UntypedFormArray;
  }
  get linkCtrls() {
    return this.sankeyDataFormGroup.get('links') as UntypedFormArray;
  }

  constructor(
    private basicEditorHelper: FlowResultsEditorBasicHelperService,
    private fb: UntypedFormBuilder,
  ) {}

  ngOnInit(): void {
    this.subToCtrlChange();
  }

  ngOnDestroy(): void {
    this.onClose();
  }

  onSave(): void {
    this.save.emit(this.basicEditorHelper.getData());
  }

  onColorChange(e: unknown, id: string, key: string, type: any): void {
    this.sankeyDataFormGroup.get(`${type}s`).markAsDirty();
    this.onCtrlChange(e, id, key, type);
  }

  onCtrlChange(e: unknown, id: string, key: string, type: any): void {
    const value = this.determineCtrlValue(e, key);
    this.ctrlChangeHandler$.next({
      changedData: { id, value, key },
      type,
    });
  }

  onDeleteNode(ndId: string, idx: number): void {
    this.basicEditorHelper.deleteNode(ndId);
    this.nodeCtrls.removeAt(idx);
    this.nodeCtrls.markAsDirty();
  }

  onAddNode(): void {
    this.basicEditorHelper.addNode();
  }

  onAddLink(): void {
    const link = this.basicEditorHelper.addLink();
  }

  onDeleteLink(linkId: string) {
    this.basicEditorHelper.deleteLink(linkId);
    this.sankeyDataFormGroup.get('links').markAsDirty();
  }

  getLinkErrors(idx: number): Record<string, string[]> {
    return Coerce.toObject(this.sankeyDataFormGroup.get(['links', idx]).errors);
  }
  getLinkErrorKeys(idx: number): string[] {
    return Object.keys(this.getLinkErrors(idx));
  }

  hasSourceTargetError(idx: number, key: string): boolean {
    return [
      Coerce.toArray(this.getLinkErrors(idx)[ERROR_KEY_REQUIRED]).includes(key),
      Coerce.toArray(this.getLinkErrorKeys(idx)).includes(ERROR_KEY_CIRCULAR),
      Coerce.toArray(this.getLinkErrorKeys(idx)).includes(ERROR_KEY_UNIQUE),
    ].some(Boolean);
  }
  hasValueError(idx: number): boolean {
    return Coerce.toArray(this.getLinkErrors(idx)[ERROR_KEY_REQUIRED]).includes(
      'value',
    );
  }

  private deriveNodeOptions(nodes: NodesResultEnhanced[]): void {
    if (!nodes || !nodes.length) return;
    this.nodeOptions.set(nodes.filter((node) => !!node.name));
  }

  private handleNodeCtrlCreation(nodes: NodesResultEnhanced[]): void {
    if (!nodes || !nodes.length) return;
    if (!this.nodeCtrls.controls.length) {
      nodes.forEach((nd) => this.createNodeCtrl(nd));
    } else if (this.nodeCtrls.controls.length < nodes.length) {
      const exIds = this.nodeCtrls.controls.map((ctr) => ctr.get('id').value);
      const newNodes = nodes.filter((nd) => !exIds.includes(nd.id));
      newNodes.forEach((nd) => this.createNodeCtrl(nd, true));
    } else if (this.nodeCtrls.controls.length === nodes.length) {
      this.nodeCtrls.patchValue(nodes);
    }
  }

  private createNodeCtrl(node: NodesResultEnhanced, append = false): void {
    const ctrl = this.fb.group({
      id: [node.id],
      name: [
        node.name,
        [
          Validators.required,
          NodesFlowResultsEditorValidator.unique('name', node.id),
        ],
      ],
      color: [node?.color],
    });
    if (append) {
      this.nodeCtrls.insert(0, ctrl);
    } else {
      this.nodeCtrls.push(ctrl);
    }
  }

  private handleNodeChange(d: InputChangeHandling): void {
    if (this.nodeCtrls.invalid) return;
    this.basicEditorHelper.updateNode(d.changedData);
  }

  private handleLinkControlCreation(links: LinksResultEnhanced[]): void {
    this.linkCtrls.clear();
    links.forEach((link) => this.addLinkCtrls(link));
    this.linkCtrls.controls.forEach((link) => link.updateValueAndValidity());
  }

  private addLinkCtrls(link: LinksResultEnhanced): void {
    const fn = LinksFlowResultsEditorValidator.valid;
    const rowCtrl = this.fb.group({
      [SankeyEditorBasicInputTypes.SOURCE]: [link.source],
      [SankeyEditorBasicInputTypes.TARGET]: [link.target],
      [SankeyEditorBasicInputTypes.VALUE]: [link.value],
      [SankeyEditorBasicInputTypes.COLOR]: [link.color],
      id: [link.id],
    });
    rowCtrl.addAsyncValidators(fn(link.id));
    this.linkCtrls.push(rowCtrl);
  }

  private handleLinkChange(d: InputChangeHandling): void {
    this.basicEditorHelper.updateLink(d.changedData);
  }

  private subToCtrlChange(): void {
    this.ctrlChangeHandler$
      .pipe(untilDestroyed(this), debounceTime(DEBOUNCE_TIME))
      .subscribe((d) => {
        if (d.type === SankeyEditorBasicDataType.NODE) {
          this.handleNodeChange(d);
        } else if (d.type === SankeyEditorBasicDataType.LINK) {
          this.handleLinkChange(d);
        }
      });
  }

  private determineCtrlValue(e: any, key: string): string {
    // TODO: improve more on cyclomatic complexity
    const enumKey = key as SankeyEditorBasicInputTypes;
    if (
      [
        SankeyEditorBasicInputTypes.NAME,
        SankeyEditorBasicInputTypes.VALUE,
      ].includes(enumKey)
    ) {
      return ((e as Event).target as HTMLInputElement).value;
    } else if (key === SankeyEditorBasicInputTypes.COLOR) {
      return e as string;
    } else if (
      [
        SankeyEditorBasicInputTypes.SOURCE,
        SankeyEditorBasicInputTypes.TARGET,
      ].includes(enumKey)
    ) {
      return e.value as string;
    }
  }

  private onClose() {
    this.basicEditorHelper.clearData();
  }
}
