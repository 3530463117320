import { Component, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResultsPerceptionService } from '../case-results-perception';
import {
  ResultsVisualizerController,
  RESULT_VIZ_SERVICE,
} from '../results-visualizer';
import { EmissionsResultService } from './emissions-results.service';

@Component({
  selector: 'prosumer-emissions-results',
  templateUrl: './emissions-results.component.html',
  styleUrls: ['./emissions-results.component.scss'],
  providers: [
    { provide: RESULT_VIZ_SERVICE, useClass: EmissionsResultService },
  ],
})
export class EmissionsResultsComponent extends ResultsVisualizerController {
  constructor(
    @Inject(RESULT_VIZ_SERVICE) private service: EmissionsResultService,
    perceptionService: ResultsPerceptionService,
  ) {
    super(service, perceptionService);
  }

  getLegendNames$(): Observable<string[]> {
    return this.service.getResultDataStream().pipe(
      map((data) => this.service.buildSliceData(data)),
      map((sliceData) =>
        Object.values(sliceData).reduce(
          (list, curr) => [...list, ...Object.keys(curr)],
          [],
        ),
      ),
    );
  }
}
