import { TitleCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ResultsPerceptionService } from '@prosumer/results/components/case-results-perception';
import { ResultNameValue } from '@prosumer/results/components/results-visualizer';

import { DEFAULT_DEBOUNCE_TIME } from 'prosumer-app/app.references';
import { FormFieldOption, contains } from 'prosumer-app/libs/eyes-shared';
import { ResultStore } from 'prosumer-app/stores';
import { DispatchService } from 'prosumer-scenario/containers';
import { StackedBarMeta } from 'prosumer-shared/modules/chartjs/stacked-bar-chartjs';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { StorageDispatchAdapter } from '../adapters';

/**
 * constants for no of days per month.
 */
export const arrDaysAndMonths = () => ({
  january: 31,
  february: 28,
  march: 31,
  april: 30,
  may: 31,
  june: 30,
  july: 31,
  august: 31,
  september: 30,
  october: 31,
  november: 30,
  december: 31,
});

const STORAGE_CHARGE = 'Storage Charge';
const STORAGE_DISCH = 'Storage Discharge';

@Component({
  selector: 'prosumer-storage-monthly-dispatch',
  templateUrl: './storage-monthly-dispatch.component.html',
  styleUrls: ['./storage-monthly-dispatch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StorageMonthlyDispatchComponent
  extends StorageDispatchAdapter
  implements OnInit
{
  /**
   * data loaded output emitter.
   */
  @Output() dataLoaded = new EventEmitter<Array<any>>();

  _storageOptions: Array<FormFieldOption<string>>;
  storageControl = new UntypedFormControl('');
  loading$ = new BehaviorSubject<boolean>(false);
  storageCharge$: Observable<any>;
  startingHour = 0;
  legendFilter$ = new BehaviorSubject<Array<string>>([]);
  @Input() scenarioName: string;
  /**
   * setter of storage options.
   *
   * @param storageOptions storageOptions.
   */
  @Input() set storageOptions(storageOptions: Array<FormFieldOption<string>>) {
    this._storageOptions = storageOptions;
    if (storageOptions && storageOptions.length > 0) {
      this.storageControl.setValue(storageOptions[0].value);
      this.loadStorageMovement();
    }
  }

  /**
   * getter of storage options.
   */
  get storageOptions(): Array<FormFieldOption<string>> {
    return this._storageOptions;
  }

  constructor(
    private _dispatchService: DispatchService,
    private _resultStore: ResultStore,
    private _titleCase: TitleCasePipe,
    perception: ResultsPerceptionService,
  ) {
    super(perception);
  }

  /**
   * Initializes controls.
   */
  initControls = () => {
    this.storageControl.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => this.loadStorageMovement());
  };

  ngOnInit(): void {
    this.initControls();
    this.initializeChartDataAndColors();
  }

  /**
   * Loads storage movement based on the selected storage name.
   */
  loadStorageMovement = () => {
    this.loading$.next(true);
    this.storageCharge$ = combineLatest([
      this._dispatchService.year$,
      this._dispatchService.node$,
      this._dispatchService.energyVector$,
      this.legendFilter$,
      combineLatest([
        this._dispatchService.minDate$,
        this._dispatchService.maxDate$,
      ]).pipe(
        debounceTime(DEFAULT_DEBOUNCE_TIME),
        distinctUntilChanged(),
        takeUntil(this.componentDestroyed$),
      ),
    ]).pipe(
      switchMap(([year, node, energyVector, legendFilter]) =>
        this._resultStore
          .getStorageCharge$(
            year,
            node,
            energyVector,
            this.storageControl.value,
          )
          .pipe(
            tap(() => (this.startingHour = 0)),
            map((filteredData) =>
              !filteredData || filteredData.length === 0
                ? undefined
                : Object.keys(arrDaysAndMonths()).map((month) => {
                    const endingHour =
                      this.startingHour + arrDaysAndMonths()[month] * 24;
                    const mvtValues = filteredData?.values.map(
                      (value) => value * -1,
                    );
                    let v = mvtValues
                      .slice(this.startingHour, endingHour)
                      .reduce((acc, hourlyVal) => acc + hourlyVal, 0.0);
                    if (contains(legendFilter, STORAGE_CHARGE) && v > 0) {
                      v = 0;
                    }
                    if (contains(legendFilter, STORAGE_DISCH) && v < 0) {
                      v = 0;
                    }
                    const mvtOfTheMonth = {
                      name: this._titleCase.transform(month).substring(0, 3),
                      value: v,
                    };
                    this.startingHour = endingHour;
                    return mvtOfTheMonth;
                  }),
            ),
            takeUntil(this.componentDestroyed$),
          ),
      ),
      tap((data: Array<any>) => {
        this.loading$.next(false);
        this.dataLoaded.emit(data);
        this.startingHour = 0;
      }),
      takeUntil(this.componentDestroyed$),
    );
  };

  formatTick = (tick: string) => tick;

  getChartName(): string {
    return `Storage Monthly Dispatch (${this.storageControl.value})`;
  }

  getNgxChartsDataStream(): Observable<ResultNameValue[]> {
    return this.storageCharge$;
  }

  injectAxisNames(data: StackedBarMeta): StackedBarMeta {
    return { ...data, xAxisName: 'Months', yAxisName: 'Power [kWh]' };
  }
}
