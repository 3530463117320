import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { EnergyVector } from 'prosumer-app/+scenario/models';
import {
  CustomValidators,
  fadeInAnimation,
  FormDialogComponent,
} from 'prosumer-app/libs/eyes-shared';
import {
  expandYearlyValuesIfApplicable,
  YearlyValues,
} from 'prosumer-app/shared';
import { provideUpserter } from 'prosumer-app/stores';
import { PowerNeededStore } from 'prosumer-app/stores/power-needed';
import { of } from 'rxjs';

import { SCPowerNeededFormDialogData } from './sc-power-needed-form-dialog.model';

@UntilDestroy()
@Component({
  selector: 'prosumer-sc-power-needed-form-dialog',
  templateUrl: './sc-power-needed-form-dialog.component.html',
  styleUrls: ['./sc-power-needed-form-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInAnimation],
  providers: [provideUpserter(PowerNeededStore)],
})
export class ScPowerNeededFormDialogComponent
  extends FormDialogComponent<
    ScPowerNeededFormDialogComponent,
    SCPowerNeededFormDialogData
  >
  implements OnInit
{
  energyVectors: Array<EnergyVector> = [];

  get energyVectorIdControl() {
    return this.form.controls.energyVectorId;
  }

  get neededPowerControl() {
    return this.form.controls.neededPower;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SCPowerNeededFormDialogData,
    public changeDetector: ChangeDetectorRef,
    public dialogRef: MatDialogRef<ScPowerNeededFormDialogComponent>,
    public formBuilder: UntypedFormBuilder,
  ) {
    super(null, changeDetector, dialogRef, formBuilder);
  }

  defineForm() {
    return {
      energyVectorId: ['', [Validators.required]],
      neededPower: ['', Validators.required],
    };
  }

  ngOnInit() {
    super.ngOnInit();
    this.initData();
    this.updateValidators();
    this.take1FromFirstFormChangeForManualChangeDetection();
  }

  initData() {
    this.energyVectors = this.data.energyVectors || [];
    this.form.patchValue({
      energyVectorId: this.data.powerNeeded.energyVectorId,
      neededPower: this.sanitizeYearlyValues(this.data.powerNeeded.neededPower),
    });
  }

  private sanitizeYearlyValues(values: YearlyValues): YearlyValues {
    return expandYearlyValuesIfApplicable(values, this.getStartEndPeriod());
  }

  private getStartEndPeriod(): [number, number] {
    return [this.data.startYear, this.data.endYear];
  }

  updateValidators() {
    this.energyVectorIdControl.setAsyncValidators([
      CustomValidators.dataExist(
        !!this.data.scPowerNeededList && this.data.scPowerNeededList.length > 0
          ? of(this.data.scPowerNeededList)
          : null,
        'energyVectorId',
        this.data.powerNeeded,
      ),
    ]);
  }

  onSaveSuccess(): void {
    this.dialogRef.close();
  }

  onSaveAttempt(): void {
    this.changeDetector.detectChanges();
  }

  private take1FromFirstFormChangeForManualChangeDetection(): void {
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      this.form.markAsDirty();
      this.changeDetector.detectChanges();
    });
  }
}
