import { ActiveKeeperService } from 'prosumer-app/services/active-keeper';
import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import { WithProfilesCreator } from '../creator';
import { ProfileStore } from '../profile';
import { FilterCriteria, TDBDataQuery } from '../tdb-data';
import { EGCEmission } from './egc-emission.state';
import { EGCEmissionStore } from './egc-emission.store';

@Injectable({
  providedIn: 'root',
})
export class EGCEmissionCreator extends WithProfilesCreator<EGCEmission> {
  constructor(
    private readonly egcEmissions: EGCEmissionStore,
    readonly profiles: ProfileStore,
    readonly keeper: ActiveKeeperService,
    private readonly tdbQuery: TDBDataQuery,
  ) {
    super(profiles, keeper);
  }

  createEntity(data: EGCEmission): Observable<unknown> {
    return this.egcEmissions.create(this.injectTdbFilters(data));
  }
  updateEntity(data: EGCEmission): Observable<unknown> {
    return this.egcEmissions.edit(data.id, this.injectTdbFilters(data));
  }

  private injectTdbFilters(egce: EGCEmission) {
    const tdbFilters = this.getProfileFilters(egce.id);
    if (JSON.stringify(tdbFilters) === '{}') return egce;
    return { ...egce, tdbFilters };
  }

  private getProfileFilters(id: string): FilterCriteria {
    const tdbFilters = { ...this.tdbQuery.getActiveFilters() };
    return this.purifyFilters(tdbFilters);
  }

  private purifyFilters(tdbFilters: FilterCriteria): FilterCriteria {
    const { category, item, geography, scenario, unit, granularity } =
      tdbFilters;
    return { category, item, geography, scenario, unit, granularity };
  }
}
