import capitalize from 'lodash/capitalize';
import { EnergyVectorInfoService } from 'prosumer-app/+scenario/services';
import { PREDEFINED_ENERGY_VECTOR_MAP } from 'prosumer-app/app.references';
import { BaseControlComponent } from 'prosumer-app/libs/eyes-shared';
import {
  IconButtonToggle,
  IconButtonToggleSelection,
} from 'prosumer-app/shared/components/icon-button-toggle';
import { EnergyVector, EnergyVectorReq } from 'prosumer-scenario/models';
import { BehaviorSubject } from 'rxjs';

import { SelectionModel } from '@angular/cdk/collections';
import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  Self,
} from '@angular/core';
import { NgControl } from '@angular/forms';

@Component({
  selector: 'prosumer-energy-vector-select',
  templateUrl: './energy-vector-select.component.html',
  styleUrls: ['./energy-vector-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnergyVectorSelectComponent
  extends BaseControlComponent
  implements OnInit
{
  static nextUniqueId = 0;
  uid = `prosumer-energy-vector-select-${EnergyVectorSelectComponent.nextUniqueId++}`;
  _selectedEnergyVectors: Array<EnergyVector>;
  selection = new SelectionModel<any>(true);
  readonly waitingResponse = new BehaviorSubject<Record<string, boolean>>({});
  readonly waitingResponse$ = this.waitingResponse.asObservable();

  @Input() energyVectors: Array<IconButtonToggle<string>>;
  @Input() showAdd: boolean;
  @Input() set selectedEnergyVectors(vectors: Array<EnergyVector>) {
    this._selectedEnergyVectors = vectors;
    this.setPredefinedEnergyVectors(this._selectedEnergyVectors);
  }

  @Output() add = new EventEmitter<void>();
  @Output() energyVectorDeselected = new EventEmitter<any>();
  @Output() energyVectorSelected = new EventEmitter<any>();
  @Output() energyVectorSaved = new EventEmitter<Record<string, string>>();

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    public changeDetector: ChangeDetectorRef,
    private readonly evInfoService: EnergyVectorInfoService,
  ) {
    super(ngControl, changeDetector);
  }

  mapToEnergyVectorOptions(selected: any[]): Array<EnergyVector> {
    return selected.map((value) => ({
      name: PREDEFINED_ENERGY_VECTOR_MAP[value],
      value,
    }));
  }

  ngOnInit() {
    this.selection.changed.subscribe(() =>
      this.onChange(this.mapToEnergyVectorOptions(this.selection.selected)),
    );
  }

  writeValue(value: Array<EnergyVector>) {
    this.setPredefinedEnergyVectors(value);
    super.writeValue(value);
  }

  onSelect(selection: IconButtonToggleSelection<string>) {
    this.updateWaitForResponseFlags(selection.value, true);
    if (selection.selected) {
      this.saveEnergyVector(selection);
    } else {
      this.deleteEnergyVector(selection);
    }
  }

  onClickAdd() {
    this.add.emit();
  }

  mapDataToEnergyVectorReq(
    selection: IconButtonToggleSelection<string>,
  ): EnergyVectorReq {
    return {
      name: selection.icon,
      energyVectorId: selection.value,
      isCustom: false,
    };
  }

  private saveEnergyVector(selection: IconButtonToggleSelection<string>): void {
    const data = this.mapDataToEnergyVectorReq(selection);
    this.evInfoService.createEnergyVector(data).subscribe(
      () => this.onSuccesfulSave(selection),
      (error: HttpErrorResponse) => {
        this.finishedSaving(selection, error.error?.error ?? error.message);
      },
      () => this.updateWaitForResponseFlags(selection.value, false),
    );
  }

  private deleteEnergyVector(
    selection: IconButtonToggleSelection<string>,
  ): void {
    this.evInfoService.deleteEnergyVector(selection.value).subscribe(
      () => this.onSuccessfulDelete(selection),
      (error: HttpErrorResponse) =>
        this.finishedSaving(selection, error.error?.error ?? error.message),
      () => this.updateWaitForResponseFlags(selection.value, false),
    );
  }

  private onSuccesfulSave(selection: IconButtonToggleSelection<string>): void {
    this.selection.select(selection.value);
    this.onSuccessfulOp(selection);
  }

  private onSuccessfulDelete(
    selection: IconButtonToggleSelection<string>,
  ): void {
    this.selection.deselect(selection.value);
    this.onSuccessfulOp(selection);
  }

  private onSuccessfulOp(selection: IconButtonToggleSelection<string>) {
    this.finishedSaving(selection);
    this.energyVectorSelected.emit(selection.value);
  }

  private finishedSaving(
    selection: IconButtonToggleSelection<string>,
    errorMsg: string = '',
  ) {
    this.energyVectorSaved.emit({
      [selection.value]: this.formatErrorMessage(selection.label, errorMsg),
    });
  }

  private updateWaitForResponseFlags(key: string, value: boolean) {
    this.waitingResponse.next({
      ...this.waitingResponse.value,
      [key]: value,
    });
  }

  private formatErrorMessage(label: string, errorMsg: string): string {
    return errorMsg ? `${capitalize(label)}: ${errorMsg}` : errorMsg;
  }

  protected setPredefinedEnergyVectors(value: Array<EnergyVector>) {
    if (value) {
      this.selection.clear();
      this.selection.select(
        ...value.map((energyVectorOption) => energyVectorOption.value),
      );
    }
  }
}
