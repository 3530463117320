import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { EntityUpsertionTrigger } from 'prosumer-app/+scenario/directives/entity-upsertion-trigger';
import { NotificationsService } from 'prosumer-app/shared/services/notification';
import {
  TaxAndSubsidy,
  TaxAndSubsidyStore,
} from 'prosumer-app/stores/tax-and-subsidy';

@Component({
  selector: 'prosumer-taxub-upsert-trigger',
  templateUrl: './taxub-upsert-trigger.component.html',
  styleUrls: ['./taxub-upsert-trigger.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaxubUpsertTriggerComponent extends EntityUpsertionTrigger<TaxAndSubsidy> {
  constructor(
    private readonly taxes: TaxAndSubsidyStore,
    private readonly notifs: NotificationsService,
  ) {
    super();
  }

  create(data: TaxAndSubsidy): Observable<unknown> {
    return this.taxes
      .create(data)
      .pipe(catchError((error) => this.handleError(error)));
  }

  edit(id: string, data: TaxAndSubsidy): Observable<unknown> {
    return this.taxes
      .edit(id, data)
      .pipe(catchError((error) => this.handleError(error)));
  }

  private handleError(error: HttpErrorResponse): Observable<HttpErrorResponse> {
    this.notifs.showError(error.error.error);
    return throwError(error);
  }
}
