import { Injectable } from '@angular/core';
import { StoreConfig } from '@datorama/akita';
import { RegulationsMapper } from 'prosumer-app/+scenario/services/mappers';
import { ScenarioDetailStore, ScenarioDetailType } from '../scenario-detail';
import { Regulation, RegulationBERsp } from './regulation.state';

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: ScenarioDetailType.regulation })
export class RegulationStore extends ScenarioDetailStore<Regulation> {
  toBE(from: Regulation): unknown {
    return RegulationsMapper.meteringToBE(from);
  }

  toFE(from: RegulationBERsp): Regulation {
    return RegulationsMapper.meteringToFE(from);
  }
}
