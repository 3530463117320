import { Injectable } from '@angular/core';
import { ActiveKeeperService } from 'prosumer-app/services/active-keeper';
import { ScenarioDetailQuery } from '../scenario-detail';
import { VehiclesDispatch } from './vehicles-dispatch.state';
import { VehiclesDispatchStore } from './vehicles-dispatch.store';

@Injectable({ providedIn: 'root' })
export class VehiclesDispatchQuery extends ScenarioDetailQuery<VehiclesDispatch> {
  constructor(
    readonly store: VehiclesDispatchStore,
    readonly keeper: ActiveKeeperService,
  ) {
    super(store, keeper);
  }
  getDisplayName(entity: VehiclesDispatch): string {
    return entity.vehicleName;
  }
}
