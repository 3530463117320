import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { EmissionsDirectionComponent } from './emissions-direction.component';

@NgModule({
  declarations: [EmissionsDirectionComponent],
  imports: [
    CommonModule,
    MatButtonToggleModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatIconModule,
  ],
  exports: [EmissionsDirectionComponent],
})
export class EmissionsDirectionModule {}
