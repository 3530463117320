export const groupDataSetByDayAndHour = (dataSet: Array<any>) => {
  const holder = {};
  const result = dataSet.reduce((acc, current) => {
    const key = current.day + '-' + current.hour;
    // new index based on day and hour
    current.newIndex = computeNewIndex(current.day, current.hour);

    if (!holder[key]) {
      // create a copy of current as initial value
      holder[key] = Object.assign({}, current);
      holder[key].count = 1;
      acc.push(holder[key]);
    } else {
      holder[key].value += current.value;
      holder[key].count += 1;
    }
    holder[key].average = holder[key].value / holder[key].count;
    return acc;
  }, []);

  // sort result based on index; return data from Monday to Sunday
  result.sort((a, b) => a.newIndex - b.newIndex);
  return result;
};

const computeNewIndex = (day: number, hour: number) => {
  // day 0 == Sunday, but since in the graph, the first day is Monday, we move days with sunday to end
  const hoursOfSixDays = 144;
  const startHour = day === 0 ? hoursOfSixDays : (day - 1) * 24;
  // start of new index is 1
  return startHour + hour + 1;
};
