import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BarAndAreaResultsModule } from '../bar-and-area-results';
import { ResultsVisualizerModule } from '../results-visualizer';
import { CashflowResultsComponent } from './cashflow-results.component';

@NgModule({
  declarations: [CashflowResultsComponent],
  imports: [
    CommonModule,
    ResultsVisualizerModule,
    BarAndAreaResultsModule,
    TranslateModule,
  ],
  exports: [CashflowResultsComponent],
})
export class CashflowResultsModule {}
