<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
  <div fxLayout="row" fxLayoutAlign="start start">
    <ngx-charts-number-card
      [view]="view"
      [results]="totalDiscount"
      [scheme]="colorSchemeTotalDiscount"
      [labelFormatting]="labelFormatting"
      [valueFormatting]="valueFormatting"
      (select)="onSelect($event)"
      data-testid="prosumer-cost-totalDiscount"
    >
    </ngx-charts-number-card>
    <prosumer-tooltip-anchor
      [message]="'output_main.output_main_total_discounted_cost'"
      class="attached-component"
      data-testid="prosumer-cost-totalDiscount-tooltip"
    >
    </prosumer-tooltip-anchor>
  </div>
  <div fxLayout="row" fxLayoutAlign="start start">
    <ngx-charts-number-card
      [view]="view"
      [results]="capex"
      [scheme]="colorSchemeCapex"
      [labelFormatting]="labelFormatting"
      [valueFormatting]="valueFormatting"
      (select)="onSelect($event)"
      data-testid="prosumer-cost-capex"
    >
    </ngx-charts-number-card>
    <prosumer-tooltip-anchor
      [message]="'output_main.output_main_discounted_capex'"
      class="attached-component"
      data-testid="prosumer-cost-capex-tooltip"
    >
    </prosumer-tooltip-anchor>
  </div>
  <div fxLayout="row" fxLayoutAlign="start start">
    <ngx-charts-number-card
      [view]="view"
      [results]="opex"
      [scheme]="colorSchemeOpex"
      [labelFormatting]="labelFormatting"
      [valueFormatting]="valueFormatting"
      (select)="onSelect($event)"
      data-testid="prosumer-cost-opex"
    >
    </ngx-charts-number-card>
    <prosumer-tooltip-anchor
      [message]="'output_main.output_main_final_opex'"
      [tooltipPosition]="'left'"
      class="attached-component"
      data-testid="prosumer-cost-opex-tooltip"
    >
    </prosumer-tooltip-anchor>
  </div>
  <div fxLayout="row" fxLayoutAlign="start start">
    <ngx-charts-number-card
      [view]="view"
      [results]="co2Emissions"
      [scheme]="colorSchemeCo2Emissions"
      [labelFormatting]="labelFormatting"
      [valueFormatting]="co2EmissionsFormatting"
      (select)="onSelect($event)"
      data-testid="prosumer-cost-co2Emissions"
    >
    </ngx-charts-number-card>
    <prosumer-tooltip-anchor
      [message]="'output_main.output_main_co2_emissions_kpi'"
      [tooltipPosition]="'left'"
      class="attached-component"
      data-testid="prosumer-cost-co2Emissions-tooltip"
    >
    </prosumer-tooltip-anchor>
  </div>
</div>
