<div fxLayout="row">
  <h3 class="eyes-remove-margin">
    {{
      'Scenario.labels.wizard.frequencyControl.headers.reserveNetwork'
        | translate
    }}
    <prosumer-tooltip-anchor
      [message]="'wizard_frequency_control.reserve_network'"
    ></prosumer-tooltip-anchor>
  </h3>
  <span fxFlex></span>
  <button
    *ngIf="!hideAdd"
    mat-button
    type="button"
    color="primary"
    (click)="onAdd()"
    [disabled]="disabled"
    [attr.data-testid]="'network-table-add'"
  >
    <mat-icon aria-hidden="false" matPrefix>add</mat-icon
    ><b>{{ 'Generic.labels.new' | translate }}</b>
  </button>
</div>
<mat-table
  matSort
  [attr.data-testid]="'reserve-network-table'"
  class="eyes-table"
  [dataSource]="source$ | async"
>
  <ng-container
    *ngFor="let column of columnsDef | keyvalue"
    [cdkColumnDef]="column.key"
  >
    <mat-header-cell
      *cdkHeaderCellDef
      mat-sort-header
      [disabled]="!column.value.sortable"
    >
      {{ column.value.name }}
      <prosumer-tooltip-anchor
        *ngIf="column.value.toolTip"
        [message]="column.value.toolTip"
      >
      </prosumer-tooltip-anchor>
    </mat-header-cell>

    <mat-cell *cdkCellDef="let row" [ngSwitch]="column.value.type">
      <div *ngSwitchCase="'action'">
        <button
          mat-icon-button
          type="button"
          color="primary"
          *ngIf="!hideView"
          [disabled]="disabled"
          [attr.data-testid]="'network-view'"
          (click)="onViewEdit(row)"
        >
          <mat-icon
            aria-hidden="false"
            aria-label="View"
            tooltipPosition="center"
            eyesTooltip="View"
            >visibility</mat-icon
          >
        </button>
        <button
          mat-icon-button
          type="button"
          color="primary"
          *ngIf="!hideEdit"
          [disabled]="disabled"
          [attr.data-testid]="'network-edit'"
          (click)="onViewEdit(row)"
        >
          <mat-icon
            aria-hidden="false"
            aria-label="Edit"
            tooltipPosition="center"
            eyesTooltip="Edit"
            >edit</mat-icon
          >
        </button>
        <button
          mat-icon-button
          type="button"
          color="primary"
          *ngIf="!hideDelete"
          [disabled]="disabled"
          [attr.data-testid]="'network-delete'"
          (click)="onDelete(row)"
        >
          <mat-icon
            aria-hidden="false"
            aria-label="Delete"
            tooltipPosition="center"
            eyesTooltip="Delete"
            >delete_forever</mat-icon
          >
        </button>
      </div>

      <ng-container *ngrxLet="refs$ as refs">
        <div *ngSwitchCase="'reference'">
          {{
            (refs[column.value.referenceKey] || {})[row[column.key]] ||
              row[column.key]
          }}
        </div>
      </ng-container>

      <div *ngSwitchCase="'custom'" [ngSwitch]="column.key">
        <mat-icon
          aria-hidden="false"
          *ngSwitchCase="'direction'"
          aria-label="Direction"
        >
          {{ DIRECTION_ICONS[row[column.key]] }}
        </mat-icon>
      </div>

      <div *ngSwitchDefault>
        {{ row[column.key] }}
      </div>
    </mat-cell>
  </ng-container>

  <mat-header-row *cdkHeaderRowDef="columnsDef | keys"></mat-header-row>
  <mat-row
    *cdkRowDef="let row; columns: columnsDef | keys"
    [@rowAnimation]
  ></mat-row>
</mat-table>

<prosumer-table-message
  *ngrxLet="hasData$ as hasData"
  [style.margin-left.px]="20"
  [style.margin-top.px]="5"
  [loading]="loading"
  [error]="error"
  [dataLength]="!hasData"
>
</prosumer-table-message>

<mat-paginator
  hidePageSize
  showFirstLastButtons
  pageSize="10"
  [class.paginator-dark]="isDarkModeEnabled"
></mat-paginator>
