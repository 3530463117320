import moment from 'moment';
import { DEFAULT_DEBOUNCE_TIME } from 'prosumer-app/app.references';
import { ResultStore } from 'prosumer-app/stores';
import { combineLatest, Observable } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ResultsPerceptionService } from '@prosumer/results/components/case-results-perception';
import { VisualizerData } from '@prosumer/results/components/results-visualizer';

import { TraditionalDispatchAdapter } from '../adapters';
import { DispatchService } from '../dispatch.service';

@Component({
  selector: 'prosumer-daily-dispatch',
  templateUrl: './daily-dispatch.component.html',
  styleUrls: ['./daily-dispatch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DailyDispatchComponent
  extends TraditionalDispatchAdapter
  implements OnInit
{
  constructor(
    private _dispatchService: DispatchService,
    private _resultStore: ResultStore,
    perception: ResultsPerceptionService,
  ) {
    super(perception);
  }

  @Input() type: 'production' | 'consumption';
  @Input() chartTitle: string;
  @Input() scenarioName: string;
  @Output() dataLoaded = new EventEmitter<Array<any>>();

  data$: Observable<any>;
  allData: any;

  ngOnInit() {
    this.data$ = combineLatest([
      this._resultStore.loading$,
      this._resultStore.isOutputSplit$,
      this._dispatchService.year$,
      this._dispatchService.node$,
      this._dispatchService.energyVector$,
      combineLatest([
        this._dispatchService.minDate$,
        this._dispatchService.maxDate$,
      ]).pipe(
        debounceTime(DEFAULT_DEBOUNCE_TIME),
        distinctUntilChanged(),
        takeUntil(this.componentDestroyed$),
      ),
    ]).pipe(
      filter(([loading, isSplit]) => !loading || !isSplit),
      switchMap(
        ([loading, isSplit, year, node, energyVector, [minDay, maxDay]]) =>
          this._resultStore
            .getRawDispatchByParams$(year, node, energyVector, this.type)
            .pipe(
              tap((data) => {
                this.allData = { dispatchData: data };
              }),
              map((filteredData) => {
                const minDate =
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  year && minDay
                    ? moment({
                        y: +year,
                        M: 0,
                        d: 1,
                        h: 0,
                        m: 0,
                        s: 0,
                        ms: 0,
                      }).dayOfYear(minDay)
                    : undefined;
                const maxDate =
                  year && maxDay
                    ? // eslint-disable-next-line @typescript-eslint/naming-convention
                      moment({
                        y: +year,
                        M: 0,
                        d: 1,
                        h: 23,
                        m: 59,
                        s: 59,
                        ms: 999,
                      }).dayOfYear(maxDay)
                    : undefined;
                return !filteredData || filteredData.length === 0
                  ? undefined
                  : filteredData.map((filteredDataItem) => {
                      const dataSet = !filteredDataItem.values
                        ? []
                        : (filteredDataItem.values as Array<any>)
                            .map((value, index) => ({
                              // eslint-disable-next-line @typescript-eslint/naming-convention
                              hour: moment({
                                y: +year,
                                M: 0,
                                d: 1,
                                h: 0,
                                m: 0,
                                s: 0,
                                ms: 0,
                              })
                                .hour(index)
                                .get('h'),
                              // eslint-disable-next-line @typescript-eslint/naming-convention
                              name: moment({
                                y: +year,
                                M: 0,
                                d: 1,
                                h: 0,
                                m: 0,
                                s: 0,
                                ms: 0,
                              })
                                .hour(index)
                                .toISOString(),
                              // eslint-disable-next-line @typescript-eslint/naming-convention
                              moment: moment({
                                y: +year,
                                M: 0,
                                d: 1,
                                h: 0,
                                m: 0,
                                s: 0,
                                ms: 0,
                              }).hour(index),
                              value,
                            }))
                            .filter((dataSetItem) =>
                              minDate && maxDate
                                ? minDate.isSameOrBefore(dataSetItem.moment) &&
                                  maxDate.isSameOrAfter(dataSetItem.moment)
                                : true,
                            );

                      if (
                        dataSet &&
                        dataSet instanceof Array &&
                        dataSet.length > 0
                      ) {
                        const series = [];
                        Array.from(Array(24).keys()).forEach((hour) => {
                          const hourDataSet = dataSet.filter(
                            (data) => data && data.hour === hour,
                          );
                          const hourData = hourDataSet
                            .map((data) => ({ ...data, name: '' + (hour + 1) }))
                            .reduce((prev, curr) => ({
                              ...curr,
                              value: prev.value + curr.value,
                            }));
                          series.push({
                            ...hourData,
                            value: hourData.value / (hourDataSet.length || 1),
                          });
                        });
                        return {
                          name: filteredDataItem.der,
                          series,
                        };
                      }
                      return {
                        name: filteredDataItem.der,
                        series: undefined,
                      };
                    });
              }),
              takeUntil(this.componentDestroyed$),
            ),
      ),
      tap((data) => {
        this.dataLoaded.emit(data);
      }),
      shareReplay(1),
      takeUntil(this.componentDestroyed$),
    );

    this.initializeChartDataAndColors();
  }

  formatTick = (tick: string) => tick;

  getChartName(): string {
    return this.chartTitle;
  }

  getNgxChartsDataStream(): Observable<VisualizerData[]> {
    return this.data$;
  }
}
