import {
  ScenarioInfoCascader,
  ScenarioInfoService,
} from 'prosumer-app/+scenario/services';
import { CascadeController } from 'prosumer-app/services/cascade-controller';
import { ScenarioGeneric } from 'prosumer-app/stores/scenario-generic';
import { Coerce } from 'prosumer-core/utils';
import { iif, Observable } from 'rxjs';

import { inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TimeHorizonService {
  private controller = inject(CascadeController);
  private shittyInfo = inject(ScenarioInfoService);
  private cascader = inject(ScenarioInfoCascader);

  updateProjectDuration(data: unknown = {}): Observable<unknown> {
    return this.shittyInfo.updateScenario(data, true);
  }

  updateYearsToOptimize(previous: number[], data: Partial<ScenarioGeneric>) {
    return iif(
      () => this.shouldGetAffected(previous, data),
      this.cascader.update('yearsToOptimize', data),
      this.shittyInfo.updateScenario(data),
    );
  }

  private shouldGetAffected(
    previous: number[],
    data: Partial<ScenarioGeneric>,
  ) {
    return [
      this.wasYearRemoved(previous, data),
      this.isNotFullPeriod(data),
    ].every(Boolean);
  }

  private isNotFullPeriod(data: Partial<ScenarioGeneric>) {
    return !Coerce.toObject(data.timeHorizon).fullTimeHorizon;
  }

  private wasYearRemoved(previous: number[], data: Partial<ScenarioGeneric>) {
    return data.yearsToOptimize.years.length < previous.length;
  }
}
