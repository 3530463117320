<prosumer-sparkline-table
  [columnsDef]="tableMetaData"
  [references]="references$ | ngrxPush"
  [data]="vehicleStationsList$ | async"
  [searchInput]="true"
  [searchPredicate]="vehicleSearchPredicate"
  [disableNewButton]="!hasVehicleDispatch"
  (add)="onAdd()"
  (edit)="onEdit($event)"
  (delete)="onDelete($event)"
  [hideAdd]="isViewOnly"
  [hideEdit]="isViewOnly"
  [hideDelete]="isViewOnly"
  [hideView]="!isViewOnly"
>
</prosumer-sparkline-table>
