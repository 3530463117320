import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { CustomOptions } from 'prosumer-app/+scenario/models/optimization-cockpit.model';
import { DialogService } from 'prosumer-app/libs/eyes-core';
import {
  ColumnDefinition,
  containsSubstring,
} from 'prosumer-app/libs/eyes-shared';
import {
  OptimizationCustomOption,
  OptimizationCustomOptionQuery,
  OptimizationCustomOptionStore,
} from 'prosumer-app/stores/custom-option';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  CustomOptionsFormDialogComponent,
  CustomOptionsFormDialogData,
} from '../custom-options-form-dialog';

@Component({
  selector: 'prosumer-custom-options-form-table',
  templateUrl: './custom-options-form-table.component.html',
  styleUrls: ['./custom-options-form-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomOptionsFormTableComponent implements OnInit {
  @Input() scenarioIdentity: unknown = {};

  @Input() isViewOnly: boolean;

  readonly columnsDef: ColumnDefinition = {
    name: {
      name: 'Name',
      sortable: true,
    },
    value: {
      name: 'Value',
      sortable: true,
    },
    actions: {
      name: 'Actions',
      type: 'action',
      sortable: true,
    },
  };

  source$: Observable<OptimizationCustomOption[]>;

  constructor(
    private readonly dialog: DialogService,
    private readonly store: OptimizationCustomOptionStore,
    private readonly query: OptimizationCustomOptionQuery,
  ) {}

  ngOnInit(): void {
    this.source$ = this.query.selectAllForActiveScenario();
  }

  customOptionsSearchPredicate = (data: CustomOptions, filter: string) =>
    containsSubstring(data.name, filter);

  onAdd() {
    this.dialog.openDialog(
      CustomOptionsFormDialogComponent,
      this.generateDialogData(),
    );
  }

  onEdit(data: OptimizationCustomOption) {
    this.dialog.openDialog(CustomOptionsFormDialogComponent, {
      ...this.generateDialogData(),
      mode: 'edit',
      name: data.name,
      value: data.value,
      id: data.id,
      isViewOnly: this.isViewOnly,
    });
  }

  onDelete(data: OptimizationCustomOption) {
    this.store.deleteOne(data.id).pipe(take(1)).subscribe();
  }

  private generateDialogData() {
    return {
      width: 600,
      mode: 'add',
      disableClose: true,
    } as CustomOptionsFormDialogData;
  }
}
