import { Directive, ElementRef, HostListener, Input } from '@angular/core';

import { TableExporterService } from './table-exporter.service';
import { TableExportType } from './table-exporter.service.model';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[exportTable]',
  providers: [TableExporterService],
})
export class ExportTableDirective {
  @Input() exportTable: unknown;
  @Input() format: TableExportType = 'xlsx';
  @Input() name = 'export_output';

  @HostListener('click') onMouseClick(): void {
    const nativeElement = this.extractNativeElement(
      this.exportTable as ParentElementRef,
    );
    this.service.exportTableAs(nativeElement, this.name, this.format);
  }
  constructor(private service: TableExporterService) {}

  private extractNativeElement(element: ParentElementRef): unknown {
    return element._elementRef.nativeElement;
  }
}

type ParentElementRef = {
  _elementRef: ElementRef;
};
