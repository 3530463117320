import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ResultsVisualizerModule } from '@prosumer/results/components/results-visualizer';
import { BarAndAreaResultsModule } from '../bar-and-area-results';
import { TopologyResultsComponent } from './topology-results.component';

@NgModule({
  declarations: [TopologyResultsComponent],
  imports: [
    CommonModule,
    ResultsVisualizerModule,
    BarAndAreaResultsModule,
    TranslateModule,
  ],
  exports: [TopologyResultsComponent],
})
export class TopologyResultsModule {}
