import {
  EnergyVector,
  Equipment,
  Load,
  Netting,
  NettingForm,
  TaxAndSubsidies,
} from 'prosumer-app/+scenario/models';
import { DialogService, RouterStore } from 'prosumer-app/libs/eyes-core';
import {
  ColumnDefinition,
  FormFieldOption,
  StepFormComponent,
  containsSubstring,
  generateShortUID,
  toObject,
} from 'prosumer-app/libs/eyes-shared';
import { Observable, combineLatest } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
  Optional,
  Self,
} from '@angular/core';
import { UntypedFormBuilder, NgControl } from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NettingCompletion } from 'prosumer-app/+scenario/services/completion-strategies';
import {
  ScenarioCompletionService,
  ScenarioWizardStep,
} from 'prosumer-app/+scenario/services/scenario-completion';
import {
  ReferencesBuilder,
  TableRefs,
} from 'prosumer-app/services/references-builder';
import { ManagedDataService } from 'prosumer-app/shared/services/managed-data';
import { ScenarioDetailType } from 'prosumer-app/stores';
import { NettingStore } from 'prosumer-app/stores/netting';
import { NodeQuery } from 'prosumer-app/stores/node';
import {
  TaxAndSubsidy,
  TaxAndSubsidyStore,
} from 'prosumer-app/stores/tax-and-subsidy';
import { NettingFormDialogComponent } from './netting-form-dialog';
import { NettingFormService } from './netting-form.service';
import { TaxSubsidyFormDialogComponent } from './tax-subsidy-form-dialog';

@UntilDestroy()
@Component({
  selector: 'prosumer-netting-form',
  templateUrl: './netting-form.component.html',
  styleUrls: ['./netting-form.component.scss'],
  providers: [
    { provide: 'netting', useClass: ManagedDataService },
    { provide: 'taxAndSubsidies', useClass: ManagedDataService },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NettingFormComponent
  extends StepFormComponent
  implements OnInit, AfterViewInit
{
  _scenarioIdentity: any;
  _nodeOptions: Array<FormFieldOption<string>> = [];
  _evOptions: Array<FormFieldOption<string>> = [];
  nettingOptions: Array<FormFieldOption<string>> = [];
  projectId: string;
  caseId: string;
  scenarioId: string;
  _equipmentList: Array<Equipment> = [];

  @Input() isViewOnly: boolean;
  @Input() energyVectors: Array<EnergyVector>;
  @Input() equipments: Array<Equipment>;
  @Input() loads: Array<Load>;
  @Input() set nodeOptions(nodeOptions: Array<FormFieldOption<string>>) {
    this._nodeOptions = nodeOptions;
    this.generateMetaData();
  }
  get nodeOptions(): Array<FormFieldOption<string>> {
    return this._nodeOptions;
  }

  @Input() set evOptions(evOptions: Array<FormFieldOption<string>>) {
    this._evOptions = evOptions;
    this.generateMetaData();
  }
  get evOptions(): Array<FormFieldOption<string>> {
    return this._evOptions;
  }
  @Input() set scenarioIdentity(_scenarioIdentity: any) {
    this._scenarioIdentity = _scenarioIdentity;
  }
  get scenarioIdentity(): any {
    return this._scenarioIdentity;
  }

  @Input() startYear: number;
  @Input() endYear: number;
  @Input() isMultiNode: boolean;

  readonly refs$: Observable<TableRefs> = this.refBuilder.selectRefs();

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    public changeDetector: ChangeDetectorRef,
    public formBuilder: UntypedFormBuilder,
    @Inject('netting') public manageData: ManagedDataService<Netting>,
    @Inject('taxAndSubsidies')
    public taxSubsidyManageData: ManagedDataService<TaxAndSubsidies>,
    private router: RouterStore,
    private dialog: DialogService,
    private completion: ScenarioCompletionService,
    private service: NettingFormService,
    private readonly refBuilder: ReferencesBuilder,
    private readonly nettingStore: NettingStore,
    private readonly taxubStore: TaxAndSubsidyStore,
    private readonly nodesQuery: NodeQuery,
  ) {
    super(ngControl, changeDetector, formBuilder);
    this.subscribeToFormForCompletionTracking();
  }

  readonly nettingList$ = this.service.selectActiveNettings();
  readonly taxSubsidyList$ = this.taxSubsidyManageData.listData$;
  readonly taxAndSubs$ = this.service.selectActiveTaxAndSubs();

  references = {};
  columnsDef: ColumnDefinition;

  defineForm() {
    return {
      netting: [],
      taxAndSubsidies: [],
    };
  }

  ngAfterViewInit() {
    this.setIds();

    combineLatest([this.nettingList$, this.taxSubsidyList$])
      .pipe(this.takeUntilShare())
      .subscribe(() => {
        this.form.markAsDirty();
        this.changeDetector.markForCheck();
      });
    // for every load list update, patch
    this.manageData.listData$.subscribe((entry) => {
      this.form.controls.netting.patchValue(entry);
      this.nettingOptions = entry.map((nettingData) => ({
        name: nettingData.name,
        value: nettingData.id,
      }));
      this.references = {
        ...this.references,
        netting: toObject(entry, 'id', 'name'),
      };
    });
    this.taxSubsidyManageData.listData$.subscribe((entry) =>
      this.form.controls.taxAndSubsidies.patchValue(entry),
    );
  }

  ngOnInit() {
    this.generateMetaData();
    this.form.valueChanges.pipe(this.takeUntil()).subscribe((formValue) => {
      this.onChange(formValue);
    });
  }

  setIds() {
    this.router.params$.pipe(this.takeUntil()).subscribe((data) => {
      this.projectId = data.projectId;
      this.caseId = data.caseId;
      this.scenarioId = data.scenarioId;
    });
  }

  /**
   * Generated the intial load data to be passed
   * to the add/edit load dialog
   */
  generateInitialLoadData = () => {
    const dialogOptions = this.service.prepOptionsForNettingDialog();
    return {
      mode: 'add',
      width: '60%',
      isMultiNode: this.isMultiNode,
      endUseLoad: {
        name: '',
        timePartition: '',
        energyVectorId: '',
        nodes: this.resolveDefaultNode(),
        equipments: [],
        loads: [],
      },
      ...dialogOptions,
    };
  };

  generateInitialTaxSubsidyData = () => ({
    mode: 'add',
    width: '60%',
    isMultiNode: this.isMultiNode,
    nettingOptions: this.nettingOptions,
    nodeOptions: this.nodeOptions,
    startYear: this.startYear,
    endYear: this.endYear,
    editData: {
      nodes: this.resolveDefaultNode(),
    },
    disableClose: true,
    ...this.service.prepOptionsFoTaxAndSubsidyDialog(),
  });

  private resolveDefaultNode(): string[] {
    const nodes = this.nodesQuery.getNodes();
    return nodes.length === 1 ? [nodes[0].nodeId] : [];
  }

  onAddNetting() {
    this.dialog
      .openDialog(NettingFormDialogComponent, {
        ...this.generateInitialLoadData(),
      })
      .pipe(take(1))
      .subscribe((value) => {
        if (!!value) {
          this.manageData.add({ ...value, forSaving: true });
        }
      });
  }

  onDeleteNetting(netting: Netting) {
    this.service
      .deleteNetting(netting.id)
      .pipe(
        take(1),
        switchMap(() => this.taxubStore.improvedGetAll()),
      )
      .subscribe();
  }

  getAssociatedTaxAndSubsidies(netting: Netting) {
    return this.taxSubsidyManageData
      .getListData()
      .filter((taxAndSubsidy) => taxAndSubsidy.netting === netting.id);
  }

  onEditNetting(netting: Netting) {
    this.nettingStore
      .getSingle(ScenarioDetailType.netting, netting.id)
      .pipe(
        take(1),
        switchMap((data) =>
          this.dialog.openDialog(NettingFormDialogComponent, {
            ...this.generateInitialLoadData(),
            mode: 'edit',
            id: netting.id,
            endUseLoad: data,
            isViewOnly: this.isViewOnly || this.mode === 'read',
          }),
        ),
      )
      .subscribe();
  }

  onAddTaxAndSubsidies() {
    this.dialog
      .openDialog(TaxSubsidyFormDialogComponent, {
        ...this.generateInitialTaxSubsidyData(),
      })
      .pipe(take(1))
      .subscribe((value) => {
        if (!!value) {
          this.taxSubsidyManageData.add({
            id: generateShortUID(),
            ...value,
            forSaving: true,
          });
        }
      });
  }

  onEditTaxAndSubsidies(data: TaxAndSubsidies) {
    if (!data) {
      return;
    }

    this.taxubStore
      .getSingle(ScenarioDetailType.taxAndSubsidy, data.id)
      .pipe(
        take(1),
        switchMap((taxSubData) =>
          this.dialog.openDialog(TaxSubsidyFormDialogComponent, {
            ...this.generateInitialTaxSubsidyData(),
            ...data,
            mode: 'edit',
            editData: taxSubData,
            isViewOnly: this.isViewOnly || this.mode === 'read',
          }),
        ),
      )
      .subscribe();
  }

  onDeleteTaxAndSubsidies(taxAndSub: TaxAndSubsidy): void {
    this.service.deleteTaxAndSub(taxAndSub.id).pipe(take(1)).subscribe();
  }

  /**
   * table metadata and filter definiton
   */
  generateMetaData = () => {
    this.references = {
      nodes: toObject(this.nodeOptions, 'value', 'name'),
      energyVectors: toObject(this.evOptions, 'value', 'name'),
      netting: toObject(this.manageData.getListData(), 'id', 'name'),
    };
  };

  writeValue(value: NettingForm) {
    this.setValue(value);
    super.writeValue(value);
  }

  setValue(value: NettingForm) {
    if (!!value) {
      this.manageData.setListData([...value.netting]);
      this.taxSubsidyManageData.setListData([...value.taxAndSubsidies]);
    }
  }

  searchPredicate = (data: TaxAndSubsidies, filter: string) => {
    let netting = '';

    this.manageData.getListData().forEach((nettingData) => {
      if (nettingData.id === data.netting) {
        netting = nettingData.name;
      }
    });

    return containsSubstring(netting, filter);
  };

  private subscribeToFormForCompletionTracking(): void {
    const strategy = new NettingCompletion();
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((form) => {
      this.completion.setForStep(
        ScenarioWizardStep.netting,
        strategy.determineStatus(form),
      );
    });
  }
}
