<form (ngSubmit)="onClickNext()">
  <!-- Routes -->
  <mat-expansion-panel [expanded]="expanded">
    <mat-expansion-panel-header
      [collapsedHeight]="collapsedHeight"
      [expandedHeight]="expandedHeight"
    >
      <mat-panel-title data-testid="mobility-router-panel-title">
        {{ 'Scenario.labels.wizard.mobility.headers.routes' | translate }}
        <prosumer-tooltip-anchor
          [message]="
            'wizard_mobility.wizard_mobility_routes_section' | translate
          "
        >
        </prosumer-tooltip-anchor>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <prosumer-routes-form-component
      [isViewOnly]="isViewOnly || mode === 'read'"
      [startYear]="startYear"
      [endYear]="endYear"
      [scenarioIdentity]="scenarioIdentity"
      (routeDeleteEvent)="onDeleteRoutes($event)"
      data-testid="mobility-router-table"
    ></prosumer-routes-form-component>
  </mat-expansion-panel>
  <!-- Vehicle Dispatch -->
  <mat-expansion-panel [expanded]="expanded">
    <mat-expansion-panel-header
      [collapsedHeight]="collapsedHeight"
      [expandedHeight]="expandedHeight"
    >
      <mat-panel-title data-testid="vehicle-dispatch-panel-title">
        {{
          'Scenario.labels.wizard.mobility.headers.vehiclesDispatch' | translate
        }}
        <prosumer-tooltip-anchor
          [message]="
            'wizard_mobility.wizard_mobility_dispatch_section' | translate
          "
        >
        </prosumer-tooltip-anchor>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <prosumer-vehicles-dispatch-form
      [isViewOnly]="isViewOnly || mode === 'read'"
      [startYear]="startYear"
      [endYear]="endYear"
      (vehicleDeleteEvent)="onDeleteVehicles($event)"
      data-testid="vehicle-dispatch-table"
    >
    </prosumer-vehicles-dispatch-form>
  </mat-expansion-panel>
  <!-- Station Vehicle Association -->
  <mat-expansion-panel [expanded]="expanded">
    <mat-expansion-panel-header
      [collapsedHeight]="collapsedHeight"
      [expandedHeight]="expandedHeight"
    >
      <mat-panel-title data-testid="station-vehicle-panel-title">
        {{
          'Scenario.labels.wizard.mobility.headers.stationVehicleAssociation'
            | translate
        }}
        <prosumer-tooltip-anchor
          [message]="
            'wizard_mobility.wizard_mobility_station_vehicle_section'
              | translate
          "
        >
        </prosumer-tooltip-anchor>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <prosumer-vehicle-station-form-component
      [isMultiNode]="isMultiNode"
      [isViewOnly]="isViewOnly || mode === 'read'"
      [scenarioIdentity]="scenarioIdentity"
      [vehicleDispatchList]="allVehicleDispatches$ | async"
      data-testid="station-vehicle-table"
    >
    </prosumer-vehicle-station-form-component>
  </mat-expansion-panel>
  <prosumer-stepper-nav
    [disabled]="disabled"
    [previousDisabled]="previousDisabled"
    [previousLabel]="previousLabel"
    [previousColor]="previousColor"
    [nextDisabled]="nextDisabled"
    [nextLabel]="nextLabel"
    [nextColor]="nextColor"
    [reverseButtons]="reverseButtons"
    (previous)="onClickPrevious()"
  >
  </prosumer-stepper-nav>
</form>
