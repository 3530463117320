import { Injectable } from '@angular/core';
import { StoreConfig } from '@datorama/akita';
import { FrequencyControlMapper } from 'prosumer-app/+scenario/services/mappers';
import { ScenarioDetailStore, ScenarioDetailType } from '../scenario-detail';
import {
  ShortCircuitEquipment,
  ShortCircuitEquipmentIncoming,
  ShortCircuitEquipmentOutgoing,
} from './short-circuit-equipment.state';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: ScenarioDetailType.scEquipment })
export class ShortCircuitEquipmentStore extends ScenarioDetailStore<ShortCircuitEquipment> {
  toBE(from: ShortCircuitEquipment): ShortCircuitEquipmentOutgoing {
    return FrequencyControlMapper.scEquipmentToBE(from);
  }

  toFE(from: ShortCircuitEquipmentIncoming): ShortCircuitEquipment {
    return FrequencyControlMapper.scEquipmentToFE(from);
  }
}
