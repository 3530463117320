import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EquipmentQuery } from '../equipment';
import {
  OperationalCostInfo,
  OperationalCostState,
} from './operational-cost.state';
import { OperationalCostStore } from './operational-cost.store';

@Injectable({
  providedIn: 'root',
})
export class OperationalCostQuery extends QueryEntity<OperationalCostState> {
  constructor(
    store: OperationalCostStore,
    private readonly equipments: EquipmentQuery,
  ) {
    super(store);
  }

  selectActiveOnes(): Observable<OperationalCostInfo[]> {
    return this.selectAll().pipe(
      map((costs) => this.onlyForActiveEquipment(costs)),
    );
  }

  private onlyForActiveEquipment(
    costs: OperationalCostInfo[],
  ): OperationalCostInfo[] {
    return costs.filter((cost) => this.isForActiveEquipment(cost));
  }

  private isForActiveEquipment(cost: OperationalCostInfo): boolean {
    return cost.equipmentId === this.equipments.getActiveId();
  }
}
