import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { LetDirective, PushPipe } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';
import { OpcostsUpsertTriggerComponent } from './opcosts-upsert-trigger.component';

@NgModule({
  declarations: [OpcostsUpsertTriggerComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    TranslateModule,
    LetDirective,
    PushPipe,
  ],
  exports: [OpcostsUpsertTriggerComponent],
})
export class OpcostsUpsertTriggerModule {}
