import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EgcListerDirective } from './egc-lister.directive';

@NgModule({
  declarations: [EgcListerDirective],
  imports: [CommonModule],
  exports: [EgcListerDirective],
})
export class EgcListerModule {}
