<prosumer-progress-card
  [showProgress]="loading$ | async"
  data-testid="topology-line-dialog"
>
  <form [formGroup]="linesForm" (ngSubmit)="onSubmit()">
    <h2 class="mat-headline-6" mat-dialog-title>
      {{ data?.mode | titlecase }} Line
    </h2>
    <div class="mat-body" mat-dialog-content>
      <!-- General Parameters -->
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header
          collapsedHeight="35px"
          expandedHeight="35px"
        >
          <mat-panel-title>
            <h3 class="mat-h3">
              {{ 'Scenario.labels.general.parameters' | translate }}
            </h3>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div fxLayout="column">
          <div fxLayout="row" fxLayoutGap="10px">
            <!-- Line Name -->
            <prosumer-input
              fxFlex="49.9%"
              module="Scenario"
              formFieldName="lines.name"
              data-testid="topology-line-name"
              [control]="linesForm?.controls?.name"
              [required]="true"
            >
            </prosumer-input>
            <!-- Energy Vector -->
            <prosumer-select
              [placeholder]="'Scenario.placeholders.energyVector' | translate"
              [label]="'Scenario.labels.energyVector' | translate"
              [options]="energyVectorOptions$ | async"
              [control]="linesForm?.controls?.energyVectorId"
              [required]="true"
              [errorMessageMap]="errorMessages['energyVectorId']"
              [tooltip]="'wizard_topology.wizard_topology_energy_vector'"
              fxFlex="49.9%"
              data-testid="topology-energy-vector"
            >
            </prosumer-select>
          </div>
          <div fxLayout="row" fxLayoutGap="10px">
            <!-- Origin -->
            <prosumer-select
              [placeholder]="'Scenario.placeholders.originNode' | translate"
              [label]="'Scenario.labels.originNode' | translate"
              [options]="nodeOptions$ | async"
              [control]="linesForm?.controls?.originNodeId"
              [required]="true"
              [errorMessageMap]="errorMessages['originNodeId']"
              [tooltip]="'wizard_topology.wizard_topology_origin_node'"
              fxFlex="49.9%"
              data-testid="topology-origin-node"
            >
            </prosumer-select>
            <!-- Destination -->
            <prosumer-select
              [placeholder]="'Scenario.placeholders.destiNode' | translate"
              [label]="'Scenario.labels.destiNode' | translate"
              [options]="nodeOptions$ | async"
              [control]="linesForm?.controls?.destinationNodeId"
              [required]="true"
              [errorMessageMap]="errorMessages['destinationNodeId']"
              [tooltip]="'wizard_topology.wizard_topology_destination_node'"
              fxFlex="49.9%"
              data-testid="topology-desti-node"
            >
            </prosumer-select>
          </div>
          <div fxLayout="row" fxLayoutGap="10px">
            <!-- Bi-directional option TODO-->
            <prosumer-select
              [label]="'Scenario.labels.bidirectional' | translate"
              [options]="bidirectionalOptions"
              [control]="linesForm?.controls?.bidirectional"
              [required]="true"
              [tooltip]="'wizard_topology.wizard_topology_bidirectional_line'"
              fxFlex="49.9%"
            >
            </prosumer-select>
            <!-- Distance -->
            <prosumer-input
              fxFlex="49.9%"
              type="number"
              module="Scenario"
              formFieldName="lines.distance"
              data-testid="topology-distance"
              [control]="linesForm?.controls?.distance"
              [inputTooltip]="'wizard_topology.wizard_topology_distance'"
              [required]="true"
            >
            </prosumer-input>
          </div>
          <prosumer-radio-button
            [label]="'Scenario.labels.general.source' | translate"
            [options]="profileOptions"
            [control]="linesForm?.controls?.sourceType"
            [labelTooltip]="'wizard_topology.wizard_topology_source'"
          >
          </prosumer-radio-button>
          <div
            *ngIf="linesForm?.controls?.sourceType?.value === 'library'"
            fxLayout="column"
            fxLayoutGap="10px"
          >
            <prosumer-sparkline-table
              maxHeight="300px"
              [columnsDef]="columnsDef"
              [data]="library$ | async"
              [selected]="linesForm.controls.library?.value?.id"
              [loading]="libraryService.loading$ | async"
              [error]="libraryService.error$ | async"
              [messages]="messages"
              [hideAdd]="true"
              [hideEdit]="true"
              [hideDelete]="true"
              (selectionChanged)="onSelect($event)"
              data-testid="topology-vector-library"
            >
            </prosumer-sparkline-table>

            <!-- Using class 'hidden' will use a special mat-form-field to be used when displaying only the error message -->
            <mat-form-field class="hidden">
              <input matInput [formControl]="linesForm?.controls?.library" />
              <mat-error
                *ngIf="linesForm?.controls?.library?.errors?.required"
                [innerHTML]="
                  'Scenario.messages.der.library.required' | translate
                "
              >
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </mat-expansion-panel>

      <!-- Technical Parameters -->
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header
          collapsedHeight="35px"
          expandedHeight="35px"
        >
          <mat-panel-title>
            <h3 class="mat-h3">
              {{ 'Scenario.labels.technical.parameters' | translate }}
            </h3>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div fxLayout="column">
          <div fxLayout="row" fxLayoutGap="10px">
            <!-- Efficiency rate -->
            <prosumer-yearly-chart-input
              [formControl]="linesForm?.controls?.yearlyEfficiency"
              [startYear]="linesForm?.controls?.startYear?.value"
              [endYear]="linesForm?.controls?.endYear?.value"
              [errorMessage]="'Scenario.messages.lines.efficiency'"
              [contextHelpMsg]="'wizard_topology.wizard_topology_efficiency'"
              [inputLabel]="'Scenario.labels.efficiency' | translate"
              fxFlex="49.9%"
              data-testid="wizard-topology-efficiency"
            >
            </prosumer-yearly-chart-input>
            <div fxFlex="49.9%"></div>
          </div>
        </div>
      </mat-expansion-panel>

      <!-- Scope3 Emissions Parameters -->
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header
          collapsedHeight="35px"
          expandedHeight="35px"
        >
          <mat-panel-title>
            <h3 class="mat-h3">
              {{ 'Scenario.labels.co2Emissions.parameters' | translate }}
            </h3>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div fxLayout="column">
          <div fxLayout="row" fxLayoutGap="10px">
            <!-- Build emissions kW -->
            <prosumer-input
              type="number"
              placeholder="0.0"
              fxFlex="49.9%"
              *ngIf="false"
              [control]="linesForm?.controls?.buildEmissionskwm"
              [label]="
                'Scenario.labels.co2Emissions.buildEmissionsKwm' | translate
              "
              [errorMessageMap]="errorMessages?.buildEmissionsKwm"
              [required]="true"
              [inputTooltip]=""
              data-testid="wizard-topology-buildEmissionsKwm"
            >
            </prosumer-input>
            <prosumer-yearly-chart-input
              [formControl]="linesForm?.controls?.yearlyBuildEmissionsKwm"
              [startYear]="linesForm?.controls?.startYear?.value"
              [endYear]="linesForm?.controls?.endYear?.value"
              [errorMessage]="'Scenario.messages.lines.buildEmissionsKwm'"
              [inputLabel]="
                'Scenario.labels.co2Emissions.buildEmissionsKwm' | translate
              "
              fxFlex="49.9%"
              [contextHelpMsg]=""
              data-testid="wizard-topology-yearlyBuildEmissionsKwm"
            >
            </prosumer-yearly-chart-input>
            <!-- Build emissions indivisible -->
            <prosumer-input
              type="number"
              placeholder="0.0"
              fxFlex="49.9%"
              *ngIf="false"
              [control]="linesForm?.controls?.buildEmissionsIndivisible"
              [label]="
                'Scenario.labels.co2Emissions.buildEmissionsIndivisiblekgCO2investm'
                  | translate
              "
              [errorMessageMap]="
                errorMessages?.buildEmissionsIndivisiblekgCO2investm
              "
              [required]="true"
              [inputTooltip]=""
              data-testid="wizard-topology-buildEmissionsIndivisible"
            >
            </prosumer-input>
            <prosumer-yearly-chart-input
              [formControl]="
                linesForm?.controls?.yearlyBuildEmissionsIndivisible
              "
              [startYear]="linesForm?.controls?.startYear?.value"
              [endYear]="linesForm?.controls?.endYear?.value"
              [errorMessage]="
                'Scenario.messages.lines.buildEmissionsIndivisiblekgCO2investm'
              "
              [inputLabel]="
                'Scenario.labels.co2Emissions.buildEmissionsIndivisiblekgCO2investm'
                  | translate
              "
              fxFlex="49.9%"
              [contextHelpMsg]=""
              data-testid="wizard-topology-yearlyBuildEmissionsIndivisible"
            >
            </prosumer-yearly-chart-input>
          </div>
        </div>
      </mat-expansion-panel>

      <!-- Sizing Parameters -->
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header
          collapsedHeight="35px"
          expandedHeight="35px"
        >
          <mat-panel-title>
            <h3 class="mat-h3">
              {{ 'Scenario.labels.sizing.parameters' | translate }}
            </h3>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div fxLayout="column">
          <div fxLayout="row" fxLayoutGap="10px">
            <!-- Min size -->
            <prosumer-yearly-chart-input
              [formControl]="linesForm?.controls?.yearlyMinSize"
              [startYear]="linesForm?.controls?.startYear?.value"
              [endYear]="linesForm?.controls?.endYear?.value"
              [errorMessage]="'Scenario.messages.lines.minSize'"
              [contextHelpMsg]="'wizard_topology.wizard_topology_minimum_size'"
              [inputLabel]="'Scenario.labels.minSize' | translate"
              fxFlex="49.9%"
              data-testid="wizard-topology-sizing-minimum-size"
            >
            </prosumer-yearly-chart-input>
            <!-- Max size -->

            <prosumer-yearly-chart-input
              [formControl]="linesForm?.controls?.yearlyMaxSize"
              [startYear]="linesForm?.controls?.startYear?.value"
              [endYear]="linesForm?.controls?.endYear?.value"
              [errorMessage]="'Scenario.messages.lines.maxSize'"
              [contextHelpMsg]="'wizard_topology.wizard_topology_maximum_size'"
              [inputLabel]="'Scenario.labels.maxSize' | translate"
              fxFlex="49.9%"
              data-testid="wizard-topology-sizing-maximum-size"
            >
            </prosumer-yearly-chart-input>
          </div>

          <div fxLayout="row" fxLayoutGap="10px">
            <!-- Forced Investment TODO: -->
            <prosumer-select
              fxFlex="49.9%"
              [label]="'Scenario.labels.forcedInvestment' | translate"
              [options]="forcedInvestOptions"
              [control]="linesForm?.controls?.forcedInvestment"
              [required]="true"
              [tooltip]="'wizard_topology.wizard_topology_forced_investment'"
              data-testid="wizard-topology-force-investment"
            >
            </prosumer-select>

            <!-- Existing Asset -->
            <prosumer-select
              fxFlex="49.9%"
              [label]="'Scenario.labels.existingAsset' | translate"
              [options]="existingAssetOptions"
              [control]="linesForm?.controls?.existingAsset"
              [required]="true"
              [tooltip]="'wizard_topology.wizard_topology_existing_asset'"
              [errorMessageMap]="errorMessages?.existingAsset"
              data-testid="wizard-topology-existing-asset"
            >
            </prosumer-select>
          </div>

          <div fxLayout="row" fxLayoutGap="10px">
            <!-- Capacity Expansion -->
            <prosumer-select
              fxFlex="49.9%"
              [label]="'Scenario.labels.capacityExpansion' | translate"
              [options]="capacityExpansionOptions"
              [control]="linesForm?.controls?.capacityExpansion"
              [required]="true"
              [tooltip]="'wizard_topology.wizard_topology_capacity_expansion'"
              data-testid="wizard-topology-capacity-expansion"
            >
            </prosumer-select>

            <div fxFlex="49.9%"></div>
          </div>
        </div>
      </mat-expansion-panel>

      <!-- Economical Parameters -->
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header
          collapsedHeight="35px"
          expandedHeight="35px"
        >
          <mat-panel-title>
            <h3 class="mat-h3">
              {{ 'Scenario.labels.economical.parameters' | translate }}
            </h3>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div fxLayout="column">
          <div fxLayout="row" fxLayoutGap="10px">
            <!-- Build Cost -->

            <prosumer-yearly-chart-input
              [formControl]="linesForm?.controls?.yearlyBuildCost"
              [startYear]="linesForm?.controls?.startYear?.value"
              [endYear]="linesForm?.controls?.endYear?.value"
              [errorMessage]="'Scenario.messages.lines.buildCost'"
              [contextHelpMsg]="'wizard_topology.wizard_topology_build_cost'"
              [inputLabel]="'Scenario.labels.buildCost' | translate"
              fxFlex="49.9%"
              data-testid="wizard-topology-yearlyBuildCost"
            >
            </prosumer-yearly-chart-input>
            <!-- Indivisible Cost -->

            <prosumer-yearly-chart-input
              [formControl]="linesForm?.controls?.yearlyIndivisibleCost"
              [startYear]="linesForm?.controls?.startYear?.value"
              [endYear]="linesForm?.controls?.endYear?.value"
              [errorMessage]="'Scenario.messages.lines.indivisibleCost'"
              [contextHelpMsg]="
                'wizard_topology.wizard_topology_indivisible_cost'
              "
              [inputLabel]="'Scenario.labels.indiviCost' | translate"
              fxFlex="49.9%"
              data-testid="wizard-topology-yearlyIndivisibleCost"
            >
            </prosumer-yearly-chart-input>
          </div>
          <div fxLayout="row" fxLayoutGap="10px">
            <!-- FO&M Charge -->

            <prosumer-yearly-chart-input
              [formControl]="linesForm?.controls?.yearlyFomCharge"
              [startYear]="linesForm?.controls?.startYear?.value"
              [endYear]="linesForm?.controls?.endYear?.value"
              [errorMessage]="'Scenario.messages.lines.fomCharge'"
              [contextHelpMsg]="'wizard_topology.wizard_topology_fom_charge'"
              [inputLabel]="'Scenario.labels.foAndMCharge' | translate"
              fxFlex="49.9%"
              data-testid="wizard-topology-yearlyFomCharge"
            >
            </prosumer-yearly-chart-input>
            <!-- FO&M Per Install -->
            <prosumer-input
              type="number"
              [placeholder]="'Scenario.placeholders.foAndMPerInst' | translate"
              *ngIf="false"
              [label]="'Scenario.labels.foAndMPerInst' | translate"
              [control]="linesForm?.controls?.fomInstall"
              [required]="true"
              [errorMessageMap]="errorMessages['fomInstall']"
              [inputTooltip]="'wizard_topology.wizard_topology_fom_per_install'"
              fxFlex="49.9%"
            >
            </prosumer-input>
            <prosumer-yearly-chart-input
              [formControl]="linesForm?.controls?.yearlyFomInstall"
              [startYear]="linesForm?.controls?.startYear?.value"
              [endYear]="linesForm?.controls?.endYear?.value"
              [errorMessage]="'Scenario.messages.lines.fomInstall'"
              [contextHelpMsg]="
                'wizard_topology.wizard_topology_fom_per_install'
              "
              [inputLabel]="'Scenario.labels.foAndMPerInst' | translate"
              fxFlex="49.9%"
              data-testid="wizard-topology-yearlyFomInstall"
            >
            </prosumer-yearly-chart-input>
          </div>
          <div fxLayout="row" fxLayoutGap="10px">
            <!-- Technical Life -->

            <prosumer-yearly-chart-input
              [formControl]="linesForm?.controls?.yearlyTechnicalLife"
              [startYear]="linesForm?.controls?.startYear?.value"
              [endYear]="linesForm?.controls?.endYear?.value"
              [errorMessage]="'Scenario.messages.lines.technicalLife'"
              [contextHelpMsg]="
                'wizard_topology.wizard_topology_technical_life'
              "
              [inputLabel]="'Scenario.labels.technicalLife' | translate"
              fxFlex="49.9%"
              data-testid="wizard-topology-yearlyTechnicalLife"
            >
            </prosumer-yearly-chart-input>
            <!-- Depreciation Time -->

            <prosumer-yearly-chart-input
              [formControl]="linesForm?.controls?.yearlyDepreciationTime"
              [startYear]="linesForm?.controls?.startYear?.value"
              [endYear]="linesForm?.controls?.endYear?.value"
              [errorMessage]="'Scenario.messages.lines.depreciationTime'"
              [contextHelpMsg]="
                'wizard_topology.wizard_topology_depreciation_time'
              "
              [inputLabel]="'Scenario.labels.depreciationTime' | translate"
              fxFlex="49.9%"
              data-testid="wizard-topology-yearlyDepreciationTime"
            >
            </prosumer-yearly-chart-input>
          </div>
        </div>
      </mat-expansion-panel>
    </div>
    <div mat-dialog-actions fxLayout="row">
      <button
        *ngIf="!isViewOnly"
        data-testid="lines-form-submit"
        mat-flat-button
        type="submit"
        color="primary"
        [disabled]="
          linesForm?.pristine || linesForm?.invalid || (loading$ | async)
        "
      >
        {{ data?.confirm || 'OK' }}
      </button>
      <button
        [disabled]="loading$ | async"
        data-testid="lines-form-cancel"
        mat-flat-button
        type="button"
        color=""
        (click)="onClose()"
      >
        <span *ngIf="!isViewOnly; else closeButton">{{
          data?.close || ('Generic.labels.cancel' | translate)
        }}</span>
        <ng-template #closeButton>
          <span>{{ 'Generic.labels.close' | translate }}</span>
        </ng-template>
      </button>
    </div>
    <div class="error-message">
      <mat-error *ngIf="errorMessage$.value" [innerHtml]="errorMessage$.value">
      </mat-error>
    </div>
  </form>
</prosumer-progress-card>
