<mat-card appearance="outlined" fxLayout="column" fxLayoutGap="10px">
  <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-between center">
    <div
      class="chips-filter-container"
      fxFlex="calc(50% - 20px)"
      fxLayout="row wrap"
      fxLayoutGap="5px"
      fxLayoutAlign="space-between"
    >
      <prosumer-filter-chips
        fxFlex
        filterBy="value"
        class="eyes-spacer-half"
        [filterTitle]="'Scenario.labels.compare.general.node' | translate"
        [dataSource]="nodeOptions"
        (filters)="onFilterNode($event)"
      >
      </prosumer-filter-chips>

      <prosumer-filter-chips
        fxFlex
        filterBy="value"
        class="eyes-spacer-half"
        [dataSource]="typeOptions"
        [filterTitle]="'Scenario.labels.compare.general.type' | translate"
        (filters)="onFilterType($event)"
      >
      </prosumer-filter-chips>

      <prosumer-filter-chips
        fxFlex
        filterBy="value"
        class="eyes-spacer-half"
        [filterTitle]="
          'Scenario.labels.compare.general.energyVector' | translate
        "
        [dataSource]="energyVectorOptions"
        (filters)="onFilterEnergyVector($event)"
      >
      </prosumer-filter-chips>
    </div>

    <div
      class="value-toggle-container"
      fxFlex="calc(50% - 20px)"
      fxLayoutAlign="end"
    >
      <mat-button-toggle-group
        name="valueToggle"
        fxLayout="row wrap"
        fxLayoutAlign="end center"
        [formControl]="valueToggleControl"
      >
        <mat-button-toggle
          *ngFor="let option of valueOptions"
          [value]="option?.key"
        >
          {{ option?.value | translate }}
          <prosumer-tooltip-anchor
            tooltipPosition="left"
            [message]="option?.context"
          ></prosumer-tooltip-anchor>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>

  <div class="chart-container" *ngIf="showChart$ | async" fxFlex [@fadeIn]>
    <ngx-charts-bar-horizontal-2d
      legendPosition="below"
      yAxisLabel="Equipment"
      [scheme]="chartScheme"
      [view]="[chartWidth$ | async, chartHeight$ | async]"
      [results]="filteredData$ | async"
      [showXAxisLabel]="true"
      [xAxis]="true"
      [xAxisLabel]="
        equipmentValueOptions[valueToggleControl?.value]?.label | translate
      "
      [showYAxisLabel]="true"
      [yAxis]="true"
      [legend]="true"
      [tooltipDisabled]="false"
      [customColors]="customColors"
    >
    </ngx-charts-bar-horizontal-2d>
  </div>
</mat-card>
