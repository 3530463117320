import { ColumnDefinition } from 'prosumer-app/libs/eyes-shared';
import { NameValidator } from 'prosumer-app/shared';

import { Validators } from '@angular/forms';

export const scenarioUploadPageTokens = {
  gamsTableColumnDef: {
    name: {
      name: 'File Name',
    },
    actions: {
      name: 'Actions',
      type: 'action',
      flex: '20%',
    },
  } as ColumnDefinition,
  scenarioTableColumnDef: {
    scenarioName: {
      name: 'Name',
      type: 'editableValue',
      hideTitle: true,
      validators: [
        Validators.required,
        NameValidator.invalidCharacters(),
        NameValidator.maxCharLength(),
        NameValidator.whiteSpaceOnly(),
      ],
    },
    fileName: {
      name: 'File',
    },
    statusIcon: {
      name: 'Status',
      type: 'statusIcon',
      flex: '70px',
      alignment: 'center',
    },
    actions: {
      name: 'Action',
      type: 'action',
      flex: '75px',
    },
  } as ColumnDefinition,
};
