<ngx-charts-bar-vertical-stacked
  [view]="chartDimensions"
  [results]="data$ | async"
  [scheme]="chartScheme"
  [xAxis]="chartShowXAxis"
  [xAxisLabel]="chartXAxisLabel"
  [showXAxisLabel]="chartShowXLabel"
  [yAxis]="chartShowYAxis"
  [yAxisLabel]="chartYAxisLabel$ | async"
  [showYAxisLabel]="chartShowYLabel"
  [legend]="chartShowLegend"
  (activate)="getSeriesMembers($event)"
  (select)="onSelect($event)"
>
  <ng-template #tooltipTemplate let-model="model">
    <prosumer-series-tooltip
      [currentSeriesData]="model"
      [seriesDataList]="tooltipData"
      [unit]="toolTipYAxisLabel"
      [titleFormat]="chartTooltipTitleFormat"
      format="1.1-1"
    >
    </prosumer-series-tooltip>
  </ng-template>
</ngx-charts-bar-vertical-stacked>
