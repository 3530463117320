import { Injectable } from '@angular/core';
import { ActiveKeeperService } from 'prosumer-app/services/active-keeper';
import { ScenarioDetailQuery } from 'prosumer-app/stores/scenario-detail';
import { ReserveEquipment } from './reserve-equipment.state';
import { ReserveEquipmentStore } from './reserve-equipment.store';

@Injectable({
  providedIn: 'root',
})
export class ReserveEquipmentQuery extends ScenarioDetailQuery<ReserveEquipment> {
  constructor(store: ReserveEquipmentStore, keeper: ActiveKeeperService) {
    super(store, keeper);
  }
}
