import {
  BaseComponent,
  CustomValidators,
  FormFieldErrorMessageMap,
  FormFieldOption,
  FormService,
} from 'prosumer-app/libs/eyes-shared';
import { Station } from 'prosumer-scenario/models/equipments.model';
import {
  EnergyVectorCascaderService,
  NodeCascaderService,
} from 'prosumer-scenario/services';
import { ProfileType } from 'prosumer-scenario/types';
import { convertToYearlyValues } from 'prosumer-shared/utils';
import { NameValidator } from 'prosumer-shared/validators/name/name.validators';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TitleCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { EquipmentFormDialogData } from '../equipment-dialog.model';

@Component({
  selector: 'prosumer-station',
  templateUrl: './station.component.html',
  styleUrls: ['./station.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [EnergyVectorCascaderService, NodeCascaderService, TitleCasePipe],
})
export class StationComponent extends BaseComponent implements OnInit {
  @ViewChild('submit', { read: ElementRef }) submitButton: ElementRef;
  submitted$ = new BehaviorSubject<boolean>(false);

  @Input() nodeOptions: Array<FormFieldOption<string>> = [];
  @Input() outputEnergyVectorOptions: Array<FormFieldOption<string>> = [];

  stationForm = this._formBuilder.group({
    id: '',
    name: ['', Validators.required],
    type: 'station',
    outputEnergyVector: ['', Validators.required],
    nodes: [[], Validators.required],
    scenarioVariation: 'basecase',
    sourceType: 'custom',
    yearlyCapacityLoss: [
      convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
      Validators.required,
    ],
    numberOfConnections: [
      convertToYearlyValues('1', this.data.startYear, this.data.endYear),
      Validators.required,
    ],
    powerChargingPerConnection: [
      convertToYearlyValues('100000.0', this.data.startYear, this.data.endYear),
      Validators.required,
    ],
    vehicleToGrid: [false, Validators.required],
    timeSlots: [
      convertToYearlyValues('1', this.data.startYear, this.data.endYear),
      Validators.required,
    ],
    yearlyMinPower: [
      convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
      Validators.required,
    ],
    yearlyMaxPower: [
      convertToYearlyValues('100000.0', this.data.startYear, this.data.endYear),
      Validators.required,
    ],
    minConnections: [
      convertToYearlyValues('0', this.data.startYear, this.data.endYear),
      Validators.required,
    ],
    maxConnections: [
      convertToYearlyValues('100', this.data.startYear, this.data.endYear),
      Validators.required,
    ],
    forcedInvestment: [false, Validators.required],
    existingAsset: [false, Validators.required],
    capacityExpansion: [false, Validators.required],
    yearlyBuildCost: [
      convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
      Validators.required,
    ],
    yearlyIndivisibleCost: [
      convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
      Validators.required,
    ],
    connectionCost: [
      convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
      Validators.required,
    ],
    yearlyFOAndMCharge: [
      convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
      Validators.required,
    ],
    yearlyFOAndMInstall: [
      convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
      Validators.required,
    ],
    opexPerHour: [
      convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
      Validators.required,
    ],
    yearlyTechnicalLife: [
      convertToYearlyValues('20.0', this.data.startYear, this.data.endYear),
      Validators.required,
    ],
    yearlyDepreciationTime: [
      convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
      Validators.required,
    ],
    yearlyBuildEmissionsKw: [
      convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
      Validators.required,
    ],
    yearlyBuildEmissionsIndivisible: [
      convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
      Validators.required,
    ],
    yearlyFootprint: [
      convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
    ],
  });
  scenarioVariationOptions: Array<FormFieldOption<string>> = [];
  profileOptions: Array<FormFieldOption<ProfileType>> =
    this.data.profileOptions;
  isMultiNode = true;

  nameCtrl: UntypedFormControl = this.stationForm.get(
    'name',
  ) as UntypedFormControl;
  scenarioVariationCtrl: UntypedFormControl = this.stationForm.get(
    'scenarioVariation',
  ) as UntypedFormControl;
  errorMessages: FormFieldErrorMessageMap =
    this._formService.getErrorMessageMap('Scenario.messages.der');
  nodeWarningShown$ = new BehaviorSubject<boolean>(false);
  evWarningShown$ = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EquipmentFormDialogData<Station>,
    private _formBuilder: UntypedFormBuilder,
    private _formService: FormService,
    private _evCascaderService: EnergyVectorCascaderService,
    private _nodeCascaderService: NodeCascaderService,
  ) {
    super();
  }

  ngOnInit() {
    if (!this.data) {
      return;
    }

    this.initValidators();

    this.stationForm.patchValue({
      ...this.data.equipment,
      yearlyFootprint: convertToYearlyValues(
        '0.0',
        this.data.startYear,
        this.data.endYear,
      ),
      type: 'station',
    });

    if (this.data.scenarioVariationOptions) {
      this.scenarioVariationOptions.push(...this.data.scenarioVariationOptions);
    }
    this.initOutputEnergyVectorValueChange();
    this.initNodeValueChange();
  }

  onConfirm(): Station {
    this.submit();
    if (this.stationForm.valid) {
      return {
        ...this.stationForm.getRawValue(),
        nodes:
          this.stationForm.getRawValue()['nodes']['nodes'] ||
          this.stationForm.getRawValue()['nodes'] ||
          [],
      };
    }
  }

  // onClose(): void {
  //   this._dialogRef.close();
  // }

  initValidators() {
    // Name Validators
    const skipEquipment =
      this.data.mode === 'edit' ? this.data.equipment : null;
    this.nameCtrl.setAsyncValidators(
      CustomValidators.dataExist(this.data.equipment$, 'name', skipEquipment, {
        scenarioVariation: this.scenarioVariationCtrl,
      }),
    );
    this.scenarioVariationCtrl.valueChanges
      .pipe(this.takeUntil())
      .subscribe(() => this.nameCtrl.updateValueAndValidity());

    // Existing Asset Validators (Apply same value validator for existing asset and forced investment)
    this.stationForm.controls.existingAsset.setValidators(
      CustomValidators.sameValue(this.stationForm.controls.forcedInvestment),
    );
    this.stationForm.controls.forcedInvestment.valueChanges
      .pipe(this.takeUntil())
      .subscribe(() =>
        this.stationForm.controls.existingAsset.updateValueAndValidity(),
      );
    this.stationForm.controls.name.setValidators([
      NameValidator.validWithCore(),
    ]);
  }

  submit(): void {
    this.submitted$.next(true);
    if (!!this.submitButton && !!this.submitButton.nativeElement) {
      this.submitButton.nativeElement.click();
    }
  }

  initOutputEnergyVectorValueChange() {
    const revertVectorValue = (currentEnergyVector) => {
      this.stationForm.controls.outputEnergyVector.patchValue(
        currentEnergyVector,
      );
    };

    this.stationForm
      .get('outputEnergyVector')
      .valueChanges.pipe(takeUntil(this.componentDestroyed$))
      .subscribe((value) => {
        // show warning dialog only once
        if (this.evWarningShown$.value && this.stationForm.dirty) {
          return;
        }
        const currentEnergyVector =
          this.stationForm.value['outputEnergyVector'];

        const isNettingAffected = this._evCascaderService.isNettingAffected(
          this.stationForm.value['id'],
          [currentEnergyVector],
          this.data.currentNetting,
        );
        this._evCascaderService
          .showCascadingWarningDialog([isNettingAffected])
          .subscribe((isOk) => {
            if (!isOk) {
              revertVectorValue(currentEnergyVector);
              return;
            }
            if (isNettingAffected) {
              this.evWarningShown$.next(true);
            }
          });
      });
  }

  initNodeValueChange() {
    this.stationForm
      .get('nodes')
      .valueChanges.pipe(takeUntil(this.componentDestroyed$))
      .subscribe((value) => {
        // show warning dialog only once
        if (this.nodeWarningShown$.value && this.stationForm.dirty) {
          return;
        }

        const currentNodes = this.stationForm.value['nodes'];
        const isNettingAffected = this._nodeCascaderService.isNettingAffected(
          this.stationForm.value['id'],
          value.nodes,
          this.data.currentNetting,
        );

        if (isNettingAffected) {
          this.nodeWarningShown$.next(true);
        }
        this._nodeCascaderService
          .showCascadingWarningDialog([isNettingAffected])
          .subscribe((isOk) => {
            // TODO: implement revert option
          });
      });
  }
}
