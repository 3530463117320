/* eslint-disable @typescript-eslint/naming-convention */
export enum ResultsVisualizationType {
  INCREMENTAL = 'incremental',
  CUMULATIVE = 'cumulative',
}

export const ResultsVisualizerTokens = {
  typeIconMap: {
    [ResultsVisualizationType.INCREMENTAL]: 'stacked_bar_chart',
    [ResultsVisualizationType.CUMULATIVE]: 'stacked_line_chart',
  },
};

export type VisualizerActiveFilter = { [label: string]: string[] };
export type ResultNameValue = { name: string; value: number };
export type ResultYearValue = { [year: number]: number };

export interface VisualizerFilter {
  readonly label: string;
  readonly options: string[];
}

export type ResultData = { [name: string]: ResultValue };
type ResultValue = string | number | ResultYearValue;

export interface VisualizerData {
  readonly name: string;
  readonly series: ResultNameValue[];
}

export interface ResultVisualizerSlice {
  readonly name: string;
  readonly unit?: string;
  readonly tooltip?: string;
}
