import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { StackedBarChartjsModule } from 'prosumer-shared/modules/chartjs/stacked-bar-chartjs';

import { ComparisonVisualizerModule } from '../comparison-visualizer';
import { TopologyComparisonComponent } from './topology-comparison.component';

@NgModule({
  declarations: [TopologyComparisonComponent],
  imports: [
    CommonModule,
    ComparisonVisualizerModule,
    StackedBarChartjsModule,
    TranslateModule,
  ],
  exports: [TopologyComparisonComponent],
})
export class TopologyComparisonModule {}
