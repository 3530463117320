import { Directive, OnInit } from '@angular/core';
import { NotificationsService } from 'prosumer-app/shared/services/notification';
import { EnergyGridConnectionStore } from 'prosumer-app/stores/energy-grid-connection';
import { take } from 'rxjs/operators';

@Directive({
  selector: '[prosumerEgcGetter]',
})
export class EgcGetterDirective implements OnInit {
  constructor(
    private readonly egc: EnergyGridConnectionStore,
    private readonly notifs: NotificationsService,
  ) {}

  ngOnInit(): void {
    this.egc
      .getAllEGCs()
      .pipe(take(1))
      .subscribe({
        error: () => this.notifs.showError(this.getErrorMsg()),
      });
  }

  private getErrorMsg(): string {
    return 'Failed to get the list of Energy Grid Connections';
  }
}
