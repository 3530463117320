import { DIALOG_SIZES, DIRECTION_ICONS } from 'prosumer-app/app.references';
import { DialogService } from 'prosumer-app/libs/eyes-core';
import {
  ColumnDefinition,
  ManagedTableComponent,
  rowAnimation,
} from 'prosumer-app/libs/eyes-shared';
import { ReferencesBuilder } from 'prosumer-app/services/references-builder/references.builder';
import { SpinningReserveActivation } from 'prosumer-app/stores/spinning-reserve-activation/spinning-reserve-activation.state';
import { FrequencyControlFormService } from 'prosumer-scenario/components/frequency-control-form/frequency-control-form.service';
import {
  ReserveActivationFormDialogComponent,
  ReserveActivationFormDialogData,
} from 'prosumer-scenario/components/frequency-control-form/spinning-reserves/activation-form-dialog';
import { ActivationReserve } from 'prosumer-scenario/models/frequency-control.model';
import { ManagedDataService } from 'prosumer-shared/services/managed-data/managed-data.service';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';

type Source = MatTableDataSource<SpinningReserveActivation>;

@Component({
  selector: 'prosumer-activation-table',
  templateUrl: './activation-table.component.html',
  styleUrls: ['./activation-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ManagedDataService],
  animations: [rowAnimation],
})
export class ActivationTableComponent
  extends ManagedTableComponent<ActivationReserve>
  implements OnInit
{
  // eslint-disable-next-line @typescript-eslint/naming-convention
  readonly DIRECTION_ICONS = { ...DIRECTION_ICONS };

  @Input() hideAdd: boolean;
  @Input() hideEdit: boolean;
  @Input() hideDelete: boolean;
  @Input() hideView: boolean;

  get commonData() {
    return {
      width: DIALOG_SIZES.small,
      ...this.service.prepForReserveActivation(),
    };
  }

  source$: Observable<Source>;
  hasData$: Observable<boolean>;
  references$ = this.referenceBuilder.selectRefs();

  constructor(
    readonly ngControl: NgControl,
    readonly changeDetector: ChangeDetectorRef,
    readonly managedData: ManagedDataService<ActivationReserve>,
    private readonly dialogService: DialogService,
    private readonly service: FrequencyControlFormService,
    private readonly referenceBuilder: ReferencesBuilder,
  ) {
    super(ngControl, changeDetector, managedData);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.source$ = this.selectSource();
    this.hasData$ = this.selectHasData();
  }

  defineColumns(): ColumnDefinition {
    return {
      energyVectorId: {
        name: 'Energy Vector',
        type: 'reference',
        referenceKey: 'energyVectors',
        sortable: true,
        toolTip: 'wizard_frequency_control.reserve_energy_vector',
      },
      placeholder1: {},
      direction: {
        name: 'Direction',
        type: 'custom',
        sortable: true,
        toolTip: 'wizard_frequency_control.direction',
      },
      rate: {
        name: 'Activation Rate [-/h]',
        sortable: true,
        toolTip: 'wizard_frequency_control.activation_rate',
      },
      duration: {
        name: 'Activation Duration [h]',
        sortable: true,
        toolTip: 'wizard_frequency_control.activation_duration',
      },
      actions: {
        name: 'Actions',
        type: 'action',
      },
    };
  }
  onAdd(): void {
    this.dialogService.openDialog(ReserveActivationFormDialogComponent, {
      ...this.commonData,
      mode: 'add',
    } as ReserveActivationFormDialogData);
  }

  onViewEdit(data: SpinningReserveActivation): void {
    this.dialogService.openDialog(ReserveActivationFormDialogComponent, {
      ...this.commonData,
      mode: this.getViewEditMode(),
      currentReserveActivation: data,
    } as ReserveActivationFormDialogData);
  }

  onDelete(data: SpinningReserveActivation): void {
    this.service.deleteReserveActivation(data.id).pipe(take(1)).subscribe();
  }

  private getViewEditMode = (): string => (this.hideView ? 'edit' : 'view');

  private selectHasData(): Observable<boolean> {
    return this.service
      .selectReserveActivations()
      .pipe(map((activations) => activations.length > 0));
  }

  private selectSource(): Observable<Source> {
    return this.service
      .selectReserveActivations()
      .pipe(map((activations) => this.toSource(activations)));
  }

  private toSource(activations: SpinningReserveActivation[]): Source {
    return new MatTableDataSource(activations);
  }
}
