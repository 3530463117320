<prosumer-page>
  <div
    fxLayout="row"
    fxLayoutAlign="center center"
    class="eyes-spacer-half"
    fxLayoutGap="10px"
  >
    <prosumer-select
      fxFlex="30%"
      class="eyes-maximize-width"
      [label]="comparedScenariosFilter.label"
      [placeholder]="comparedScenariosFilter.placeholder"
      [disabled]="loading$ | async"
      [options]="comparedScenariosFilter.options"
      [value]="comparedScenariosFilter.value"
      (eyesSelectionChange)="onSelectComparedScenario($event)"
    >
    </prosumer-select>
    <div fxLayout="row wrap" fxLayoutAlign="center">
      <prosumer-compare-summary
        fxFlex="70%"
        [referenceId]="referenceId$ | async"
        [results]="orderedScenarios$ | async"
        [caseId]="(case$ | async)?.id"
        [selectable]="dispatchTabSelected$ | async"
        (selectScenario)="onSelectScenario($event)"
      >
      </prosumer-compare-summary>
    </div>
  </div>

  <mat-tab-group
    color="accent"
    (selectedIndexChange)="onTabChange($event)"
    data-testid="scenario-compare-page-tab"
  >
    <mat-tab [disabled]="disabled$ | async">
      <ng-template mat-tab-label>
        {{ 'Scenario.labels.compare.tabs.main' | translate }}
        <prosumer-tooltip-anchor
          [message]="'compare_main.main_tab'"
        ></prosumer-tooltip-anchor>
      </ng-template>
      <ng-template matTabContent>
        <prosumer-compare-main
          *ngIf="(disabled$ | async) !== true"
          [result]="mainResultList$ | async"
          [@fadeIn]
        ></prosumer-compare-main>
      </ng-template>
    </mat-tab>

    <mat-tab [disabled]="disabled$ | async">
      <ng-template mat-tab-label>
        {{ 'Scenario.labels.compare.tabs.equipment' | translate }}
        <prosumer-tooltip-anchor
          [message]="'compare_equipment.equipment_tab'"
          data-testid="equipment-tooltip"
        ></prosumer-tooltip-anchor>
      </ng-template>

      <ng-template matTabContent>
        <prosumer-equipment-comparison
          *ngIf="(disabled$ | async) !== true"
          [caseId]="(case$ | async)?.id"
        ></prosumer-equipment-comparison>
      </ng-template>
    </mat-tab>

    <mat-tab [disabled]="disabled$ | async">
      <ng-template mat-tab-label>
        {{ 'Scenario.labels.compare.tabs.topology' | translate }}
        <prosumer-tooltip-anchor
          [message]="'compare_topology.topology_tab'"
          data-testid="topology-tooltip"
        ></prosumer-tooltip-anchor>
      </ng-template>

      <ng-template matTabContent>
        <prosumer-topology-comparison
          *ngIf="(disabled$ | async) !== true"
          [caseId]="(case$ | async)?.id"
        ></prosumer-topology-comparison>
      </ng-template>
    </mat-tab>

    <mat-tab [disabled]="disabled$ | async">
      <ng-template mat-tab-label>
        {{ 'Scenario.labels.compare.tabs.cashflow' | translate }}
        <prosumer-tooltip-anchor
          [message]="'compare_cashflow.cashflow_tab'"
          data-testid="cashflow-tooltip"
        ></prosumer-tooltip-anchor>
      </ng-template>
      <ng-template matTabContent>
        <prosumer-cashflow-container
          *ngIf="(disabled$ | async) !== true"
          [paybackResults]="paybackResultList$ | async"
          [cashFlowResults]="cashFlowResultList$ | async"
          [caseId]="(case$ | async)?.id"
          [customColors]="customColors$ | async"
        >
        </prosumer-cashflow-container>
      </ng-template>
    </mat-tab>

    <mat-tab [disabled]="disabled$ | async">
      <ng-template mat-tab-label>
        {{ 'Scenario.labels.compare.tabs.emissions' | translate }}
        <prosumer-tooltip-anchor
          [message]="'TBD'"
          data-testid="emission-tooltip"
        ></prosumer-tooltip-anchor>
      </ng-template>

      <ng-template matTabContent>
        <prosumer-emission-comparison
          *ngIf="(disabled$ | async) !== true"
          [caseId]="(case$ | async)?.id"
        ></prosumer-emission-comparison>
      </ng-template>
    </mat-tab>

    <mat-tab [disabled]="disabled$ | async">
      <ng-template mat-tab-label>
        {{ 'Scenario.labels.compare.tabs.energyBalance' | translate }}
        <prosumer-tooltip-anchor
          [message]="'compare_energy_balance.energy_balance_tab'"
          data-testid="energyBalance-tooltip"
        ></prosumer-tooltip-anchor>
      </ng-template>

      <ng-template matTabContent>
        <prosumer-compare-energy-balance
          *ngIf="(disabled$ | async) !== true"
          class="eyes-spacer-top"
          [energyBalanceResults]="energyBalanceResultList$ | async"
          [scenarios]="resultList$ | async"
          [nodeOptions]="energyBalanceNodeOptions$ | async"
          [yearOptions]="energyBalanceYearOptions$ | async"
          [energyVectorOptions]="energyBalanceEnergyVectorOptions$ | async"
          [chartScheme]="chartScheme$ | async"
          [customColors]="customColors$ | async"
          [caseId]="(case$ | async)?.id"
        >
        </prosumer-compare-energy-balance>
      </ng-template>
    </mat-tab>

    <mat-tab [disabled]="disabled$ | async">
      <ng-template mat-tab-label>
        {{ 'Scenario.labels.compare.tabs.dispatch' | translate }}
        <prosumer-tooltip-anchor
          [message]="'compare_dispatch.dispatch_tab'"
          data-testid="dispatch-tooltip"
        ></prosumer-tooltip-anchor>
      </ng-template>

      <ng-template matTabContent>
        <prosumer-compare-dispatch
          *ngIf="(disabled$ | async) !== true"
          class="eyes-spacer-top"
          [selectedResults]="selectedScenarios$ | async"
          [dispatchResults]="dispatchResultList$ | async"
          [typeOptions]="dispatchTypeOptions$ | async"
          [yearOptions]="dispatchYearOptions$ | async"
          [nodeOptions]="dispatchNodeOptions$ | async"
          [energyVectorOptions]="dispatchEnergyVectorOptions$ | async"
          [resultTypeOptions]="dispatchResultTypeOptions$ | async"
          [caseId]="(case$ | async)?.id"
        >
        </prosumer-compare-dispatch>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
  <div
    *ngIf="loading$ | async"
    class="prosumer-message-container eyes-spacer-top"
    [@fadeIn]
    [attr.data-testid]="'prosumer-compare-loading-indicator'"
  >
    <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center center">
      <mat-spinner color="accent" diameter="75"></mat-spinner>
      <div fxFlex></div>
      <span class="prosumer-results-message1">{{
        'Result.messages.loadingResults' | translate
      }}</span>
      <span class="prosumer-results-message2">{{
        'Result.messages.loadingWait' | translate
      }}</span>
    </div>
  </div>
  <div
    *ngIf="error$ | async"
    class="prosumer-message-container"
    [ngClass]="{ error: error$ | async }"
    fxLayoutAlign="center center"
  >
    <h2 class="prosumer-results-message1">Error: {{ error$ | async }}</h2>
  </div>
</prosumer-page>
