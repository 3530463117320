import { Equipment } from 'prosumer-app/+scenario/models';
import { ActiveKeeperService } from 'prosumer-app/services/active-keeper';
import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import { WithProfilesCreator } from '../creator';
import { ProfileStore } from '../profile';
import { TDBDataQuery } from '../tdb-data';
import { EquipmentStore } from './equipment.store';

export interface EquipmentParents {
  readonly projectId: string;
  readonly caseId: string;
  readonly scenarioId: string;
}

export type EquipmentCreatorData = EquipmentParents & Equipment;

@Injectable({ providedIn: 'root' })
export class EquipmentCreator extends WithProfilesCreator<Equipment> {
  constructor(
    readonly equipments: EquipmentStore,
    readonly profiles: ProfileStore,
    readonly keeper: ActiveKeeperService,
    private readonly tdbQuery: TDBDataQuery,
  ) {
    super(profiles, keeper);
  }

  createEntity(data: Equipment): Observable<unknown> {
    return this.equipments.createEquipment(this.injectTdbFilters(data));
  }
  updateEntity(data: Equipment): Observable<unknown> {
    return this.equipments.updateEquipment(this.injectTdbFilters(data));
  }

  private injectTdbFilters(data: Equipment) {
    const tdbProfileFilters = this.getProfileFilters(data.id);
    if (JSON.stringify(tdbProfileFilters) === '{}') return data;
    return { ...data, tdbProfileFilters };
  }

  private getProfileFilters(id: string): Record<string, string | number> {
    const tdbProfileFilters = { ...this.tdbQuery.getActiveFilters() };
    return this._purifyFilters(tdbProfileFilters);
  }

  private _purifyFilters(
    tdbFilters: Record<string, string | number>,
  ): Record<string, string | number> {
    const { geography, technology } = tdbFilters;
    return { geography, technology };
  }
}
