import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NotificationsService } from 'prosumer-app/shared/services/notification';
import { EGCCreator } from 'prosumer-app/stores/energy-grid-connection';
import { EnergyGridConnection } from 'prosumer-scenario/models';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EntityUpsertionTrigger } from '../../directives/entity-upsertion-trigger';

@Component({
  selector: 'prosumer-egc-upsert-trigger',
  templateUrl: './egc-upsert-trigger.component.html',
  styleUrls: ['./egc-upsert-trigger.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EgcUpsertTriggerComponent extends EntityUpsertionTrigger<EnergyGridConnection> {
  constructor(
    private readonly creator: EGCCreator,
    readonly notifs: NotificationsService,
  ) {
    super();
  }

  create(data: EnergyGridConnection): Observable<unknown> {
    return this.creator
      .create(data)
      .pipe(catchError((error) => this.handleError(error)));
  }
  edit(id: string, data: EnergyGridConnection): Observable<unknown> {
    return this.creator
      .update({ ...data, id })
      .pipe(catchError((error) => this.handleError(error)));
  }

  private handleError(error: HttpErrorResponse): Observable<HttpErrorResponse> {
    this.notifs.showError(error.error.error);
    return throwError(error);
  }
}
