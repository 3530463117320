import { TimeForm } from 'prosumer-app/+scenario/models';
import { Utils } from 'prosumer-core/utils';

import { CompletionStrategy } from './completion-strategy';

export class TimeHorizonCompletion extends CompletionStrategy<TimeForm> {
  isFull(form: TimeForm): boolean {
    return this.fullConditions(form).every(Boolean);
  }
  isHalf(form: TimeForm): boolean {
    return this.fullConditions(form).some(Boolean);
  }

  private fullConditions(form: TimeForm): boolean[] {
    return [
      this.hasItems(form.projectDuration),
      this.hasItems(form.yearsToOptimize),
      this.determineHorizonFullity(form),
    ];
  }

  private determineHorizonFullity(form: TimeForm): boolean {
    return [this.isFullHorizon(form), this.hasFilledPeriods(form)].some(
      Boolean,
    );
  }

  private hasFilledPeriods(form: TimeForm): boolean {
    return !!Utils.resolveToEmptyArray(form.timeHorizonPeriods).length;
  }

  private isFullHorizon(form: TimeForm): boolean {
    return form.timeHorizon === 'full';
  }

  private hasItems(list: unknown[]): boolean {
    return !!Utils.resolveToEmptyArray(list).length;
  }
}
