import { Library } from 'prosumer-app/+scenario/models';
import { LineInfoService } from 'prosumer-app/+scenario/services/line-info';
import { LineMapper } from 'prosumer-app/+scenario/services/mappers/line.mapper';
import { ProfileType } from 'prosumer-app/+scenario/types';
import { BOOLEAN_OPTIONS } from 'prosumer-app/app.references';
import { LoggerService } from 'prosumer-app/libs/eyes-core';
import {
  BaseComponent,
  ColumnDefinition,
  CustomValidators,
  FormFieldErrorMessageMap,
  FormFieldOption,
  FormService,
} from 'prosumer-app/libs/eyes-shared';
import { LibraryService, ScenarioDetailType } from 'prosumer-app/stores';
import {
  EnergyVectorQuery,
  EnergyVectorStore,
} from 'prosumer-app/stores/energy-vector';
import { LineInfo, LineQuery } from 'prosumer-app/stores/line';
import { NodeQuery, NodeStore } from 'prosumer-app/stores/node';
import { Utils } from 'prosumer-core/utils';
import {
  convertToYearlyValues,
  getSelectedEnergyVector,
} from 'prosumer-shared/utils';
import { NameValidator } from 'prosumer-shared/validators';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, startWith, switchMap, take, tap } from 'rxjs/operators';

import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { LinesFormDialogData } from './lines-form-dialog.model';

@UntilDestroy()
@Component({
  selector: 'prosumer-lines-form-dialog',
  templateUrl: './lines-form-dialog.component.html',
  styleUrls: ['./lines-form-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinesFormDialogComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  loading$ = this.lineQuery.selectLoading();

  defaultValues = {
    efficiency: '1.0',
    buildEmissionsKwm: '0.0',
    buildEmissionsIndivisible: '0.0',
    minSize: '0.0',
    maxSize: '100000.0',
    buildCost: '0.0',
    indivisibleCost: '0.0',
    fomCharge: '0.0',
    fomInstall: '0.0',
    technicalLife: 20,
    depreciationTime: 0,
    // advanced-input TODO: set defaultvalues
    yearlyEfficiency: !!!this.data
      ? {}
      : convertToYearlyValues('1.0', this.data.startYear, this.data.endYear),
    yearlyBuildEmissionsKwm: !!!this.data
      ? {}
      : convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
    yearlyBuildEmissionsIndivisible: !!!this.data
      ? {}
      : convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
    yearlyMinSize: !!!this.data
      ? {}
      : convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
    yearlyMaxSize: !!!this.data
      ? {}
      : convertToYearlyValues(
          '100000.0',
          this.data.startYear,
          this.data.endYear,
        ),
    yearlyBuildCost: !!!this.data
      ? {}
      : convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
    yearlyIndivisibleCost: !this.data
      ? {}
      : convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
    yearlyFomCharge: !!!this.data
      ? {}
      : convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
    yearlyFomInstall: !!!this.data
      ? {}
      : convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
    yearlyTechnicalLife: !!!this.data
      ? {}
      : convertToYearlyValues('20', this.data.startYear, this.data.endYear),
    yearlyDepreciationTime: !!!this.data
      ? {}
      : convertToYearlyValues('0', this.data.startYear, this.data.endYear),
  };
  linesForm: UntypedFormGroup = this._formService.initForm({
    name: '',
    energyVectorId: '',
    originNodeId: '',
    destinationNodeId: '',
    distance: '0.0',
    bidirectional: true,
    forcedInvestment: false,
    existingAsset: false,
    capacityExpansion: false,
    efficiency: this.defaultValues.efficiency,
    buildEmissionsKwm: this.defaultValues.buildEmissionsKwm,
    buildEmissionsIndivisible: this.defaultValues.buildEmissionsIndivisible,
    minSize: this.defaultValues.minSize,
    maxSize: this.defaultValues.maxSize,
    buildCost: this.defaultValues.buildCost,
    indivisibleCost: this.defaultValues.indivisibleCost,
    fomCharge: this.defaultValues.fomCharge,
    fomInstall: this.defaultValues.fomInstall,
    technicalLife: this.defaultValues.technicalLife,
    depreciationTime: this.defaultValues.depreciationTime,
    yearlyEfficiency: [
      this.defaultValues.yearlyEfficiency,
      Validators.required,
    ],
    yearlyBuildEmissionsKwm: [
      this.defaultValues.yearlyBuildEmissionsKwm,
      Validators.required,
    ],
    yearlyBuildEmissionsIndivisible: [
      this.defaultValues.yearlyBuildEmissionsIndivisible,
      Validators.required,
    ],
    yearlyMinSize: [this.defaultValues.yearlyMinSize, Validators.required],
    yearlyMaxSize: [this.defaultValues.yearlyMaxSize, Validators.required],
    yearlyBuildCost: [this.defaultValues.yearlyBuildCost, Validators.required],
    yearlyIndivisibleCost: [
      this.defaultValues.yearlyIndivisibleCost,
      Validators.required,
    ],
    yearlyFomCharge: [this.defaultValues.yearlyFomCharge, Validators.required],
    yearlyFomInstall: [
      this.defaultValues.yearlyFomInstall,
      Validators.required,
    ],
    yearlyTechnicalLife: [
      this.defaultValues.yearlyTechnicalLife,
      Validators.required,
    ],
    yearlyDepreciationTime: [
      this.defaultValues.yearlyDepreciationTime,
      Validators.required,
    ],
    sourceType: 'library',
    library: [null],
    startYear: !!!this.data ? 0 : this.data.startYear,
    endYear: !!!this.data ? 0 : this.data.endYear,
  });
  errorMessages: FormFieldErrorMessageMap =
    this._formService.getErrorMessageMap('Scenario.messages.lines');
  energyVectorOptions$ = this.evQuery.energyVectors$.pipe(untilDestroyed(this));
  nodeOptions$ = this.nodeQuery.selectActiveNodes().pipe(untilDestroyed(this));
  bidirectionalOptions: Array<FormFieldOption<any>> = [];
  forcedInvestOptions = BOOLEAN_OPTIONS;
  existingAssetOptions = BOOLEAN_OPTIONS;
  capacityExpansionOptions = BOOLEAN_OPTIONS;
  profileOptions: Array<FormFieldOption<ProfileType>> = [];
  library$: Observable<Array<Library>>;
  viewInitialized: boolean;
  isViewOnly: boolean;

  columnsDef: ColumnDefinition = {
    selection: {
      type: 'selection',
      flex: '50px',
    },
    id: {
      name: 'ID',
      flex: '20%',
      sortable: true,
    },
    description: {
      name: 'Description',
      flex: 'calc(35% - 50px)',
      sortable: true,
    },
    buildCost: {
      name: 'Build Cost [€/kW/m]',
      flex: '15%',
      sortable: true,
      alignment: 'flex-end',
    },
    indivisibleCost: {
      name: 'Indivisible Cost [€/invest/m]',
      flex: '15%',
      sortable: true,
      alignment: 'flex-end',
    },
    technicalLife: {
      name: 'Technical Life [year]',
      flex: '15%',
      sortable: true,
      alignment: 'flex-end',
    },
  };

  get messages() {
    return {
      loading: 'Scenario.messages.library.loading',
      noRecords: this.linesForm?.controls?.energyVectorId?.value
        ? 'Scenario.messages.library.noLibraryForEnergyVector'
        : 'Scenario.messages.library.noEnergyVectorSelected',
      noResults: 'Scenario.messages.library.noLibraryForEnergyVector',
      error: 'Scenario.messages.library.error',
    };
  }

  errorMessage$ = new BehaviorSubject<string>('');

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LinesFormDialogData,
    public dialogRef: MatDialogRef<LinesFormDialogComponent>,
    public libraryService: LibraryService,
    private _changeDetector: ChangeDetectorRef,
    private _formService: FormService,
    private _logger: LoggerService,
    public lineInfoService: LineInfoService,
    private lineQuery: LineQuery,
    private nodeQuery: NodeQuery,
    private nodeStore: NodeStore,
    private evQuery: EnergyVectorQuery,
    private evStore: EnergyVectorStore,
  ) {
    super();
  }

  ngOnInit() {
    if (!this.data) {
      return;
    }

    this.generateOptions();
    this.initValidators();

    this.isViewOnly = this.data.isViewOnly || false;

    const { lineData, startYear, endYear } = this.data;
    this.linesForm.patchValue(
      { ...LineMapper.toFE(lineData, startYear, endYear) },
      { emitEvent: false },
    );
    this.data.energyVectorId = this.linesForm.controls.energyVectorId.value;

    this.linesForm.controls.sourceType.valueChanges
      .pipe(this.takeUntil())
      .subscribe((profileType) => {
        if (profileType === 'custom') {
          this.linesForm.controls.library.clearValidators();
          this.linesForm.controls.library.patchValue(undefined);
        } else {
          this.linesForm.controls.library.patchValue(null);
          this.linesForm.controls.library.setValidators(Validators.required);
        }
      });

    this.library$ = combineLatest([
      this.linesForm.controls.energyVectorId.valueChanges.pipe(
        getSelectedEnergyVector(
          this.data.energyVectorId,
          this.energyVectorOptions$,
        ),
      ),
    ]).pipe(
      tap(() => {
        if (this.viewInitialized) {
          this.linesForm.controls.library.patchValue(undefined);
          this._changeDetector.markForCheck();
        }
      }),
      switchMap(([energyVectorId]) =>
        this.libraryService.getLibraryList$('topology', energyVectorId),
      ),
      this.takeUntilShare(),
    );

    this.linesForm.controls.library.valueChanges
      .pipe(this.takeUntil())
      .subscribe((library: Library) => {
        this._logger.debug(library);
        if (!library) {
          return;
        }
        // advanced input
        this.patchControlWithValue(
          this.linesForm.controls.yearlyEfficiency,
          library.efficiency
            ? convertToYearlyValues(library.efficiency, startYear, endYear)
            : undefined,
          this.defaultValues.yearlyEfficiency,
        );
        this.patchControlWithValue(
          this.linesForm.controls.yearlyBuildEmissionsKwm,
          library.buildEmissionsKwm
            ? convertToYearlyValues(
                library.buildEmissionsKwm,
                startYear,
                endYear,
              )
            : undefined,
          this.defaultValues.yearlyBuildEmissionsKwm,
        );
        this.patchControlWithValue(
          this.linesForm.controls.yearlyBuildEmissionsIndivisible,
          library.buildEmissionsIndivisible
            ? convertToYearlyValues(
                library.buildEmissionsIndivisible,
                startYear,
                endYear,
              )
            : undefined,
          this.defaultValues.yearlyBuildEmissionsIndivisible,
        );
        this.patchControlWithValue(
          this.linesForm.controls.yearlyMinSize,
          library.minPower
            ? convertToYearlyValues(library.minPower, startYear, endYear)
            : undefined,
          this.defaultValues.yearlyMinSize,
        );
        this.patchControlWithValue(
          this.linesForm.controls.yearlyMaxSize,
          library.maxPower
            ? convertToYearlyValues(library.maxPower, startYear, endYear)
            : undefined,
          this.defaultValues.yearlyMaxSize,
        );
        this.patchControlWithValue(
          this.linesForm.controls.yearlyBuildCost,
          library.buildCost
            ? convertToYearlyValues(library.buildCost, startYear, endYear)
            : undefined,
          this.defaultValues.yearlyBuildCost,
        );
        this.patchControlWithValue(
          this.linesForm.controls.yearlyIndivisibleCost,
          library.indivisibleCost
            ? convertToYearlyValues(library.indivisibleCost, startYear, endYear)
            : undefined,
          this.defaultValues.yearlyIndivisibleCost,
        );
        this.patchControlWithValue(
          this.linesForm.controls.yearlyFomCharge,
          library.fOAndMCharge
            ? convertToYearlyValues(library.fOAndMCharge, startYear, endYear)
            : undefined,
          this.defaultValues.yearlyFomCharge,
        );
        this.patchControlWithValue(
          this.linesForm.controls.yearlyFomInstall,
          library.fOAndMPerInstall
            ? convertToYearlyValues(
                library.fOAndMPerInstall,
                startYear,
                endYear,
              )
            : undefined,
          this.defaultValues.yearlyFomInstall,
        );
        this.patchControlWithValue(
          this.linesForm.controls.yearlyDepreciationTime,
          library.depreciationTime
            ? convertToYearlyValues(
                library.depreciationTime.toString(),
                startYear,
                endYear,
              )
            : undefined,
          this.defaultValues.yearlyDepreciationTime,
        );
        this.patchControlWithValue(
          this.linesForm.controls.yearlyTechnicalLife,
          library.technicalLife
            ? convertToYearlyValues(
                library.technicalLife.toString(),
                startYear,
                endYear,
              )
            : undefined,
          this.defaultValues.yearlyTechnicalLife,
        );

        this.patchControlWithValue(
          this.linesForm.controls.efficiency,
          library.efficiency,
          this.defaultValues.efficiency,
        );
        this.patchControlWithValue(
          this.linesForm.controls.buildEmissionsKwm,
          library.buildEmissionsKwm,
          this.defaultValues.buildEmissionsKwm,
        );
        this.patchControlWithValue(
          this.linesForm.controls.buildEmissionsIndivisible,
          library.buildEmissionsIndivisible,
          this.defaultValues.buildEmissionsIndivisible,
        );
        this.patchControlWithValue(
          this.linesForm.controls.minSize,
          library.minPower,
          this.defaultValues.minSize,
        );
        this.patchControlWithValue(
          this.linesForm.controls.maxSize,
          library.maxPower,
          this.defaultValues.maxSize,
        );
        this.patchControlWithValue(
          this.linesForm.controls.buildCost,
          library.buildCost,
          this.defaultValues.buildCost,
        );
        this.patchControlWithValue(
          this.linesForm.controls.indivisibleCost,
          library.indivisibleCost,
          this.defaultValues.indivisibleCost,
        );
        this.patchControlWithValue(
          this.linesForm.controls.fomCharge,
          library.fOAndMCharge,
          this.defaultValues.fomCharge,
        );
        this.patchControlWithValue(
          this.linesForm.controls.fomInstall,
          library.fOAndMPerInstall,
          this.defaultValues.fomInstall,
        );
        this.patchControlWithValue(
          this.linesForm.controls.depreciationTime,
          library.depreciationTime,
          this.defaultValues.depreciationTime,
        );
        this.patchControlWithValue(
          this.linesForm.controls.technicalLife,
          library.technicalLife,
          this.defaultValues.technicalLife,
        );
      });

    this.temporarilySubscribeToSourceTypeForLibraryGetting();
  }

  private temporarilySubscribeToSourceTypeForLibraryGetting(): void {
    this.selectSourceTypeWithLibrary()
      .pipe(untilDestroyed(this))
      .subscribe(() => this.getLibraries());
  }

  private getLibraries(): void {
    this.libraryService.get('topology');
  }

  private selectSourceTypeWithLibrary(): Observable<unknown> {
    return this.selectSourceType().pipe(
      filter((sourceType) => sourceType === 'library'),
    );
  }

  private selectSourceType(): Observable<string> {
    const sourceTypeControl = this.linesForm.get('sourceType');
    return sourceTypeControl.valueChanges.pipe(
      startWith(sourceTypeControl.value),
    );
  }

  ngAfterViewInit() {
    this.viewInitialized = true;
  }

  generateOptions(): void {
    this.retrieveEnergyVectors();
    this.retrieveNodes();

    if (this.data.bidirectionalOptions) {
      this.data.bidirectionalOptions.forEach((option) =>
        this.bidirectionalOptions.push(option),
      );
    }
    if (this.data.profileOptions) {
      this.data.profileOptions.forEach((option) =>
        this.profileOptions.push(option),
      );
    }
  }

  initValidators(isEditMode?: boolean): void {
    isEditMode = this.data.mode === 'edit';
    this.linesForm.controls.name.setValidators([
      Validators.required,
      NameValidator.validWithCore(),
    ]);
    this.linesForm.controls.name.setAsyncValidators(
      CustomValidators.dataExist(
        this.lineQuery.selectAllForActiveScenario(),
        'name',
        this.data.lineData,
      ),
    );

    const destination = this.linesForm.controls.destinationNodeId;
    destination.setValidators(this.originDestiUniquenessValidator());

    this.linesForm.controls.originNodeId.valueChanges
      .pipe(this.takeUntil())
      .subscribe(() => destination.updateValueAndValidity());

    this.linesForm.controls.distance.setValidators(
      CustomValidators.mustBePositiveNumber,
    );

    // Apply same value validator for existing asset and forced investment
    this.linesForm.controls.existingAsset.setValidators(
      CustomValidators.sameValue(this.linesForm.controls.forcedInvestment),
    );
    this.linesForm.controls.forcedInvestment.valueChanges
      .pipe(this.takeUntil())
      .subscribe(() =>
        this.linesForm.controls.existingAsset.updateValueAndValidity(),
      );

    this.linesForm.controls.library.valueChanges.subscribe((libraryVal) => {
      if (
        !this.linesForm.controls.library.value &&
        this.linesForm.controls.library.value === 'library'
      ) {
        this.linesForm.controls.library.setErrors({ required: true });
      }
    });
  }

  originDestiUniquenessValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let areTheyTheSame = false;
      const originNodeId = this.linesForm.controls.originNodeId;
      const destinationNodeId = this.linesForm.controls.destinationNodeId;
      if (
        originNodeId &&
        originNodeId.value &&
        destinationNodeId &&
        destinationNodeId.value
      ) {
        areTheyTheSame = originNodeId.value === destinationNodeId.value;
      }
      return areTheyTheSame ? { invalid: { value: control.value } } : null;
    };
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if (this.linesForm.invalid) {
      return;
    }

    this.errorMessage$.next('');
    if (this.data.mode === 'add') {
      this.createLine(this.linesForm.getRawValue());
    } else if (this.data.mode === 'edit') {
      this.updateLine(this.linesForm.getRawValue());
    }
  }

  onSelect(libraryId: string) {
    this.library$.pipe(take(1)).subscribe((library) => {
      if (!!library && library.length > 0) {
        this.linesForm.controls.library.patchValue(
          library.find(({ id }) => id === libraryId),
        );
        this.linesForm.markAsDirty();
      }
    });
  }

  createLine(data: Record<string, unknown>) {
    this.lineInfoService.createLine(data).subscribe({
      next: () => this.dialogRef.close(),
      error: (error: HttpErrorResponse) => {
        const errObj = Utils.resolveToEmptyObject(error.error);
        this.errorMessage$.next(errObj['error'] ?? error.message);
      },
    });
  }

  updateLine(data: LineInfo) {
    this.lineInfoService
      .updateLine({ ...data, lineId: this.data.lineData.lineId })
      .subscribe({
        next: () => this.dialogRef.close(),
        error: (error: HttpErrorResponse) => {
          const errObj = Utils.resolveToEmptyObject(error.error);
          this.errorMessage$.next(errObj['error'] ?? error.message);
        },
      });
  }

  private retrieveNodes() {
    this.nodeStore.getNodes().pipe(take(1)).subscribe();
  }

  private retrieveEnergyVectors(): void {
    this.evStore
      .getEnergyVectors({ dataType: ScenarioDetailType.energyVector })
      .pipe(take(1))
      .subscribe();
  }
}
