import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { LetDirective, PushPipe } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';
import { NettingUpsertTriggerComponent } from './netting-upsert-trigger.component';

@NgModule({
  declarations: [NettingUpsertTriggerComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    LetDirective,
    PushPipe,
    TranslateModule,
  ],
  exports: [NettingUpsertTriggerComponent],
})
export class NettingUpsertTriggerModule {}
