import {
  Netting,
  NettingForm,
  TaxAndSubsidies,
} from 'prosumer-app/+scenario/models';
import { Utils } from 'prosumer-core/utils';

import { CompletionStrategy } from './completion-strategy';

export class NettingCompletion extends CompletionStrategy<NettingForm> {
  isFull(form: NettingForm): boolean {
    return this.getFieldsEvaluation(form).every(Boolean);
  }
  isHalf(form: NettingForm): boolean {
    return this.getFieldsEvaluation(form).some(Boolean);
  }

  private getFieldsEvaluation(form: NettingForm): boolean[] {
    return [
      this.hasItems(form.netting),
      this.hasItems(form.taxAndSubsidies),
      this.allNettingIsUsed(form.netting, form.taxAndSubsidies),
    ];
  }

  private hasItems(list: unknown[]): boolean {
    return !!Utils.resolveToEmptyArray(list).length;
  }

  private allNettingIsUsed(
    netting: Netting[],
    taxAndSubsidies: TaxAndSubsidies[],
  ) {
    if (this.hasItems(netting) && this.hasItems(taxAndSubsidies)) {
      const usedNettingIds = taxAndSubsidies.map((item) => item.netting);
      return !netting.filter((item) => !usedNettingIds.includes(item.id))
        .length;
    }
    return false;
  }
}
