import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { EmissionsData } from '@prosumer/results/models';
import { ResultsExtractorService } from '@prosumer/results/state';

import {
  GroupedSlicesStrategy,
  ReducedResultNameValues,
  ReducedSliceData,
} from '../bar-and-area-results';
import { ResultNameValue, ResultVisualizerSlice } from '../results-visualizer';

type ReducedEmissionList = { [name: string]: EmissionsData[] };

@Injectable()
export class EmissionsResultService extends GroupedSlicesStrategy<EmissionsData> {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly FILTER_KEYS = ['type', 'scope', 'node', 'name'];

  constructor(private results: ResultsExtractorService) {
    super();
  }

  buildSliceData(raw: EmissionsData[]): ReducedSliceData {
    return this.FILTER_KEYS.reduce((acc, curr) => {
      const groupedList = this.reduceData(raw, curr);
      const reducedGroupList = this.reduceToSumOfValuesWithinGroup(groupedList);
      acc[curr] = this.filterZeroValues(reducedGroupList);
      return acc;
    }, {});
  }

  filterZeroValues(data: ReducedResultNameValues): ReducedResultNameValues {
    return Object.entries(data)
      .filter(
        ([key, value]) => !value.every((yearlyValue) => !!!yearlyValue.value),
      )
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});
  }

  getSlices(): ResultVisualizerSlice[] {
    return this.FILTER_KEYS.map((name) => ({ name }));
  }
  getYearsToOptimizeStream(): Observable<number[]> {
    return this.results.getOptimizedYearsStream();
  }

  getAllYearsStream(): Observable<number[]> {
    return this.results.getAllYearsStream();
  }

  getFilterKeys(): string[] {
    return this.FILTER_KEYS;
  }
  getResultDataStream(): Observable<EmissionsData[]> {
    return this.results.getEmissionsStream();
  }

  private reduceData(raw: EmissionsData[], slice: string): ReducedEmissionList {
    return raw.reduce((acc, curr) => {
      const value = curr[slice];
      const running = acc[value] || [];
      acc[value] = [...running, curr];
      return acc;
    }, {});
  }

  private reduceToSumOfValuesWithinGroup(
    reduced: ReducedEmissionList,
  ): ReducedResultNameValues {
    return Object.entries(reduced).reduce((acc, [name, list]) => {
      acc[name] = this.createNameValues(list);
      return acc;
    }, {});
  }

  private createNameValues(list: EmissionsData[]): ResultNameValue[] {
    const years = this.collectYears(list);
    return years.map((year) => ({
      name: String(year),
      value: this.sumEmissionsForAYear(list, year),
    }));
  }

  private sumEmissionsForAYear(list: EmissionsData[], year: number): number {
    return list
      .filter((single) => single.year === year)
      .reduce((sum, curr) => sum + curr?.value, 0);
  }

  private collectYears(list: EmissionsData[]): number[] {
    return Array.from(new Set(list.map((l) => l.year)));
  }
}
