import { Injectable } from '@angular/core';
import { EnergyGridConnection } from 'prosumer-app/+scenario/models';
import { VariationFinder } from 'prosumer-app/+scenario/services';
import { EnergyGridConnectionQuery } from 'prosumer-app/stores/energy-grid-connection';
import { EnergyVectorQuery } from 'prosumer-app/stores/energy-vector';
import { EquipmentQuery } from 'prosumer-app/stores/equipment';
import {
  PowerNeeded,
  PowerNeededQuery,
  PowerNeededStore,
} from 'prosumer-app/stores/power-needed';
import {
  ReserveEquipment,
  ReserveEquipmentQuery,
  ReserveEquipmentStore,
} from 'prosumer-app/stores/reserve-equipment';
import {
  ReserveNetwork,
  ReserveNetworkQuery,
  ReserveNetworkStore,
} from 'prosumer-app/stores/reserve-network';
import {
  ShortCircuitEquipment,
  ShortCircuitEquipmentQuery,
  ShortCircuitEquipmentStore,
} from 'prosumer-app/stores/short-circuit-equipment';
import {
  SpinningReserveActivation,
  SpinningReserveActivationQuery,
  SpinningReserveActivationStore,
} from 'prosumer-app/stores/spinning-reserve-activation';
import {
  SpinningReserveMargin,
  SpinningReserveMarginQuery,
  SpinningReserveMarginStore,
} from 'prosumer-app/stores/spinning-reserve-margin';
import {
  SpinningReserveMarket,
  SpinningReserveMarketQuery,
  SpinningReserveMarketStore,
} from 'prosumer-app/stores/spinning-reserve-market';
import { Observable } from 'rxjs';
import {
  OptionsForPowerNeededDialogData,
  OptionsForSCEquipmentDialogData,
  OptsForReserveActivation,
  OptsForReserveEquipmentDialog,
  OptsForReserveMarginDialog,
  OptsForReserveMarket,
  OptsForReserveNetwork,
} from './frequency-control-form.types';

@Injectable({ providedIn: 'root' })
export class FrequencyControlFormService {
  constructor(
    private readonly powerStore: PowerNeededStore,
    private readonly powerQuery: PowerNeededQuery,
    private readonly scEquipmentQuery: ShortCircuitEquipmentQuery,
    private readonly scEquipmentStore: ShortCircuitEquipmentStore,
    private readonly energyVector: EnergyVectorQuery,
    private readonly equipmentQuery: EquipmentQuery,
    private readonly margins: SpinningReserveMarginQuery,
    private readonly marginStore: SpinningReserveMarginStore,
    private readonly activations: SpinningReserveActivationQuery,
    private readonly activationStore: SpinningReserveActivationStore,
    private readonly reserveEquips: ReserveEquipmentQuery,
    private readonly reserveEquipStore: ReserveEquipmentStore,
    private readonly reserveMarket: SpinningReserveMarketQuery,
    private readonly reserveMarketStore: SpinningReserveMarketStore,
    private readonly reserveNetowrk: ReserveNetworkQuery,
    private readonly reserveNetowrkStore: ReserveNetworkStore,
    private readonly egcs: EnergyGridConnectionQuery,
    private readonly varFinder: VariationFinder,
  ) {}

  selectReserveEquipments(): Observable<ReserveEquipment[]> {
    return this.reserveEquips.selectAllForActiveScenario();
  }

  selectReserveActivations(): Observable<SpinningReserveActivation[]> {
    return this.activations.selectAllForActiveScenario();
  }

  selectPowersNeeded(): Observable<PowerNeeded[]> {
    return this.powerQuery.selectAllForActiveScenario();
  }

  selectShortCircuitEquipments(): Observable<ShortCircuitEquipment[]> {
    return this.scEquipmentQuery.selectAllForActiveScenario();
  }

  selectSpinningReserveMargins(): Observable<SpinningReserveMargin[]> {
    return this.margins.selectAllForActiveScenario();
  }

  selectSpinningReserveMarkets(): Observable<SpinningReserveMarket[]> {
    return this.reserveMarket.selectAllForActiveScenario();
  }

  selectReserveNetowrk(): Observable<ReserveNetwork[]> {
    return this.reserveNetowrk.selectAllForActiveScenario();
  }

  deletePowerNeeded(id: string): Observable<unknown> {
    return this.powerStore.deleteOne(id);
  }

  deleteReserveMargin(id: string): Observable<unknown> {
    return this.marginStore.deleteOne(id);
  }

  deleteReserveNetwork(id: string): Observable<unknown> {
    return this.reserveNetowrkStore.deleteOne(id);
  }

  prepOptionsForPowerNeededDialog(): OptionsForPowerNeededDialogData {
    return {
      scPowerNeededList$: this.selectPowersNeeded(),
      scPowerNeededList: this.powerQuery.getAllForActiveScenario(),
      energyVectors: this.energyVector.getVectorOptions(),
    };
  }

  prepOptionsForSCEquipmentDialog(): OptionsForSCEquipmentDialogData {
    return {
      equipments: this.scEquipmentQuery.getAllForActiveScenario(),
      equipmentOptions: this.equipmentQuery.getActiveEquipments(),
    };
  }

  prepForReserveActivation(): OptsForReserveActivation {
    return {
      currentReserveActivationList: this.activations.getAllForActiveScenario(),
      energyVectorOptions: this.energyVector.getVectorOptions(),
    };
  }

  preOptsForReserveMarginDialog(): OptsForReserveMarginDialog {
    return {
      currentReserveMarginList: this.margins.getAllForActiveScenario(),
      energyVectorOptions: this.energyVector.getVectorOptions(),
    };
  }

  preOptsForReserveEquipmentDialog(): OptsForReserveEquipmentDialog {
    return {
      currentReserveEquipmentList: this.reserveEquips.getAllForActiveScenario(),
      energyVectorOptions: this.energyVector.getVectorOptions(),
      equipmentOptions: this.equipmentQuery.getActiveEquipments(),
    };
  }

  prepForReserveMarket(): OptsForReserveMarket {
    return {
      currentReserveMarketList: this.reserveMarket.getAllForActiveScenario(),
      energyVectorOptions: this.energyVector.getVectorOptions(),
      marketOptions: this.buildMarketOpts(),
    };
  }

  prepForReserveNetworkDialog(): OptsForReserveNetwork {
    return {
      energyVectorOptions: this.energyVector.getVectorOptions(),
      currentReserveNetworkList: this.reserveNetowrk.getAllForActiveScenario(),
    };
  }

  deleteSCEquipment(id: string): Observable<unknown> {
    return this.scEquipmentStore.deleteOne(id);
  }

  deleteReserveActivation(id: string): Observable<unknown> {
    return this.activationStore.deleteOne(id);
  }

  deleteReserveEquipment(id: string): Observable<unknown> {
    return this.reserveEquipStore.deleteOne(id);
  }

  deleteReserveMarket(id: string): Observable<unknown> {
    return this.reserveMarketStore.deleteOne(id);
  }

  private buildMarketOpts(): EnergyGridConnection[] {
    let markets = this.egcs.getAllForActiveScenario();
    markets = this.injectVariationNames(markets);
    return markets;
  }

  private injectVariationNames(
    to: EnergyGridConnection[],
  ): EnergyGridConnection[] {
    return to.map((egc) => this.injectVariationName(egc));
  }

  private injectVariationName(to: EnergyGridConnection): EnergyGridConnection {
    return { ...to, marketName: this.buildMarketName(to) };
  }

  private buildMarketName(egc: EnergyGridConnection): string {
    return `${egc.marketName} (${this.varFinder.getVariationName(
      egc.scenarioVariation,
    )})`;
  }
}
