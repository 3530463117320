<div
  fxLayout="column"
  fxLayoutAlign="start stretch"
  class="simple-chips-container"
>
  <mat-form-field
    appearance="outline"
    class="ease-on-interact hide-subscript full-width"
  >
    <mat-label>{{ label }}</mat-label>
    <input
      #chipInput
      matInput
      [formControl]="chipInputCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      (keydown.enter)="onKeydownEnter()"
    />
    <mat-autocomplete
      #auto="matAutocomplete"
      autoActiveFirstOption
      (optionSelected)="selected($event)"
    >
      <mat-option
        *ngFor="let option of filteredOptions$ | async"
        [value]="option"
      >
        {{ option }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <mat-chip-grid #chipList>
    <div
      class="active-chips-list eased"
      [class.invisible]="(activeChips$ | async)?.length === 0"
    >
      <mat-chip-row
        *ngFor="let chip of activeChips$ | async"
        class="mat-chip-selected"
        selected
        [removable]="removable"
        (removed)="onChipRemove(chip)"
        [value]="chip"
        color="primary"
      >
        {{ chip }}
        <mat-icon aria-hidden="false" matChipRemove *ngIf="removable"
          >cancel</mat-icon
        >
      </mat-chip-row>
    </div>
  </mat-chip-grid>
</div>
