<form [formGroup]="form" (ngSubmit)="onClickNext()">
  <mat-expansion-panel [expanded]="expanded">
    <prosumer-sparkline-table
      [columnsDef]="tableMetaData"
      [references]="references$ | async"
      [data]="equipmentList$ | async"
      [searchInput]="true"
      [searchPredicate]="equipmentsSearchPredicate"
      (add)="onAddEvent($event)"
      (edit)="onEditEvent($event)"
      (delete)="onDeleteEvent($event)"
      (duplicate)="onDuplicate($event)"
      [duplicable]="true"
      [hideAdd]="isViewOnly || mode === 'read'"
      [hideEdit]="isViewOnly || mode === 'read'"
      [hideDuplicate]="isViewOnly || mode === 'read'"
      [hideDelete]="isViewOnly || mode === 'read'"
      [hideView]="!isViewOnly && mode !== 'read'"
    >
    </prosumer-sparkline-table>
  </mat-expansion-panel>

  <prosumer-stepper-nav
    data-testid="equipment-stepper-nav"
    [disabled]="disabled"
    [previousDisabled]="previousDisabled"
    [previousLabel]="previousLabel"
    [previousColor]="previousColor"
    [nextDisabled]="nextDisabled"
    [nextLabel]="nextLabel"
    [nextColor]="nextColor"
    [reverseButtons]="reverseButtons"
    (previous)="onClickPrevious()"
  >
  </prosumer-stepper-nav>
</form>
