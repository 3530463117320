import {
  BarDatum,
  StackedBarMeta,
} from 'prosumer-shared/modules/chartjs/stacked-bar-chartjs';

import { Directive } from '@angular/core';
import { ReducedResultNameValues } from '@prosumer/results/components/bar-and-area-results';
import { ResultNameValue } from '@prosumer/results/components/results-visualizer';

import { DispatchAdapter } from './dispatch.adapter';

@Directive()
export abstract class StorageDispatchAdapter extends DispatchAdapter<
  ResultNameValue[],
  StackedBarMeta
> {
  abstract formatTick(tick: string): string;

  mapToChartJSChart(from: ResultNameValue[]): StackedBarMeta {
    const storageData = [
      {
        name: StorageDirection.CHARGE,
        values: this.buildPositiveChargeValues(from) as any,
      },
      {
        name: StorageDirection.DISCHARGE,
        values: this.buildNegativeChargeValues(from),
      },
    ];
    return {
      axisTicks: from.map((f) => f.name),
      data: this.filterOutZeroValues(storageData),
    };
  }

  filterOutZeroValues(data: any[]): BarDatum[] {
    return data.filter((datum) => !!Object.keys(datum.values).length);
  }
  getLegendNames(_: ResultNameValue[]): string[] {
    return Object.values(StorageDirection);
  }

  formatTicks(data: StackedBarMeta): StackedBarMeta {
    return {
      ...data,
      axisTicks: data.axisTicks.map((tick) => this.formatTick(tick)),
    };
  }

  injectAxisNames(data: StackedBarMeta): StackedBarMeta {
    return { ...data, xAxisName: 'Hours', yAxisName: 'Power [kW]' };
  }

  private buildPositiveChargeValues(
    data: ResultNameValue[],
  ): ReducedResultNameValues {
    const positiveValues = data.filter((single) => single.value > 0);
    return this.reduceToNameValues(positiveValues);
  }

  private buildNegativeChargeValues(
    data: ResultNameValue[],
  ): ReducedResultNameValues {
    const negativeValues = data.filter((single) => single.value < 0);
    return this.reduceToNameValues(negativeValues);
  }

  private reduceToNameValues(list: ResultNameValue[]): ReducedResultNameValues {
    return list.reduce((acc, curr) => {
      acc[this.formatTick(curr.name)] = curr.value;
      return acc;
    }, {});
  }
}

export enum StorageDirection {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  CHARGE = 'Storage Charge',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  DISCHARGE = 'Storage Discharge',
}
