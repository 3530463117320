<form [formGroup]="form" (ngSubmit)="onClickNext()">
  <mat-expansion-panel [expanded]="expanded">
    <prosumer-sparkline-table
      [columnsDef]="tableMetaData"
      data-testid="loads-table"
      [references]="references$ | async"
      [data]="loads$ | async"
      [searchInput]="true"
      [searchPredicate]="loadsSearchPredicate"
      (add)="onAddLoads($event)"
      (edit)="onEditLoads($event)"
      (delete)="onDeleteLoads($event)"
      (duplicate)="onDuplicate($event)"
      [duplicable]="true"
      [hideAdd]="isReadOnly()"
      [hideEdit]="isReadOnly()"
      [hideDuplicate]="isReadOnly()"
      [hideDelete]="isReadOnly()"
      [hideView]="!isReadOnly()"
    >
    </prosumer-sparkline-table>
  </mat-expansion-panel>
  <prosumer-stepper-nav
    data-testid="loads-stepper-nav"
    [disabled]="disabled"
    [previousDisabled]="previousDisabled"
    [previousLabel]="previousLabel"
    [previousColor]="previousColor"
    [nextDisabled]="nextDisabled"
    [nextLabel]="nextLabel"
    [nextColor]="nextColor"
    [reverseButtons]="reverseButtons"
    (previous)="onClickPrevious()"
  >
  </prosumer-stepper-nav>
</form>
