<mat-progress-bar
  color="accent"
  mode="indeterminate"
  *ngIf="binaryLoading$ | async"
></mat-progress-bar>

<form [formGroup]="energyGridConnectionsForm">
  <h2 class="mat-headline-6" mat-dialog-title>
    {{ data?.mode | titlecase }} Commodity (Energy Grid Connection)
  </h2>

  <div class="mat-body" mat-dialog-content>
    <div fxLayout="column">
      <!-- Name and Node-->
      <div fxLayout="row" fxLayoutGap="10px">
        <!-- Market Name -->
        <prosumer-input
          type="string"
          fxFlex="33%"
          module="Scenario"
          formFieldName="energyGridConnections.marketName"
          data-testid="energy-grid-connections-form-dialog-market-name"
          [inputTooltip]="'wizard_commodities.wizard_commodities_market'"
          [control]="energyGridConnectionsForm?.controls?.marketName"
          [required]="true"
        ></prosumer-input>

        <!-- Delivery and Type -->
        <prosumer-select
          fxFlex="33%"
          [placeholder]="
            'Scenario.placeholders.energyGridConnections.delivery' | translate
          "
          [tooltip]="
            'wizard_commodities.wizard_commodities_energy_grid_connections'
          "
          [label]="'Scenario.labels.energyGridConnections.delivery' | translate"
          [options]="deliveryOptions"
          [control]="energyGridConnectionsForm?.controls?.delivery"
          [required]="true"
          [errorMessageMap]="errorMessages['delivery']"
          data-testid="energy-grid-connections-form-dialog-delivery"
        ></prosumer-select>

        <!-- Type -->
        <prosumer-select
          fxFlex="33%"
          [placeholder]="
            'Scenario.placeholders.energyGridConnections.type' | translate
          "
          [tooltip]="'wizard_commodities.wizard_commodities_type'"
          [label]="'Scenario.labels.energyGridConnections.type' | translate"
          [options]="typeOptions"
          [control]="energyGridConnectionsForm?.controls?.type"
          data-testid="energy-grid-connections-form-dialog-type"
        >
        </prosumer-select>
      </div>

      <div fxLayout="row" fxLayoutGap="10px" fxAlign="start">
        <!-- Node Input -->
        <prosumer-node-filterchip-component
          fxFlex="66.5%"
          *ngIf="isMultiNode"
          [tooltip]="'wizard_commodities.wizard_commodities_node'"
          [required]="true"
          [nodeOptions]="nodeOptions"
          formControlName="nodes"
          [errorMessage]="errorMessages.nodes"
          [setSubmitted]="submitted$ | async"
          data-testid="energy-grid-connections-form-dialog-node-input"
        ></prosumer-node-filterchip-component>

        <!-- Scenario Variation -->
        <prosumer-select
          fxFlex="{{ isMultiNode ? '32.5%' : '100%' }}"
          *ngIf="scenarioVariationOptions.length > 1"
          [placeholder]="
            'Scenario.placeholders.energyGridConnections.scenarioVariation'
              | translate
          "
          [label]="
            'Scenario.labels.energyGridConnections.scenarioVariation'
              | translate
          "
          [options]="scenarioVariationOptions"
          [control]="energyGridConnectionsForm?.controls?.scenarioVariation"
          [errorMessageMap]="errorMessages['scenarioVariation']"
          data-testid="energy-grid-connections-form-dialog-scenario-variation"
        ></prosumer-select>
      </div>

      <prosumer-tdb-commodity-prices
        *ngIf="hasTdbAccess$ | async"
        [profileFilters]="profileFilters"
        [initValueCommodityOption]="updateValueFromEVSelection$ | async"
        [yearsList]="[data.startYear, data.endYear]"
        (filtersChanged)="onFilterChanged($event)"
        data-testid="egc-dialog-tdb-commodity-prices-component"
      ></prosumer-tdb-commodity-prices>

      <!-- Energy Tariff Load Profile -->
      <!-- <div fxLayout="row" fxLayoutGap="16px"> -->
      <prosumer-yearly-loads-interval
        formControlName="profiles"
        [required]="true"
        [startYear]="energyGridConnectionsForm?.controls?.startYear.value"
        [endYear]="energyGridConnectionsForm?.controls?.endYear.value"
        [defaultLocation]="'energyGridConnections.energyTariff'"
        [useLibraryPanel]="false"
        [mode]="data.mode"
        [loading]="binaryLoading$ | async"
        [yearlyLoadMessages]="yearlyLoadMessage"
        [alwaysCustom]="true"
        [yAxisLabel]="'EUR/kWh'"
        [yearlyLoadEnabled]="false"
        [allowNegativeInput]="true"
        (selectedTabChange)="onSelectInterval($event)"
      >
      </prosumer-yearly-loads-interval>
      <!-- </div> -->

      <!-- Capacity and Connection Tariff -->
      <div fxLayout="row" fxLayoutGap="10px">
        <!-- Capacity Tariff -->
        <prosumer-yearly-chart-input
          fxFlex="33%"
          formControlName="capacityTariff"
          inputLabel="Scenario.labels.energyGridConnections.capacityTariff"
          errorMessage="Scenario.messages.capacityTariff"
          contextHelpMsg="wizard_commodities.wizard_commodities_capacity_Tariff"
          [startYear]="data?.startYear"
          [endYear]="data?.endYear"
          data-testid="capacity-tariff"
          [isViewOnly]="data?.isViewOnly"
        ></prosumer-yearly-chart-input>

        <!-- Connection Tariff -->
        <prosumer-yearly-chart-input
          fxFlex="33%"
          formControlName="connectionTariff"
          inputLabel="Scenario.labels.energyGridConnections.connectionTariff"
          errorMessage="Scenario.messages.connectionTariff"
          contextHelpMsg="wizard_commodities.wizard_commodities_connection_Tariff"
          [startYear]="data?.startYear"
          [endYear]="data?.endYear"
          data-testid="connection-tariff"
          [isViewOnly]="data?.isViewOnly"
        ></prosumer-yearly-chart-input>

        <div fxFlex="33%"></div>
      </div>
    </div>
  </div>

  <div mat-dialog-actions fxLayout="row" fxLayoutGap="10px">
    <prosumer-egc-upsert-trigger
      *ngIf="!data?.isViewOnly"
      [disabled]="
        energyGridConnectionsForm?.pristine || (binaryLoading$ | async)
      "
      [data]="mappedValue$ | ngrxPush"
      [mode]="data.mode"
      [id]="data.energyGridConnectionData?.id"
      [valid]="energyGridConnectionsForm?.valid"
      (ok)="onSaveSuccess()"
      (attempt)="onSaveAttempt()"
    ></prosumer-egc-upsert-trigger>

    <button
      mat-flat-button
      type="button"
      color=""
      (click)="onClose()"
      [disabled]="binaryLoading$ | async"
      data-testid="energy-grid-connection-form-dialog-cancel"
    >
      <span *ngIf="!data?.isViewOnly; else closeButton">
        {{ 'Generic.labels.cancel' | translate }}
      </span>

      <ng-template #closeButton>
        <span>{{ 'Generic.labels.close' | translate }}</span>
      </ng-template>
    </button>
  </div>
</form>
