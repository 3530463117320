import { PipeUtils, Utils } from 'prosumer-core/utils';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

import {
  GroupedSlicesStrategy,
  ReducedResultNameValues,
  ReducedSliceData,
} from '@prosumer/results/components/bar-and-area-results';
import {
  ResultNameValue,
  ResultVisualizerSlice,
} from '@prosumer/results/components/results-visualizer';

import { ComparisonData, ComparisonTray } from '../base';

export abstract class ComparisonVisualizerService<
  T extends ComparisonData,
> extends GroupedSlicesStrategy<T> {
  private activeYearSubject = new BehaviorSubject<number | string>(undefined);
  comparisonNames$ = new BehaviorSubject<string[]>(undefined);

  get activeYear(): string {
    return this.activeYearSubject.value.toString();
  }

  abstract getSlices(): ResultVisualizerSlice[];
  abstract getFilterKeys(): string[];
  abstract getAllYearsStream(): Observable<number[]>;
  abstract getComparisonTray$(): Observable<ComparisonTray<T>>;

  getYearsToOptimizeStream(): Observable<number[]> {
    return of([]);
  }
  getResultDataStream(): Observable<T[]> {
    return combineLatest([
      this.getComparisonTray$(),
      this.getActiveYear$(),
    ]).pipe(
      map(([tray]) => this.reduceComparisonData(tray)),
      withLatestFrom(this.getActiveYear$()),
      map(([reducedList, activeYear]) =>
        activeYear.toString() === 'All'
          ? reducedList
          : this.filterOutInactiveYear(reducedList, activeYear),
      ),
    );
  }

  setActiveYear(year: number | string): void {
    this.activeYearSubject.next(year);
  }

  buildSliceData(raw: T[]): ReducedSliceData {
    const scenarioNames = this.collectScenarioNames(raw);
    return this.getSlices().reduce((acc, slice) => {
      acc[slice.name] = this.reduceScenarioValuesToNameValues(
        scenarioNames,
        raw,
        slice.name,
      );
      return acc;
    }, {});
  }

  collectScenarioNames(raw: T[]): string[] {
    const collected = raw.map((r) => r.scenarioName);
    const dedupedNames = Utils.removeDuplicates(collected);
    this.comparisonNames$.next(dedupedNames);
    return dedupedNames;
  }

  private reduceScenarioValuesToNameValues(
    names: string[],
    data: T[],
    slice: string,
  ): ReducedResultNameValues {
    return names.reduce((acc, scenarioName) => {
      const scenarioValues = data.filter(
        (d) => d.scenarioName === scenarioName,
      );
      acc[scenarioName] = this.mapSliceDataToNameValue(slice, scenarioValues);
      return acc;
    }, {});
  }

  private mapSliceDataToNameValue(slice: string, raw: T[]): ResultNameValue[] {
    return raw.map((data) => ({ name: data.name, value: data[slice] }));
  }

  private filterOutInactiveYear(
    reducedList: T[],
    activeYear: number | string,
  ): T[] {
    return reducedList.filter((data) => data.year === activeYear);
  }

  private getActiveYear$(): Observable<number | string> {
    return this.activeYearSubject
      .asObservable()
      .pipe(PipeUtils.filterOutUndefined);
  }

  private reduceComparisonData(tray: ComparisonTray<T>): T[] {
    return Object.entries(tray).reduce(
      (acc, [name, data]) => [
        ...acc,
        ...this.injectScenarioNameToComparisonData(name, data as T[]),
      ],
      [],
    );
  }

  private injectScenarioNameToComparisonData(
    scenarioName: string,
    data: T[],
  ): T[] {
    return data.map((datum) => ({ ...datum, scenarioName }));
  }
}
