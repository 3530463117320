<prosumer-page>
  <prosumer-scenario-navigation>
    <prosumer-scenario-variation-option
      fxFlex="35%"
      [scenarioVariationOptions]="scenarioVariationOptions | async"
      [scenario]="selectedScenario$ | async"
      (variationChange)="onVariationChange($event)"
    >
    </prosumer-scenario-variation-option>
  </prosumer-scenario-navigation>

  <mat-tab-group
    #tabGroup
    color="accent"
    (selectedTabChange)="onTabSelectionChange($event)"
    data-testid="simulation-results-tab"
  >
    <mat-tab [disabled]="disabled$ | async">
      <ng-template mat-tab-label>
        {{ 'Scenario.labels.simulationResults.tabs.main' | translate }}
        <prosumer-tooltip-anchor
          data-testid="simulation-main-tab-tooltip"
          [message]="'output_main.output_main_title'"
        ></prosumer-tooltip-anchor>
      </ng-template>
      <ng-template matTabContent>
        <div
          *ngIf="error$ | async"
          class="prosumer-message-container"
          [ngClass]="{ error: error$ | async }"
          fxLayoutAlign="center center"
        >
          <h2 class="prosumer-results-message1">Error: {{ error$ | async }}</h2>
        </div>
        <prosumer-main-output
          *ngIf="(disabled$ | async) !== true"
          [caseId]="caseIdParam$ | async"
          [scenarioName]="
            (selectedScenario$ | async)
              ? (selectedScenario$ | async).name
              : 'scenario'
          "
        ></prosumer-main-output>
      </ng-template>
    </mat-tab>
    <mat-tab
      [disabled]="disabled$ | async"
      *ngIf="systemVisualizationTabVisible"
    >
      <ng-template mat-tab-label>
        {{
          'Scenario.labels.simulationResults.tabs.systemVisualization'
            | translate
        }}
        <prosumer-tooltip-anchor
          data-testid="simulation-system-overview-tab-tooltip"
          [message]="'system_overview.system_overview_tab'"
        ></prosumer-tooltip-anchor>
      </ng-template>
      <ng-template matTabContent>
        <div
          *ngIf="error$ | async"
          class="prosumer-message-container"
          [ngClass]="{ error: error$ | async }"
          fxLayoutAlign="center center"
        >
          <h2 class="prosumer-results-message1">Error: {{ error$ | async }}</h2>
        </div>
        <prosumer-system-visualization
          [data]="systemVisualization$ | async"
          [dimensions]="dimensions$ | async"
          *ngIf="(disabled$ | async) !== true"
        ></prosumer-system-visualization>
      </ng-template>
    </mat-tab>
    <mat-tab [disabled]="disabled$ | async">
      <ng-template mat-tab-label>
        {{ 'Scenario.labels.simulationResults.tabs.equipment' | translate }}
        <prosumer-tooltip-anchor
          data-testid="simulation-equipment-tab-tooltip"
          [message]="'output_equipment.output_equipment_title'"
        ></prosumer-tooltip-anchor>
      </ng-template>
      <ng-template matTabContent>
        <!-- <prosumer-der-investments></prosumer-der-investments> -->
        <prosumer-equipment-results
          *ngIf="(disabled$ | async) !== true"
          [caseId]="caseIdParam$ | async"
          [scenarioName]="(selectedScenario$ | async).name"
        ></prosumer-equipment-results>
      </ng-template>
    </mat-tab>
    <mat-tab
      [disabled]="disabled$ | async"
      [aria-labelledby]="hideTopology ? 'hidden' : ''"
    >
      <ng-template mat-tab-label>
        {{ 'Scenario.labels.simulationResults.tabs.topology' | translate }}
        <prosumer-tooltip-anchor
          data-testid="simulation-topology-tab-tooltip"
          [message]="'output_topology.output_topology_title'"
        ></prosumer-tooltip-anchor>
      </ng-template>
      <ng-template matTabContent>
        <!-- <prosumer-line-investment></prosumer-line-investment> -->
        <prosumer-topology-results
          *ngIf="(disabled$ | async) !== true"
          [caseId]="caseIdParam$ | async"
          [scenarioName]="(selectedScenario$ | async).name"
        ></prosumer-topology-results>
      </ng-template>
    </mat-tab>
    <mat-tab [disabled]="disabled$ | async">
      <ng-template mat-tab-label>
        {{ 'Scenario.labels.simulationResults.tabs.energyBalance' | translate }}
        <prosumer-tooltip-anchor
          data-testid="simulation-energy-balance-tab-tooltip"
          [message]="'output_energy_balance.output_energy_balance_title'"
          [tooltipPosition]="'left'"
        ></prosumer-tooltip-anchor>
      </ng-template>
      <ng-template matTabContent>
        <prosumer-energy-balance-results
          *ngIf="(disabled$ | async) !== true"
          [caseId]="caseIdParam$ | async"
          [scenarioName]="(selectedScenario$ | async).name"
        ></prosumer-energy-balance-results>
      </ng-template>
    </mat-tab>
    <mat-tab [disabled]="disabled$ | async">
      <ng-template mat-tab-label>
        {{ 'Scenario.labels.simulationResults.tabs.cashflow' | translate }}
        <prosumer-tooltip-anchor
          data-testid="simulation-cash-flow-tab-tooltip"
          [message]="'output_cashflow.output_cashflow_title'"
          [tooltipPosition]="'left'"
        ></prosumer-tooltip-anchor>
      </ng-template>
      <ng-template matTabContent>
        <prosumer-cashflow-results
          *ngIf="(disabled$ | async) !== true"
          [caseId]="caseIdParam$ | async"
          [scenarioName]="(selectedScenario$ | async).name"
        ></prosumer-cashflow-results>
      </ng-template>
    </mat-tab>
    <mat-tab [disabled]="disabled$ | async">
      <ng-template mat-tab-label>
        {{ 'Scenario.labels.simulationResults.tabs.co2Emissions' | translate }}
        <prosumer-tooltip-anchor
          data-testid="simulation-co2-emissions-tab-tooltip"
          [message]="'output_CO2.output_CO2_title'"
          [tooltipPosition]="'left'"
        ></prosumer-tooltip-anchor>
      </ng-template>
      <ng-template matTabContent>
        <prosumer-emissions-results
          *ngIf="(disabled$ | async) !== true"
          [caseId]="caseIdParam$ | async"
          [scenarioName]="(selectedScenario$ | async).name"
        ></prosumer-emissions-results>
      </ng-template>
    </mat-tab>
    <mat-tab [disabled]="disabled$ | async">
      <ng-template mat-tab-label>
        {{ 'Scenario.labels.simulationResults.tabs.dispatch' | translate }}
        <prosumer-tooltip-anchor
          [message]="'output_dispatch.output_dispatch_title'"
          data-testid="simulation-dispatch-tab-tooltip"
          [tooltipPosition]="'left'"
        ></prosumer-tooltip-anchor>
      </ng-template>
      <ng-template matTabContent>
        <prosumer-dispatch
          *ngIf="(disabled$ | async) !== true"
          [caseId]="caseIdParam$ | async"
          [scenarioName]="(selectedScenario$ | async).name"
        ></prosumer-dispatch>
      </ng-template>
    </mat-tab>
    <mat-tab [disabled]="disabled$ | async">
      <ng-template mat-tab-label>
        {{ 'Scenario.labels.simulationResults.tabs.flows' | translate }}
        <prosumer-tooltip-anchor
          [message]="'output_flows.output_flows_title'"
          data-testid="simulation-flow-tab-tooltip"
          [tooltipPosition]="'left'"
        ></prosumer-tooltip-anchor>
      </ng-template>
      <ng-template matTabContent>
        <prosumer-flow-results
          *ngIf="(disabled$ | async) !== true"
          [caseId]="caseIdParam$ | async"
          [scenarioName]="(selectedScenario$ | async).name"
        ></prosumer-flow-results>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
  <div *ngIf="loading$ | async" class="prosumer-message-container">
    <div
      fxLayout="column"
      fxLayoutGap="10px"
      fxLayoutAlign="center center"
      [attr.data-testid]="'simulation-loading-indicator'"
    >
      <mat-spinner color="accent" diameter="75"></mat-spinner>
      <div fxFlex></div>
      <span class="prosumer-results-message1">{{
        'Result.messages.loadingResults' | translate
      }}</span>
      <span class="prosumer-results-message2">{{
        'Result.messages.loadingWait' | translate
      }}</span>
    </div>
  </div>
</prosumer-page>
