import { Injectable } from '@angular/core';
import { ActiveKeeperService } from 'prosumer-app/services/active-keeper';
import { ScenarioDetailQuery } from '../scenario-detail';
import { SpinningReserveMargin } from './spinning-reserve-margin.state';
import { SpinningReserveMarginStore } from './spinning-reserve-margin.store';

@Injectable({ providedIn: 'root' })
export class SpinningReserveMarginQuery extends ScenarioDetailQuery<SpinningReserveMargin> {
  constructor(store: SpinningReserveMarginStore, keeper: ActiveKeeperService) {
    super(store, keeper);
  }
}
