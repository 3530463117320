import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EgcEmissionsGetterDirective } from './egc-emissions-getter.directive';

@NgModule({
  declarations: [EgcEmissionsGetterDirective],
  imports: [CommonModule],
  exports: [EgcEmissionsGetterDirective],
})
export class EgcEmissionsGetterModule {}
