import {
  utils,
  WorkBook,
  WorkSheet,
  writeFile,
} from 'xlsx/dist/xlsx.mini.min.js';

import { Injectable } from '@angular/core';

import { TableExportType } from './table-exporter.service.model';

@Injectable({ providedIn: 'root' })
export class TableExporterService {
  exportTableAs(data: unknown, fileName: string, bookType: TableExportType) {
    const ws: WorkSheet = utils.table_to_sheet(data);
    this.writeToFile(ws, fileName, bookType);
  }

  exportJsonAs(data: unknown[], fileName: string, bookType: TableExportType) {
    const ws: WorkSheet = utils.json_to_sheet(data);
    this.writeToFile(ws, fileName, bookType);
  }

  private writeToFile(
    ws: WorkSheet,
    fileName: string,
    bookType: TableExportType,
  ) {
    const wb: WorkBook = utils.book_new();
    utils.book_append_sheet(wb, ws, fileName.substring(0, 30)); // max sheet char is 30
    writeFile(wb, `${fileName}.${bookType}`, { bookType });
  }
}
