@defer {
  <div class="flex flex-column gap-3">
    <div class="flex justify-content-between">
      <strong class="text-xl line-height-3 w-9">
        Comments for {{ controller.commentingOn() }}
      </strong>
      <button mat-icon-button (click)="onClose()" data-testid="close-comments">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    @if (comments().loading || deleteComment().loading) {
      <div data-testid="comments-loading" class="flex flex-column gap-3">
        <ngx-skeleton-loader
          [theme]="{ height: '36px', width: '82px' }"
        ></ngx-skeleton-loader>

        @for (i of [0, 0]; track $index) {
          <div class="flex flex-column">
            @for (i of ['80%', '40%', '60%']; track $index) {
              <ngx-skeleton-loader [theme]="{ width: i }"></ngx-skeleton-loader>
            }
          </div>
        }
      </div>
    } @else {
      <div>
        <button
          data-testid="add-comment"
          mat-stroked-button
          color="primary"
          (click)="onAddComment()"
          [disabled]="isAdding"
        >
          <mat-icon>add_comment</mat-icon>
          New
        </button>
      </div>
      <div
        #commentList
        class="flex flex-column gap-3 pr-3 overflow-auto h-30rem"
      >
        @for (comment of comments().data?.comments ?? []; track comment.id) {
          <prosumer-comment
            [data]="comment"
            [currentUserId]="currentUser()?.id"
            [upserting]="(editCommentBag[comment.id] ?? emptySignal)().loading"
            [hasError]="(editCommentBag[comment.id] ?? emptySignal)().error"
            (editSave)="onEditSave(comment.id, $event)"
            (delete)="onDelete(comment.id)"
            (cancelEditing)="editCommentBag[comment.id] = null"
          ></prosumer-comment>
        } @empty {
          @if (!isAdding) {
            <span class="text-right opacity-30 mt-3">
              ...Feels lonely here. Be the first to comment!
            </span>
          }
        }

        @if (isAdding) {
          <prosumer-comment
            mode="create"
            data-testid="adding-comment"
            [upserting]="addComment().loading"
            [userNameWhenAdding]="currentUser()?.fullName"
            (addSave)="onAddSave($event)"
            (cancelAdding)="isAdding = false"
          ></prosumer-comment>
        }
      </div>
    }
  </div>
}
