import { SCENARIO_TYPE } from 'prosumer-app/app.references';
import { ScenarioGenericStore } from 'prosumer-app/stores/scenario-generic';
import { ProsumerRoutePathService } from 'prosumer-core/services';
import { Utils } from 'prosumer-core/utils';
import { DownloadEntity, Scenario } from 'prosumer-scenario/models';
import { ScenarioFacadeService } from 'prosumer-scenario/state';
import { isResultsDownloadable } from 'prosumer-shared/utils';
import { Observable } from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  pluck,
  startWith,
  take,
} from 'rxjs/operators';

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'prosumer-scenario-navigation',
  templateUrl: './scenario-navigation.component.html',
  styleUrls: ['./scenario-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProsumerRoutePathService],
})
export class ScenarioNavigationComponent {
  private selectedScenario: Scenario | undefined;

  readonly scenario$: Observable<Scenario> = this.selectScenario();
  readonly urlTail$: Observable<string> = this.selectCurrentRouteTail();
  readonly inputsing$ = this.selectDownloadingInputs();
  readonly resultsing$ = this.selectDownloadingResults();

  constructor(
    private scenarioFacade: ScenarioFacadeService,
    private routes: ProsumerRoutePathService,
    private router: Router,
    private scenarioStore: ScenarioGenericStore,
  ) {
    this.subscribeToSelectedScenarioForLocalStoring();
  }

  onDownloadInputData(event: Event) {
    event.stopPropagation();

    this.scenarioStore
      .getInfo()
      .pipe(take(1))
      .subscribe((data) => {
        const scenario = this.getScenario();
        const patchedScenario = this.patchScenarioWithLatestInfo(
          scenario,
          data,
        );

        if (this.isInputReadyForDownload(patchedScenario)) {
          this.scenarioFacade.download(patchedScenario, 'getSigned', 'input');
        } else {
          this.scenarioFacade.getDraftInput(patchedScenario, 'input');
        }
      });
  }

  private patchScenarioWithLatestInfo(scenario: Scenario, latestData: any) {
    const general = {
      name: latestData.name,
      wacc: latestData.economics?.wacc,
    };
    return {
      ...scenario,
      general,
      inputFileGenerated: latestData.inputFileGenerated,
    };
  }

  inputDisabled(): boolean {
    return this.getScenario().scenarioType === SCENARIO_TYPE.UPLOADED;
  }

  outputDisabled(): boolean {
    return !isResultsDownloadable(this.getScenario());
  }

  onDownloadOutput(event: Event) {
    event.stopPropagation();
    this.scenarioFacade.download(
      this.getScenario(),
      'getOutputSigned',
      'output',
    );
  }

  onToInputs(): void {
    this.router.navigate(this.routeFragmentsToInputs(this.getScenario()));
  }

  onToResults(): void {
    this.router.navigate(this.routeFragmentsToResults(this.getScenario()));
  }

  private selectDownloadingInputs(): Observable<boolean> {
    return this.selectScenarioDownloading().pipe(
      map((download) => download === 'input'),
    );
  }

  private selectDownloadingResults(): Observable<boolean> {
    return this.selectScenarioDownloading().pipe(
      map((download) => download === 'output'),
    );
  }

  private selectScenarioDownloading(): Observable<DownloadEntity> {
    return this.selectScenario().pipe(pluck('downloading'));
  }

  private routeFragmentsToInputs(scenario: Scenario): string[] {
    const { projectId, caseId, id } = scenario;
    return this.routes.updateScenario(projectId, caseId, id);
  }

  private routeFragmentsToResults(scenario: Scenario): string[] {
    const { projectId, caseId, id } = scenario;
    return this.routes.scenarioResults(projectId, caseId, id);
  }

  private subscribeToSelectedScenarioForLocalStoring(): void {
    this.selectScenario()
      .pipe(untilDestroyed(this))
      .subscribe((scenario) => (this.selectedScenario = scenario));
  }

  private selectScenario(): Observable<Scenario> {
    return this.scenarioFacade.selectedData$.pipe(filter(Boolean));
  }

  private getScenario(): Scenario {
    return Utils.resolveToEmptyObject(this.selectedScenario);
  }

  private isInputReadyForDownload(scenario: Scenario): boolean {
    return (
      scenario.scenarioType === SCENARIO_TYPE.UPLOADED ||
      scenario.inputFileGenerated
    );
  }

  private selectCurrentRouteTail(): Observable<string> {
    return this.router.events.pipe(
      debounceTime(100),
      startWith({}),
      map(() => this.router.url),
      map((whole) => this.extractLastFragment(whole)),
    );
  }

  private extractLastFragment(wholeUrl: string): string {
    return wholeUrl.split('/').pop();
  }
}
