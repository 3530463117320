<div fxLayout="column">
  <prosumer-chart-exporter
    [id]="id"
    [chartInstance]="chartInstance"
    [scenarioName]="scenarioName"
    [resultsName]="resultsName"
    [legendShowing]="isLegendShowing"
    (legendToggle)="onLegendToggle()"
  >
  </prosumer-chart-exporter>
  <canvas [id]="id" [attr.data-testid]="id"></canvas>
  <prosumer-custom-legend
    *ngIf="isLegendShowing"
    [details]="legendDetails"
    (mouseclickItem)="onLegendDataToggle($event)"
    (mouseenterItem)="onMouseEnter($event)"
    (mouseleaveItem)="onMouseLeave()"
  ></prosumer-custom-legend>
</div>
