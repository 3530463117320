import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslateModule } from '@ngx-translate/core';
import { ResultsTabulatorModule } from '@prosumer/results/components/results-tabulator';
import { TooltipModule } from 'prosumer-app/libs/eyes-shared';
import { SimpleChipsModule } from 'prosumer-shared/components/simple-chips';
import { ComparisonVisualizerComponent } from './comparison-visualizer.component';

@NgModule({
  declarations: [ComparisonVisualizerComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    SimpleChipsModule,
    ReactiveFormsModule,
    MatButtonToggleModule,
    TranslateModule,
    MatSlideToggleModule,
    MatIconModule,
    ResultsTabulatorModule,
    MatInputModule,
    TooltipModule,
    MatSelectModule,
  ],
  exports: [ComparisonVisualizerComponent],
})
export class ComparisonVisualizerModule {}
