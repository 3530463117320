import { NetworkReserve } from 'prosumer-app/+scenario/models';
import { DIALOG_SIZES, DIRECTION_ICONS } from 'prosumer-app/app.references';
import { DialogService } from 'prosumer-app/libs/eyes-core';
import {
  ColumnDefinition,
  ManagedTableComponent,
  rowAnimation,
} from 'prosumer-app/libs/eyes-shared';
import {
  ReferencesBuilder,
  TABLE_REF_KEYS,
} from 'prosumer-app/services/references-builder';
import { ManagedDataService } from 'prosumer-app/shared/services/managed-data';
import { ReserveNetwork } from 'prosumer-app/stores/reserve-network';
import { FrequencyControlFormService } from 'prosumer-scenario/components/frequency-control-form/frequency-control-form.service';
import {
  ReserveNetworkFormDialogComponent,
  ReserveNetworkFormDialogData,
} from 'prosumer-scenario/components/frequency-control-form/spinning-reserves/network-form-dialog';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { DarkReaderWrapper } from 'prosumer-app/core';

type TableSource = MatTableDataSource<ReserveNetwork>;
@Component({
  selector: 'prosumer-reserve-network-table',
  templateUrl: './network-table.component.html',
  styleUrls: ['./network-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ManagedDataService],
  animations: [rowAnimation],
})
export class ReserveNetworkTableComponent
  extends ManagedTableComponent<NetworkReserve>
  implements OnInit
{
  readonly isDarkModeEnabled = DarkReaderWrapper.isEnabled();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  readonly DIRECTION_ICONS = { ...DIRECTION_ICONS };

  @Input() energyVectors = [];

  @Input() hideAdd: boolean;
  @Input() hideEdit: boolean;
  @Input() hideDelete: boolean;
  @Input() hideView: boolean;

  source$: Observable<TableSource>;
  hasData$: Observable<boolean>;

  readonly refs$ = this.refs.selectRefs();

  get commonData() {
    return {
      width: DIALOG_SIZES.small,
      disableClose: true,
      ...this.frControlFormService.prepForReserveNetworkDialog(),
    };
  }

  constructor(
    public ngControl: NgControl,
    public changeDetector: ChangeDetectorRef,
    public managedData: ManagedDataService<NetworkReserve>,
    public dialogService: DialogService,
    private readonly frControlFormService: FrequencyControlFormService,
    private readonly refs: ReferencesBuilder,
  ) {
    super(ngControl, changeDetector, managedData);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.source$ = this.selectSource();
    this.hasData$ = this.selectHasData();
  }

  defineColumns(): ColumnDefinition {
    return {
      energyVectorId: {
        name: 'Energy Vector',
        type: 'reference',
        referenceKey: TABLE_REF_KEYS.energyVectors,
        sortable: true,
        toolTip: 'wizard_frequency_control.reserve_energy_vector',
      },
      placeholder1: {},
      direction: {
        name: 'Direction',
        type: 'custom',
        sortable: true,
        toolTip: 'wizard_frequency_control.direction',
      },
      placeholder2: {},
      requirement: {
        name: 'Requirement [p.u.]',
        sortable: true,
        toolTip: 'wizard_frequency_control.network_requirement',
      },
      actions: {
        name: 'Actions',
        type: 'action',
      },
    };
  }

  onAdd() {
    this.dialogService.openDialog(ReserveNetworkFormDialogComponent, {
      ...this.commonData,
      mode: 'add',
    } as ReserveNetworkFormDialogData);
  }

  onViewEdit(data: NetworkReserve) {
    this.dialogService.openDialog(ReserveNetworkFormDialogComponent, {
      ...this.commonData,
      mode: this.getViewEditMode(),
      currentReserveNetwork: data,
    } as ReserveNetworkFormDialogData);
  }

  onDelete(data: ReserveNetwork) {
    this.frControlFormService
      .deleteReserveNetwork(data.id)
      .pipe(take(1))
      .subscribe();
  }

  private getViewEditMode = (): string => (this.hideView ? 'edit' : 'view');

  private selectHasData(): Observable<boolean> {
    return this.frControlFormService
      .selectReserveNetowrk()
      .pipe(map((data) => data.length > 0));
  }

  private selectSource(): Observable<TableSource> {
    return this.frControlFormService
      .selectReserveNetowrk()
      .pipe(map((data) => this.toSource(data)));
  }

  private toSource(data: ReserveNetwork[]): TableSource {
    return new MatTableDataSource(data);
  }
}
