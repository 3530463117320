<div *ngIf="!hideFilters && !loading" style="gap: 15px">
  <prosumer-filter-chips
    *ngFor="let filter of filtersData | keyvalue"
    filterBy="{{ filter.value.filterBy }}"
    [dataSource]="_messages"
    filterTitle="{{ filter.value.filterTitle }}"
    fxFlex="{{ filter.value.fxFlex }}"
    (filters)="handleFilterChange($event, filter.value.filterBy)"
  >
  </prosumer-filter-chips>
</div>
<prosumer-sparkline-table
  *ngIf="!loading"
  [columnsDef]="tableMetaData"
  [data]="_messages"
  [messages]="tableMessages"
  [hideAdd]="true"
  [hideEdit]="true"
  [hideDelete]="true"
>
</prosumer-sparkline-table>
