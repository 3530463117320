import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ActiveKeeperService } from 'prosumer-app/services/active-keeper';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ScenarioDetailState } from '../scenario-detail';
import { LoadInfo } from './load.state';
import { LoadStore } from './load.store';
import { Utils } from 'prosumer-app/core/utils';

@Injectable({ providedIn: 'root' })
export class LoadQuery extends QueryEntity<ScenarioDetailState<LoadInfo>> {
  constructor(
    public store: LoadStore,
    private readonly keeper: ActiveKeeperService,
  ) {
    super(store);
  }

  selectActiveLoads(): Observable<LoadInfo[]> {
    return this.selectActiveScenarioId().pipe(
      switchMap((id) => this.selectLoadsByScenario(id)),
    );
  }

  private selectActiveScenarioId(): Observable<string> {
    return this.keeper.selectActive().pipe(map((active) => active.scenario));
  }

  private selectLoadsByScenario(id: string): Observable<LoadInfo[]> {
    return this.selectAll().pipe(
      map((loads) => this.onlyForScenario(loads, id)),
    );
  }

  private onlyForScenario(loads: LoadInfo[], id: string): LoadInfo[] {
    return loads.filter((load) => load.scenarioUuid === id);
  }

  getActiveLoads(): LoadInfo[] {
    return Utils.resolveToEmptyArray(
      this.getAll().filter(
        (load) => load.scenarioUuid === this.getActiveScenario(),
      ),
    );
  }

  private getActiveScenario(): string {
    return this.keeper.getActiveEntities().scenario;
  }
}
