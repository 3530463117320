/* eslint-disable @typescript-eslint/naming-convention */
export const equipmentMockData = [
  {
    energy_vector: 'h2_building/elec_building',
    init_invest_cost: [
      { name: '2017', value: 2123 },
      { name: '2018', value: 1200 },
      { name: '2019', value: 950 },
      { name: '2020', value: 650 },
    ],
    init_oper_cost: [
      { name: '2017', value: 3400 },
      { name: '2018', value: 1201 },
      { name: '2019', value: 702 },
      { name: '2020', value: 353 },
    ],
    input_fluid: 'h2_building',
    name: 'h1_FC',
    node: 'Node_1',
    output_fluid: 'elec_building',
    size_kw: [
      { name: '2017', value: 4230 },
      { name: '2018', value: 5600 },
      { name: '2019', value: 3530 },
      { name: '2020', value: 7500 },
    ],
    size_kwh: [
      { name: '2017', value: 1230 },
      { name: '2018', value: 2900 },
      { name: '2019', value: 3230 },
      { name: '2020', value: 4500 },
    ],
    total_cost: [
      { name: '2017', value: 2230 },
      { name: '2018', value: 2900 },
      { name: '2019', value: 3230 },
      { name: '2020', value: 2500 },
    ],
    type: 'Converter',
    yearly_cons: [
      { name: '2017', value: 2230 },
      { name: '2018', value: 3900 },
      { name: '2019', value: 2230 },
      { name: '2020', value: 1500 },
    ],
    yearly_prod: [
      { name: '2017', value: 4930 },
      { name: '2018', value: 3100 },
      { name: '2019', value: 2230 },
      { name: '2020', value: 1500 },
    ],
  },
  {
    energy_vector: 'h2_building/elec_building',
    init_invest_cost: [
      { name: '2017', value: 1000 },
      { name: '2018', value: 900 },
      { name: '2019', value: 800 },
      { name: '2020', value: 750 },
    ],
    init_oper_cost: [
      { name: '2017', value: 5000 },
      { name: '2018', value: 4201 },
      { name: '2019', value: 3502 },
      { name: '2020', value: 1653 },
    ],
    input_fluid: 'h2_building',
    name: 'h2_FC',
    node: 'Node_1',
    output_fluid: 'elec_building',
    size_kw: [
      { name: '2017', value: 3230 },
      { name: '2018', value: 2900 },
      { name: '2019', value: 4230 },
      { name: '2020', value: 1500 },
    ],
    size_kwh: [
      { name: '2017', value: 2230 },
      { name: '2018', value: 4900 },
      { name: '2019', value: 2230 },
      { name: '2020', value: 4500 },
    ],
    total_cost: [
      { name: '2017', value: 3230 },
      { name: '2018', value: 4900 },
      { name: '2019', value: 1230 },
      { name: '2020', value: 2500 },
    ],
    type: 'Converter',
    yearly_cons: [
      { name: '2017', value: 6230 },
      { name: '2018', value: 6900 },
      { name: '2019', value: 5230 },
      { name: '2020', value: 4500 },
    ],
    yearly_prod: [
      { name: '2017', value: 4230 },
      { name: '2018', value: 5900 },
      { name: '2019', value: 6230 },
      { name: '2020', value: 7500 },
    ],
  },
  {
    energy_vector: 'h2_building/elec_building',
    init_invest_cost: [
      { name: '2017', value: 5001 },
      { name: '2018', value: 205 },
      { name: '2019', value: 506 },
      { name: '2020', value: 657 },
    ],
    init_oper_cost: [
      { name: '2017', value: 10000 },
      { name: '2018', value: 2001 },
      { name: '2019', value: 1502 },
      { name: '2020', value: 655 },
    ],
    input_fluid: 'h2_building',
    name: 'h3_FC',
    node: 'Node_1',
    output_fluid: 'elec_building',
    size_kw: [
      { name: '2017', value: 3230 },
      { name: '2018', value: 4900 },
      { name: '2019', value: 5230 },
      { name: '2020', value: 6500 },
    ],
    size_kwh: [
      { name: '2017', value: 4230 },
      { name: '2018', value: 1900 },
      { name: '2019', value: 3230 },
      { name: '2020', value: 4500 },
    ],
    total_cost: [
      { name: '2017', value: 1230 },
      { name: '2018', value: 2900 },
      { name: '2019', value: 3230 },
      { name: '2020', value: 4500 },
    ],
    type: 'Converter',
    yearly_cons: [
      { name: '2017', value: 7230 },
      { name: '2018', value: 6900 },
      { name: '2019', value: 5230 },
      { name: '2020', value: 4500 },
    ],
    yearly_prod: [
      { name: '2017', value: 4230 },
      { name: '2018', value: 3900 },
      { name: '2019', value: 2230 },
      { name: '2020', value: 1500 },
    ],
  },
  {
    energy_vector: 'electricity',
    init_invest_cost: [
      { name: '2017', value: 1234 },
      { name: '2018', value: 200 },
      { name: '2019', value: 500 },
      { name: '2020', value: 650 },
    ],
    init_oper_cost: [
      { name: '2017', value: 5000 },
      { name: '2018', value: 201 },
      { name: '2019', value: 502 },
      { name: '2020', value: 653 },
    ],
    input_fluid: 'electricity',
    name: 'elec_FC',
    node: 'Node_2',
    output_fluid: 'electricity',
    size_kw: [
      { name: '2017', value: 3231 },
      { name: '2018', value: 4901 },
      { name: '2019', value: 5231 },
      { name: '2020', value: 6501 },
    ],
    size_kwh: [
      { name: '2017', value: 2230 },
      { name: '2018', value: 900 },
      { name: '2019', value: 4230 },
      { name: '2020', value: 1500 },
    ],
    total_cost: [
      { name: '2017', value: 230 },
      { name: '2018', value: 900 },
      { name: '2019', value: 230 },
      { name: '2020', value: 500 },
    ],
    type: 'Renewable Generator',
    yearly_cons: [
      { name: '2017', value: 230 },
      { name: '2018', value: 900 },
      { name: '2019', value: 230 },
      { name: '2020', value: 100 },
    ],
    yearly_prod: [
      { name: '2017', value: 230 },
      { name: '2018', value: 240 },
      { name: '2019', value: 230 },
      { name: '2020', value: 200 },
    ],
  },
];

export const scenarioMockData = {
  time: {
    yearsToOptimize: [2017, 2018, 2019],
  },
};
