import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Input,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

import {
  BaseComponent,
  contains,
  fadeInAnimation,
  getKeys,
} from 'prosumer-app/libs/eyes-shared';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';

import {
  COMPARE_TOPOLOGY_RESULTS_VALUE_OPTIONS,
  ENGIE_DEFAULT_SCHEME,
} from 'prosumer-app/app.references';
import { CompareTopologyResultItem, Scenario } from 'prosumer-scenario/models';

const DEFAULT_BAR_WIDTH = 13;
const DEFAULT_SPACING = 8.1;
const DEFAULT_OFFSET = 74.6;
const DEFAULT_WIDTH_OFFSET = 100;

/**
 * Component used for equipment comparison
 */
@Component({
  selector: 'prosumer-compare-topology',
  templateUrl: './compare-topology.component.html',
  styleUrls: ['./compare-topology.component.scss'],
  animations: [fadeInAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompareTopologyComponent
  extends BaseComponent
  implements AfterViewInit
{
  constructor(
    private formBuilder: UntypedFormBuilder,
    private elementRef: ElementRef,
  ) {
    super();
  }

  @Input() topologyResults: Array<CompareTopologyResultItem>;
  @Input() scenarios: Array<Scenario>;

  equipmentValueOptions = COMPARE_TOPOLOGY_RESULTS_VALUE_OPTIONS;

  // Node options in the input chips to filter the equipment results
  @Input() topologyOriginNodeOptions: Array<any>;

  // Type options in the input chips to filter the equipment results
  @Input() topologyDestinationNodeOptions: Array<any>;

  // Energy vector options in the input chips to filter the equipment results in the charts
  @Input() energyVectorOptions: Array<any>;

  // Value options in the button toggle to show what equipment result value to show in the charts
  @Input() valueOptions: Array<any> = getKeys(this.equipmentValueOptions).map(
    (key) => ({
      key,
      value: this.equipmentValueOptions[key].label,
      context: this.equipmentValueOptions[key].context,
    }),
  );

  valueToggleControl = this.formBuilder.control('sizekW');

  onFilterOriginNode$ = new BehaviorSubject(null);
  onFilterDestinationNode$ = new BehaviorSubject(null);
  energyVectorFilters$ = new BehaviorSubject(null);

  chartWidth$ = new BehaviorSubject(0);
  chartHeight$ = new BehaviorSubject(0);

  valueToggle$ = this.valueToggleControl.valueChanges.pipe(
    startWith(this.valueToggleControl.value),
    this.takeUntilShare(),
  );

  @Input() chartScheme = ENGIE_DEFAULT_SCHEME;
  @Input() customColors = [];
  /**
   * Filters the equipment results whenever the value toggle, node, type, and energy vector filters change;
   * Also calculates the chart height on change
   */
  filteredData$ = combineLatest([
    this.valueToggle$,
    this.onFilterOriginNode$,
    this.onFilterDestinationNode$,
    this.energyVectorFilters$,
  ]).pipe(
    debounceTime(400),
    distinctUntilChanged(),
    switchMap(([value, originNodes, destinationNodes, energyVectors]) =>
      of(
        this.mapToChartData(
          this.scenarios,
          this.filterTopologyResults(this.topologyResults, {
            originNodes,
            destinationNodes,
            energyVectors,
          }),
          value,
        ),
      ),
    ),
    tap((results) =>
      this.setChartHeight(
        this.calculateHeight(this.scenarios.length, results.length),
      ),
    ),
    this.takeUntilShare(),
  );

  showChart$ = this.filteredData$.pipe(
    filter((data) => !!data && data.length > 0),
    this.takeUntilShare(),
  );

  /**
   * Calculates the chart width when resizing the browser
   */
  /* istabul ignore next */
  @HostListener('window:resize') onResize() {
    this.setChartWidth(this.calculateWidth());
  }

  ngAfterViewInit() {
    this.setChartWidth(this.calculateWidth());
  }

  /**
   * Maps the equipment results, scenarios, and values to the chart-compatible data
   *
   * @param scenarios - the list of scenarios for comparison
   * @param topologyResults - the flattened equipment results
   * @param valueField - the field of the result to be mapped as the value
   */
  mapToChartData(
    scenarios: Array<Scenario>,
    topologyResults: Array<CompareTopologyResultItem>,
    valueField: string,
  ) {
    const lineNames = Array.from(
      new Set(topologyResults.map((equipment) => equipment.lineName)),
    );
    return lineNames
      .map((name) => ({
        name,
        series: scenarios.map((scenario) => ({
          name: scenario.name,
          value:
            (topologyResults.find(
              (result) =>
                result.lineName === name && result.name === scenario.name,
            ) || {})[valueField] || 0,
        })),
      }))
      .filter(
        (value) => !value.series.every((seriesItem) => seriesItem.value === 0),
      );
  }

  filterTopologyResults(
    results: Array<CompareTopologyResultItem> = this.topologyResults,
    params?: {
      originNodes?: Array<string>;
      destinationNodes?: Array<string>;
      energyVectors?: Array<string>;
    },
  ) {
    return results.filter(
      (filteredResult) =>
        (!!params && !!params.originNodes && params.originNodes.length > 0
          ? contains(params.originNodes, filteredResult.originNode)
          : true) &&
        (!!params &&
        !!params.destinationNodes &&
        params.destinationNodes.length > 0
          ? contains(params.destinationNodes, filteredResult.destinationNode)
          : true) &&
        (!!params && !!params.energyVectors && params.energyVectors.length > 0
          ? contains(params.energyVectors, filteredResult.energyVector) ||
            contains(params.energyVectors, filteredResult.energyVector)
          : true),
    );
  }

  /* istabul ignore next */
  setChartWidth(value: number) {
    this.chartWidth$.next(value);
  }

  /* istabul ignore next */
  setChartHeight(value: number) {
    this.chartHeight$.next(value);
  }

  /* istabul ignore next */
  calculateWidth(offset = DEFAULT_WIDTH_OFFSET) {
    return this.elementRef.nativeElement.clientWidth - offset;
  }

  /**
   * Calculates the height of the chart to show the bars properly
   *
   * @param scenarioLength - the length of the scenario
   * @param resultsLength - the length of the equipment results
   * @param barWidth - a constant expected bar width
   * @param spacing - a constant used to add space between bars
   * @param offset - a fixed constant to add in the calculated height
   */
  calculateHeight(
    scenarioLength: number,
    resultsLength: number,
    barWidth = DEFAULT_BAR_WIDTH,
    spacing = DEFAULT_SPACING,
    offset = DEFAULT_OFFSET,
  ) {
    return (
      ((barWidth + spacing) * scenarioLength + spacing) * resultsLength + offset
    );
  }

  /* istabul ignore next */
  onFilterOriginNode(value: Array<string>) {
    this.onFilterOriginNode$.next(value);
  }

  /* istabul ignore next */
  onFilterDestinationNode(value: Array<string>) {
    this.onFilterDestinationNode$.next(value);
  }

  /* istabul ignore next */
  onFilterEnergyVector(value: Array<string>) {
    this.energyVectorFilters$.next(value);
  }
}
