<form [formGroup]="form" (ngSubmit)="onConfirm()">
  <h2 class="mat-headline-6" mat-dialog-title>
    {{ data?.mode | titlecase }}
    {{
      'Scenario.dialog.frequencyControl.spinningReserves.network.title'
        | translate
    }}
  </h2>

  <div class="mat-body" mat-dialog-content fxLayout="column">
    <div fxLayout="row" fxLayoutGap="10px">
      <!-- Energy Vector -->
      <mat-form-field
        class="eyes-maximize-width"
        appearance="outline"
        [@fadeIn]
        data-testid="prosumer-network-form-energy-vector"
      >
        <mat-label>{{
          'Scenario.dialog.frequencyControl.spinningReserves.network.labels.energyVector'
            | translate
        }}</mat-label>

        <mat-select
          required
          formControlName="energyVectorId"
          data-testid="res-network-form-ev-select"
          [placeholder]="
            'Scenario.dialog.frequencyControl.spinningReserves.network.placeholders.energyVector'
              | translate
          "
          [hideSingleSelectionIndicator]="true"
        >
          <mat-option
            *ngFor="let energyVector of energyVectorOptions"
            [value]="energyVector.value"
          >
            {{ energyVector.name }}
          </mat-option>
        </mat-select>
        <mat-icon
          aria-hidden="false"
          class="eyes-input-tooltip-anchor"
          matSuffix
          [prosumerTooltip]="'wizard_frequency_control.reserve_energy_vector'"
        >
          help
        </mat-icon>
        <mat-error
          *ngFor="let error of energyVectorIdControl?.errors | keyvalue"
          [ngSwitch]="error.key"
        >
          <div
            *ngSwitchCase="'required'"
            [innerHtml]="
              'Scenario.dialog.frequencyControl.spinningReserves.network.errors.energyVector.required'
                | translate
            "
          ></div>
          <div
            *ngSwitchCase="'dataExist'"
            [style.line-height.px]="12"
            [innerHtml]="
              'Scenario.dialog.frequencyControl.spinningReserves.network.errors.energyVector.dataExist'
                | translate
            "
          ></div>
        </mat-error>
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-around stretch">
      <!-- Direction -->
      <mat-form-field
        class="eyes-maximize-width"
        appearance="outline"
        [@fadeIn]
        data-testid="prosumer-network-form-direction"
      >
        <mat-label>{{
          'Scenario.dialog.frequencyControl.spinningReserves.network.labels.direction'
            | translate
        }}</mat-label>

        <mat-select
          required
          formControlName="direction"
          [placeholder]="
            'Scenario.dialog.frequencyControl.spinningReserves.network.placeholders.direction'
              | translate
          "
          [hideSingleSelectionIndicator]="true"
        >
          <mat-option
            *ngFor="let direction of sortedDirections"
            [value]="direction.key"
          >
            {{ direction.value }}
          </mat-option>
        </mat-select>
        <mat-icon
          aria-hidden="false"
          class="eyes-input-tooltip-anchor"
          matSuffix
          [prosumerTooltip]="'wizard_frequency_control.direction'"
        >
          help
        </mat-icon>
        <mat-error
          *ngFor="let error of directionControl?.errors | keyvalue"
          [ngSwitch]="error.key"
        >
          <div
            *ngSwitchCase="'required'"
            [innerHtml]="
              'Scenario.dialog.frequencyControl.spinningReserves.network.errors.direction.required'
                | translate
            "
          ></div>
        </mat-error>
      </mat-form-field>

      <!-- Requirement -->
      <mat-form-field
        class="eyes-maximize-width"
        appearance="outline"
        [@fadeIn]
        data-testid="prosumer-network-form-requirement"
      >
        <mat-label>
          {{
            'Scenario.dialog.frequencyControl.spinningReserves.network.labels.requirement'
              | translate
          }}
        </mat-label>

        <input
          matInput
          formControlName="requirement"
          required
          type="number"
          [placeholder]="
            'Scenario.dialog.frequencyControl.spinningReserves.network.placeholders.requirement'
              | translate
          "
          data-testid="prosumer-network-form-requirement-input"
        />

        <mat-icon
          aria-hidden="false"
          class="eyes-input-tooltip-anchor"
          matSuffix
          [prosumerTooltip]="
            'wizard_frequency_control.network_requirement' | translate
          "
        >
          help
        </mat-icon>

        <mat-error
          *ngFor="let error of requirementControl?.errors | keyvalue"
          [ngSwitch]="error.key"
        >
          <div
            *ngSwitchCase="'required'"
            [innerHtml]="
              'Scenario.dialog.frequencyControl.spinningReserves.network.errors.requirement.required'
                | translate
            "
          ></div>
          <div
            *ngSwitchCase="'min'"
            [innerHtml]="
              'Scenario.dialog.frequencyControl.spinningReserves.network.errors.requirement.min'
                | translate
            "
          ></div>
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div mat-dialog-actions fxLayout="row" fxLayoutGap="8px">
    <prosumer-entity-upsert-button
      *ngIf="data.mode !== 'view'"
      data-testid="prosumer-network-form-ok-button"
      [id]="data.currentReserveNetwork?.id"
      [disabled]="form.pristine"
      [data]="form.value"
      [mode]="data.mode"
      [valid]="form.valid"
      (ok)="onSaveSuccess()"
      (attempt)="onSaveAttempt()"
    ></prosumer-entity-upsert-button>

    <button
      mat-flat-button
      type="button"
      (click)="onClose()"
      [attr.data-testid]="'prosumer-network-form-cancel'"
    >
      <span
        data-testid="prosumer-network-form-cancel-label"
        *ngIf="data?.mode !== 'view'; else closeButton"
      >
        {{ 'Generic.labels.cancel' | translate }}
      </span>
      <ng-template #closeButton>
        <span data-testid="prosumer-network-form-close-label">{{
          'Generic.labels.close' | translate
        }}</span>
      </ng-template>
    </button>
  </div>
</form>
