import { RegulationsForm } from 'prosumer-app/+scenario/models';
import { Utils } from 'prosumer-core/utils';

import { CompletionStrategy } from './completion-strategy';

export class RegulationsCompletion extends CompletionStrategy<RegulationsForm> {
  isFull(form: RegulationsForm): boolean {
    return this.getFields(form).every(Boolean);
  }

  isHalf(form: RegulationsForm): boolean {
    return this.getFields(form).some(Boolean);
  }

  getFields(form: RegulationsForm): boolean[] {
    return [this.hasLoads(form)];
  }

  private hasLoads(form: RegulationsForm): boolean {
    return [Utils.resolveToEmptyArray(form.meterings).length].some(Boolean);
  }
}
