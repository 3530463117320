<prosumer-stacked-area-chartjs
  *ngIf="data$ | async"
  [id]="type + '-raw-dispatch'"
  [data]="chartData$ | async"
  [allData]="allData"
  [colors]="colors$ | async"
  [enableXlsxExport]="true"
  scenarioName="{{ scenarioName }}"
  resultsName="{{ type + '_weekly_dispatch' }}"
></prosumer-stacked-area-chartjs>
