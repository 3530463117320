import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseComponent } from 'prosumer-app/libs/eyes-shared';

@Component({
  selector: 'prosumer-main-output',
  templateUrl: './main-output.component.html',
  styleUrls: ['./main-output.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainOutputComponent extends BaseComponent {
  @Input() caseId: string;
  @Input() scenarioName: string;

  constructor() {
    super();
  }
}
