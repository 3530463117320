import { Injectable } from '@angular/core';
import { ActiveKeeperService } from 'prosumer-app/services/active-keeper';
import { OptimizationCustomOptionStore } from './custom-option.store';
import { OptimizationCustomOption } from './custom-option.state';
import { ScenarioDetailQuery } from '../scenario-detail';

@Injectable({ providedIn: 'root' })
export class OptimizationCustomOptionQuery extends ScenarioDetailQuery<OptimizationCustomOption> {
  constructor(
    store: OptimizationCustomOptionStore,
    keeper: ActiveKeeperService,
  ) {
    super(store, keeper);
  }
}
