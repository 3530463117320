import { Injectable } from '@angular/core';
import { ActiveKeeperService } from 'prosumer-app/services/active-keeper';
import { ScenarioDetailQuery } from '../scenario-detail';
import { MarketLimit } from './market-limit.state';
import { MarketLimitStore } from './market-limit.store';

@Injectable({
  providedIn: 'root',
})
export class MarketLimitQuery extends ScenarioDetailQuery<MarketLimit> {
  constructor(
    readonly store: MarketLimitStore,
    readonly keeper: ActiveKeeperService,
  ) {
    super(store, keeper);
  }
}
