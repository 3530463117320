import {
  generateShadedScheme,
  RESULTS_DEFAULT_SCHEME,
} from 'prosumer-app/app.references';
import { BaseComponent } from 'prosumer-app/libs/eyes-shared';
import { ResultStore } from 'prosumer-app/stores';
import { combineLatest } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { CurrencyPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'prosumer-capex-opex',
  templateUrl: './capex-opex.component.html',
  styleUrls: ['./capex-opex.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CurrencyPipe],
})
export class CapexOpexComponent extends BaseComponent implements OnInit {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  readonly CHART_ID = 'capexOpex';
  data: any = null;
  capexTooltipData: Array<any> = [];
  opexTooltipData: Array<any> = [];

  highContrastScheme = RESULTS_DEFAULT_SCHEME;

  constructor(
    private _resultStore: ResultStore,
    public decimalPipe: CurrencyPipe,
  ) {
    super();
  }

  ngOnInit() {
    combineLatest([
      this._resultStore.derExpenses$.pipe(take(1)),
      this._resultStore.chartData$(this.CHART_ID).pipe(take(1)),
    ])
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(([fresh, cached]) => {
        this.data = cached || fresh;
        this.capexTooltipData = [];
        this.opexTooltipData = [];

        if (this.data.capex != null) {
          this.data.capex.forEach((capexElement) => {
            if (capexElement.value > 0) {
              this.capexTooltipData.push(capexElement);
            }
          });
        }

        if (this.data.opex != null) {
          this.data.opex.forEach((opexElement) => {
            if (opexElement.value > 0) {
              this.opexTooltipData.push(opexElement);
            }
          });
        }
        // Store to cache!
        if (!cached) {
          this._resultStore.updateChartDataMapping(this.CHART_ID, fresh);
        }
        this.highContrastScheme = generateShadedScheme(this.data.length);
      });
  }
}
