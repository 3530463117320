import { generateShadedScheme } from 'prosumer-app/app.references';
import { LoggerService } from 'prosumer-app/libs/eyes-core';
import {
  BaseComponent,
  FormFieldOption,
  FormService,
  contains,
} from 'prosumer-app/libs/eyes-shared';
import { ResultStore } from 'prosumer-app/stores';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';

import { DecimalPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { addBallotBox, updateLegendFilter } from '../shared/helper';

@Component({
  selector: 'prosumer-energy-balance',
  templateUrl: './energy-balance.component.html',
  styleUrls: ['./energy-balance.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnergyBalanceComponent extends BaseComponent implements OnInit {
  consumptions = [];
  productions = [];
  consumptionData: any;
  productionData: any;
  consumptionsFiltered: Array<any> = [];
  productionsFiltered: Array<any> = [];
  consumptionsKey = 'consumptions';
  productionsKey = 'productions';
  productionRange: string;
  productionTotal: number;
  consumptionRange: string;
  consumptionTotal: number;
  view = [500, 500];
  position = 'below';
  arcWidth = 0.1;
  unit = 'kWh ';
  colorScheme;

  filterForm = this.formService.initForm({
    year: [],
    node: [],
    energyVector: [],
  });
  productionData$: Observable<any>;
  consumptionData$: Observable<any>;
  yearOptions$: Observable<Array<FormFieldOption<string>>>;
  nodeOptions$: Observable<Array<FormFieldOption<string>>>;
  energyVectorOptions$: Observable<Array<FormFieldOption<string>>>;
  chartDataList = [];

  productionLegendFilter$ = new BehaviorSubject<Array<string>>([]);
  consumptionLegendFilter$ = new BehaviorSubject<Array<string>>([]);

  constructor(
    private resultStore: ResultStore,
    private formService: FormService,
    private logger: LoggerService,
    private decimal: DecimalPipe,
  ) {
    super();
  }

  formatConsumption = (value) => this.format(this.consumptionsFiltered, value);

  formatProduction = (value) => this.format(this.productionsFiltered, value);

  format = (series, value) => {
    const focus = series.find((s) => s.name === value).value;
    const total = series.reduce((a, b) => ({ value: a.value + b.value }));
    const percentage = (focus / total.value) * 100;
    return `${value} (${this.decimal.transform(focus, '1.0-0')} ${this.unit})`;
  };

  massageData(data) {
    const temp = {};
    data.forEach((d) => {
      temp[d.name] = temp[d.name] ? temp[d.name] + d.value : d.value;
    });
    let total = 0;
    const list = Object.keys(temp).map((key) => {
      total = total + temp[key];
      return {
        name: key,
        value: temp[key],
      };
    });
    return {
      total,
      list: list.map((l) => ({
        ...l,
        total,
      })),
    };
  }

  prepareData(data) {
    const temp = this.massageData(data);
    const years = this.consumptions.map((d) => parseInt(d.year, 10));
    return {
      ...temp,
      years: [...new Set(years)],
    };
  }

  ngOnInit() {
    this.productionData$ = combineLatest([
      this.filterForm.controls.year.valueChanges,
      this.filterForm.controls.node.valueChanges,
      this.filterForm.controls.energyVector.valueChanges,
      this.productionLegendFilter$,
    ]).pipe(
      switchMap(([year, node, energyVector, legendFilter]) =>
        this.resultStore
          .getEnergyBalanceByParams$(year, node, energyVector, 'production')
          .pipe(
            map((data) =>
              data.map((s) => ({
                name: addBallotBox(legendFilter, s.name),
                value: contains(legendFilter, s.name) ? 0 : s.value,
              })),
            ),
            take(1),
          ),
      ),
    );
    this.consumptionData$ = combineLatest([
      this.filterForm.controls.year.valueChanges,
      this.filterForm.controls.node.valueChanges,
      this.filterForm.controls.energyVector.valueChanges,
      this.consumptionLegendFilter$,
    ]).pipe(
      switchMap(([year, node, energyVector, legendFilter]) =>
        this.resultStore
          .getEnergyBalanceByParams$(year, node, energyVector, 'consumption')
          .pipe(
            map((data) =>
              data.map((s) => ({
                name: addBallotBox(legendFilter, s.name),
                value: contains(legendFilter, s.name) ? 0 : s.value,
              })),
            ),
            take(1),
          ),
      ),
    );

    this.productionData$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((productionData) => {
        this.productions = productionData.filter(
          (d) => d.type === 'production',
        );
        const _productions = this.prepareData(this.productions);
        this.productionsFiltered = _productions.list;
        this.productionTotal = _productions.total;
        this.productionRange = _productions.years.join(',');
        this.productionData = {
          results: this.productionsFiltered,
          total: this.productionTotal,
          range: this.productionRange,
          formatting: this.formatProduction,
          name: 'Production',
        };
        const numberOfElements =
          this.productionsFiltered.length > this.consumptionsFiltered.length
            ? this.productionsFiltered.length
            : this.consumptionsFiltered.length;
        this.colorScheme = generateShadedScheme(numberOfElements);
      });

    this.consumptionData$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((consumptionData) => {
        this.consumptions = consumptionData.filter(
          (d) => d.type === 'consumption',
        );
        const _consumptions = this.prepareData(this.consumptions);
        this.consumptionsFiltered = _consumptions.list;
        this.consumptionTotal = _consumptions.total;
        this.consumptionRange = _consumptions.years.join(',');
        this.consumptionData = {
          results: this.consumptionsFiltered,
          total: this.consumptionTotal,
          range: this.consumptionRange,
          formatting: this.formatConsumption,
          name: 'Consumption',
        };
        const numberOfElements =
          this.productionsFiltered.length > this.consumptionsFiltered.length
            ? this.productionsFiltered.length
            : this.consumptionsFiltered.length;
        this.colorScheme = generateShadedScheme(numberOfElements);
      });

    this.filterForm.controls.year.setValue([]);
    this.filterForm.controls.node.setValue([]);
    this.filterForm.controls.energyVector.setValue([]);
  }

  handleYearChange(years: any) {
    this.filterForm.controls.year.setValue(years);
  }

  handleNodeChange(nodes: any) {
    this.filterForm.controls.node.setValue(nodes);
  }

  handleVectorChange(energyVectors: any) {
    this.filterForm.controls.energyVector.setValue(energyVectors);
  }

  onSelect(selected: string, name: string) {
    updateLegendFilter(
      name === 'Production'
        ? this.productionLegendFilter$
        : this.consumptionLegendFilter$,
      selected,
    );
  }
}
