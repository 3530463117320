import { Injectable } from '@angular/core';
import { ActiveKeeperService } from 'prosumer-app/services/active-keeper';
import { ScenarioDetailQuery } from '../scenario-detail';
import { Regulation } from './regulation.state';
import { RegulationStore } from './regulation.store';

@Injectable({ providedIn: 'root' })
export class RegulationQuery extends ScenarioDetailQuery<Regulation> {
  constructor(
    readonly store: RegulationStore,
    readonly keeper: ActiveKeeperService,
  ) {
    super(store, keeper);
  }
}
