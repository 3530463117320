<ng-container *ngrxLet="startYear$ as startYear">
  <ng-container *ngrxLet="endYear$ as endYear">
    <div fxLayout="row" fxLayoutGap="16px" [formGroup]="emissionsGroup">
      <prosumer-yearly-chart-input
        fxFlex
        data-testid="scope1"
        formControlName="co2Price"
        inputLabel="Scenario.labels.emissions.co2Scope1Price"
        errorMessage="Scenario.messages.co2Scope1Price"
        contextHelpMsg="wizard_commodities.wizard_commodities_co2_price"
        [startYear]="startYear"
        [endYear]="endYear"
        [processing]="scope1Loading$ | ngrxPush"
        scenarioUpdater
        [key]="updateKey"
        [view]="updateView"
        [nextValue]="outgoingScope1$ | ngrxPush"
        [hasSkip]="true"
        [spin]="false"
        minValue="0"
        class="hide-show-subscript"
        [invalid]="co2PriceCtrl.errors"
        (statusChange)="onScope1Change($event)"
      ></prosumer-yearly-chart-input>

      <prosumer-yearly-chart-input
        fxFlex
        data-testid="scope2"
        formControlName="co2Scope2Price"
        inputLabel="Scenario.labels.emissions.co2Scope2Price"
        errorMessage="Scenario.messages.co2Scope2Price"
        [startYear]="startYear"
        [endYear]="endYear"
        [processing]="scope2Loading$ | ngrxPush"
        scenarioUpdater
        [key]="updateKey"
        [view]="updateView"
        [nextValue]="outgoingScope2$ | ngrxPush"
        [hasSkip]="true"
        [spin]="false"
        minValue="0"
        class="hide-show-subscript"
        [invalid]="co2Scope2PriceCtrl.errors"
        (statusChange)="onScope2Change($event)"
      ></prosumer-yearly-chart-input>

      <prosumer-yearly-chart-input
        fxFlex
        data-testid="scope3"
        formControlName="co2Scope3Price"
        inputLabel="Scenario.labels.emissions.co2Scope3Price"
        errorMessage="Scenario.messages.co2Scope3Price"
        [startYear]="startYear"
        [endYear]="endYear"
        [processing]="scope3Loading$ | ngrxPush"
        scenarioUpdater
        [key]="updateKey"
        [view]="updateView"
        [nextValue]="outgoingScope3$ | ngrxPush"
        [hasSkip]="true"
        [spin]="false"
        minValue="0"
        class="hide-show-subscript"
        [invalid]="co2Scope3PriceCtrl.errors"
        (statusChange)="onScope3Change($event)"
      ></prosumer-yearly-chart-input>
    </div>
  </ng-container>
</ng-container>
