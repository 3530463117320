import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { StackedBarChartjsModule } from 'prosumer-shared/modules/chartjs/stacked-bar-chartjs';
import { ComparisonVisualizerModule } from '../comparison-visualizer';
import { EquipmentComparisonComponent } from './equipment-comparison.component';

@NgModule({
  declarations: [EquipmentComparisonComponent],
  imports: [
    CommonModule,
    ComparisonVisualizerModule,
    StackedBarChartjsModule,
    TranslateModule,
  ],
  exports: [EquipmentComparisonComponent],
})
export class EquipmentComparisonModule {}
