import { CustomValidators } from 'prosumer-app/libs/eyes-shared';
import { ActiveKeeperService } from 'prosumer-app/services/active-keeper';
import { ScenarioFacadeService } from 'prosumer-scenario/state';
import { mergeMap, Observable } from 'rxjs';

import { inject } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
} from '@angular/forms';

export function getDuplicateScenarioValidator(): AsyncValidatorFn {
  const keeper = inject(ActiveKeeperService);
  const scenarioFacade = inject(ScenarioFacadeService);
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    const list$ = keeper
      .selectActive()
      .pipe(
        mergeMap(({ project: pId, case: cId }) =>
          scenarioFacade.filterScenarioList$(pId, cId),
        ),
      );
    return CustomValidators.dataExist(
      list$,
      'name',
    )(control) as Observable<ValidationErrors | null>;
  };
}
