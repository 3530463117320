import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EgcGetterDirective } from './egc-getter.directive';

@NgModule({
  declarations: [EgcGetterDirective],
  imports: [CommonModule],
  exports: [EgcGetterDirective],
})
export class EgcGetterModule {}
