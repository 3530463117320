<form [formGroup]="form" [attr.data-testid]="'power-needed-form'">
  <h2 class="mat-headline-6" mat-dialog-title>
    {{ data?.mode | titlecase }}
    {{
      'Scenario.dialog.frequencyControl.shortCircuit.powerNeeded.title'
        | translate
    }}
  </h2>

  <div class="mat-body" mat-dialog-content>
    <div fxLayout="column">
      <div fxLayout="row" fxLayoutGap="10px">
        <!-- Energy Vector -->
        <mat-form-field
          class="eyes-maximize-width"
          appearance="outline"
          [@fadeIn]
        >
          <mat-label>
            {{
              'Scenario.dialog.frequencyControl.shortCircuit.powerNeeded.labels.energyVector'
                | translate
            }}
          </mat-label>

          <mat-select
            data-testid="power-needed-ev"
            formControlName="energyVectorId"
            [hideSingleSelectionIndicator]="true"
            [attr.data-testid]="'power-needed-form-energy-vector'"
            [placeholder]="
              'Scenario.dialog.frequencyControl.shortCircuit.powerNeeded.placeholders.energyVector'
                | translate
            "
          >
            <mat-option
              *ngFor="let energyVector of energyVectors"
              [value]="energyVector.value"
            >
              {{ energyVector.name }}
            </mat-option>
          </mat-select>

          <mat-icon
            aria-hidden="false"
            matSuffix
            class="eyes-input-tooltip-anchor"
            [prosumerTooltip]="
              'wizard_frequency_control.short_circuit_energy_vector'
            "
            data-testid="power-needed-ev-tooltip"
          >
            help
          </mat-icon>

          <mat-error
            *ngFor="let error of energyVectorIdControl?.errors | keyvalue"
            [ngSwitch]="error.key"
          >
            <div
              *ngSwitchCase="'required'"
              [innerHtml]="
                'Scenario.dialog.frequencyControl.shortCircuit.powerNeeded.errors.energyVector.required'
                  | translate
              "
            ></div>
            <div
              *ngSwitchCase="'dataExist'"
              [style.line-height.px]="12"
              [innerHtml]="
                'Scenario.dialog.frequencyControl.shortCircuit.powerNeeded.errors.energyVector.dataExist'
                  | translate
              "
            ></div>
          </mat-error>
        </mat-form-field>

        <!-- Power Needed -->
        <prosumer-yearly-chart-input
          fxFlex="50%"
          formControlName="neededPower"
          inputLabel="Scenario.dialog.frequencyControl.shortCircuit.powerNeeded.labels.powerNeeded"
          errorMessage="Scenario.dialog.frequencyControl.shortCircuit.powerNeeded.errors.powerNeeded"
          contextHelpMsg="wizard_frequency_control.short_circuit_power_needed"
          [attr.data-testid]="'power-needed-form-input'"
          [startYear]="data?.startYear"
          [endYear]="data?.endYear"
          data-testid="power-needed-yearly-value"
        ></prosumer-yearly-chart-input>
      </div>
    </div>
  </div>

  <div mat-dialog-actions fxLayout="row" fxLayoutGap="8px">
    <prosumer-entity-upsert-button
      *ngIf="data.mode !== 'view'"
      [id]="data.powerNeeded?.id"
      [disabled]="form.pristine"
      [data]="form.value"
      [mode]="data.mode"
      [valid]="form.valid"
      (ok)="onSaveSuccess()"
      (attempt)="onSaveAttempt()"
    ></prosumer-entity-upsert-button>

    <button
      mat-flat-button
      type="button"
      (click)="onClose()"
      [attr.data-testid]="'power-needed-form-cancel'"
    >
      <span
        [attr.data-testid]="'power-needed-cancel-label'"
        *ngIf="data?.mode !== 'view'; else closeButton"
      >
        {{ 'Generic.labels.cancel' | translate }}
      </span>
      <ng-template #closeButton>
        <span [attr.data-testid]="'power-needed-close-label'">{{
          'Generic.labels.close' | translate
        }}</span>
      </ng-template>
    </button>
  </div>
</form>
