import { contains } from 'prosumer-app/libs/eyes-shared';
import { BehaviorSubject } from 'rxjs';

export function updateLegendFilter(
  legendFilter$: BehaviorSubject<string[]>,
  selected: unknown,
) {
  if (typeof selected !== 'string') {
    return;
  }

  const filteredLegends = legendFilter$.value;
  const selectedString = removeBallotBox(selected);

  if (contains(filteredLegends, selectedString)) {
    const index = filteredLegends.indexOf(selectedString);
    if (index > -1) {
      filteredLegends.splice(index, 1);
    }
    legendFilter$.next(filteredLegends);
  } else {
    legendFilter$.next([...filteredLegends, selectedString]);
  }
}

export function addBallotBox(legendFilter: string[], name: string) {
  return contains(legendFilter, name) ? '\u2610 ' + name : '\u2611 ' + name;
}

export function removeBallotBox(name: string) {
  if (name.charAt(0) === '\u2610' || name.charAt(0) === '\u2611') {
    name = name.slice(2);
  }
  return name;
}
