import { Observable } from 'rxjs';
import { finalize, map, pluck, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { StoreConfig } from '@datorama/akita';

import { CommoditiesMapper } from 'prosumer-app/+scenario/services/mappers';
import { EnergyGridConnection as EnergyGridConnectionFE } from 'prosumer-scenario/models';
import { CascadableDetailStore } from '../cascadable-detail';
import { ScenarioDetailType } from '../scenario-detail';
import {
  CreateFormEGC,
  EnergyGridConnectionBE,
  EnergyGridConnectionInfo,
} from './energy-grid-connection.state';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: ScenarioDetailType.energyGridConnection, idKey: 'id' })
export class EnergyGridConnectionStore extends CascadableDetailStore<EnergyGridConnectionInfo> {
  createEGC(data: CreateFormEGC): Observable<EnergyGridConnectionInfo> {
    this.setLoading(true);
    return this.handleAdd(
      this.post({
        key: 'createDetail',
        data: {},
        body: this.injectDataType(this.toBE(data)),
      }).pipe(map((data: EnergyGridConnectionBE) => this.toFE(data))),
    ).pipe(finalize(() => this.setLoading(false)));
  }

  updateEGC(
    data: EnergyGridConnectionFE,
  ): Observable<EnergyGridConnectionInfo> {
    return this.cascadeEdit(data.id, data);
  }

  getAllEGCs(): Observable<EnergyGridConnectionInfo[]> {
    this.setLoading(true);
    const data = { dataType: ScenarioDetailType.energyGridConnection };
    return this.handleUpsertMany(
      this.get({ key: 'getDetailByDataType', data, body: undefined }).pipe(
        pluck('details'),
        map((details: EnergyGridConnectionBE[]) => this.mapToFE(details)),
      ),
    ).pipe(finalize(() => this.setLoading(false)));
  }

  getSingleEGC(id: string): Observable<EnergyGridConnectionInfo> {
    return this.improvedGetSingle(id);
  }

  toBE(from: EnergyGridConnectionInfo): EnergyGridConnectionBE {
    return CommoditiesMapper.egcToBE(from);
  }

  mapToFE(details: EnergyGridConnectionBE[]): EnergyGridConnectionInfo[] {
    return details.map((item: EnergyGridConnectionBE) => this.toFE(item));
  }

  toFE(from: EnergyGridConnectionBE): EnergyGridConnectionInfo {
    return CommoditiesMapper.egcToFE(from);
  }

  private injectDataType(
    data: EnergyGridConnectionBE | EnergyGridConnectionFE,
  ): unknown {
    return { ...data, dataType: ScenarioDetailType.energyGridConnection };
  }

  private handleAdd(
    $: Observable<EnergyGridConnectionInfo>,
  ): Observable<EnergyGridConnectionInfo> {
    return $.pipe(tap((data: EnergyGridConnectionInfo) => this.add(data)));
  }

  private handleUpsertMany(
    $: Observable<EnergyGridConnectionInfo[]>,
  ): Observable<EnergyGridConnectionInfo[]> {
    return $.pipe(
      tap((data: EnergyGridConnectionInfo[]) => this.upsertMany(data)),
    );
  }

  getDataUuid(data: EnergyGridConnectionInfo): string {
    return data['id'];
  }
}
