import { Directive } from '@angular/core';
import { VisualizerData } from '@prosumer/results/components/results-visualizer';
import {
  DispatchAdapter,
  StorageDirection,
} from 'prosumer-app/+scenario/containers/simulation-results/dispatch/adapters';
import { StackedBarMeta } from 'prosumer-shared/modules/chartjs/stacked-bar-chartjs';
import { NgxChartUtils } from 'prosumer-shared/modules/charts/utils';
import { Observable } from 'rxjs';

@Directive()
export abstract class StorageDispatchComparison extends DispatchAdapter<
  VisualizerData[],
  StackedBarMeta
> {
  abstract formatTick(tick: string): string;
  abstract getChartName(): string;
  abstract getNgxChartsDataStream(): Observable<VisualizerData[]>;

  mapToChartJSChart(from: VisualizerData[]): StackedBarMeta {
    return NgxChartUtils.mapStackedBarToChartJS(
      from,
      this.getLegendNames(from),
    );
  }
  formatTicks(chartJSData: StackedBarMeta): StackedBarMeta {
    return {
      ...chartJSData,
      axisTicks: chartJSData.axisTicks.map((tick) => this.formatTick(tick)),
    };
  }
  getLegendNames(data: VisualizerData[]): string[] {
    return Object.values(StorageDirection);
  }
  injectAxisNames(data: StackedBarMeta): StackedBarMeta {
    return { ...data, xAxisName: 'Hours', yAxisName: 'kW' };
  }
}
