import { ActiveKeeperService } from 'prosumer-app/services/active-keeper';
import { Utils } from 'prosumer-core/utils';

import { Injectable } from '@angular/core';

import { NameValue, ScenarioDetailQuery } from '../scenario-detail';
import { Netting } from './netting.state';
import { NettingStore } from './netting.store';

@Injectable({ providedIn: 'root' })
export class NettingQuery extends ScenarioDetailQuery<Netting> {
  constructor(
    readonly store: NettingStore,
    readonly keeper: ActiveKeeperService,
  ) {
    super(store, keeper);
  }

  mapToNameValue(nettings: Netting[]): NameValue[] {
    return Utils.resolveToEmptyArray(nettings).map((netting: Netting) => ({
      name: netting.name,
      value: netting.id,
    }));
  }

  getNettingOptions(): NameValue[] {
    return this.mapToNameValue(this.getAllForActiveScenario());
  }
}
