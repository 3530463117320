<div
  *ngIf="data$ | async"
  fxLayout="column"
  fxLayoutAlign="start center"
  fxLayoutGap="30px"
>
  <ngx-charts-bar-vertical-stacked
    [view]="chartDimension"
    [scheme]="colorScheme"
    [results]="data$ | async"
    [gradient]="gradient"
    [xAxis]="showXAxis"
    [yAxis]="showYAxis"
    [legend]="showLegend"
    [xAxisTickFormatting]="xAxisFormatting"
    [showXAxisLabel]="showXAxisLabel"
    [showYAxisLabel]="showYAxisLabel"
    [xAxisLabel]="xAxisLabel"
    [yAxisLabel]="yAxisLabel"
    [animations]="animations"
    (activate)="getSeriesMembers($event)"
    (select)="onSelect($event)"
  >
    <ng-template #tooltipTemplate let-model="model">
      <prosumer-series-tooltip
        [currentSeriesData]="model"
        [seriesDataList]="tooltipData"
        [unit]="tooltipUnit"
        [titleFormat]="tooltipTitleFormat"
        format="1.1-1"
      >
      </prosumer-series-tooltip>
    </ng-template>
  </ngx-charts-bar-vertical-stacked>
</div>
