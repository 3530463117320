import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { LetDirective, PushPipe } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';
import { DialogDivModule } from 'prosumer-shared/components/dialog-div';
import { EmissionsDuplicateDialogComponent } from './emissions-duplicate-dialog.component';

@NgModule({
  declarations: [EmissionsDuplicateDialogComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    LetDirective,
    PushPipe,
    TranslateModule,
    MatButtonModule,
    DialogDivModule,
  ],
  exports: [EmissionsDuplicateDialogComponent],
})
export class EmissionsDuplicateDialogModule {}
