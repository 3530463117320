import { Load } from 'prosumer-app/+scenario/models';
import { LoadMapper } from 'prosumer-app/+scenario/services/mappers';
import { Observable } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { StoreConfig } from '@datorama/akita';

import { CascadableDetailStore } from '../cascadable-detail';
import { ScenarioDetailInfo, ScenarioDetailType } from '../scenario-detail';
import { LoadInfo } from './load.state';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: ScenarioDetailType.load, idKey: 'id' })
export class LoadStore extends CascadableDetailStore<LoadInfo> {
  createLoad(data: Load): Observable<unknown> {
    this.setLoading(true);
    const loadBE = LoadMapper.mapToBackend(data);
    const body = { ...loadBE, dataType: ScenarioDetailType.load };
    return this.post({ key: 'createDetail', data: {}, body }).pipe(
      map((data): LoadInfo => LoadMapper.mapToFrontend(data)),
      tap((data: LoadInfo) => this.add(data)),
      finalize(() => this.setLoading(false)),
    );
  }

  updateLoad(data: Load): Observable<unknown> {
    this.upsertLoading(data.id, true);
    const loadBE = LoadMapper.mapToBackend(data);
    const body = { ...loadBE, dataType: ScenarioDetailType.load };
    return this.patch({
      key: 'updateDetail',
      data: { loadId: data.id },
      body,
    }).pipe(
      map((data): LoadInfo => LoadMapper.mapToFrontend(data)),
      tap((data: LoadInfo) => this.upsert(data.id, data)),
      finalize(() => this.upsertLoading(data.id, false)),
    );
  }

  deleteLoad(loadId: string): Observable<unknown> {
    return this.deleteOne(loadId);
  }

  getLoads(data: Record<string, unknown>): Observable<unknown> {
    this.setLoading(true);
    return this.get({ key: 'getDetailByDataType', data, body: undefined }).pipe(
      map(
        (data: ScenarioDetailInfo<LoadInfo>): Array<LoadInfo> =>
          data.details.map((item) => LoadMapper.mapToFrontend(item)),
      ),
      tap((data: LoadInfo[]) => this.set(data)),
      finalize(() => this.setLoading(false)),
    );
  }

  duplicateLoad(data: Load): Observable<unknown> {
    this.upsertStatus(data.id, 'duplicating');
    return this.post({
      key: 'duplicateDetail',
      data: { loadId: data.id },
      body: {} as LoadInfo,
    }).pipe(tap((data: LoadInfo) => this.upsert(data.id, data)));
  }

  getDataUuid(data: Record<string, unknown>): unknown {
    return data['loadId'];
  }
}
