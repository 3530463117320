import { ActiveKeeperService } from 'prosumer-app/services/active-keeper';

import { Injectable } from '@angular/core';

import { ScenarioDetailQuery } from '../scenario-detail';
import { LineInfo } from './line.state';
import { LineStore } from './line.store';

@Injectable({ providedIn: 'root' })
export class LineQuery extends ScenarioDetailQuery<LineInfo> {
  constructor(
    readonly store: LineStore,
    readonly keeper: ActiveKeeperService,
  ) {
    super(store, keeper);
  }
}
