import { generateShortUID as genUuid } from 'prosumer-shared/utils';
import { filter, Observable } from 'rxjs';

import { Injectable, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import {
  CtrlChangedData,
  LinksResultEnhanced,
  NodesResultEnhanced,
  SankeyResultEnhanced,
} from '@prosumer/results/models';

@Injectable({ providedIn: 'root' })
export class FlowResultsEditorBasicHelperService {
  // signal examples -> https://blog.angular-university.io/angular-signals/
  private readonly _sankeyData = signal<SankeyResultEnhanced>(undefined);

  sankeyData$: Observable<SankeyResultEnhanced> = toObservable(
    this._sankeyData,
  ).pipe(filter((d) => !!d));

  clearData() {
    this._sankeyData.set(undefined);
  }

  setData(d: SankeyResultEnhanced) {
    const enhancedData = this.enhanceDataWith(structuredClone(d));
    this._sankeyData.set(enhancedData);
  }

  getData(): SankeyResultEnhanced {
    return this._sankeyData();
  }

  addLink(): void {
    this._sankeyData.update(({ nodes, links }) => ({
      links: [...links, { id: genUuid(), source: '', target: '', value: 0 }],
      nodes,
    }));
  }

  updateLink(cd: CtrlChangedData) {
    const linkList = [...this._sankeyData().links];
    const idx = linkList.findIndex((nd) => nd.id === cd.id);
    const oldD = linkList[idx];
    let newD = undefined;
    if (cd.key) {
      newD = {
        ...oldD,
        [cd.key]: cd.value,
      };
    }
    linkList.splice(idx, 1, newD);
    this._sankeyData.update((d) => ({
      ...this._sankeyData(),
      links: linkList,
    }));
  }

  deleteLink(id: string) {
    this._sankeyData.update(({ nodes, links }) => ({
      nodes,
      links: links.filter((link) => link.id !== id),
    }));
  }

  updateNode(cd: CtrlChangedData) {
    const newNd: NodesResultEnhanced = {
      id: cd.id,
      [cd.key]: cd.value,
    };
    this.changeOnNodeList(newNd);
  }

  deleteNode(id: string) {
    const newNd: NodesResultEnhanced = {
      id,
    };
    this.changeOnNodeList(newNd);
  }

  addNode() {
    const newNd: NodesResultEnhanced = {
      id: genUuid(),
      name: '',
      color: null,
    };
    this.changeOnNodeList(newNd);
  }

  private changeOnNodeList(newNd: NodesResultEnhanced) {
    // not advised to follow that guide here :P
    // was only attempted due to the node - link dep - handleAffectedLinksByName
    const nodeList = [...this._sankeyData().nodes];
    const idxExisting = nodeList.findIndex((nd) => nd.id === newNd.id);
    const oldNd = nodeList[idxExisting];
    if (Object.keys(newNd).length === 1) {
      newNd = undefined;
    } else {
      newNd = {
        ...oldNd,
        ...newNd,
      };
    }
    nodeList.splice(
      this.determinePosition(idxExisting),
      this.determineQtyToDlt(oldNd),
      newNd,
    );
    this._sankeyData.update((d) => ({
      links: this.handleAffectedLinksByName(d.links, newNd?.name, oldNd?.name),
      nodes: nodeList.filter((d) => d),
    }));
  }

  private enhanceDataWith(d: SankeyResultEnhanced): SankeyResultEnhanced {
    d?.links?.forEach((link) => this.enrichWithId(link));
    d?.nodes?.forEach((nd) => {
      this.enrichWithId(nd);
      this.enrichWithColor(nd);
    });
    return d;
  }

  private handleAffectedLinksByName(
    links: LinksResultEnhanced[],
    newName: string,
    oldName: string,
  ): LinksResultEnhanced[] {
    if (!oldName || newName === oldName) return links;
    return links.map((link) => {
      if (link.source === oldName) {
        link.source = newName;
      }
      if (link.target === oldName) {
        link.target = newName;
      }
      return link;
    });
  }

  private determinePosition(idxExisting: number): number {
    return idxExisting === -1 ? 0 : idxExisting;
  }

  private determineQtyToDlt(oldNd: any): number {
    return oldNd ? 1 : 0;
  }

  private enrichWithId(dt: any): void {
    if (dt.id) return;
    dt['id'] = genUuid();
  }

  private enrichWithColor(dt: any): void {
    if (dt.color) return;
    dt['color'] = null;
  }
}
