import moment from 'moment';
import { TraditionalDispatchAdapter } from 'prosumer-app/+scenario/containers/simulation-results/dispatch/adapters';
import {
  DAY_FORMAT,
  HOUR_FORMAT,
  MONTH_FORMAT,
} from 'prosumer-app/app.references';
import { PipeUtils } from 'prosumer-core/utils';
import { BehaviorSubject, Observable } from 'rxjs';

import { coerceNumberProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ResultsPerceptionService } from '@prosumer/results/components/case-results-perception';
import { VisualizerData } from '@prosumer/results/components/results-visualizer';

import {
  CompareDispatchChartData,
  CompareDispatchData,
} from '../compare-dispatch.model';

@Component({
  selector: 'prosumer-compare-dispatch-raw',
  templateUrl: './raw.component.html',
  styleUrls: ['./raw.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompareDispatchRawComponent
  extends TraditionalDispatchAdapter
  implements OnInit
{
  chartData: Array<any>;
  chartDataSubject = new BehaviorSubject<VisualizerData[]>([]);
  rawData: CompareDispatchData;

  @Input() customColors: any;
  @Input() set data(rawData: CompareDispatchData) {
    this.rawData = rawData;
    if (!!this.rawData) {
      this.chartData = this.mapToChartData(this.rawData);
      this.chartDataSubject.next(this.chartData);
    }
  }

  constructor(
    private _translate: TranslateService,
    perception: ResultsPerceptionService,
  ) {
    super(perception);
  }

  ngOnInit(): void {
    this.initializeChartDataAndColors();
  }

  /**
   * Maps the compare raw data to a chart compatible data
   *
   * @param rawData - the raw data
   */
  mapToChartData(
    rawData: CompareDispatchData,
  ): Array<CompareDispatchChartData> {
    return (rawData.dataList || []).map((data) => {
      const dataSet = data.values.map((value, index) => ({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        hour: moment({
          y: +coerceNumberProperty(data.year),
          M: 0,
          d: 1,
          h: 0,
          m: 0,
          s: 0,
          ms: 0,
        })
          .hour(index)
          .get('h'),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        name: moment({
          y: +coerceNumberProperty(data.year),
          M: 0,
          d: 1,
          h: 0,
          m: 0,
          s: 0,
          ms: 0,
        })
          .hour(index + (rawData.start - 1) * 24)
          .toString(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        moment: moment({
          y: +coerceNumberProperty(data.year),
          M: 0,
          d: 1,
          h: 0,
          m: 0,
          s: 0,
          ms: 0,
        }).hour(index + (rawData.start - 1) * 24),
        value,
      }));
      return {
        name: data.equipment,
        series: dataSet ? dataSet : undefined,
      };
    });
  }

  formatTick(tick: string): string {
    return this.chartXAxisFormat(tick);
  }

  /**
   * Helper to interpret moment data in X-Axis to a more readable human info
   *
   * @param value - moment data
   */
  chartXAxisFormat(value: any) {
    return moment(value, 'ddd MMM DD YYYY HH:mm:ss Z').format(
      `${MONTH_FORMAT} ${DAY_FORMAT} ${HOUR_FORMAT}`,
    );
  }

  getChartName(): string {
    return `Raw Dispatch (${this.rawData.name})`;
  }

  getNgxChartsDataStream(): Observable<VisualizerData[]> {
    return this.chartDataSubject.asObservable().pipe(PipeUtils.filterOutEmpty);
  }

  formatXAxisTickLabel = (value: any): string => value;

  formatTooltipTitle = (obj: any) =>
    obj
      ? `${this._translate.instant('Result.labels.hour')} ${(obj || {}).name}`
      : 0;
}
