import { Injectable } from '@angular/core';
import { VariationFinder } from 'prosumer-app/+scenario/services';
import { NameValue } from 'prosumer-app/stores';
import { EquipmentInfo, EquipmentQuery } from 'prosumer-app/stores/equipment';
import {
  Regulation,
  RegulationQuery,
  RegulationStore,
} from 'prosumer-app/stores/regulation';
import { Observable } from 'rxjs';
import { MeteringDialogData } from './metering-dialog/metering-dialog-model';

export type OptsForMeteringDialogData = Pick<
  MeteringDialogData,
  'nettingOptions' | 'technologyOptions' | 'existingMeterings$'
>;

@Injectable({
  providedIn: 'root',
})
export class RegulationFormService {
  constructor(
    private readonly regulations: RegulationQuery,
    private readonly equipments: EquipmentQuery,
    private readonly varFinder: VariationFinder,
    private readonly regulationStore: RegulationStore,
  ) {}

  deleteMetering(id: string): Observable<unknown> {
    return this.regulationStore.deleteOne(id);
  }

  selectRegulations(): Observable<Regulation[]> {
    return this.regulations.selectAllForActiveScenario();
  }

  prepForDialog(): OptsForMeteringDialogData {
    return {
      existingMeterings$: this.selectRegulations(),
      nettingOptions: this.buildNettingOptions(),
      technologyOptions: this.getTechOptions(),
    };
  }

  private getTechOptions(): NameValue[] {
    return this.equipmentsToNameValues(
      this.injectVarNamesToEquips(this.equipments.getActiveEquipments()),
    );
  }

  private injectVarNamesToEquips(equips: EquipmentInfo[]): EquipmentInfo[] {
    return equips.map((equip) => this.injectVariationNameToEquipment(equip));
  }

  private injectVariationNameToEquipment(equip: EquipmentInfo): EquipmentInfo {
    return { ...equip, name: this.concatenateVarNameToEquipmentName(equip) };
  }

  private concatenateVarNameToEquipmentName(equip: EquipmentInfo): string {
    return `${equip.name} (${this.varFinder.getVariationName(
      equip.scenarioVariation,
    )})`;
  }

  private equipmentsToNameValues(equipments: EquipmentInfo[]): NameValue[] {
    return equipments.map((equipment) => this.equipmentToNameValue(equipment));
  }

  private equipmentToNameValue(equipment: EquipmentInfo): NameValue {
    return { name: equipment.name, value: equipment.id };
  }

  private buildNettingOptions(): NameValue[] {
    return [
      { name: 'Instantaneous', value: 'instantaneous' },
      { name: 'Separate', value: 'separate' },
      { name: 'Yearly', value: 'yearly' },
    ];
  }
}
